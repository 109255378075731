import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { FetchData, pushArray } from "../../CommonFunctions";
import { ErrorAPI500, ErrorAPI404 } from "../../components/Errors/Errors";
import { Modal, ModalBody, ModalHeader } from "../../components/Modal/Modal";
import Typography from "../../components/Typography/Typography";
import { fetchPortfolios } from "./ClientsFunction";
import Button from "../../components/Button/Button";
import ConfirmationPopup from "../../components/PopUP/ConfirmPopUp";
import ClientProjectsSharedModal from "./ClientProjectsSharedModal";
import { PaginationComponent } from "../../components/Pagination/Pagination";
import CustomLoader from "../../components/Loader/CustomLoader";
import ClientEmpty from "./ClientEmpty";

type Props = {
  onClose: () => void;
  client_id: number;
  client: any;
};

class ClientPortfolioSharedModal extends React.Component {
  static async fire(id: number, client: any) {
    const container = document.createElement("div");
    container.id = "custom-shared-portfoloi-container";
    const root = createRoot(container);
    document.body.appendChild(container);

    const close = () => {
      root.unmount();
      container.remove();
      return false;
    };

    root.render(
      <ModalComponent onClose={close} client_id={id} client={client} />
    );
  }

  render() {
    return null;
  }
}

const ModalComponent: React.FC<Props> = ({ onClose, client_id, client }) => {
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [isLoadMore, setIsLoadMore] = useState<Boolean>(false);
  const [portfolios, setPortfolios] = useState<FetchData>();
  const [page, setPage] = useState<number>(1);
  const counter = 2;

  const ShowCount = (count: number) => {
    return count - counter;
  };

  useEffect(() => {
    if (portfolios !== undefined) {
      setIsLoading(false);
    }

    setIsLoadMore(false);
  }, [portfolios]);

  useEffect(() => {
    loadData();
  }, [page]);

  const loadPortfolios = async (id: number) => {
    return await fetchPortfolios(id, page);
  };

  const loadData = async () => {
    setIsLoadMore(true);

    const data: FetchData = await loadPortfolios(client_id);

    if (data.status === "error" && data.error === "abort") {
      return false;
    }

    if (portfolios !== undefined && page > 1 && data.status === "success") {
      let log = portfolios["data"];
      for (const i in data["data"]) {
        log = pushArray(log, data["data"][i]);
      }
      setPortfolios({
        ...portfolios,
        data: log,
      });
    } else if (page === 1) {
      setPortfolios(data);
    }
  };

  const loadmoreData = () => {
    setPage(page + 1);
  };

  const FavoriteProjects = (id: number, client_id: number, portfolio: any) => {
    ClientProjectsSharedModal.fire(id, client_id, portfolio);
  };

  return (
    <Modal isOpen onClose={onClose} size="medium" placement="right">
      <ModalHeader className="bg-gray-200">
        <div className="flex justify-between items-center w-full pe-12">
          <div>
            <Typography className="font-bold" variant="h3">
              {client.name}
            </Typography>
            <Typography
              className="text-gray-700 font-medium"
              size="lg"
              variant="p"
            >
              {client.company_name}
            </Typography>
          </div>
          <div className="flex justify-start items-center text-gray-300 font-normal text-400 -mt-1">
            <PaginationComponent handlePagination={setPage} page={Number(portfolios?.meta?.current_page??0)} totalPages={Number(portfolios?.meta?.last_page)}/>
            
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="!px-0 !pt-0">
        {isLoading ? (
          <div className="flex justify-center items-center w-full h-[calc(100%-5rem)]">
          <CustomLoader/>
        </div>
          // <div className="">
          //   <table className="w-full">
          //     <thead className="sticky left-0 top-0 z-10">
          //       <tr>
          //         <th className="bg-gray-400 px-5 py-3 text-gray-700 text-300 font-bold text-left ps-8 uppercase">
          //           Shared Portfolio
          //         </th>
          //         <th className="bg-gray-400 px-5 py-3 text-gray-700 text-300 font-bold text-left uppercase">
          //           Shared On
          //         </th>
          //         <th className="bg-gray-400 px-5 py-3 text-gray-700 text-300 font-bold text-left w-2/3 uppercase">
          //           Favorite Projects
          //         </th>
          //       </tr>
          //     </thead>
          //     <tbody>
          //       {Array.from({ length: 10 }).map((idx: any) => (
          //         <tr key={idx}>
          //           <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300 ps-8">
          //             <div key={idx} className="animate-pulse">
          //               <div className="flex-1 space-y-6">
          //                 <div className="h-5 bg-gray-100 rounded" />
          //               </div>
          //             </div>
          //           </td>
          //           <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300">
          //             <div key={idx} className="animate-pulse">
          //               <div className="flex-1 space-y-6">
          //                 <div className="h-5 bg-gray-100 rounded" />
          //               </div>
          //             </div>
          //           </td>
          //           <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300">
          //             <div key={idx} className="animate-pulse">
          //               <div className="flex-1 space-y-6">
          //                 <div className="h-5 w-1/2 bg-gray-100 rounded" />
          //               </div>
          //             </div>
          //           </td>
          //         </tr>
          //       ))}
          //     </tbody>
          //   </table>
          // </div>
        ) : portfolios !== undefined && portfolios["status"] === "success"  ? (
          <div className="">
            <table className="w-full">
              <thead className="sticky left-0 top-0 bg-gray-200 z-10">
                <tr>
                  <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase ps-8">
                    Shared Portfolio
                  </th>
                  <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase">
                    Shared On
                  </th>
                  <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase pe-8 w-2/3">
                    Favorite Projects
                  </th>
                </tr>
              </thead>
              <tbody>
                {portfolios["data"].data.map((item: any, idx: any) => (
                  <tr key={idx} className="group">
                    <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200 ps-8">
                      {item.portfolio_title}
                    </td>
                    <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                      {item.created_at}
                    </td>
                    <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200 pe-8">
                      {item.projects.length > 0 ? (
                        <ul
                          className="inline-block cursor-pointer"
                          onClick={() => {
                            FavoriteProjects(
                              item.portfolio_id,
                              client_id,
                              item
                            );
                          }}
                        >
                          {item.projects.map((projects: any, id: any) =>
                            id + 1 <= counter ? (
                              <li key={id} className="inline-block font-medium">
                                {projects}
                                {item.projects.length > id + 1 ? (
                                  <>&#x2c;&nbsp;</>
                                ) : (
                                  ""
                                )}
                              </li>
                            ) : (
                              ""
                            )
                          )}
                          {item.projects.length > counter ? (
                            <li className="inline-block font-bold text-blue-100 border-b border-blue-100">
                              +{ShowCount(item.projects.length)}
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="border-0" colSpan={3}>
                    <div className="flex w-100 justify-center text-center items-center align-middle mt-5">
                      {isLoadMore ? (
                        <div className="flex mt-2">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="duration-200 animate-pulse-3"
                          >
                            <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                          </svg>
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="duration-300 animate-pulse-2"
                          >
                            <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                          </svg>
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="duration-400 animate-pulse"
                          >
                            <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                          </svg>
                        </div>
                      ) : page < portfolios.data.meta.last_page ? (
                        <button
                          className="text-200 font-bold text-blue-500 border-b inline-block"
                          onClick={() => {
                            loadmoreData();
                          }}
                        >
                          <span className="">Load More</span>
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        // ) : portfolios !== undefined &&
        //   portfolios["status"] === "error" &&
        //   portfolios["error"] === 404 ? (
        //   <ErrorAPI404 />
        ) : (
          // <ErrorAPI500 />
          <div className="flex justify-center items-center w-full h-[calc(100%-5rem)]">
                <ClientEmpty/>
              </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ClientPortfolioSharedModal;
