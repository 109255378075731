import React from "react";
import { useNavigate } from "react-router-dom";
import Typography from "../../components/Typography/Typography";
import CreateProjectModal from "../projects/CreateProjectModal";
import productOneImage from "../../images/download.svg";
import styled from 'styled-components';
import Icons from "../../components/Icons/Icon";

export const ImgOverlay = styled.div`
  .block-area{
    position:relative;
    transition: 0.3s
  }
  .overLay{
    position:absolute;
    bottom: 0;
    left:0;
    height: 100%;
    width:100%;
    background: rgba(0,0,0,0.2);
    -webkit-transition: -webkit-transform 0.35s;
    transition: all .3s ease;
    display:none;
  }
  .block-area:hover .overLay{
    display:block;
  }
`;

const SingleProjectDraft = (props: any) => {
  const project = props.project;
  const navigate = useNavigate();

  const CreateProject = (id: number) => {
    CreateProjectModal.fire(id, navigate);
  };

  return (
    <div className="">
      <ImgOverlay className="opacity-70">
        <div className="block-area h-[12.25rem] w-full overflow-hidden flex justify-center items-center rounded-md relative hover:duration-700">
          {project.cover !== "" ? (
            <img
              src={project.cover}
              alt={project.project_title}
              className="w-full h-full object-cover"
            />
          ) : (
            <img
              src={productOneImage}
              alt={project.project_title}
              className="w-full h-full object-cover"
            />
          )}
          <div className="absolute left-3 top-3 h-8 w-8 flex justify-center items-center bg-white rounded-full"><span><Icons className="mt-1" name="draft-projects" size={16}/></span></div>
          <div className="overLay px-5 py-5">
              <div className="h-full w-full flex justify-start items-end">
                <div>
                  <Typography
                    className="text-white font-normal mt-1.5"
                    size="md"
                    variant="p"
                  >
                    {project.project_title}
                  </Typography>
                  <ul className="inline-block mt-1">
                    {project.tags !== "" && project.tags !== null
                    ? project.tags.split(",").map((item: any, idx: any) => (
                        <li key={idx} className="inline-block me-1 last:me-0">
                          <a
                            href="#"
                            className="text-200 font-light text-white bg-gray-700 px-2 py-0.5 rounded-full truncate max-w-[4.5rem] inline-block"
                          >
                            {item}
                          </a>
                        </li>
                      ))
                    : ""}
                  </ul>
                  <Typography
                    className="text-white font-light mt-1.5 line-clamp-2"
                    size="sm"
                    variant="p"
                  >
                    {project.project_description}
                  </Typography>
                  <Typography
                    className="text-white font-normal mt-1.5"
                    size="sm"
                    variant="p"
                  >
                    {project.added_by}
                  </Typography>
                  <Typography
                    className="text-white font-normal mt-1.5"
                    size="sm"
                    variant="p"
                  >
                    {project.created_at}
                  </Typography>
                </div>
              </div>
            </div>
        </div>
      </ImgOverlay>
      <div className="flex justify-between items-start gap-2 mt-2">
        <div>
          <Typography variant="h6" className="text-gray-300 font-semibold line-clamp-1">
            {project.project_title}
          </Typography>
        </div>
        <div>
          <button onClick={()=>{CreateProject(project.id)}}>
            <svg width="18" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.2601 3.59997L5.0501 12.29C4.7401 12.62 4.4401 13.27 4.3801 13.72L4.0101 16.96C3.8801 18.13 4.7201 18.93 5.8801 18.73L9.1001 18.18C9.5501 18.1 10.1801 17.77 10.4901 17.43L18.7001 8.73997C20.1201 7.23997 20.7601 5.52997 18.5501 3.43997C16.3501 1.36997 14.6801 2.09997 13.2601 3.59997Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M11.8899 5.05005C12.3199 7.81005 14.5599 9.92005 17.3399 10.2" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M3 22H21" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleProjectDraft;
