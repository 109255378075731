import React from "react";
import Select from "react-select";

export interface DropdownOption {
  value: string;
  label: string;
}

export interface DropdownProps {
  options: DropdownOption[];
  onSelect: (value: string) => void;
  className?: string;
  value?: string;
  defaultValue?: string;
  background?: boolean;
}

const ActionDropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  className,
  value,
  defaultValue,
  background,
}) => {
  const handleSelect = (selectedOption: DropdownOption | null) => {
    const value = selectedOption ? selectedOption.value : "";
    onSelect(value);
  };

  const selectedOption = options.find(
    (option) => option.value === defaultValue,
  );

  const customSingleValue = () => (
    <div
      className={`input-select pb-4 px-4 rounded-md cursor-pointer ${
        background ? "bg-gray-200" : ""
      }`}
    >
      <svg
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12" cy="6" r="2" fill="#FFFFFF" />
        <circle cx="12" cy="12" r="2" fill="#FFFFFF" />
        <circle cx="12" cy="18" r="2" fill="#FFFFFF" />
      </svg>
    </div>
  );

  return (
    <div className={`flex justify-end actiondropdown h-6`}>
      <div
        className={`w-auto inline-flex ms-auto ${className ? className : ""}`}
      >
        <Select
          options={options}
          onChange={handleSelect}
          value={selectedOption}
          unstyled
          classNames={{
            input: () => "p-0",
            control: (
              state,
            ) => `bg-primary-100 rounded-md p-0 border-0 border-gray-100 text-300 text-gray-300 !min-h-0 !h-0 !inline-block [&>.css-1qpkzkj-ValueContainer]:inline-block [&>.css-14oxtc6]:inline-block
          ${state.isFocused ? "" : ""} ${state.menuIsOpen ? "" : ""}`,
            placeholder: () => "text-gray-500 p-0",
            dropdownIndicator: () => "text-gray-500 p-0",
            menu: () =>
              "bg-white text-300 font-medium border border-gray-100 rounded-md rounded-t-0 mt-1 shadow-100 min-w-[6rem] right-0",
            option: ({
              isFocused,
              isSelected,
            }) => `px-3 py-2 text-200 relative text-gray-300 hover:cursor-pointer hover:bg-gray-200 hover:text-gray-300 first:rounded-t-md last:rounded-b-md
          ${
            isSelected
              ? "text-200 bg-gray-200 text-gray-300 hover:bg-gray-200 hover:text-gray-300 active:bg-gray-200 active:text-gray-500 after:ml-2 after:absolute after:right-2 after:content-[''] first:rounded-t-md last:rounded-b-md"
              : ""
          }
          ${isFocused ? "bg-gray-200 text-gray-300 hover:bg-gray-200" : ""} 
          `,
            noOptionsMessage: () =>
              "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm",
          }}
          isSearchable={false}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            SingleValue: customSingleValue,
          }}
          // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, }}
        />
      </div>
    </div>
  );
};

export default ActionDropdown;
