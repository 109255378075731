import React, { useState, useEffect, useRef } from "react";
import { FetchData, unsetArray } from "../../CommonFunctions";
import { Dropdown } from "../../components/DropDown/DropDown";
import SearchLink from "./SearchLink";
import AccordionTwo from "../../components/Accordion/AccordionTwo";
import Checkbox from "../../components/CheckBox/CheckBox";
import { CustomDateTimePicker } from "../../components/DateTimePicker/DatePicker";
import CustomFilters, {
  Item as FilterItem,
} from "../../components/Filters/customFilters";
import Typography from "../../components/Typography/Typography";
import { fetchPublickLinkFilters } from "./LinkManagementFunction";
import moment from "moment";
import { PaginationComponent } from "../../components/Pagination/Pagination";

const LinkManagementTopbar = (prop: any) => {
  const params = prop.params;
  const setParams = prop.events.setParams;
  const setIsLoading = prop.setIsLoading;
  const setSearchInpData = prop.events.setSearchInpData;
  const bulckExpire = prop.events.bulckExpire;
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [minDate, setMinDate] = useState<string | null>(null);
  const [isMinDateError, setIsMinDateError] = useState<Boolean>(false);
  const [expiryStartDate, setExpiryStartDate] = useState<string | null>(null);
  const [expiryEndDate, setExpiryEndDate] = useState<string | null>(null);
  const [expiryMinDate, setExpiryMinDate] = useState<string | null>(null);
  const [isExpiryMinDateError, setIsExpiryMinDateError] =
    useState<Boolean>(false);
  const [accessedStartDate, setAccessedStartDate] = useState<string | null>(
    null
  );
  const [accessedEndDate, setAccessedEndDate] = useState<string | null>(null);
  const [accessedMinDate, setAccessedMinDate] = useState<string | null>(null);
  const [isAccessedMinDateError, setIsAccessedMinDateError] =
    useState<Boolean>(false);
  const [isNotHavePassword, setIsNotHavePassword] = useState<boolean>(false);
  const [isNotHaveExpiry, setIsNotHaveExpiry] = useState<boolean>(false);
  const [isFilterShow, setIsFilterShow] = useState<boolean>(false);
  const [isLoadFilter, setLoadFilter] = useState<Boolean>(true);
  const [isClearFilter, setIsClearFilter] = useState<Boolean>(false);
  const [filters, setFilters] = useState<FetchData>();

  useEffect(() => {
    if (isLoadFilter === true) {
      // loadFilters();
    }
  }, [isLoadFilter]);

  useEffect(() => {
    setIsClearFilter(false);
  }, [params]);

  useEffect(() => {
    setMinDate(startDate);
    filterByDate();
  }, [startDate]);

  useEffect(() => {
    filterByDate();
  }, [endDate]);

  useEffect(() => {
    setExpiryMinDate(expiryStartDate);
    filterByExpiryDate();
  }, [expiryStartDate]);

  useEffect(() => {
    filterByExpiryDate();
  }, [expiryEndDate]);

  useEffect(() => {
    setAccessedMinDate(accessedStartDate);
    filterByAccessedDate();
  }, [accessedStartDate]);

  useEffect(() => {
    filterByAccessedDate();
  }, [accessedEndDate]);

  useEffect(() => {
    setIsLoading(true);
    setParams({
      ...params,
      page: 1,
      is_not_have_password: isNotHavePassword ? 1 : 0,
    });
  }, [isNotHavePassword]);

  useEffect(() => {
    setIsLoading(true);
    setParams({
      ...params,
      page: 1,
      is_not_have_expiry: isNotHaveExpiry ? 1 : 0,
    });
  }, [isNotHaveExpiry]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const loadFilters = async () => {
    setLoadFilter(false);
    setIsClearFilter(false);
    const data = await fetchPublickLinkFilters();
    setFilters(data);
  };

  const clearFilter = () => {
    setIsClearFilter(true);
    setIsLoading(true);
    setStartDate(null);
    setEndDate(null);
    setExpiryStartDate(null);
    setExpiryEndDate(null);
    setAccessedStartDate(null);
    setAccessedEndDate(null);
    setIsNotHavePassword(false);
    setIsNotHaveExpiry(false);
    setParams({
      ...params,
      page: 1,
      portfolios: [],
      users: [],
      status: [],
      start_date: null,
      end_date: null,
      expiry_start_date: null,
      expiry_end_date: null,
      accessed_start_date: null,
      accessed_end_date: null,
      is_not_have_password: 0,
      is_not_have_expiry: 0,
    });
  };

  const filterByDate = () => {
    setIsMinDateError(false);

    if (startDate === null && endDate === null) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        start_date: startDate,
        end_date: endDate,
      });
    }

    if (startDate === null || endDate === null) {
      return false;
    }

    const dateLimit = moment(startDate);
    const now = moment(endDate);

    if (now.isBefore(dateLimit)) {
      setIsMinDateError(true);
    } else if (startDate !== null && endDate !== null) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        start_date: startDate,
        end_date: endDate,
      });
    }
  };

  const filterByAccessedDate = () => {
    setIsAccessedMinDateError(false);

    if (accessedStartDate === null && accessedEndDate === null) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        accessed_start_date: accessedStartDate,
        accessed_end_date: accessedEndDate,
      });
    }

    if (accessedStartDate === null || accessedEndDate === null) {
      return false;
    }

    const dateLimit = moment(accessedStartDate);
    const now = moment(accessedEndDate);

    if (now.isBefore(dateLimit)) {
      setIsAccessedMinDateError(true);
    } else if (accessedStartDate !== null && accessedEndDate !== null) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        accessed_start_date: accessedStartDate,
        accessed_end_date: accessedEndDate,
      });
    }
  };

  const filterByExpiryDate = () => {
    setIsExpiryMinDateError(false);

    if (expiryStartDate === null && expiryEndDate === null) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        expiry_start_date: expiryStartDate,
        expiry_end_date: expiryEndDate,
      });
    }

    if (expiryStartDate === null || expiryEndDate === null) {
      return false;
    }

    const dateLimit = moment(expiryStartDate);
    const now = moment(expiryEndDate);

    if (now.isBefore(dateLimit)) {
      setIsExpiryMinDateError(true);
    } else if (expiryStartDate !== null && expiryEndDate !== null) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        expiry_start_date: expiryStartDate,
        expiry_end_date: expiryEndDate,
      });
    }
  };

  const setPortfoliosFilter = (checked: boolean, value: FilterItem) => {
    let portfolios = params["portfolios"];

    if (checked) {
      portfolios.push(value.id);
    } else {
      portfolios = unsetArray(portfolios, value.id);
    }

    setIsLoading(true);
    setParams({
      ...params,
      page: 1,
      portfolios: portfolios,
    });
  };

  const setUsersFilter = (checked: boolean, value: FilterItem) => {
    let users = params["users"];

    if (checked) {
      users.push(value.id);
    } else {
      users = unsetArray(users, value.id);
    }

    setIsLoading(true);
    setParams({
      ...params,
      page: 1,
      users: users,
    });
  };

  const setStatusFilter = (checked: boolean, value: FilterItem) => {
    let status = params["status"];

    if (checked) {
      status.push(value.id);
    } else {
      status = unsetArray(status, value.id);
    }

    setIsLoading(true);
    setParams({
      ...params,
      page: 1,
      status: status,
    });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsFilterShow(false);
    }
  };

  return (
    <div className="flex justify-between items-center bg-white shadow-300 py-5 px-5 ps-8 w-full rounded-t-md relative">
      <div className="flex justify-start items-center gap-3">
        <div className="flex justify-start items-center text-gray-300 font-normal text-400">
          <PaginationComponent handlePagination={prop.setPagination} page={Number(prop?.meta?.current_page??0)} totalPages={Number(prop?.meta?.last_page)}/>
        </div>
        {/* <div className="relative">
          <button onClick={()=>{setIsFilterShow(!isFilterShow)}} className="flex items-center gap-1 border border-gray-100 rounded-md px-4 py-2 bg-white">
            <label className="font-medium text-400 inline-block cursor-pointer">
              Filter
            </label>
            <span>
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.40002 2.09998H18.6C19.7 2.09998 20.6 2.99998 20.6 4.09998V6.29998C20.6 7.09998 20.1 8.09998 19.6 8.59998L15.3 12.4C14.7 12.9 14.3 13.9 14.3 14.7V19C14.3 19.6 13.9 20.4 13.4 20.7L12 21.6C10.7 22.4 8.90002 21.5 8.90002 19.9V14.6C8.90002 13.9 8.50002 13 8.10002 12.5L4.30002 8.49998C3.80002 7.99998 3.40002 7.09998 3.40002 6.49998V4.19998C3.40002 2.99998 4.30002 2.09998 5.40002 2.09998Z"
                  stroke="#232323"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.93 2.09998L6 9.99998"
                  stroke="#232323"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </button>
          {isFilterShow?
            <div ref={dropdownRef} className="absolute top-[46px] left-[0px] max-h-[26rem] w-[14rem] z-30 rounded-md bg-white border py-8 px-5 border-gray-100 shadow-100 overflow-auto">
              <div className="mb-6">
                <div className="flex gap-5 justify-between items-center">
                  <div className="text-200 font-bold text-gray-300">FILTER</div>
                  <button onClick={()=>{clearFilter()}} className="text-200 font-medium text-primary-100">
                    Clear All
                  </button>
                </div>
              </div>
              <>
                {!isClearFilter?(
                  <>
                    <div className="my-4">
                      <div className="flex justify-start items-start gap-2">
                        <Checkbox
                          checked={isNotHavePassword}
                          onCustomChange={(value)=>{setIsNotHavePassword(value)}}
                          size="sm"
                        />
                        <div className="text-gray-300 font-medium text-200">
                          Not Have Password
                        </div>
                      </div>
                      <div className="flex justify-start items-start gap-2 mt-3">
                        <Checkbox
                          checked={isNotHaveExpiry}
                          onCustomChange={(value)=>{setIsNotHaveExpiry(value)}}
                          size="sm"
                        />
                        <div className="text-gray-300 font-medium text-200">
                          Not Have Expiry Date
                        </div>
                      </div>
                    </div>
                    {filters !== undefined && filters["status"] === "success" ? (
                      <>
                        <AccordionTwo
                          count={2}
                          subTitle=""
                          time=""
                          title="Portfolio"
                        >
                          <div className="mt-4">
                            <CustomFilters selected={params["portfolios"]} filter={filters?.data.portfolios} type="key" isSearchBar={false} count={4} onChange={setPortfoliosFilter} />
                          </div>
                        </AccordionTwo>
                        <AccordionTwo
                          count={3}
                          subTitle=""
                          time=""
                          title="Generated By"
                        >
                          <div className="mt-4">
                            <CustomFilters selected={params["users"]} filter={filters?.data.users} type="key" isSearchBar={false} count={4} onChange={setUsersFilter} />
                          </div>
                        </AccordionTwo>
                        <AccordionTwo
                          count={4}
                          subTitle=""
                          time=""
                          title="Status"
                        >
                          <div className="mt-4">
                            <CustomFilters selected={params["status"]} filter={filters?.data.status} type="key" isSearchBar={false} count={4} onChange={setStatusFilter} />
                          </div>
                        </AccordionTwo>
                      </>
                    ):""}
                    <AccordionTwo count={5} subTitle="" time="" title="Generated On">
                      <div className="px-3 small-datepicker">
                        <div className="mt-3">
                          <label className="text-gray-700 font-normal text-300">
                            Start
                          </label>
                          <CustomDateTimePicker
                            size="sm"
                            dateFormat="YYYY-MM-DD"
                            onChange={(date:Date|null,value: string|null) => {
                              setStartDate(value);
                            }}
                            selectedDate={startDate}
                            maxDate={moment(new Date()).format("yyyy-MM-DD")}
                          />
                          {startDate === null && endDate !== null?(
                            <Typography
                              className="text-primary-100 font-normal"
                              variant="p"
                              size="sm"
                            >Please select start date</Typography>
                          ):""}
                        </div>
                        <div className="mt-3">
                          <label className="text-gray-700 font-normal text-300">End</label>
                          <CustomDateTimePicker
                            size="sm"
                            dateFormat="YYYY-MM-DD"
                            onChange={(date:Date|null,value: string|null) => {
                              setEndDate(value);
                            }}
                            selectedDate={endDate}
                            minDate={minDate !== null?minDate:null}
                            maxDate={moment(new Date()).format("yyyy-MM-DD")}
                          />
                          {startDate !== null && endDate === null?(
                            <Typography
                              className="text-primary-100 font-normal"
                              variant="p"
                              size="sm"
                            >Please select end date</Typography>
                          ):isMinDateError?(
                            <Typography
                              className="text-primary-100 font-normal"
                              variant="p"
                              size="sm"
                            >End date can not be less than start date</Typography>
                          ):""}
                        </div>
                      </div>
                    </AccordionTwo>
                    <AccordionTwo count={6} subTitle="" time="" title="Expiry">
                      <div className="px-3 small-datepicker">
                        <div className="mt-3">
                          <label className="text-gray-700 font-normal text-300">
                            Start
                          </label>
                          <CustomDateTimePicker
                            size="sm"
                            dateFormat="YYYY-MM-DD"
                            onChange={(date:Date|null,value: string|null) => {
                              setExpiryStartDate(value);
                            }}
                            selectedDate={expiryStartDate}
                          />
                          {expiryStartDate === null && expiryEndDate !== null?(
                            <Typography
                              className="text-primary-100 font-normal"
                              variant="p"
                              size="sm"
                            >Please select start date</Typography>
                          ):""}
                        </div>
                        <div className="mt-3">
                          <label className="text-gray-700 font-normal text-300">End</label>
                          <CustomDateTimePicker
                            size="sm"
                            dateFormat="YYYY-MM-DD"
                            onChange={(date:Date|null,value: string|null) => {
                              setExpiryEndDate(value);
                            }}
                            selectedDate={expiryEndDate}
                            minDate={expiryMinDate !== null?expiryMinDate:null}
                          />
                          {expiryStartDate !== null && expiryEndDate === null?(
                            <Typography
                              className="text-primary-100 font-normal"
                              variant="p"
                              size="sm"
                            >Please select end date</Typography>
                          ):isExpiryMinDateError?(
                            <Typography
                              className="text-primary-100 font-normal"
                              variant="p"
                              size="sm"
                            >End date can not be less than start date</Typography>
                          ):""}
                        </div>
                      </div>
                    </AccordionTwo>
                    <AccordionTwo count={1} subTitle="" time="" title="Accessed On">
                      <div className="px-3 small-datepicker">
                        <div className="mt-3">
                          <label className="text-gray-700 font-normal text-300">
                            Start
                          </label>
                          <CustomDateTimePicker
                            size="sm"
                            dateFormat="YYYY-MM-DD"
                            onChange={(date:Date|null,value: string|null) => {
                              setAccessedStartDate(value);
                            }}
                            selectedDate={accessedStartDate}
                            maxDate={moment(new Date()).format("yyyy-MM-DD")}
                          />
                          {accessedStartDate === null && accessedEndDate !== null?(
                            <Typography
                              className="text-primary-100 font-normal"
                              variant="p"
                              size="sm"
                            >Please select start date</Typography>
                          ):""}
                        </div>
                        <div className="mt-3">
                          <label className="text-gray-700 font-normal text-300">End</label>
                          <CustomDateTimePicker
                            size="sm"
                            dateFormat="YYYY-MM-DD"
                            onChange={(date:Date|null,value: string|null) => {
                              setAccessedEndDate(value);
                            }}
                            selectedDate={accessedEndDate}
                            minDate={accessedMinDate !== null?accessedMinDate:null}
                            maxDate={moment(new Date()).format("yyyy-MM-DD")}
                          />
                          {accessedStartDate !== null && accessedEndDate === null?(
                            <Typography
                              className="text-primary-100 font-normal"
                              variant="p"
                              size="sm"
                            >Please select end date</Typography>
                          ):isAccessedMinDateError?(
                            <Typography
                              className="text-primary-100 font-normal"
                              variant="p"
                              size="sm"
                            >End date can not be less than start date</Typography>
                          ):""}
                        </div>
                      </div>
                    </AccordionTwo>
                  </>
                ):""}
              </>
            </div>
          :""}
        </div>
        <div className="flex items-center gap-2">
          <label className="text-gray-300 font-medium text-400 inline-block">
            Sort By
          </label>
          <div className="w-[6.25rem] relative">
            <Dropdown
              onChange={(value:any)=>{
                setIsLoading(true);
                setParams({
                  ...params,
                  page:1,
                  sort_by: value["value"] === "recent"?["created_at", "DESC"]:["created_at", "ASC"]
                });
              }}
              options={[
                {
                  label: "Recent",
                  value: "recent",
                },
                {
                  label: "Oldest",
                  value: "name",
                },
              ]}
              type="smallBox"
              defaultValue={{ label: "Recent", value: "recent" }}
              isSearchable={false}
              menuPlacement="top"
            />
          </div>
        </div> */}
        {/* <div>
          <button onClick={bulckExpire} className="text-300 text-primary-100 font-semibold border-b border-primary-100">
            Expire Selected Link
          </button>
        </div> */}
      </div>
      <div className="flex justify-end items-center gap-4">
        <button
          onClick={bulckExpire}
          className="text-400 text-primary-100 font-bold"
        >
          {/* opacity-60 cursor-default */}
          Expire Selected Link
        </button>
        <div className="w-[18.125rem] font-light">
          <SearchLink setSearchInpData={setSearchInpData} />
        </div>
      </div>
    </div>
  );
};

export default LinkManagementTopbar;
