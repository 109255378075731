import React,{useState} from "react";
import { createRoot } from "react-dom/client";
import {
  updateProjectStatus
} from "../../CommonFunctions";
import {
  Modal,
  ModalBody,
  ModalFooter
} from "../../components/Modal/Modal";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import Spinner from "../../components/Spinner/Spinner";

type Props = {
  onClose: () => void;
  id:number;
  updateData:any;
};

class ApproveModalConfirm extends React.Component {
  static async fire(id:number,updateData:any) {
    const container = document.createElement("div");
    container.id = "custom-apporve-container"+id;
    const root = createRoot(container);
    document.body.appendChild(container);

    const close = () => {
      root.unmount();
      container.remove();
      return false;
    };

    root.render(
      <ModalComponent
        onClose={close}
        id={id}
        updateData={updateData}
      />,
    );
  }

  render() {
    return null;
  }
}

const ModalComponent: React.FC<Props> = ({ onClose,id,updateData }) => {
  const [isFromSubmiting, setIsFromSubmiting] = useState<boolean>(false);

  const approve = () => {
    updateProjectStatus(id,1,'',setIsFromSubmiting,updateData,onClose);
  }

  return (
    <Modal isOpen onClose={onClose} size="small" placement="center">
      <ModalBody className="!py-12">
        <div className="flex justify-start items-center gap-3">
            <div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 8V13" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11.9945 16H12.0035" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <Typography variant="h5" className="font-bold">
            Are you sure you want to approve?
            </Typography>
        </div>
      </ModalBody>
      <ModalFooter className="flex justify-end items-center bg-gray-200 gap-5">
      <Button
            label="No, cancel" variant="outline" size="large" 
            onClick={onClose}
        />
        <Button
            label={isFromSubmiting?<Spinner labal="Processing..." />:"Yes, I'm sure"} 
            variant="primary" 
            size="large"
            isDisabled={isFromSubmiting}
            onClick={()=>{approve()}}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ApproveModalConfirm;
