import React from "react";
import { useNavigate } from "react-router-dom";
import Typography from "../../components/Typography/Typography";
import ActionDropdown from "../../components/ActionDropdown/ActionDropdown";
import productOneImage from "../../images/download.svg";
import { updatePortfolioStatus } from "./PortfolioFunction";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { hasPermission } from "../../helpers/accessHelper";
import store from "../../store";
import { updateHeaderData } from "../../User";

export const ImgOverlay = styled.div`
  .block-area {
    position: relative;
    transition: 0.3s;
  }
  .overLay {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    -webkit-transition: -webkit-transform 0.35s;
    transition: all 0.3s ease;
    display: none;
  }
  .block-area:hover .overLay {
    display: block;
  }
`;

const SinglePortfolioArchive = (props: any) => {
  const portfolio = props.portfolio;
  const navigate = useNavigate();
  const loadData = props.loadData;

  const UnarchivePortfolio = async (id: number) => {
    await updatePortfolioStatus(id, 1, loadData,true,"status");
  };

  const handlePortfolioClick = () => {
    const data = {
      portfolio_title: portfolio.portfolio_title,
      navigate: navigate
    };
    store.dispatch(
      updateHeaderData(data),
    );
  };
  
  return (
    <div className="">
      <ImgOverlay>
        <Link
          to={"/portfolios/projects/"+portfolio.id}
          className="block-area h-[12.25rem] w-full overflow-hidden flex justify-center items-center rounded-md relative hover:duration-700"
          onClick={handlePortfolioClick}
       >
          {portfolio.cover !== "" ? (
            <img
              src={portfolio.cover}
              alt={portfolio.portfolio_title}
              className="w-full h-full object-cover relative block"
            />
          ) : (
            <img
              src={productOneImage}
              alt={portfolio.portfolio_title}
              className="w-full h-full object-cover relative block"
            />
          )}
          <div className="overLay px-3 py-3">
            <div className="h-full w-full flex justify-end items-end relative">
              <div className="h-full w-full flex justify-start items-end">
                <div className="w-full">
                  <Typography
                    className="text-white font-normal mt-1.5"
                    size="md"
                    variant="p"
                  >
                    {portfolio.portfolio_title}
                  </Typography>
                  <ul className="inline-block mt-1">
                    {portfolio.tags !== "" && portfolio.tags !== null
                    ? portfolio.tags.split(",").map((item: any, idx: any) => (
                        <li key={idx} className="inline-block me-1 last:me-0">
                          <a
                            href="#"
                            className="text-200 font-light text-white bg-gray-700 px-2 py-0.5 rounded-full truncate max-w-[4.5rem] inline-block"
                          >
                            {item}
                          </a>
                        </li>
                      ))
                    : ""}
                  </ul>
                  <Typography
                    className="text-white font-normal mt-1.5"
                    size="sm"
                    variant="p"
                  >
                    {portfolio.added_by}
                  </Typography>
                  <Typography
                    className="text-white font-normal mt-1.5"
                    size="sm"
                    variant="p"
                  >
                    {portfolio.created_at}
                  </Typography>
                </div>
              </div>
              {/* <div className="flex justify-end items-center absolute right-0 bottom-0 z-20 gap-3">
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="34"
                    viewBox="0 0 36 34"
                    fill="none"
                  >
                    <ellipse
                      cx="17.8321"
                      cy="17.2298"
                      rx="17.5733"
                      ry="16.638"
                      fill="white"
                    />
                    <path
                      d="M15.2123 14.1684L20.0474 12.6425C22.2172 11.9577 23.3961 13.0793 22.6785 15.1336L21.0668 19.7114C19.9848 22.7902 18.2079 22.7902 17.1259 19.7114L16.6475 18.3526L15.2123 17.8997C11.9605 16.8752 11.9605 15.1983 15.2123 14.1684Z"
                      stroke="#585757"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="36"
                    height="34"
                    viewBox="0 0 36 34"
                    fill="none"
                  >
                    <ellipse
                      cx="17.647"
                      cy="17.2298"
                      rx="17.5733"
                      ry="16.638"
                      fill="white"
                    />
                    <path
                      d="M21.9916 16.768C23.5781 16.768 24.2371 16.1903 23.6513 14.2954C23.2547 13.0187 22.0953 11.9211 20.7468 11.5455C18.7454 10.9909 18.1353 11.6149 18.1353 13.1169V14.7807C18.1353 16.1903 18.7454 16.768 19.9658 16.768H21.9916Z"
                      stroke="#585757"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M23.0164 18.3281C22.4489 21.0029 19.7397 22.944 16.6583 22.4702C14.3457 22.1178 12.4846 20.3558 12.1063 18.1663C11.6121 15.2604 13.6501 12.6954 16.463 12.1523"
                      stroke="#585757"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div> */}
              <div className="absolute left-0 top-0">
                <span className="inline-block text-100 font-normal px-2.5 py-1 rounded-full bg-blue-300 text-white">Archived</span>
              </div>
            </div>
          </div>
        </Link>
      </ImgOverlay>
      <div className="flex justify-between items-start gap-2 mt-2">
        <div>
          <Typography
            variant="h6"
            className="text-primary-100 font-semibold line-clamp-1"
          >
            {portfolio.portfolio_title}
          </Typography>
          {/* <Typography
            className="text-gray-300 font-bold mt-2.5"
            size="md"
            variant="p"
          >
            {portfolio.added_by}
          </Typography> */}
        </div>
        <div>

        {hasPermission(props.role, 'Portfolios', 'Update')&&
          <ActionDropdown
            defaultValue="Edit"
            onSelect={(value) => {
              if (value === "Edit") {
                navigate("/portfolios/show/" + portfolio.id);
              } else {
                UnarchivePortfolio(portfolio.id);
              }
            }}
            options={[
              {
                label: "Edit",
                value: "Edit",
              },
              {
                label: "Unarchive",
                value: "Unarchive",
              },
            ]}
            value=""
          />
        }
        </div>
      </div>
      {/* <ul className="inline-block">
        {portfolio.tags !== "" && portfolio.tags !== null
          ? portfolio.tags.split(",").map((item: any, idx: any) => (
              <li key={idx} className="inline-block mt-2 me-2">
                <a
                  href="#"
                  className="text-200 font-bold text-white bg-gray-700 px-4 py-1 rounded-full"
                >
                  {item}
                </a>
              </li>
            ))
          : ""}
      </ul> */}
    </div>
  );
};

export default SinglePortfolioArchive;
