import React from "react";
import clarieniLogo from "../../images/clarieniLogo.png";
import Icons from "../../components/Icons/Icon";
import Typography from "../../components/Typography/Typography";
import SupportExpiredModal from "./SupportExpiredModal";

const ErrorRevok = () => {
  return (
    <div className="min-h-screen w-full bg-gray-400">
      <div className="pt-[4.5rem] flex justify-center max-md:pt-8">
        <img src={clarieniLogo} alt={clarieniLogo} className="max-w-full max-md:w-[13.75rem] max-md:h-[3.125rem]" />
      </div>
      <div className="flex justify-center mt-[4.625rem]">
        <Icons
          name="lockBg"
          variant="bold"
          size={65}
          className="constant-tilt-shake"
        />
      </div>
      <div className="grid grid-cols-5 justify-center xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-1 sm:grid-cols-1 min-[320px]:grid-cols-1">
        <div></div>
        <div className="col-span-3 mx-20 max-md:mx-5">
          <div className="">
            <div className="flex justify-center text-center mt-2">
              <Typography variant="h3" className="text-gray-300 font-bold">
                Unauthorized
              </Typography>
            </div>
            <div className="flex justify-center text-center mt-2">
              <Typography
                size="xxl"
                variant="p"
                className="text-gray-500 font-normal"
              >
                You don’t have access to view this portfolio. Please contact to support for view the portfolio
              </Typography>
            </div>
            <div className="flex justify-center mt-7 pb-14">
              <a
                href="#"
                className="text-400 fonr-normal text-secondary-100 border-b border-secondary-100"
                onClick={()=>{
                  SupportExpiredModal.fire();
                }}
              >
                Need Support?
              </a>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default ErrorRevok;
