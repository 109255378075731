import React from "react";

import styled from "styled-components";

export const ImgGradient = styled.div`
  .pickgradient {
    background-size: cover;
    height: 13.313rem;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 8px 8px 0 0;
  }
  .pickgradient::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 8px 8px 0 0;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 100%
    );
  }
`;

const SingleProjectLoader = () => {
  return (
    <div className="bg-white rounded-lg overflow-hidden max-h-[24.375rem]">
      <ImgGradient>
        <div className="animate-pulse">
          <div className="flex-1 space-y-6">
            <div className="h-44 bg-gray-100 rounded" />
          </div>
        </div>
      </ImgGradient>
      <div className="pt-3 pb-6 px-6">
        <div className="animate-pulse">
          <div className="flex gap-3">
            <div className="h-3 w-1/2 bg-gray-100 rounded" />
          </div>
        </div>
        <div className="animate-pulse mt-7">
          <div className="flex gap-3">
            <div className="h-3 w-10 bg-gray-100 rounded" />
            <div className="h-3 w-10 bg-gray-100 rounded" />
          </div>
        </div>
        <div className="animate-pulse mt-7">
          <div className="flex-1 space-y-1">
            <div className="h-3 w-full bg-gray-100 rounded" />
            <div className="h-3 w-1/2 bg-gray-100 rounded" />
            <div className="h-3 w-full bg-gray-100 rounded" />
            <div className="h-3 w-1/2 bg-gray-100 rounded" />
            <div className="h-3 w-full bg-gray-100 rounded" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProjectLoader;
