import React from "react";
import { Modal, ModalBody, ModalFooter } from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";
import Typography from "../../components/Typography/Typography";

type Props = {
  onClose: () => void;
};

const ArchivedConfirmModal: React.FC<Props> = ({
    onClose
}) => {
  return (
    <Modal isOpen onClose={onClose} size="smallextra" placement="center" className="z-[9999]">
      <ModalBody className="!px-10 !py-10">
        <div className="w-[30.625rem] pb-12">
            <Typography variant="h3" className="font-bold text-gray-300">
                Are you sure you want to archive this account?
            </Typography>
            <Typography className="text-gray-700 font-normal mt-2"
                size="xxl"
                variant="p"
                >
                The data will remain intact and you can unarchive the account anytime.
            </Typography>
        </div>
      </ModalBody>
      <ModalFooter className="bg-gray-200">
        <div className="w-full flex justify-end items-end gap-3">
            <Button
                label="Cancel"
                onClick={onClose}
                variant="outline"
                size="large"
            />
            <Button
                label="Yes, I am sure"
                onClick={function noRefCheck(){}}
                variant="secondary"
                size="large"
            />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ArchivedConfirmModal;
