import React, { useEffect, useState } from "react";

export interface Tab {
  id: string;
  label: string;
  content: React.ReactNode;
}

export interface TabsProps {
  tabs: Tab[];
  className?: string;
  onClick: (tabId: string) => void;
  size: "sm" | "md" | "secondary"; // Added "secondary" size
  currentTab?: string,
  classNameNav?: string;
  classNamePane?: string;
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  className,
  onClick,
  size,
  currentTab,
  classNameNav,
  classNamePane,
}) => {
  const [activeTab, setActiveTab] = useState(currentTab ? currentTab : tabs[0]?.id);

  const handleTabClick = (id: string) => {
    setActiveTab(id);
    onClick(id);
  };

  useEffect(() => {
    if(currentTab) {
      handleTabClick(currentTab)
    }
  }, [currentTab]);

  return (
    <div
      className={` ${className ? className : ""} ${
        size === "md"
          ? ""
          : size === "secondary"
          ? "text-200 font-normal text-black"
          : "sm-size"
      }`}
    >
      <div
        className={`flex gap-5 text-gray-300 text-200 cssUseOnly overflow-x-auto ${
          size === "md" ? "text-400 gap-10" : ""
        } ${classNameNav ? classNameNav : ""} ${
          size === "secondary" ? "bg-white h-11 pt-4 flex justify-center gap-6 px-12" : ""
        } ${classNameNav ? classNameNav : ""} `}
      >
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`cursor-pointer font-medium tab cssUseOnlyAdd ${
              size === "md" ? "pb-5" : size === "secondary" ? "pb-6" : "pb-2"
            } ${
              activeTab === tab.id
                ? `active font-bold border-b-2 ${
                    size === "secondary"
                      ? "text-black border-secondary-100 !font-bold !border-b-[3px]"
                      : "text-primary-100 border-primary-100"
                  }`
                : ""
            }`}
            onClick={() => handleTabClick(tab.id)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div
        className={`${
          size === "md" ? "pt-5" : size === "secondary" ? "pt-0" : "pt-2"
        } ${classNamePane ? classNamePane : ""}`}
      >
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab-pane ${activeTab === tab.id ? "active" : ""}`}
          >
            {activeTab === tab.id && tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
