import React, { useEffect, useState } from "react";
import UserFilter from "./UserFilter";
import UserTopbar from "./UserTopbar";
import Checkbox from "../../components/CheckBox/CheckBox";
import userImage from "../../../src/images/Profile.png";
import UserEmpty from "./UserEmpty";

const UserManagement = () => {
  return (
    <div className="flex h-[calc(100vh-7.5rem)] py-2">
      <div className="w-full overflow-auto relative flex flex-col">
        <div className="flex justify-start items-center gap-8">
          <button className="text-400 font-nromal text-gray-300 pb-5">
            All (34)
          </button>
          <button className="text-400 font-nromal text-gray-300 pb-5">
            Admin (14)
          </button>
          <button className="text-400 text-gray-300 pb-5 font-bold border-b-2 border-primary-100">
            Sales Admin (20)
          </button>
        </div>
        <div className="flex flex-grow overflow-auto w-full border border-gray-100 rounded-md shadow-300 relative">
          <UserFilter />
          <div className="w-[calc(100%-3.75rem)] ms-[3.75rem] h-full bg-gray-200 rounded-e-md overflow-auto relative">
            <div className="flex flex-col h-full">
              <div className="">
                <UserTopbar />
              </div>
              <div className="flex flex-grow overflow-auto bg-white">
                <div className="w-full">
                  <table className="w-full min-w-[1160px]">
                    <thead className="sticky left-0 top-0 bg-gray-200 z-10">
                      <tr>
                        <th className="text-200 font-bold text-gray-700 px-5 py-[0.75rem] ps-8 text-start">
                          <Checkbox
                            checked
                            onChange={function noRefCheck() {}}
                            size="sm"
                          />
                        </th>
                        <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start">
                          NAME
                        </th>
                        <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start">
                          ROLE
                        </th>
                        <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start">
                          TITLE
                        </th>
                        <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start">
                          EMAIL ADDRESS
                        </th>
                        <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start">
                          PHONE
                        </th>
                        <th className="text-200 font-bold text-gray-700 px-5 py-4 pe-8 text-start">
                          STATUS
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="group">
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 ps-8 text-start border-b border-gray-400 group-hover:bg-gray-200">
                          <Checkbox
                            checked
                            onChange={function noRefCheck() {}}
                            size="sm"
                          />
                        </td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                          <div className="flex justify-start items-center gap-2">
                            <img
                              src={userImage}
                              alt="userImage"
                              className="h-8 w-8 rounded-full"
                            />
                            <div>Ronald Richards</div>
                          </div>
                        </td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">Sales Admin</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">UX Design</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">venkateshiyer@myoffice.com</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">+123 456 7890</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 pe-8 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">
                          <span className="inline-block py-1 px-2.5 bg-pastel-300 text-blue-100 text-200 font-medium rounded-full">Active</span>
                        </td>
                      </tr>
                      <tr className="group">
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 ps-8 text-start border-b border-gray-400 group-hover:bg-gray-200">
                          <Checkbox
                            checked
                            onChange={function noRefCheck() {}}
                            size="sm"
                          />
                        </td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                          <div className="flex justify-start items-center gap-2">
                            <img
                              src={userImage}
                              alt="userImage"
                              className="h-8 w-8 rounded-full"
                            />
                            <div>Ronald Richards</div>
                          </div>
                        </td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">Sales Admin</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">UX Design</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">venkateshiyer@myoffice.com</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">+123 456 7890</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 pe-8 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">
                          <span className="inline-block py-1 px-2.5 bg-gray-600 text-primary-100 text-200 font-medium rounded-full">Archived</span>
                        </td>
                      </tr>
                      <tr className="group">
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 ps-8 text-start border-b border-gray-400 group-hover:bg-gray-200">
                          <Checkbox
                            checked
                            onChange={function noRefCheck() {}}
                            size="sm"
                          />
                        </td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                          <div className="flex justify-start items-center gap-2">
                            <img
                              src={userImage}
                              alt="userImage"
                              className="h-8 w-8 rounded-full"
                            />
                            <div>Ronald Richards</div>
                          </div>
                        </td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">Sales Admin</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">UX Design</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">venkateshiyer@myoffice.com</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">+123 456 7890</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 pe-8 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">
                          <span className="inline-block py-1 px-2.5 bg-pastel-300 text-blue-100 text-200 font-medium rounded-full">Active</span>
                        </td>
                      </tr>
                      <tr className="group">
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 ps-8 text-start border-b border-gray-400 group-hover:bg-gray-200">
                          <Checkbox
                            checked
                            onChange={function noRefCheck() {}}
                            size="sm"
                          />
                        </td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                          <div className="flex justify-start items-center gap-2">
                            <img
                              src={userImage}
                              alt="userImage"
                              className="h-8 w-8 rounded-full"
                            />
                            <div>Ronald Richards</div>
                          </div>
                        </td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">Sales Admin</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">UX Design</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">venkateshiyer@myoffice.com</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">+123 456 7890</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 pe-8 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">
                          <span className="inline-block py-1 px-2.5 bg-pastel-300 text-blue-100 text-200 font-medium rounded-full">Active</span>
                        </td>
                      </tr>
                      <tr className="group">
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 ps-8 text-start border-b border-gray-400 group-hover:bg-gray-200">
                          <Checkbox
                            checked
                            onChange={function noRefCheck() {}}
                            size="sm"
                          />
                        </td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                          <div className="flex justify-start items-center gap-2">
                            <img
                              src={userImage}
                              alt="userImage"
                              className="h-8 w-8 rounded-full"
                            />
                            <div>Ronald Richards</div>
                          </div>
                        </td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">Sales Admin</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">UX Design</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">venkateshiyer@myoffice.com</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">+123 456 7890</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 pe-8 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">
                          <span className="inline-block py-1 px-2.5 bg-pastel-300 text-blue-100 text-200 font-medium rounded-full">Active</span>
                        </td>
                      </tr>
                      <tr className="group">
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 ps-8 text-start border-b border-gray-400 group-hover:bg-gray-200">
                          <Checkbox
                            checked
                            onChange={function noRefCheck() {}}
                            size="sm"
                          />
                        </td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                          <div className="flex justify-start items-center gap-2">
                            <img
                              src={userImage}
                              alt="userImage"
                              className="h-8 w-8 rounded-full"
                            />
                            <div>Ronald Richards</div>
                          </div>
                        </td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">Sales Admin</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">UX Design</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">venkateshiyer@myoffice.com</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">+123 456 7890</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 pe-8 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">
                          <span className="inline-block py-1 px-2.5 bg-pastel-300 text-blue-100 text-200 font-medium rounded-full">Active</span>
                        </td>
                      </tr>
                      <tr className="group">
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 ps-8 text-start border-b border-gray-400 group-hover:bg-gray-200">
                          <Checkbox
                            checked
                            onChange={function noRefCheck() {}}
                            size="sm"
                          />
                        </td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                          <div className="flex justify-start items-center gap-2">
                            <img
                              src={userImage}
                              alt="userImage"
                              className="h-8 w-8 rounded-full"
                            />
                            <div>Ronald Richards</div>
                          </div>
                        </td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">Sales Admin</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">UX Design</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">venkateshiyer@myoffice.com</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">+123 456 7890</td>
                        <td className="text-300 text-gray-300 font-normal px-5 py-4 pe-8 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">
                          <span className="inline-block py-1 px-2.5 bg-pastel-300 text-blue-100 text-200 font-medium rounded-full">Active</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                    {/* <div className="flex justify-center items-center h-[calc(100%-3rem)] w-full">
                      <UserEmpty/>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
