import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Typography from "../../components/Typography/Typography";
import product12 from "../../images/clientProject/p13.jpeg";
import styled from "styled-components";
import Icons from "../../components/Icons/Icon";
import LinkImage from "../../images/clientProject/links.png"
import docImage from "../../images/clientProject/docs.png"
import videoImage from "../../images/clientProject/videos.png"

export const ImgGradient = styled.div`
  .pickgradient {
    background-size: cover;
    height: 13.313rem;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 8px 8px 0 0;
  }
  .pickgradient::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 8px 8px 0 0;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 100%
    );
  }
`;

export const SingleProjectsFeatured = (props: any) => {
  const clientToken = useSelector((state: any) => state.clientToken);
  const asset = props.asset;
  const navigate = useNavigate();

  return (
    <div
      className="bg-white rounded-lg overflow-hidden h-full max-sm:h-auto cursor-pointer"
      onClick={(event) => {
        navigate("/client/project/" + asset.id);
      }}
    >
      {/* <Typography
        variant="p"
        size="sm"
        className="text-white font-normal line-clamp-1"
      >
        FEATURED
      </Typography> */}
      <ImgGradient>
        <div
          className="pickgradient relative"
          // style={{
          //   backgroundImage: `url(${
          //     asset.type === "Projects"
          //       ? asset.cover
          //         ? asset.cover
          //         : product12
          //       : asset.type === "Images"
          //       ? asset.url
          //       : product12
          //   })`,
          // }}
          style={{
            backgroundImage: `url(${
              asset.type === "Projects"
                ? asset.cover ? asset.cover : product12
                : asset.type === "Images"
                  ? asset.url
                  : asset.type === "Documents"
                    ? docImage
                    : asset.type === "Videos"
                      ? videoImage
                      : asset.type === "Links"
                        ? LinkImage
                        : product12  
            })`,
          }}
        >
          <div className="absolute left-3 top-3 z-10 hover: cursor-auto">
            <Icons name="bag" variant="stroke" size={24} />
          </div>
          {asset.type === "Projects" ? (
            <>
            <div className="absolute left-3 top-3 z-10 hover: cursor-auto">
            <Icons name="bag" variant="stroke" size={24} className={asset.is_featured === true ? '[&>svg>path]:stroke-primary-100' : '' }/>
            </div>
            <div className="absolute right-3 top-3 z-10">
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  props.events.updateFavorite(
                    asset.project_id,
                    asset.is_favorite
                  );
                }}
              >
                <svg
                  className={`${
                    asset.is_favorite ? "fill-primary-100" : "hover:fill-none"
                  } group`}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className={`${
                      asset.is_favorite
                        ? "stroke-primary-100"
                        : "group-hover:stroke-white"
                    }`}
                  />
                </svg>
              </button>
            </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </ImgGradient>
      <div className="bg-secondary-100 pt-3 pb-6 px-6">
        <Typography variant="h5" className="text-black font-bold line-clamp-2">
          {asset.type === "Projects" ? asset.project_title : asset.name}
        </Typography>

        <div className="mt-1 text-pastel-600 font-normal text-200 overflow-hidden inline-block w-full">
          {asset.tags !== "" && asset.tags !== null
            ? asset.tags.split(",").map((item: any, idx: any) => (
                <span key={idx} className="inline-block line-clamp-1 max-w-full break-words me-1.5">
                  <span className="max-w-full line-clamp-1">
                    {item}
                  </span>
                  {/* {idx !== 0 ? (
                    <span className="h-1 w-1 bg-primary-100 rounded-full mb-1"></span>
                  ) : (
                    ""
                  )} */}
                  
                </span>
              ))
            : ""}
            
        </div>
        <Typography
          variant="p"
          size="sm"
          className="text-pastel-700 font-normal line-clamp-4 mt-1.5 overflow-hidden"
        >
          {asset.project_description}
        </Typography>
      </div>
    </div>
  );
};
