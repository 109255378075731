import React, { useEffect, useState } from "react";
import SearchPortfolio from "./SearchPortfolio";
import { useParams } from "react-router-dom";
import { FetchData, convertParamsToArr, indexOf, showToastAlert } from "../../CommonFunctions";
import { confirmRevokeAccess, confirmrevokeAccessBulk, fetchInvitaions } from "./PortfolioFunction";
import EmptyPortfolios from "./EmptyPortfolios";
import Typography from "../../components/Typography/Typography";
import ClientProjectsSharedModal from "./ClientProjectsSharedModal";
import CheckBox2 from "../../components/CheckBox/CheckBox2";
import { PaginationComponent } from "../../components/Pagination/Pagination";
import CustomLoader from "../../components/Loader/CustomLoader";
import { useSelector } from "react-redux";
import { hasPermission } from "../../helpers/accessHelper";
import PortfolioEmpty from "./PortfolioEmpty";
import ShareInsightEmpty from "./ShareInsightEmpty";
import store from "../../store";
import { updateHeaderData } from "../../User";
import { useNavigate } from "react-router-dom";

const ShareInsights = () => {
  let paramsData = useParams();
  paramsData = convertParamsToArr({ params: paramsData });
  paramsData["sort_by"] = ["id", "DESC"];
  paramsData["search"] = paramsData[1] !== undefined && paramsData[1] !== "" ?"":"";
  paramsData["page"] = 1;

  const [isLoading, setIsLoading] = useState(true);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [portfoliosData, setPortfoliosData] = useState<FetchData>();
  const [params, setParams] = useState(paramsData);
  const [searchInp, setSearchInp] = useState<string | null>(null);
  const [publicLinkId, setPublicLinkId] = useState<number[]>([]);
  const [role, setRole] = useState<string>("");
  const User = useSelector((state: any) => state.user);
  const navigate = useNavigate();
  
  useEffect(()=>{
    setRole(User.role)
  }, [User])
  const counter = 2;

  useEffect(() => {
    setIsLoading(true);
    setIsAllSelected(false);
    setPublicLinkId([]);
    loadData();
  }, [params]);

  useEffect(() => {
    if (portfoliosData !== undefined) {
      setIsLoading(false);
    }
  }, [portfoliosData]);

  const loadData = async () => {
    const data:FetchData = await fetchInvitaions({params});
    if(data.status === "error" && data.error === "abort"){return false;}
    setPortfoliosData(data);
  };

  useEffect(() => {
    searchPortfolios();
  }, [searchInp]);

  const setSearchInpData = (e: any) => {
    setSearchInp(e.target.value);
  };

  const searchPortfolios = async () => {
    if (searchInp != null && (searchInp.length >= 1 || searchInp.length <= 0)) {
      setIsLoading(true);
      setParams({
        ...params,
        page:1,
        1: searchInp,
      });
    }
  };

  const bulckRevokeAccess = async () => {
    if(publicLinkId.length <=0){
      showToastAlert("Please select at least one link");
      return false;
    }
    await confirmrevokeAccessBulk(
      publicLinkId,
      setPublicLinkId,
      setPortfoliosData,
      portfoliosData
    );
  };

  const revokeAccess = async (id: number) => {
    await confirmRevokeAccess(id, setPortfoliosData, portfoliosData);
  };

  const FavoriteProjects = (id: number, client_id: number, portfolio: any) => {
    ClientProjectsSharedModal.fire(id, client_id, portfolio);
  };

  const ShowCount = (count: number) => {
    return count - counter;
  };

  const selectPublicLink = (id: number) => {
    if (!indexOf(publicLinkId, id)) {
      setPublicLinkId((oldArray) => [...oldArray, id]);
    } else {
      const newArray: number[] = [];

      for (let i = 0; i < publicLinkId.length; i++) {
        if (publicLinkId[i] !== id) {
          newArray.push(publicLinkId[i]);
        }
      }

      setPublicLinkId(newArray);
    }
  };

  const selectAllPublicLink = () => {
    const newArray: number[] = [];

    if (!isAllSelected) {
      for (const i in portfoliosData?.data) {
        // if(portfoliosData?.data[i].status){
        //   newArray.push(portfoliosData?.data[i].id);
        // }
        newArray.push(portfoliosData?.data[i].id);
      }
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
    
    setPublicLinkId(newArray);
  };

  const events = {
    loadData: loadData,
    setParams:setParams,
  };
  const setPagination=(page: number)=>{
    setParams({
      ...params,
      page:page,
    });
  }
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: '2-digit'
  };

  useEffect(() => {
    if (portfoliosData !== undefined) {
      setIsLoading(false);

      if(portfoliosData.status === "success"){
        const data = {
          portfolio_title:portfoliosData.data.portfolio_title,
          navigate:navigate
        }
        store.dispatch(
          updateHeaderData(data),
        );
      }
    }
  }, [portfoliosData]);

  return (
    <div className="my-2 relative h-[calc(100vh-8rem)] border border-gray-100 rounded-md shadow-300 bg-white">
      <div className="flex justify-between items-center bg-white py-5 px-5 sticky left-0 top-0 z-[1] w-full rounded-t-md">
          <div className="flex justify-start items-center text-gray-300 font-normal text-400">
            <PaginationComponent handlePagination={setPagination} page={Number(portfoliosData?.meta?.current_page??1)} totalPages={Number(portfoliosData?.meta?.last_page)}/>

          </div>
        
        <div className="flex justify-end items-center gap-4">

        {hasPermission(role, 'Portfolios', 'RevokeAccess')&&
          <button className="text-400 font-bold text-primary-100" onClick={() => bulckRevokeAccess()}>
            Revoke Access
          </button>
        }
          <div className="w-[18.125rem]">
            <SearchPortfolio setSearchInpData={setSearchInpData} type='Viewer' />
          </div>
        </div>
      </div>
      <div className="rounded-md h-[calc(100%-80px)] w-full overflow-auto">
        <table className="w-full min-w-[1160px]">
          <thead className="sticky left-0 top-0 bg-gray-200 z-10">
            <tr>
            {hasPermission(role, 'Portfolios', 'RevokeAccess')&&
              <th className="text-200 font-bold text-gray-700 px-5 py-4 ps-8 text-start">
                <CheckBox2
                  checked={isAllSelected}
                  onChange={selectAllPublicLink}
                  size="sm"
                />
              </th>
               }
              <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase">
                Viewer
              </th>
              <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase">
                Favorite Projects
              </th>
              <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase">
                Last accessed on
              </th>
              <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase">
                Shared on
              </th>
              <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase">
                Shared by
              </th>
              <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase">
              {hasPermission(role, 'Portfolios', 'RevokeAccess')&&
                "Action"
              }
              </th>
            
            </tr>
          </thead>
          <tbody>
         
            {isLoading?(
              <div className="flex justify-center items-center w-full h-[calc(100%-8rem)] absolute">
              <CustomLoader/>
            </div>
              // Array.from({ length: 8 }).map((_, idx) => (
              //   <tr key={idx} className="group">
              //     {Array.from({ length: 7 }).map((_, idx1) => (
              //       <td key={idx1} className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300 ps-8">
              //         <div key={idx} className="animate-pulse">
              //           <div className="flex-1 space-y-6">
              //             <div className="h-5 bg-gray-100 rounded" />
              //           </div>
              //         </div>
              //       </td>
              //     ))}
              //   </tr>
              // ))
            ):portfoliosData !== undefined && portfoliosData["status"] === "success" && portfoliosData.data.length > 0 ?(
              portfoliosData.data.map((item: any, idx: any) => {
                const formattedDate = item.last_access ? new Date(item.last_access).toLocaleDateString('en-US', options) : "-";
                return (
                <tr key={idx} className="group">
                  {hasPermission(role, 'Portfolios', 'RevokeAccess')&&
                  <td className="text-300 text-gray-300 font-normal px-5 py-4 ps-8 text-start border-b border-gray-400 group-hover:bg-gray-200">
                    <CheckBox2
                      checked={indexOf(publicLinkId, item.id)}
                      onChange={() => {
                        selectPublicLink(item.id);
                      }}
                      // disabled={item.status?false:true}
                      size="sm"
                    />
                  </td>
                  }
                  <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                    {item.name}
                  </td>
                  <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">
                    {item.projects.length > 0 ? (
                      <ul
                        className="inline-block cursor-pointer"
                        onClick={() => {
                          FavoriteProjects(
                            item.portfolio_id,
                            item.client_id,
                            item
                          );
                        }}
                      >
                        {item.projects.map((projects: any, id: any) =>
                          id + 1 <= counter ? (
                            <li key={id} className="inline-block me-0.5">
                              {projects}
                              {item.projects.length > id + 1 ? (
                                <>&#x2c;</>
                              ) : (
                                ""
                              )}
                            </li>
                          ) : (
                            ""
                          )
                        )}
                        {item.projects.length > counter ? (
                          <li className="inline-block text-blue-100 font-bold cursor-pointer me-0.5">
                            +{ShowCount(item.projects.length)}
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">
                    {/* {item.last_access ? new Date(item.last_access).toLocaleDateString() : "-"} */}
                    {formattedDate}
                  </td>
                  <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">
                    {item.created_at}
                  </td>
                  <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">
                    {item.shared_by}
                  </td>
                  <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start whitespace-nowrap border-b border-gray-400 group-hover:bg-gray-200">
                  {hasPermission(role, 'Portfolios', 'RevokeAccess')&&
                    <>
                    {item.status?
                      <button className="text-400 font-bold text-primary-100 border-b border-primary-100" onClick={() => revokeAccess(item.id)}>
                        Revoke Access
                      </button>
                    :
                      <span className="text-300 font-normal text-gray-300">Revoked</span>
                    }
                    </>}
                  </td>
                </tr>
               );
              })
            
            // ): portfoliosData !== undefined && portfoliosData["status"] === "error" &&
            //   portfoliosData["error"] === 404 ? (
            //     <tr className="group">
            //       <td colSpan={7}>
            //         <div className="flex justify-center items-center h-[calc(100vh-16.5rem)]">
            //           <EmptyPortfolios message="No invitaions shared" />
            //         </div>
            //       </td>
            //     </tr>
            ) : (
              <tr className="group">
                <td colSpan={7}>
                <div className="flex justify-center items-center w-full h-[calc(100vh-16.75rem)]">
                <ShareInsightEmpty/>
              </div>
                  {/* <div className="flex justify-center items-center h-[calc(100vh-16.5rem)]">
                    <Typography variant="h5">Something went wrong!</Typography>
                  </div> */}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ShareInsights;
