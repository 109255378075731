import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Formik, Form, ErrorMessage } from "formik";
import {
  FetchData,
  inputChangeHandler,
  pushArray,
  showToastAlert,
  unsetArray,
  unsetAssocciativeArray,
  array_search,
  indexOf
} from "../../CommonFunctions";
import { Modal } from "../../components/Modal/Modal";
import { ModalHeader } from "../../components/Modal/Modal";
import { ModalBody } from "../../components/Modal/Modal";
import { ModalFooter } from "../../components/Modal/Modal";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import TextInput from "../../components/TextInput/TextInput";
import Checkbox from "../../components/CheckBox/CheckBox";
import { DropDownTwo,SelectOption } from "../../components/DropDown/DropDownTwo";
import InputWithIcon from "../../components/InputWithIcon/InputWithIcon";
import Icons from "../../components/Icons/Icon";
import Spinner from "../../components/Spinner/Spinner";
import { CustomDateTimePicker } from "../../components/DateTimePicker/DatePicker";
import moment from 'moment';
import {
  fetchClients,
  sharePortfolio,
  generateLink,
  updatePortfolioPublicStatus,
  validationPasswordSchema,
  validationExpiryDateSchema,
  updatePassword,
  updateExpiryDate,
  getInternalUsers,
  saveClients,
} from "./PortfolioFunction";
import {
  InputFiled as ClientInputFiled,
  validationSchema as clientValidationSchema
} from "../clients/ClientsFunction";
import InvitedClients from "./InvitedClients";

type Props = {
  onClose: () => void;
  portfolio: any;
};

class InviteClientsModal extends React.Component {
  static async fire(portfolio: any) {
    const container = document.createElement("div");
    container.id = "custom-client-container";
    const root = createRoot(container);
    document.body.appendChild(container);

    const close = () => {
      root.unmount();
      container.remove();
      return false;
    };

    root.render(
      <ModalComponent onClose={close} portfolio={portfolio} />,
    );
  }

  render() {
    return null;
  }
}

const ModalComponent: React.FC<Props> = ({
  onClose,
  portfolio,
}) => {
  const [isFromSubmiting, setIsFromSubmiting] = useState(false);
  const [isShareed, setIsShareed] = useState(false);
  const [isShareing, setIsShareing] = useState(false);
  const [isPasswordFromSubmiting, setIsPasswordFromSubmiting] = useState(false);
  const [isExpiryDateFromSubmiting, setIsExpiryDateFromSubmiting] = useState(false);
  const [isLinkGenerate, setIsLinkGenerate] = useState(false);
  const [isUsersList, setIsUsersList] = useState(false);
  const [isClientList, setIsClientList] = useState(false);
  const [isLinkGenerating, setIsLinkGenerating] = useState(false);
  const [isDisabled, setIsDisabled] = useState(portfolio.is_public === 0?true:false);
  const [isShowAddClient, setIsShowAddClient] = useState(false);
  const [isShowPassword, setShowPassword] = useState(false);
  const [isShowExpiryDate, setShowExpiryDate] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [clients, setClients] = useState();
  const [users, setUsers] = useState();
  const [publicLink, setPublicLink] = useState<any | null>(null);
  const [selectedClients, setSelectedClients] = useState<any>([]);
  const [oldSelectedClients, setOldSelectedClients] = useState<any>([]);
  const [sharedClients, setSharedClients] = useState<any>([]);
  const [deletedClients, setDeletedClients] = useState<any>([]);
  const [notSelectedClients, setNotSelectedClients] = useState<any>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [oldSelectedUsers, setOldSelectedUsers] = useState<any>([]);
  const [sharedUsers, setSharedUsers] = useState<any>([]);
  const [deletedUsers, setDeletedUsers] = useState<any>([]);
  const [notSelectedUsers, setNotSelectedUsers] = useState<any>([]);
  const [clientValue, setClientValue] = useState<SelectOption | SelectOption[] | null>({ label: "Select Client", value: "" });
  const [userValue, setUserValue] = useState<SelectOption | SelectOption[] | null>({ label: "Select One or More User", value: "" });

  useEffect(()=>{
    if(isClientList === false){
      loadClient();
    }
  },[isClientList]);

  useEffect(()=>{
    if(isUsersList === false){
      getUsers();
    }
  },[isUsersList]);

  // useEffect(()=>{
  //   if(isLinkGenerate === false){
  //     generate();
  //   }
  // },[isLinkGenerate]);

  useEffect(()=>{
    if(sharedClients.length > 0 || sharedUsers.length > 0 || deletedClients.length > 0 || deletedUsers.length > 0){
      setIsShareed(true);
    }else{
      setIsShareed(false);
    }
  },[sharedClients,sharedUsers,deletedClients,deletedUsers]);

  const FormData: ClientInputFiled = {
    name: "",
    email: "",
    company_name:"",
    status: 1,
    is_converted: 0,
  };

  const addClient = async (id: any) => {
    const client = array_search(clients,'id',id);
    updateData(
      pushArray(selectedClients,client),
      unsetAssocciativeArray(notSelectedClients, "value", id),
      clients,
      pushArray(sharedClients,id),
      unsetArray(deletedClients, id)
    );
    setClientValue({ label: "Select Client", value: "" });
  };

  const addUser = async (id: any) => {
    const user = array_search(users,'id',id);
    updateUserData(
      pushArray(selectedUsers,user),
      unsetAssocciativeArray(notSelectedUsers, "value", id),
      users,
      pushArray(sharedUsers,user),
      unsetAssocciativeArray(deletedUsers,'id', id)
    );
    setUserValue({ label: "Select One or More User", value: "" });
  };

  const updateStatus = async (id: any,checked:boolean) => {
    await updatePortfolioPublicStatus(portfolio.id,checked,setIsDisabled);
  };

  const deleteClient = async (id: BigInteger,item:any) => {
    updateData(
      unsetAssocciativeArray(selectedClients, "id", id),
      pushArray(notSelectedClients, {
        label: item.name+" ["+item.email+"]",
        value: item.id
      }),
      clients,
      unsetArray(sharedClients, id),
      !indexOf(oldSelectedClients,id)?pushArray(deletedClients,id):deletedClients
    );
  };

  const deleteUser = async (id: BigInteger,item:any) => {
    updateUserData(
      unsetAssocciativeArray(selectedUsers, "id", id),
      pushArray(notSelectedUsers, {
        label: item.name+" ["+item.email+"]",
        value: item.email
      }),
      users,
      unsetAssocciativeArray(sharedUsers, "id",id),
      !indexOf(oldSelectedUsers,id)?pushArray(deletedUsers,item):deletedUsers
    );
  };

  const getUsers = async () => {
    const fetchData:FetchData = await getInternalUsers(portfolio.id);
    const data = fetchData["status"] === "success" ? fetchData["data"] : [];

    const options: any = [];
    const oldSelected: any = [];
    options[0] = { label: "Select One or More User", value: ""};
    let j = 1;
    for (const i in data["notSelectedClient"]) {
      oldSelected[i] = data["notSelectedClient"][i].id;
      options[j] = { label: data["notSelectedClient"][i].name+' ['+data["notSelectedClient"][i].email+']', value: data["notSelectedClient"][i].id };
      j++;
    }

    setUsers(data.clients);
    setSelectedUsers(data.selectedClient);
    setOldSelectedUsers(oldSelected);
    setNotSelectedUsers(options);
    setIsUsersList(true);
  };

  const loadClient = async () => {
    const fetchData:FetchData = await fetchClients(portfolio.id);
    const data = fetchData["status"] === "success" ? fetchData["data"] : [];

    const options: any = [];
    const oldSelected: any = [];
    options[0] = { label: "Select Client", value: ""};
    options[1] = { label: "Add New Client", value: "add_new"};
    let j = 2;
    for (const i in data["notSelectedClient"]) {
      oldSelected[i] = data["notSelectedClient"][i].id;
      options[j] = { label: data["notSelectedClient"][i].name+' ['+data["notSelectedClient"][i].email+']', value: data["notSelectedClient"][i].id };
      j++;
    }

    setClients(data.clients);
    setSelectedClients(data.selectedClient);
    setOldSelectedClients(oldSelected);
    setNotSelectedClients(options);
    setIsClientList(true);
  };

  const generate = async () => {
    setIsLinkGenerating(true);
    setIsCopied(false);
    await generateLink({
      portfolio_id: portfolio.id,
      setPublicLink:setPublicLink
    });
    setIsLinkGenerate(true);
    setIsLinkGenerating(false);
  };

  const updateData = (selected:any,notSelected:any,allClients:any,shared:any,deleted:any) => {
    setSelectedClients(selected);
    setNotSelectedClients(notSelected);
    setClients(allClients);
    setSharedClients(shared);
    setDeletedClients(deleted);
  }

  const updateUserData = (selected:any,notSelected:any,allUsers:any,shared:any,deleted:any) => {
    setSelectedUsers(selected);
    setNotSelectedUsers(notSelected);
    setUsers(allUsers);
    setSharedUsers(shared);
    setDeletedUsers(deleted);
  }

  const submitHandler = async (values: ClientInputFiled, { setErrors }: any) => {
    await saveClients({
      setIsFromSubmiting: setIsFromSubmiting,
      formData: values,
      setErrorMessage: setErrors,
      loadData: async (json:FetchData) => {
        if(json.status === "success"){
          setClients(pushArray(clients,json.data));
          setIsShowAddClient(false);
          await addClient(json.data.id);
        }
      },
    });
  };

  const submitPasswordHandler = async (values: any, { setErrors }: any) => {
    await updatePassword({
      portfolio_id:portfolio.id,
      setIsPasswordFromSubmiting: setIsPasswordFromSubmiting,
      id: publicLink !== null?publicLink.id:null,
      formData: values,
      setErrorMessage: setErrors,
      setShowPassword: setShowPassword,
      setPublicLink:setPublicLink
    });
  };

  const submitExpiryDateHandler = async (values: any, { setErrors }: any) => {
    await updateExpiryDate({
      portfolio_id:portfolio.id,
      setIsExpiryDateFromSubmiting: setIsExpiryDateFromSubmiting,
      id: publicLink !== null?publicLink.id:null,
      formData: values,
      setErrorMessage: setErrors,
      setShowExpiryDate: setShowExpiryDate,
      setPublicLink:setPublicLink
    });
  };

  const copyToClipBoard = async (copyMe:string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setIsCopied(true);
    } catch (err) {
      setIsCopied(false);
    }
  };

  const share = async () => {
    await sharePortfolio({
      portfolio_id:portfolio.id,
      setIsShareing:setIsShareing,
      sharedClients:sharedClients,
      deletedClients:deletedClients,
      sharedUsers:sharedUsers,
      deletedUsers:deletedUsers,
      onClose:onClose
    });
  }

  return (
    <Modal
      isOpen
      onClose={onClose}
      placement='right' 
      size='medium'
    >
      <ModalHeader>
        <Typography
          className="flex justify-start items-center font-medium"
          variant="h3"
        >
          <span className='text-gray-300 flex me-2 font-bold'>
            <span className='me-1'>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.96 6.17004C18.96 7.56004 20.34 9.77004 20.62 12.32" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.48999 12.37C3.74999 9.82997 5.10999 7.61997 7.08999 6.21997" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.19 20.9399C9.35 21.5299 10.67 21.8599 12.06 21.8599C13.4 21.8599 14.66 21.5599 15.79 21.0099" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.06 7.70001C13.5954 7.70001 14.84 6.45537 14.84 4.92001C14.84 3.38466 13.5954 2.14001 12.06 2.14001C10.5247 2.14001 9.28003 3.38466 9.28003 4.92001C9.28003 6.45537 10.5247 7.70001 12.06 7.70001Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4.82999 19.92C6.36534 19.92 7.60999 18.6753 7.60999 17.14C7.60999 15.6046 6.36534 14.36 4.82999 14.36C3.29464 14.36 2.04999 15.6046 2.04999 17.14C2.04999 18.6753 3.29464 19.92 4.82999 19.92Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M19.17 19.92C20.7054 19.92 21.95 18.6753 21.95 17.14C21.95 15.6046 20.7054 14.36 19.17 14.36C17.6347 14.36 16.39 15.6046 16.39 17.14C16.39 18.6753 17.6347 19.92 19.17 19.92Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </span>
            Share-
          </span>
          {portfolio.portfolio_title}
        </Typography>
      </ModalHeader>
      <ModalBody>
        {isClientList && isUsersList?(
          <>
            <div className='grid grid-cols-2'>
              <div className="w-[27.5rem]">
                <div className='flex items-center gap-2.5'>
                  <Checkbox
                    checked={isDisabled}
                    onCustomChange={(value) => {
                      updateStatus(portfolio.id,value);
                    }}
                  />
                  <label className='text-gray-300 font-medium text-400'>Private Portfolio</label>
                </div>
              </div>
            </div>
            {isShowAddClient?(
              <Formik
                initialValues={FormData}
                validationSchema={clientValidationSchema}
                onSubmit={submitHandler}
              >
                {({ setValues, values }) => (
                  <Form>
                    <div className='grid grid-cols-4 mt-7 gap-3'>
                      <div className="">
                        <label className='text-400 font-medium text-gray-300 block mb-2'>Client Name <span className="text-primary-100">*</span></label>
                        <TextInput
                          name="name"
                          placeholder="Client Name"
                          type="text"
                          disabled={isDisabled}
                          onChange={(event) => {
                            inputChangeHandler(event.target.value, {
                              setValues: setValues,
                              key: "name",
                              formData: values,
                            });
                          }}
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="error text-error text-200 mt-1"
                        />
                      </div>
                      <div className="">
                        <label className='text-400 font-medium text-gray-300 block mb-2'>Email Address <span className="text-primary-100">*</span></label>
                        <TextInput
                          name="email"
                          placeholder="Email Address"
                          type="text"
                          disabled={isDisabled}
                          onChange={(event) => {
                            inputChangeHandler(event.target.value, {
                              setValues: setValues,
                              key: "email",
                              formData: values,
                            });
                          }}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error text-error text-200 mt-1"
                        />
                      </div>
                      <div>
                        <label className='text-400 font-medium text-gray-300 block mb-2'>Company Name <span className="text-primary-100">*</span></label>
                        <TextInput
                          name="company_name"
                          placeholder="Company Name"
                          type="text"
                          disabled={isDisabled}
                          onChange={(event) => {
                            inputChangeHandler(event.target.value, {
                              setValues: setValues,
                              key: "company_name",
                              formData: values,
                            });
                          }}
                        />
                        <ErrorMessage
                          name="company_name"
                          component="div"
                          className="error text-error text-300 mt-1"
                        />
                      </div>
                      <div className="flex gap-2.5 justify-end">
                        <div className="mt-7">
                          <Button
                            type="submit"
                            label={
                              isFromSubmiting ? (
                                <Spinner labal="Processing..." />
                              ) : (
                                "Save"
                              )
                            }
                            isDisabled={isDisabled?isDisabled:isFromSubmiting}
                            variant="primary"
                            size="large"
                            className="!py-[11px]"
                          />
                        </div>
                        <div className="mt-7">
                          <Button
                            type="button"
                            label="Cancel"
                            onClick={()=>{setIsShowAddClient(false)}}
                            variant="outline"
                            size="large"
                            className="!py-[10px]"
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            ):(
              <div className='grid grid-cols-2'>
                <div className='mt-7'>
                  <div className="w-[27.5rem]">
                    <label className='text-400 font-medium text-gray-300 block mb-2'>Named Client</label>
                    <DropDownTwo
                      disabled={isDisabled}
                      placeholder='Select Client'
                      options={notSelectedClients !== undefined?notSelectedClients:[]}
                      value={clientValue}
                      type='box'
                      onChange={(
                        value: SelectOption | SelectOption[] | null
                      ) => {
                        setClientValue(value);
                        if(value !== null && !Array.isArray(value) && value.value === "add_new"){
                          setIsShowAddClient(true);
                        }else if(value !== null && !Array.isArray(value) && value.value !== ""){
                          addClient(value.value);
                        }
                      }}
                    />
                  </div>
                  {!isDisabled?
                    <div className='mt-2'>
                      <InvitedClients 
                        InvitedClients={selectedClients!==undefined?selectedClients:[]}
                        count={3}
                        onDelete={deleteClient}
                      />
                    </div>
                  :""}
                </div>
              </div>
            )}
            <div className='grid grid-cols-2'>
              <div>
                <div className='mt-7 w-[27.5rem]'>
                  <div className="w-full">
                    <label className='text-400 font-medium text-gray-300 block mb-2'>Specific User</label>
                    <DropDownTwo
                      disabled={isDisabled}
                      placeholder='Select One or More User'
                      options={notSelectedUsers !== undefined?notSelectedUsers:[]}
                      value={userValue}
                      type='box'
                      onChange={(
                        value: SelectOption | SelectOption[] | null
                      ) => {
                        setUserValue(value);
                        if(value !== null && !Array.isArray(value) && value.value !== ""){
                          addUser(value.value);
                        }
                      }}
                    />
                  </div>
                  {!isDisabled?
                    <div className='mt-3 w-full'>
                      <InvitedClients 
                        InvitedClients={selectedUsers!== undefined?selectedUsers:[]}
                        count={3}
                        onDelete={deleteUser}
                      />
                    </div>
                  :""}
                </div>
              </div>
            </div>
            {isLinkGenerate?(
              <div className='mt-5'>
                <label className='text-400 font-medium text-gray-300 block mb-2'>Private Portfolio</label>
                <div className='flex justify-between items-center gap-10'>
                  <div className='flex items-center gap-4'>
                    <div className='relative w-[27.5rem]'>
                      <InputWithIcon className='cursor-pointer'
                        icon={<span onClick={()=>{
                          if(!isDisabled){
                            copyToClipBoard(publicLink !== null?publicLink.url:"");
                          }
                        }}>
                          {isCopied?
                            <Icons className="mt-1"
                              name="tick-circle"
                              variant="stroke"
                              size={20}
                            />
                          :
                            <Icons className="mt-1"
                              name="duplicate"
                              variant="stroke"
                              size={20}
                            />
                          }
                        </span>}
                        onChange={function noRefCheck() { }}
                        onClick={()=>{
                          if(!isDisabled){
                            copyToClipBoard(publicLink !== null?publicLink.url:"");
                          }
                        }}
                        position="after"
                        value={publicLink !== null?publicLink.url:null}
                        readOnly={true}
                        disabled={isDisabled}
                      />
                    </div>
                    {/*<button onClick={generate} disabled={isDisabled}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" 
                        className={isLinkGenerating?"animate-spin":""}>
                        <path d="M22 12C22 17.52 17.52 22 12 22C6.48 22 3.11 16.44 3.11 16.44M3.11 
                        16.44H7.63M3.11 16.44V21.44M2 12C2 6.48 6.44 2 12 2C18.67 2 22 7.56 22 7.56M22 
                        7.56V2.56M22 7.56H17.56" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </button>*/}
                  </div>
                  <div className='flex items-center gap-3'>
                    {!isShowPassword && !isShowExpiryDate?(
                      <>
                        <Button
                          type="button"
                          label={publicLink.password !== null && publicLink.password !== ""?"Edit Password":"Set Password"}
                          isDisabled={isDisabled}
                          variant="primary"
                          size="medium"
                          onClick={()=>{setShowPassword(true)}}
                        />
                        {publicLink.has_token_expiry_date !== null && publicLink.has_token_expiry_date !== ""?(
                          <div className="flex justify-start items-center">
                            <div>
                              <Typography className="text-seconday-200 font-semibold" variant="h6">
                                {publicLink.has_token_expiry_date_formated}
                              </Typography>
                            </div>
                            <div className="">
                              <Button
                                type="button"
                                label="Edit Expiry Date"
                                variant="outline"
                                size="medium"
                                onClick={() => {setShowExpiryDate(true)}}
                              />
                              {/* <button className=""
                                onClick={() => {setShowExpiryDate(true)}}
                              >
                                <span>
                                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                  <path d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                  <path d="M3 22H21" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                  </svg>
                                </span>
                              </button> */}
                            </div>
                          </div>
                        ):(
                          <Button
                            type="button"
                            label="Set Expiry Date"
                            isDisabled={isDisabled}
                            variant="outline"
                            size="medium"
                            onClick={()=>{setShowExpiryDate(true)}}
                          />
                        )}
                      </>
                    ):isShowPassword?(
                      <Formik
                        initialValues={{
                          password:publicLink !== null?publicLink.password:null
                        }}
                        validationSchema={validationPasswordSchema}
                        onSubmit={submitPasswordHandler}
                      >
                        {({ setValues, values }) => (
                          <Form>
                            <div className='flex gap-3'>
                              <div>
                                <TextInput
                                  name="password"
                                  onChange={(event) => {
                                    inputChangeHandler(event.target.value, {
                                      setValues: setValues,
                                      key: "password",
                                      formData: values,
                                    });
                                  }}
                                  placeholder="Enter Password"
                                  type="password"
                                  icon={true}
                                  value={values.password}
                                  disabled={isDisabled}
                                />
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className="error text-error text-300 mt-1"
                                />
                              </div>
                              <div className="flex gap-2.5">
                                <div>
                                  <Button
                                    type="submit"
                                    label={
                                      isPasswordFromSubmiting ? (
                                        <Spinner labal="Saving..." />
                                      ) : (
                                        "Save"
                                      )
                                    }
                                    isDisabled={isDisabled?isDisabled:isPasswordFromSubmiting}
                                    variant="primary"
                                    size="large"
                                    className="!py-[11px]"
                                  />
                                </div>
                                <div>
                                  <Button
                                    type="button"
                                    label="Cancel"
                                    onClick={()=>{setShowPassword(false)}}
                                    variant="outline"
                                    size="large"
                                    className="!py-[10px]"
                                  />
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    ):isShowExpiryDate?(
                      <Formik
                        initialValues={{
                          expiry_date:publicLink !== null?publicLink.has_token_expiry_date:null
                        }}
                        validationSchema={validationExpiryDateSchema}
                        onSubmit={submitExpiryDateHandler}
                      >
                        {({ setValues, values }) => (
                          <Form>
                            <div className='flex gap-3'>
                              <div>
                                <CustomDateTimePicker
                                  dateFormat="YYYY-MM-DD"
                                  minDate={moment(new Date()).format("yyyy-MM-DD")}
                                  onChange={(date:Date|null,value: string|null) => {
                                    inputChangeHandler(value, {
                                      setValues: setValues,
                                      key: "expiry_date",
                                      formData: values,
                                    });
                                  }}
                                  selectedDate={publicLink !== null?publicLink.has_token_expiry_date:null}
                                  disabled={isDisabled}
                                />
                                <ErrorMessage
                                  name="expiry_date"
                                  component="div"
                                  className="error text-error text-300 mt-1"
                                />
                              </div>
                              <div className="flex gap-2.5">
                                <div>
                                  <Button
                                    type="submit"
                                    label={
                                      isExpiryDateFromSubmiting ? (
                                        <Spinner labal="Saving..." />
                                      ) : (
                                        "Save"
                                      )
                                    }
                                    isDisabled={isDisabled?isDisabled:isExpiryDateFromSubmiting}
                                    variant="primary"
                                    size="large"
                                    className="!py-[11px]"
                                  />
                                </div>
                                <div>
                                  <Button
                                    type="button"
                                    label="Cancel"
                                    onClick={()=>{setShowExpiryDate(false)}}
                                    variant="outline"
                                    size="large"
                                    className="!py-[10px]"
                                  />
                                </div>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    ):""}
                  </div>
                </div>
              </div>
            ):(
              <div className='mt-7'>
                <Button
                  type="button"
                  label={isLinkGenerating?<Spinner labal="Generating..." />:"Generate Public Link"}
                  isDisabled={isDisabled}
                  variant="primary"
                  size="medium"
                  onClick={generate} 
                />
              </div>
            )}
          </>
        ):(
          <>
            <div className='grid grid-cols-2'>
              <div className="animate-pulse">
                <div className="flex-1 space-y-6">
                  <div className="h-5 w-40 bg-gray-100 rounded" />
                </div>
              </div>
            </div>
            <div className='grid grid-cols-2 mt-10'>
              <div className="animate-pulse">
                <div className="flex-1 space-y-6">
                  <div className="h-10 bg-gray-100 rounded" />
                </div>
                <div className="flex-1 space-y-6 mt-7">
                  <div className="h-10 w-3/4 bg-gray-100 rounded" />
                </div>
              </div>
            </div>
            <div className='grid grid-cols-2 mt-10'>
              <div className="animate-pulse">
                <div className="flex-1 space-y-6">
                  <div className="h-10 bg-gray-100 rounded" />
                </div>
                <div className="flex-1 space-y-6 mt-7">
                  <div className="h-10 w-3/4 bg-gray-100 rounded" />
                </div>
              </div>
            </div>
            <div className='grid grid-cols-2 mt-10'>
              <div className="animate-pulse">
                <div className="flex-1 space-y-6">
                  <div className="h-10 bg-gray-100 rounded" />
                </div>
              </div>
            </div>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <div className="flex justify-start gap-5">
          {isClientList && isUsersList?(
            <>
              <Button
                type="button"
                onClick={share}
                label={
                  isShareing ? (
                    <Spinner labal="Processing..." />
                  ) : (
                    "Share"
                  )
                }
                isDisabled={isDisabled?isDisabled:!isShareed?!isShareed:isShareing}
                variant="primary"
                size="large"
              />
              <Button
                type="button"
                label="Cancel"
                onClick={onClose}
                variant="outline"
                size="large"
              />
            </>
          ):(
            <div className="animate-pulse">
              <div className="flex gap-5">
                <div className="h-14 w-40 bg-gray-100 rounded" />
                <div className="h-14 w-40 bg-gray-100 rounded" />
              </div>
            </div>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default InviteClientsModal;
