import React, { useState, KeyboardEvent, ChangeEvent } from "react";
import TextInput from "../TextInput/TextInput";
import {indexOf} from "../../CommonFunctions";

export type TagInputProps = {
  onTagsChange: (tags: string[],error?:string) => void;
  value: [];
  placeholder?: string;
  disabled?:boolean;
  max?:number | null;
  name?:string | null;
};

const TagInput: React.FC<TagInputProps> = ({
  onTagsChange,
  placeholder,
  value,
  disabled,
  max = null,
  name = ""
}) => {
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState<string[]>(value);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      event.preventDefault();

      if(max!==null && tags.length >= max){
        setInputValue("");
        onTagsChange(tags,"Only "+max+" "+name+" allowed");
        return false;
      }

      const newTag = inputValue.trim().toLowerCase();

      if(indexOf(tags,newTag)){
        setInputValue("");
        return false;
      }

      const updatedTags = [...tags, newTag];
      setTags(updatedTags);
      onTagsChange(updatedTags,"");
      setInputValue("");
    }
  };

  const handleInputBlur = (event: ChangeEvent<HTMLInputElement>) => {
    if (inputValue.trim() !== "") {
      event.preventDefault();

      if(max!==null && tags.length >= max){
        setInputValue("");
        onTagsChange(tags,"Only "+max+" "+name+" allowed");
        return false;
      }
      
      const newTag = inputValue.trim().toLowerCase();

      if(indexOf(tags,newTag)){
        setInputValue("");
        return false;
      }
      
      const updatedTags = [...tags, newTag];
      setTags(updatedTags);
      onTagsChange(updatedTags,"");
      setInputValue("");
    }
  };

  const handleTagRemove = (tagToRemove: string) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
    onTagsChange(updatedTags,"");
  };

  return (
    <div className={`gap-2 w-full border border-gray-100 px-4 min-h-[2.875rem] py-1.5 text-500 focus-within:border-secondary-100 focus-within:shadow-200
      font-normal rounded-md text-gray-300 flex flex-wrap focus:border-secondary-100 
      focus:shadow-200 ${disabled?"bg-gray-200":"bg-white"}`}>
      {tags.map((tag,idx) => (
        <div
          key={idx}
          className="tag-label px-2 py-[0.375rem] bg-gray-400 rounded-md flex gap-1.5 items-center"
        >
          <span className="text-400 text-gray-300 max-w-[6.25rem] whitespace-nowrap overflow-ellipsis overflow-hidden">
            {tag}
          </span>
          <button type="button" className="tag-remove" onClick={() => handleTagRemove(tag)}>
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.96816 2.09283C9.26106 1.79994 9.26106 1.32506 8.96816 1.03217C8.67527 0.739277 8.2004 0.739277 7.9075 1.03217L5.00017 3.93951L2.09283 1.03217C1.79994 0.739277 1.32506 0.739277 1.03217 1.03217C0.739277 1.32506 0.739277 1.79994 1.03217 2.09283L3.93951 5.00017L1.03217 7.9075C0.739277 8.2004 0.739277 8.67527 1.03217 8.96816C1.32506 9.26105 1.79994 9.26105 2.09283 8.96816L5.00017 6.06083L7.9075 8.96816C8.2004 9.26105 8.67527 9.26105 8.96816 8.96816C9.26106 8.67527 9.26106 8.2004 8.96816 7.9075L6.06083 5.00017L8.96816 2.09283Z"
                fill="#888888"
              />
            </svg>
          </button>
        </div>
      ))}
      <TextInput
        value={inputValue}
        onChange={handleInputChange}
        onKeyPress={handleInputKeyPress}
        onBlur={handleInputBlur}
        placeholder={placeholder}
        disabled={disabled}
        className="border-0 focus:!shadow-0 !p-0"
        containerClassName="self-center flex-grow min-w-min"
      />
    </div>
  );
};

export default TagInput;
