import React, { useState } from "react";
import Checkbox from "../../components/CheckBox/CheckBox";
import { Dropdown } from "../../components/DropDown/DropDown";
import Button from "../../components/Button/Button";
import CreateCategoryModal from "./CreateCategoryModal";
import SearchCategory from "./SearchCategory";
import { PaginationComponent } from "../../components/Pagination/Pagination";

const CategoriesTopbar = (prop: any) => {
  const params = prop.params;
  const setParams = prop.events.setParams;
  const setIsLoading = prop.setIsLoading;
  const setSearchInpData = prop.events.setSearchInpData;

  const CreateCategory = () => {
    CreateCategoryModal.fire(0, prop.events.loadData);
  };

  return (
    <div className="flex justify-between items-center bg-white shadow-300 py-4 px-5 ps-8 sticky left-0 top-0 z-[1] w-full rounded-tr-md">
      <div className="flex justify-start items-center gap-4">
        {/* <button
          className="flex items-center gap-2.5 border border-gray-100 rounded-md px-4 py-2 group"
          onClick={() => {
            setIsLoading(true);
            setParams({
              ...params,
              page:1,
              sort_by:
                params["sort_by"] !== undefined &&
                params["sort_by"][1] === "DESC"
                  ? ["id", "ASC"]
                  : ["id", "DESC"],
            });
          }}
        >
          <label
            className={`font-medium text-400 inline-block group-hover:text-blue-300
                  ${
                    params["sort_by"][1] === "DESC"
                      ? "text-blue-300"
                      : "text-gray-300"
                  }
                `}
          >
            Recent
          </label>
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className={`group-hover:stroke-blue-300 
                      ${
                        params["sort_by"][1] === "DESC"
                          ? "stroke-blue-300"
                          : "stroke-gray-300"
                      }`}
                d="M3 7H21"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                className={`group-hover:stroke-blue-300 
                      ${
                        params["sort_by"][1] === "DESC"
                          ? "stroke-blue-300"
                          : "stroke-gray-300"
                      }`}
                d="M6 12H18"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                className={`group-hover:stroke-blue-300 
                      ${
                        params["sort_by"][1] === "DESC"
                          ? "stroke-blue-300"
                          : "stroke-gray-300"
                      }`}
                d="M10 17H14"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </span>
        </button> */}
        {/* <div className="flex items-center gap-2">
          <label className="text-gray-300 font-medium text-400 inline-block">
            Sort By
          </label>
          <div className="w-[6.25rem]">
            <Dropdown
              onChange={(value:any)=>{
                setIsLoading(true);
                setParams({
                  ...params,
                  page:1,
                  sort_by: value["value"] === "recent"?["created_at", "DESC"]:["created_at", "ASC"]
                });
              }}
              options={[
                {
                  label: "Recent",
                  value: "recent",
                },
                {
                  label: "Oldest",
                  value: "name",
                }
              ]}
              type="smallBox"
              defaultValue={{label:"Recent","value":"recent"}}
              isSearchable={false}
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <label className="text-gray-300 font-medium text-400">
            Show Inactive
          </label>
          <Checkbox
            checked={params["is_inactive"]? true : false}
            onCustomChange={(value) => {
              setIsLoading(true);
              const is_inactive = value ? 1 : 0;
              setParams({
                ...params,
                page:1,
                is_inactive: is_inactive,
              });
            }}
          />
        </div> */}
        <div className="flex justify-start items-center text-gray-300 font-normal text-400">
          <PaginationComponent handlePagination={prop.setPagination} page={Number(prop?.meta?.current_page??0)} totalPages={Number(prop?.meta?.last_page)}/>
        </div>
      </div>
      <div className="flex justify-end gap-4 items-center">
        <div className="w-[18rem]">
          <SearchCategory setSearchInpData={setSearchInpData}/>
        </div>
        <Button
          label="Add New Service"
          onClick={() => CreateCategory()}
          size="medium"
          variant="primary"
        />
      </div>
    </div>
  );
};

export default CategoriesTopbar;
