import React, { useState } from "react";
import SideNavLogoOpen from "../../images/SideNavLogoOpen.svg";
import Icons from "../Icons/Icon";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const EchoPulseMenuActive = styled.a`
  .active {
    background: #ffffff29;
  }
`;
interface NavItemProps {
  icon: string; // Allow any string value for icon
  isActive: boolean;
  label: string;
  link: string;
  isOpen:boolean;
  onClick: () => void;
}

const NavItem: React.FC<NavItemProps> = ({
  icon,
  isActive,
  label,
  link,
  isOpen = false,
  onClick,
}) => {
  return (
    <div className={isActive ? "" : ""}>
      <EchoPulseMenuActive>
      <NavLink
        to={link ?? ""}
        onClick={onClick}
        // className={`cursor-pointer flex gap-3 items-center rounded-md w-full min-w-[0.625rem] px-[0.625rem] hover:bg-white hover:bg-opacity-5 ${
        //   isActive ? "!bg-white !bg-opacity-20" : ""
        // }`}
        className={`cursor-pointer flex gap-3 items-center rounded-md w-full min-w-[0.625rem] px-[0.625rem] hover:bg-white hover:bg-opacity-5
          }`}
      >
        {/* {icon && (
          <span className="h-10 inline-flex items-center justify-center">
            <Icons
              name={icon as any} // Use "as any" to bypass type checking for specific icon names
              variant="sidebar"
              color={isActive ? "#fff" : "#D9D9D9"}
              size={20}
            />
          </span>
        )}
        {label && (
          <span
            className={`whitespace-nowrap overflow-hidden text-ellipsis ${isOpen?`inline-block`:`hidden group-hover:inline-block`} ${
              isActive ? "text-white" : ""
            }`}
          >
            {label}
          </span>
        )} */}
         {icon && (
            <span className="h-10 inline-flex items-center justify-center">
              <Icons
                name={icon as any}
                variant="sidebar"
                color={isActive ? "#fff" : "#D9D9D9"}
                size={20}
              />
            </span>
          )}
          {label && (
            <span
              className={` group-hover:inline-block whitespace-nowrap overflow-hidden text-ellipsis ${
                isActive ? "text-white" : ""
              }`}
            >
              {label}
            </span>
          )}
      </NavLink>
      </EchoPulseMenuActive>
    </div>
  );
};

export interface MenuProps {
  navigationItems: { icon: string; label: string; link: string }[];
  isActive: string;
  isOpen: boolean;
  setIsOpen: any;
}
const Menu: React.FC<MenuProps> = ({
  navigationItems,
  isActive = "Dashboard",
  isOpen = false,
  setIsOpen = "",
}) => {
  const [activeItem, setActiveItem] = useState<string>(isActive);

  const handleItemClick = (label: string) => {
    setActiveItem(label);
  };
  return (
    <div className="fixed h-full z-[999] group top-0 left-0">
      <div
        className={`z-50 h-full flex flex-col py-5 
         bg-gray-300 text-gray-100 text-400 transition-all ease-in-out duration-200 
           group ${isOpen?`w-60`:`fixed top-0 left-0 bottom-0 w-[4.5rem] hover:w-60`}`
        }
      >
        <div className="px-4">
          <img
            src={SideNavLogoOpen}
            alt="logo"
            className="w-auto max-w-full h-10 object-cover object-left-top"
          />
        </div>
        <div className="flex flex-col gap-2 mt-10 overflow-auto px-4">
          {navigationItems.map(({ icon, label, link }) => (
            <NavItem
              key={label}
              isActive={activeItem === label}
              icon={icon}
              label={label}
              link={link}
              isOpen={isOpen}
              onClick={() => handleItemClick(label)}
            />
          ))}
        </div>
      </div>
      {!isOpen?
        <button className="bg-w absolute left-[4.5rem] p-[.75rem] top-1/2" onClick={()=>{setIsOpen(true)}}>
            <svg
              width="0.625rem"
              height="1.125rem"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.02598 0.875713C0.769061 1.15466 0.769061 1.60692 1.02598 1.88587L7.57833 8.99984L1.02598 16.1138C0.769061 16.3928 0.769061 16.845 1.02598 17.124C1.28291 17.4029 1.69947 17.4029 1.95639 17.124L8.97393 9.50491C9.09731 9.37096 9.16663 9.18928 9.16663 8.99984C9.16663 8.8104 9.09731 8.62872 8.97393 8.49476L1.95639 0.875713C1.69947 0.596767 1.28291 0.596767 1.02598 0.875713Z"
                fill="#646464"
              />
            </svg>
        </button>
      :
        <button className="bg-w absolute left-60 p-[.75rem] top-1/2 -translate-y-1/2" onClick={()=>{setIsOpen(false)}}>
          <svg
              width="0.125rem"
              height="2rem"
              viewBox="0 0 2 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="2" height="32" rx="1" fill="#888888" />
            </svg>
        </button>
      }
    </div>
  );
};

export default Menu;
