import React, { useState, useEffect } from "react";
import { useParams,Link } from "react-router-dom";
import { convertParamsToArr, FetchData,pushArray } from "../../CommonFunctions";
import Tag from "../../components/Tags/Tags";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import { fetchClients,confirmDeleteClients } from "./ClientsFunction";
import ClientsFilter from "./ClientsFilter";
import ClientsTopbar from "./ClientsTopbar";
import EmptyClients from "./EmptyClients";
import CreateClientModal from "./CreateClientModal";
import ClientPortfolioSharedModal from "./ClientPortfolioSharedModal";
import ClientsEmpty from "./ClientsEmpty";
import { useSelector } from "react-redux";
import { hasPermission } from "../../helpers/accessHelper";
import CustomLoader from "../../components/Loader/CustomLoader";
import ClientEmpty from "./ClientEmpty";

const Clients = () => {
  let paramsData = useParams();
  paramsData = convertParamsToArr({ params: paramsData });
  paramsData["sort_by"] = ["created_at", "DESC"];
  paramsData["status"] = [0,1];
  paramsData["is_converted"] = 0;
  paramsData["start_date"] = null;
  paramsData["end_date"] = null;
  paramsData["page"] = 1;

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadMore, setIsLoadMore] = useState<Boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState<Boolean>(false);
  const [clientsData, setClientsData] = useState<FetchData>();
  const [searchInp, setSearchInp] = useState<any>(null);
  const [params, setParams] = useState(paramsData);
  
  const [role, setRole] = useState<string>("");
  const User = useSelector((state: any) => state.user);
  useEffect(()=>{
    setRole(User.role)
  }, [User])
  useEffect(() => {
    if (clientsData !== undefined) {
      setIsLoading(false);
    }

    setIsLoadMore(false);
  }, [clientsData]);

  useEffect(() => {
    setIsLoadMore(true);
    loadData(false);
  }, [params]);

  useEffect(() => {
    searchClients();
  }, [searchInp]);

  const deleteClients = async (id: BigInteger) => {
    await confirmDeleteClients(id, params, setClientsData,clientsData);
  };

  const setSearchInpData = (e: any) => {
    setSearchInp(e.target.value);
  };

  const searchClients = async () => {
    if (searchInp != null && (searchInp.length >= 1 || searchInp.length <= 0)) {
      setIsLoading(true);
      setParams({
        ...params,
        page:1,
        0: searchInp,
      });
    }
  };

  const loadData = async (isLoader = true) => {
    if(isLoader){
      setIsLoading(true);
      params["page"] = 1;
    }

    const data:FetchData = await fetchClients({params});

    if(data.status === "error" && data.error === "abort"){return false;}
    
    // if(clientsData !== undefined && params["page"] > 1 && data.status === "success"){
    //   let log = clientsData["data"];
    //   for(const i in data["data"]){
    //     log = pushArray(log,data["data"][i]);
    //   }
    //   setClientsData({
    //     ...clientsData,
    //     data:log
    //   });
    // }else if(params["page"] === 1){
      setClientsData(data); 
    // }
  };

  const CreateClient = (id:number) => {
    CreateClientModal.fire(id, loadData);
  };

  const SharedPortfolio = (id:number,client:any) => {
    ClientPortfolioSharedModal.fire(id,client);
  };

  const setPagination=(page: number)=>{
    setParams({
      ...params,
      page:page,
    });
  };

  const events = {
    loadData: loadData,
    setIsLoadMore:setIsLoadMore,
    setParams:setParams,
    CreateClient:CreateClient,
    deleteEvent: deleteClients,
    setSearchInpData:setSearchInpData,
    searchClients:searchClients,
    SharedPortfolio:SharedPortfolio
  };
  return (
    <div className="py-2">
      <div className="relative h-[calc(100vh-8rem)] border border-gray-100 rounded-md shadow-300">
        <div className="flex h-full">
          <ClientsFilter
            params={params}
            setIsLoading={setIsLoading}
            events={events}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
          />
          <div className={`${isFilterOpen?`w-[calc(100%-12.125rem)] ms-[12.125rem]`:`w-[calc(100%-3.75rem)] ms-[3.75rem]`} h-full bg-gray-200 rounded-e-md overflow-auto`}>
          {/* <div className="w-full rounded-t-md overflow-hidden"> */}
            <ClientsTopbar
              params={params}
              setIsLoading={setIsLoading}
              events={events}
              totalData={clientsData?.data?.length}
              meta={clientsData?.meta}
              setPagination={setPagination}
              role={role}
            />
            <div className="">
              {isLoading === true ? (
                <div className="flex justify-center items-center w-full h-[calc(100vh-12.75rem)]">
                <CustomLoader/>
              </div>
                // <ClientsItemLoader />
              ) : clientsData !== undefined &&
                clientsData["status"] === "success" && clientsData.data.length > 0 ? (
                <ClientsItem 
                  clientsData={clientsData} 
                  isLoadMore={isLoadMore} 
                  params={params} 
                  events={events}
                  role={role}
                />
              // ) : clientsData !== undefined &&
              //   clientsData["status"] === "error" &&
              //   clientsData !== undefined &&
              //   clientsData["error"] === 404 ? (
              //   <EmptyClients events={events} />
              ) : (
                <div className="flex justify-center items-center w-full h-[calc(100vh-12.75rem)]">
                <ClientEmpty/>
              </div>
                // <div className="mt-20 text-center">
                //   <Typography variant="h5">Something went wrong!</Typography>
                // </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ClientsItem = (prop:any) => {
  const clientsData = prop.clientsData;
  const events = prop.events;
  const isLoadMore = prop.isLoadMore;
  const params = prop.params;
  const counter = 2;

  const ShowCount = (count:number) => {
    return count - counter;
  }
  
  return (
    <div className="overflow-auto h-[calc(100vh-12.6rem)] bg-white">
      <table className="w-full min-w-[1160px]">
        <thead className="sticky left-0 top-0 bg-gray-200 z-10">
          <tr>
            <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase ps-8">Name</th>
            <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase">Email Id</th>
            <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase">Company Name</th>
            <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase">Shared Portfolios</th>
            <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase pe-8">{hasPermission(prop.role, 'ClientManagement', 'Update') && "Actions" }</th>
          </tr>
        </thead>
        <tbody>
          {clientsData["data"].map((item: any, idx: any) => (
            <tr key={idx} className="group">
              <td className="text-300 text-gray-300 font-normal px-5 py-4 ps-8 text-start border-b border-gray-400 group-hover:bg-gray-200">{item.name}</td>
              <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">{item.email}</td>
              <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">{item.company_name}</td>
              <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                {item.portfolios.length > 0?(
                  <ul className="inline-block cursor-pointer" onClick={()=>{events.SharedPortfolio(item.id,item)}}>
                    {item.portfolios.map((portfolio: any, id: any) => (
                      (id + 1) <= counter?
                        <li key={id} className="inline-block font-medium">
                          {portfolio}
                          {item.portfolios.length > id+1?(<>&#x2c;&nbsp;</>):""}
                        </li>
                      :""
                    ))}
                    {item.portfolios.length > counter?
                      <li className="inline-block font-bold text-blue-100 border-b border-blue-100">+{ShowCount(item.portfolios.length)}</li>
                    :""}
                  </ul>
                ):"-"}
              </td>
              <td className="text-300 text-gray-300 font-normal px-5 py-4 pe-8 text-start border-b border-gray-400 group-hover:bg-gray-200">
                {hasPermission(prop.role, 'ClientManagement', 'Update') &&
                <div className="flex justify-start items-center">
                  <Button
                    label="Edit"
                    onClick={() => events.CreateClient(item.id)}
                    size="small"
                    variant="primary"
                    className="!text-200"
                  />&nbsp;
                  <div className="vr" />
                  &nbsp;
                  <Button
                    type="button"
                    label="Delete"
                    variant="outline"
                    size="small"
                    className="!text-200"
                    onClick={() => {
                      events.deleteEvent(item.id);
                    }}
                  />
                </div>
                }
              </td>
            </tr>
          ))}
          <tr>
            <td className="border-0" colSpan={5}>
              <div className="flex w-100 justify-center text-center items-center align-middle mt-5">
                {isLoadMore?(
                  <div className="flex mt-2">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="duration-200 animate-pulse-3"
                    >
                      <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                    </svg>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="duration-300 animate-pulse-2"
                    >
                      <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                    </svg>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="duration-400 animate-pulse"
                    >
                      <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                    </svg>
                  </div>
                ):(
                  params['page'] < clientsData.meta.last_page?
                    <button
                      className="text-200 font-bold text-blue-500 border-b inline-block"
                      onClick={()=>{
                        events.setParams({
                          ...params,
                          page:params["page"]+1
                        });
                      }}
                    >
                      {/* <span className="">Load More</span> */}
                    </button>
                  :""
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {/* <div className="flex justify-center items-center w-full h-[calc(100%-3rem)]">
        <ClientsEmpty />
      </div> */}
      {/* <div className="flex justify-center items-center w-full h-[calc(100%-3rem)]">
        <CustomLoader />
      </div> */}
    </div>
  );
};

const ClientsItemLoader = (prop:any) => {
  return (
    <div className="overflow-auto h-[calc(100vh-15.25rem)] bg-white">
      <table className="w-full">
        <thead className="sticky left-0 top-0 z-10">
          <tr>
            <th className="bg-gray-200 px-5 py-3 text-gray-700 text-300 font-bold text-left">Name</th>
            <th className="bg-gray-200 px-5 py-3 text-gray-700 text-300 font-bold text-left">Email Id</th>
            <th className="bg-gray-200 px-5 py-3 text-gray-700 text-300 font-bold text-left">Company Name</th>
            <th className="bg-gray-200 px-5 py-3 text-gray-700 text-300 font-bold text-left">Shared Portfolios</th>
            <th className="bg-gray-200 px-5 py-3 text-gray-700 text-300 font-bold text-left">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 7 }).map((idx: any) => (
            <tr key={idx}>
              <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300 ps-8">
                <div key={idx} className="animate-pulse">
                  <div className="flex-1 space-y-6">
                    <div className="h-5 bg-gray-100 rounded" />
                  </div>
                </div>
              </td>
              <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300">
                <div key={idx} className="animate-pulse">
                  <div className="flex-1 space-y-6">
                    <div className="h-5 bg-gray-100 rounded" />
                  </div>
                </div>
              </td>
              <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300">
                <div key={idx} className="animate-pulse">
                  <div className="flex-1 space-y-6">
                    <div className="h-5 bg-gray-100 rounded" />
                  </div>
                </div>
              </td>
              <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300">
                <div key={idx} className="animate-pulse">
                  <div className="flex-1 space-y-6">
                    <div className="h-5 bg-gray-100 rounded" />
                  </div>
                </div>
              </td>
              <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300">
                <div key={idx} className="animate-pulse">
                  <div className="flex-1 space-y-6">
                    <div className="h-5 bg-gray-100 rounded" />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Clients;
