import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useLoaderData } from "react-router-dom";
import { FetchData } from "../../CommonFunctions";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import productOneImage from "../../images/download.svg";
import ListLoader from "../../components/ListLoader/ListLoader";
import { fetchPortfolioShow, updatePortfolioStatus } from "./PortfolioFunction";
import PreviewAssetModal from "./PreviewAssetModal";
import porfolioTwoImage from "../../images/portfolioTwo.png";
import videoCircleImage from "../../images/video-circle.svg";

import styled from "styled-components";
import InlineTag from "../../components/Tags/InlineTag";
import { updateHeaderData } from "../../User";
import store from "../../store";
import Icons from "../../components/Icons/Icon";

export const ImgOverlay = styled.div`
  .block-area {
    position: relative;
    transition: 0.5s;
  }
  .overLay {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    -webkit-transition: -webkit-transform 0.35s;
    transition: all 0.3s ease;
    display: none;
    z-index: 10;
  }
  .block-area:hover .overLay {
    display: block;
  }
`;

const PreviewPortfolio = () => {
  const [portfoliosData] = useState<FetchData>(useLoaderData());
  const [isLoading, setIsLoading] = useState(true);
  const [tags, setTags] = useState<any>("");
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(false);
  }, [portfoliosData]);

  const loadData = () => {
    navigate("/portfolios");
  };

  const PublishPortfolio = async (id: number) => {
    await updatePortfolioStatus(id, 1, loadData);
  };

  const PreviewAsset = (asset: any) => {
    PreviewAssetModal.fire(asset);
  };
  useEffect(() => {
    if (portfoliosData !== undefined) {
      setIsLoading(false);
      setTags(portfoliosData["data"]["tags"]);
    }
  }, [portfoliosData]);

  useEffect(() => {
    if (portfoliosData !== undefined) {
      setIsLoading(false);

      if(portfoliosData.status === "success"){
        const data = {
          portfolio_title:portfoliosData.data.portfolio_title,
          navigate:navigate
        }
        store.dispatch(
          updateHeaderData(data),
        );
      }
    }
  }, [portfoliosData]);
  
  return (
    <div className="my-2 relative h-[calc(100vh-8rem)] border border-gray-100 rounded-md shadow-300 overflow-auto flex flex-col flex-grow">
      {isLoading === true ? (
        <ListLoader />
      ) : portfoliosData["status"] === "success" ? (
        <>
          <div className="flex justify-between items-center bg-white border-b border-gray-100 py-4 px-5 sticky left-0 top-0 z-[11] w-full rounded-t-md shadow-300 min-h-16">
            <div className="flex justify-start items-center gap-1">
              {/* <Typography
                className="text-seconday-200 font-semibold"
                variant="h3"
              >
                {portfoliosData.data.portfolio_title}
              </Typography> */}
              <Icons name="edit"/>
              <div className="text-[#4F4F4F] text-400 font-medium">
                Created by : {portfoliosData.data.added_by}
              </div>
            </div>
            <div className="flex justify-start gap-2.5">
              {portfoliosData.data.status !== 2 ? (
                <Button
                  type="button"
                  label="Back to Edit"
                  variant="outline"
                  size="medium"
                  onClick={() => {
                    navigate("/portfolios/show/" + portfoliosData.data.id);
                  }}
                />
              ) : (
                ""
              )}
              {portfoliosData.data.assets !== undefined &&
              portfoliosData.data.assets !== null ? (
                <Button
                  type="button"
                  label="Publish"
                  variant="primary"
                  size="medium"
                  onClick={() => {
                    PublishPortfolio(portfoliosData.data.id);
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="px-5 py-5">
            {/* <InlineTag
              tags={
                tags !== "" && tags !== null && tags !== undefined
                  ? tags.split(",")
                  : []
              }
            /> */}
            <div className="grid grid-cols-4 gap-5">
              {portfoliosData.data.assets !== undefined &&
              portfoliosData.data.assets !== null
                ? portfoliosData.data.assets.map((asset: any, idx: any) => (
                    <div key={idx} className="">
                      <ImgOverlay>
                        <div className="block-area h-40 w-full overflow-hidden flex justify-center items-center rounded-md relative hover:duration-700">
                          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
                            {asset.type === "Documents" ? (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M15.75 9H8.25"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M15.75 15H8.25"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ) : asset.type === "Projects" ? (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.5 7.67001V6.70001C7.5 4.45001 9.31 2.24001 11.56 2.03001C14.24 1.77001 16.5 3.88001 16.5 6.51001V7.89001"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M8.99999 22H15C19.02 22 19.74 20.39 19.95 18.43L20.7 12.43C20.97 9.99 20.27 8 16 8H7.99999C3.72999 8 3.02999 9.99 3.29999 12.43L4.04999 18.43C4.25999 20.39 4.97999 22 8.99999 22Z"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M15.4955 12H15.5045"
                                  stroke="#292D32"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M8.49451 12H8.50349"
                                  stroke="#292D32"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ) : asset.type === "Images" ? (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M21.6799 16.9599L18.5499 9.64988C17.4899 7.16988 15.5399 7.06988 14.2299 9.42988L12.3399 12.8399C11.3799 14.5699 9.58993 14.7199 8.34993 13.1699L8.12993 12.8899C6.83993 11.2699 5.01993 11.4699 4.08993 13.3199L2.36993 16.7699C1.15993 19.1699 2.90993 21.9999 5.58993 21.9999H18.3499C20.9499 21.9999 22.6999 19.3499 21.6799 16.9599Z"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M6.96997 8C8.62682 8 9.96997 6.65685 9.96997 5C9.96997 3.34315 8.62682 2 6.96997 2C5.31312 2 3.96997 3.34315 3.96997 5C3.96997 6.65685 5.31312 8 6.96997 8Z"
                                  stroke="#292D32"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ) : asset.type === "Videos" ? (
                              <img
                                src={videoCircleImage}
                                alt={videoCircleImage}
                                className="w-[84px] h-[84px] object-cover relative block"
                              />
                            ) : asset.type === "Links" ? (
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M15.4101 18.5899C15.0101 18.5899 14.6801 18.2599 14.6801 17.8599C14.6801 17.4599 15.0101 17.1299 15.4101 17.1299C18.2301 17.1299 20.5301 14.8299 20.5301 12.0099C20.5301 9.18992 18.2301 6.88992 15.4101 6.88992C12.5901 6.88992 10.2901 9.18992 10.2901 12.0099C10.2901 12.4099 9.96008 12.7399 9.56008 12.7399C9.16008 12.7399 8.83008 12.4099 8.83008 12.0099C8.83008 8.37992 11.7801 5.41992 15.4201 5.41992C19.0601 5.41992 22.0001 8.36992 22.0001 11.9999C22.0001 15.6299 19.0501 18.5899 15.4101 18.5899Z"
                                  fill="#292D32"
                                />
                                <path
                                  d="M8.59 5.40991C8.99 5.40991 9.32 5.73991 9.32 6.13991C9.32 6.53991 8.99 6.86991 8.59 6.86991C5.77 6.86991 3.47 9.16991 3.47 11.9899C3.47 14.8099 5.77 17.1099 8.59 17.1099C11.41 17.1099 13.71 14.8099 13.71 11.9899C13.71 11.5899 14.04 11.2599 14.44 11.2599C14.84 11.2599 15.17 11.5899 15.17 11.9899C15.17 15.6199 12.22 18.5799 8.58 18.5799C4.94 18.5799 2 15.6299 2 11.9999C2 8.36991 4.95 5.40991 8.59 5.40991Z"
                                  fill="#292D32"
                                />
                              </svg>
                            ) : (
                              <></>
                            )}
                          </div>
                          {asset.type === "Projects" ? (
                            asset.cover !== "" ? (
                              <img
                                src={asset.cover}
                                alt={asset.project_title}
                                className="w-full h-full object-cover relative block"
                              />
                            ) : (
                              <img
                                src={porfolioTwoImage}
                                alt={asset.name}
                                className="w-full h-full object-cover relative block"
                              />
                            )
                          ) : asset.type === "Images" ? (
                            <img
                              src={asset.url}
                              alt={asset.name}
                              className="w-full h-full object-cover relative block"
                            />
                          ) : (
                            <img
                              src={porfolioTwoImage}
                              alt={asset.name}
                              className="w-full h-full object-cover relative block"
                            />
                          )}
                          <div className="overLay px-5 py-5">
                            <div className="h-full w-full flex justify-start items-end">
                              <div>
                                <Typography
                                  className="text-white font-normal mt-1.5 line-clamp-1"
                                  size="sm"
                                  variant="p"
                                >
                                  {asset.type === "Projects"
                                    ? asset.project_title
                                    : asset.name}
                                </Typography>
                                <ul className="inline-block mt-1">
                                  {asset.tags !== "" && asset.tags !== null
                                    ? asset.tags
                                        .split(",")
                                        .map((item: any, idx: any) => (
                                          <li
                                            key={idx}
                                            className="inline-block me-1 last:me-0"
                                          >
                                            <a
                                              href="#"
                                              className="text-200 font-light text-white bg-gray-700 px-2 py-0.5 rounded-full truncate max-w-[4.5rem] inline-block"
                                            >
                                              {item}
                                            </a>
                                          </li>
                                        ))
                                    : ""}
                                </ul>
                                {/* <Typography
                                  className="text-white font-light mt-1.5 line-clamp-2"
                                  size="sm"
                                  variant="p"
                                >
                                  {portfolio.portfolio_description}
                                </Typography> */}
                              </div>
                            </div>
                            <div className="absolute right-4 top-4">
                              <button
                                className="relative"
                                onClick={() => PreviewAsset(asset)}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M21 9V3H15"
                                    stroke="#FFF"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M3 15V21H9"
                                    stroke="#FFF"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M21 3L13.5 10.5"
                                    stroke="#FFF"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M10.5 13.5L3 21"
                                    stroke="#FFF"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </ImgOverlay>
                      <div className="flex justify-between items-start gap-2 mt-2">
                        <div>
                          <Typography
                            variant="h6"
                            className="text-gray-300 font-semibold line-clamp-1"
                          >
                            {asset.asset_title
                              ? asset.asset_title
                              : asset.project_title}
                          </Typography>
                        </div>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </>
      ) : portfoliosData["status"] === "error" &&
        portfoliosData["error"] === 404 ? (
        <div className="mt-20 text-center">
          <Typography variant="h5">Portfolio not found!</Typography>
        </div>
      ) : (
        <div className="mt-20 text-center">
          <Typography variant="h5">Something went wrong!</Typography>
        </div>
      )}
    </div>
  );
};

export const PreviewPortfolioLoader = fetchPortfolioShow;

export default PreviewPortfolio;
