import React from "react";
import { FieldProps } from "formik";
import FileUpload, { FileUploadProps } from "../FileUpload/FileUpload";

interface CustomUploadFieldProps extends FieldProps, FileUploadProps {
  label: string;
}

const CustomUploadField: React.FC<CustomUploadFieldProps> = ({
  field,
  form,
  label,
  ...props
}) => {
  const { name } = field;

  return (
    <div>
      <FileUpload label={label} {...field} {...props} />
    </div>
  );
};

export default CustomUploadField;
