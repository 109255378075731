import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useLoaderData } from "react-router-dom";
import { FetchData } from "../../CommonFunctions";
import AssetSlider from "./AssetSlider";
import Typography from "../../components/Typography/Typography";
import Icons from "../../components/Icons/Icon";
import Tooltip from "../../components/Tooltip/Tooltip";
import Button from "../../components/Button/Button";
import ListLoader from "../../components/ListLoader/ListLoader";
import { fetchProjectShow, updateProjectStatus } from "./ProjectFunction";
import styled from "styled-components";
import CustomLoader from "../../components/Loader/CustomLoader";
import { updateHeaderData } from "../../User";
import store from "../../store";

export const CustomRightToolTip = styled.div`
  .tooltipRight {
    position: relative;
    display: inline-block;
    z-index: 99;
    cursor: default;
    width: 100%;
  }
  .tooltipRight .tooltiptext {
    visibility: hidden;
    min-height:20px;
    min-width: 180px;
    background-color: #fff;
    color: #232323;
    text-align: center;
    padding: 8px 6px;
    position: absolute;
    z-index: 99;
    top: 125%;
    left: 0;
    filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.12));
    font-size: 11px;
    font-weight: 500;
    border-radius:0px 4px 4px 4px;
  }
  .tooltipRight .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 99%;
    left: 12px;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }

  .tooltipRight:hover .tooltiptext {
    visibility: visible;
  }
`;

const PreviewAsset = () => {
  const [projectsData] = useState<FetchData>(useLoaderData());
  const [isLoading, setIsLoading] = useState(true);
  const [projectAssets, setProjectAssets] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(false);
    if (
      projectsData !== undefined &&
      projectsData.data !== undefined &&
      projectsData.data.assets !== undefined
    ) {
      setProjectAssets(projectsData.data.assets);
    }
  }, [projectsData]);

  const loadData = () => {
    navigate("/projects");
  };

  const PublishProject = async (id: number) => {
    await updateProjectStatus(id, 1, loadData);
  };
  
  useEffect(() => {
    if (projectsData !== undefined) {
     // setIsLoading(false);

      if(projectsData.status === "success"){
        const data = {
          client_name:projectsData.data.client_name,
          project_title:projectsData.data.project_title,
          project_description:projectsData.data.project_description,
          navigate:navigate
        }
        store.dispatch(
          updateHeaderData(data),
        );
      }
    }
  }, [projectsData]);

  return (
    <div>
      {isLoading === true ? (
        <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
        <CustomLoader/>
      </div>
        // <ListLoader />
      ) : projectsData["status"] === "success" ? (
        <>
          <div className="flex justify-between items-center border-b border-gray-100 pb-4 relative after:absolute after:left-0 after:-bottom-[5px] after:w-20 after:h-1 after:bg-blue-300">
            <div>
              {/* <Typography
                variant="h4"
                className="text-seconday-200 font-medium"
              >
                {projectsData.data.project_title}
              </Typography> */}
              <div className="flex justify-start items-center gap-1">
                <Icons name="edit"/>
                <Typography
                  variant="h6"
                  className="text-seconday-200 font-bold"
                >
                  Created by : {projectsData.data.user.name}
                </Typography>

                {/* <Tooltip
                  size="large"
                  content={projectsData.data.project_description}
                  placement="right"
                  trigger
                >
                  <Icons
                    name="info-circle"
                    variant="bold"
                    className="-mb-0.5 ms-1"
                    size={15}
                    color="#232323"
                  />
                </Tooltip> */}
                {/* <CustomRightToolTip>
                  <div className="tooltipRight">
                    <div className="w-full">
                      <Icons
                        name="info-circle"
                        variant="bold"
                        className="-mb-0.5 ms-1"
                        size={15}
                        color="#232323"
                      />
                    </div>
                    <div className="tooltiptext">
                      {projectsData.data.project_description}
                    </div>
                  </div>
                </CustomRightToolTip> */}
              </div>
            </div>
            <div className="flex justify-end items-center gap-3">
              {/* <div className="text-300 text-gray-300 font-medium">
                {projectsData.data.client_name}
                <br />
                {projectsData.data.created_at}
              </div> */}
              {projectsData.data.status !== 2?
                <Button
                  type="button"
                  label="Edit"
                  variant="primary"
                  size="medium"
                  onClick={() => {
                    navigate("/projects/show/" + projectsData.data.id);
                  }}
                />
              :""}
              {projectAssets !== null && projectAssets !== undefined ?
                <Button
                  type="button"
                  label="Publish"
                  variant="primary"
                  size="medium"
                  onClick={() => {
                    PublishProject(projectsData.data.id);
                  }}
                />
              :""}
            </div>
          </div>
          <div className="mt-6">
            <AssetSlider projectAssets={projectAssets} />
          </div>
        </>
      ) : projectsData["status"] === "error" &&
        projectsData["error"] === 404 ? (
        <div className="mt-20 text-center">
          <Typography variant="h5">Project not found!</Typography>
        </div>
      ) : (
        <div className="mt-20 text-center">
          <Typography variant="h5">Something went wrong!</Typography>
        </div>
      )}
    </div>
  );
};

export const PreviewProjetLoader = fetchProjectShow;

export default PreviewAsset;
