import {
  convertParamsToArr,
  showConfirm,
  showToastAlert,
  Params,
  ShowPageLoader,
  HidePageLoader,
  callFetch,
} from "../../CommonFunctions";

const controller = new AbortController();
let repositoryController:any;
let assetController:any;

export async function fetchRepository({ params }: Params) {
  params = convertParamsToArr({ params });

  let queryString = "";
  queryString = params[1] !== undefined ? "search=" + params[1] : "";
  queryString +=
    params["sort_by"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["sort_by"] !== undefined ? "sort_by=" + params["sort_by"] : "";
  queryString +=
    params["group_by"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["group_by"] !== undefined ? "group_by=" + params["group_by"] : "";
  queryString += params["tag"] !== undefined && queryString !== "" ? "&" : "";
  queryString += params["tag"] !== undefined ? "tag=" + params["tag"] : "";
  queryString +=
    params["start_date"] !== undefined && params["start_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["start_date"] !== undefined && params["start_date"] !== null ? "start_date=" + params["start_date"] : "";
  queryString +=
    params["end_date"] !== undefined && params["end_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["end_date"] !== undefined && params["end_date"] !== null ? "end_date=" + params["end_date"] : "";
  queryString += params["tab"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["tab"] !== undefined ? "file_type=" + params["tab"] : "";
  queryString +=
    params["page"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["page"] !== undefined ? "page=" + params["page"] : "";

  if(repositoryController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await repositoryController.abort(reason);
  }

  repositoryController = new AbortController();

  return await callFetch({
    url: process.env.REACT_APP_API_URL + "assets?" + queryString,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: repositoryController,
    callback: async (json) => {
      return json;
    },
  });
}

export async function fetchAsset(id: number) {
  if(assetController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await assetController.abort(reason);
  }

  assetController = new AbortController();

  return await callFetch({
    url: process.env.REACT_APP_API_URL + "assets/" + id,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: assetController,
    callback: async (json) => {
      return json;
    },
  });
}

export const confirmDeleteFiles = async (id: BigInteger, loadData: any) => {
  await showConfirm({
    title: "Are you sure you want to delete this asset?",
    message: "The asset information will be deleted and can’t be undone. Click Yes, if you still want to continue.",
    type: "warning",
    confirm: async () => {
      await deleteFiles(id, loadData);
    },
    cancel: () => {},
  });
};

const deleteFiles = async (id: BigInteger, loadData: any) => {
  ShowPageLoader();
  return await callFetch({
    url: process.env.REACT_APP_API_URL + "assets/" + id,
    method: "DELETE",
    controller: controller,
    headers: {},
    callback: async (json) => {
      HidePageLoader();
      if (json.status == "error") {
        showToastAlert(json.message);
      } else {
        showToastAlert(json.message, 1);
        loadData();
      }
    },
  });
};
