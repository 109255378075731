import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { convertParamsToArr, FetchData, pushArray } from "../../CommonFunctions";
import { EmptyData } from "../../components/Errors/Errors";
import Typography from "../../components/Typography/Typography";
import { fetchProjets } from "./ProjectFunction";
import SingleProject from "./SingleProject";
import ProjectsFilter from "./ProjectsFilter";
import ProjectsTopbar from "./ProjectsTopbar";
import SingleProjectArchive from "./SingleProjectArchive";
import SingleProjectDraft from "./SingleProjectDraft";
import EmptyProjects from "./EmptyProjects";
import CustomLoader from "../../components/Loader/CustomLoader";
import ProjectEmpty from "./ProjectEmpty";
import AssetsEmpty from "../assets/AssetsEmpty";

const Projects = () => {
  let paramsData = useParams();
  paramsData = convertParamsToArr({ params: paramsData });
  paramsData["sort_by"] = ["created_at", "DESC"];
  paramsData["status"] = "";
  paramsData["categories"] = [];
  paramsData["industries"] = [];
  paramsData["technologies"] = [];
  paramsData["clients"] = [];
  paramsData["tags"] = [];
  paramsData["start_date"] = null;
  paramsData["end_date"] = null;
  paramsData["page"] = 1;

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadMore, setIsLoadMore] = useState<Boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState<Boolean>(false);
  const [projectsData, setProjectsData] = useState<FetchData>();
  const [params, setParams] = useState(paramsData);
  const [searchInp, setSearchInp] = useState<string | null>(null);

  useEffect(() => {
    if (projectsData !== undefined) {
      setIsLoading(false);
    }

    setIsLoadMore(false);
  }, [projectsData]);

  useEffect(() => {
    setIsLoadMore(true);
    loadData();
  }, [params]);

  const loadData = async () => {
    const data:FetchData = await fetchProjets({params});
    
    if(data.status === "error" && data.error === "abort"){return false;}

 // if(projectsData !== undefined && params["page"] > 1 && data.status === "success"){
    //   let log = projectsData["data"];
    //   for(const i in data["data"]){
    //     log = pushArray(log,data["data"][i]);
    //   }
    //   setProjectsData({
    //     ...projectsData,
    //     data:log
    //   });
    // }else if(params["page"] === 1){
          setProjectsData(data); 
            // }
  };

  useEffect(() => {
    searchPortfolios();
  }, [searchInp]);

  const setSearchInpData = (e: any) => {
    setSearchInp(e.target.value);
  };

  const searchPortfolios = async () => {
    if (searchInp != null && (searchInp.length >= 1 || searchInp.length <= 0)) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        0: searchInp,
      });
    }
  };

  const setPagination = (page: number) => {
    setParams({
      ...params,
      page: page,
    });
  };

  const events = {
    loadData: loadData,
    setIsLoadMore: setIsLoadMore,
    setParams: setParams,
  };

  return (
    <div className="py-2">
      <div className="relative h-[calc(100vh-8rem)] border border-gray-100 rounded-md shadow-300">
        <div className="flex h-full">
          <ProjectsFilter
            params={params}
            setParams={setParams}
            setIsLoading={setIsLoading}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
          />
          <div className={`${isFilterOpen ? `w-[calc(100%-12.125rem)] ms-[12.125rem]` : `w-[calc(100%-4rem)] ms-16`} h-full bg-gray-200 rounded-e-md overflow-auto`}>
            <ProjectsTopbar
              params={params}
              setParams={setParams}
              setIsLoading={setIsLoading}
              setSearchInpData={setSearchInpData}
              totalData={projectsData?.data.length}
              meta={projectsData?.meta}
              setPagination={setPagination}
            />
            <div className="p-5">
              {isLoading ? (
                <div className="flex justify-center items-center w-full h-[calc(100vh-14.75rem)]">
                <CustomLoader/>
              </div>
                // <div className="grid grid-cols-4 gap-5">
                //   {Array.from({ length: 8 }).map((_, idx) => (
                //     <div key={idx} className="animate-pulse">
                //       <div className="flex-1 space-y-6">
                //         <div className="h-[12.25rem] bg-gray-100 rounded" />
                //       </div>
                //       <div className="space-y-3 mt-2">
                //         <div className="flex justify-between items-center gap-2">
                //           <div className="h-2 w-1/2 bg-gray-100 rounded" />
                //           <div className="order-last h-2 w-5 bg-gray-100 rounded" />
                //         </div>
                //       </div>
                //     </div>
                //   ))}
                // </div>
              ) : projectsData !== undefined && projectsData.status === "success" && projectsData.data.length > 0 ? (
                <div className="grid grid-cols-4 gap-5">
                  <ProjectItem projectsData={projectsData} isLoadMore={isLoadMore} params={params} events={events} />
                </div>
              // ) : projectsData !== undefined && projectsData.status === "success" && projectsData.data.length === 0 ? (
              //   <div className="mt-20 text-center">
                  
              //     <Typography variant="h5">No Records Found</Typography>
              //   </div>
              // ) : projectsData !== undefined && projectsData.status === "error" && projectsData.error === 404 ? (
              //   <div className="flex justify-center items-center h-[calc(100vh-17rem)]">
              //     <EmptyProjects />
              //   </div>
              ) : (
                <div className="w-full h-[calc(100vh-16.75rem)] flex justify-center items-center">
                  <ProjectEmpty/>
                </div>
                // <div className="mt-20 text-center">
                //   <Typography variant="h5">Something went wrong!</Typography>
                // </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProjectItem = (props: any) => {
  const projectsData = props.projectsData;
  const isLoadMore = props.isLoadMore;
  const params = props.params;
  const loadData = props.events.loadData;
  const setParams = props.events.setParams;

  return (
    <>
      {projectsData.status === "success" && projectsData.data.length > 0 ? (
        <>
          {projectsData.data.map((item: any, idx: any) =>
            item.status === 1 ? (
              <SingleProject key={idx} project={item} loadData={loadData} />
            ) : item.status === 0 ? (
              <SingleProjectDraft key={idx} project={item} loadData={loadData} />
            ) : (
              <SingleProjectArchive key={idx} project={item} loadData={loadData} />
            )
          )}
          {/* <div className="col-span-4 w-full justify-center text-center items-center align-middle">
            {isLoadMore ? (
              <div className="flex justify-center items-center mt-2">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="duration-200 animate-pulse-3"
                >
                  <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                </svg>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="duration-300 animate-pulse-2"
                >
                  <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                </svg>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="duration-400 animate-pulse"
                >
                  <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                </svg>
              </div>
            ) : (
              params.page < projectsData.meta.last_page ? (
                <button
                  className="text-200 font-bold text-blue-500 border-b inline-block"
                  onClick={() => {
                    setParams({
                      ...params,
                      page: params.page + 1
                    });
                  }}
                >
                  <span className="">Load More</span>
                </button>
              ) : ""
            )}
          </div> */}
        </>
      // ) : projectsData.status === "error" && projectsData.error === 404 ? (
      //   <EmptyData
      //     heading={"You haven't created any project"}
      //     btnTitle={"Create new project"}
      //     link={"/projects/add"}
      //   />
      ) : (
        <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
        <AssetsEmpty/>
      </div>
        // <div className="mt-20 text-center">
        //   <Typography variant="h5">Something went wrong!</Typography>
        // </div>
      )}
    </>
  );
};

export const ProjectsLoader = fetchProjets;

export default Projects;
