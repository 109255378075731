import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import NetworkStatus from "./NetworkStatus";
import ClientAuthenticate from "./ClientAuthenticate";
import store from "./store";
import { RouterProvider, BrowserRouter } from "react-router-dom";
import Router from "./Router";
import ClientRouter from "./ClientRouter";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import reportWebVitals from "./reportWebVitals";

import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig, loginRequest } from "./authConfig";
import { callMsGraph, callMsGraphGroup } from "./graph";
import { update,updateRole } from "./User";
import { msTOkenUpdate } from "./Token";
import { rolesGroup,indexOf } from "./CommonFunctions";

const location = window.location.pathname
const pathname = location.split("/");
let msalInstance:any;

if(pathname[1] !== undefined && pathname[1] !== "client"){
  /**
   * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
   * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
   */
  msalInstance = new PublicClientApplication(msalConfig);

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  const accounts = msalInstance.getAllAccounts();

  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && accounts.length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(accounts[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback(async (event: any) => {
    if (
      (event.eventType === EventType.LOGIN_SUCCESS ||
        event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
      event.payload.account
    ) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);

      await msalInstance
      .acquireTokenSilent({
        ...loginRequest,
        account: account,
      })
      .then(async (response: any) => {
        store.dispatch(
          msTOkenUpdate({
            token: response.accessToken
          })
        );

        await callMsGraph(response.accessToken).then((response:any) => {
          if(response !== undefined){
            store.dispatch(
              update({
                businessPhones: response.businessPhones,
                displayName: response.displayName,
                givenName: response.givenName,
                id: response.id,
                jobTitle: response.jobTitle,
                mail: response.mail,
                mobilePhone: response.mobilePhone,
                officeLocation: response.officeLocation,
                preferredLanguage: response.preferredLanguage,
                surname: response.surname,
                userPrincipalName: response.userPrincipalName,
              }),
            );
          }
        });
        await callMsGraphGroup(response.accessToken).then((response:any) => {
          if(response !== undefined){
            const filterRole=response.value.filter((m:any)=>m.displayName.includes('EchoShow-'));
            if(filterRole.length>0)
            {
              store.dispatch(
                updateRole(filterRole[0].displayName),
              );
            } else {
              //Write code to logout user not let them access anything
            }
            // for(const i in response.value){
            //   store.dispatch(
            //     updateRole(response.value[i]["displayName"]),
            //   );
            //   const acseptedRoles = Object.keys(rolesGroup);
            //   if(indexOf(acseptedRoles,response.value[i]["displayName"])){
            //     break;
            //   }
            // }
          }
        });
      });
    }
  });

  setInterval(async () => {
    await msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    })
    .then(async (response: any) => {
      store.dispatch(
        msTOkenUpdate({
          token: response.accessToken
        })
      );
    });
  }, 1000*60*2);
}

const RouterSet = () => {
  const location = window.location.pathname
  const pathname = location.split("/");

  return (
    <>
      <BrowserRouter />
      {pathname[1] !== undefined && pathname[1] === "client"?
        <RouterProvider router={ClientRouter} />
        :
        <RouterProvider router={Router} />
      }
    </>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */
root.render(
  <React.StrictMode>
    <NetworkStatus>
      {pathname[1] !== undefined && pathname[1] !== "client"?(
        <MsalProvider instance={msalInstance}>
          <Provider store={store}>
            <RouterSet />  
          </Provider>
        </MsalProvider>
      ):(
        <ClientAuthenticate>
          <Provider store={store}>
            <RouterSet />  
          </Provider>
        </ClientAuthenticate>
      )}
    </NetworkStatus>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
