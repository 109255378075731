import React, { useState } from "react";
import { createRoot } from "react-dom/client";
import { Modal } from "../../components/Modal/Modal";
import { ModalHeader } from "../../components/Modal/Modal";
import { ModalBody } from "../../components/Modal/Modal";
import SearchLog from "./SearchLog";
import Auditlog from "./Auditlog";
import AccessPortfolio from "../accessPortfolio/AccessPortfolio";
type Props = {
  onClose: () => void;
};

interface Params {
  page: number;
  start_date: string | null;
  end_date: string | null;
  auditLogController: any;
}

class ShowAuditLogModal extends React.Component {
  static async fire() {
    const container = document.createElement("div");
    container.id = "custom-client-container";
    const root = createRoot(container);
    document.body.appendChild(container);

    const close = () => {
      root.unmount();
      container.remove();
      return false;
    };

    root.render(<ModalComponent onClose={close} />);
  }

  render() {
    return null;
  }
}

const ModalComponent: React.FC<Props> = ({ onClose }) => {
  const [tab, setTab] = useState<string>("auditlog");
  const [searchInp, setSearchInp] = useState<string>('');

  const setSearchInpData = (e: any) => {
    setSearchInp(e.target.value);
  };

  return (
    <Modal isOpen onClose={onClose} placement="right" size="medium">
      <ModalHeader className="pe-20">
        <div className="flex justify-between items-center w-full">
          <div className="flex justify-start items-center gap-12">
            <button 
                onClick={()=>{setTab("auditlog")}} 
                className={`relative px-2 text-700 font-bold 
                    ${tab === "auditlog"?"text-gray-300 after:absolute after:left-0 after:-bottom-[25px] after:bg-primary-100 after:h-[3px] after:w-full":""}
                `}
            >Activity Log</button>
            <button 
                onClick={()=>{setTab("portfolio_access")}} 
                className={`relative px-2 text-700 font-bold 
                    ${tab === "portfolio_access"?"text-gray-300 after:absolute after:left-0 after:-bottom-[28px] after:bg-primary-100 after:h-[3px] after:w-full":""}
                `}
            >Portfolio Access</button>
          </div>
          <div className="w-[16.75rem]">
            {tab === "portfolio_access"?(
                <SearchLog setSearchInpData={setSearchInpData}/>
            ):""}
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="!p-0">
        {tab === "auditlog"?(
            <Auditlog />
        ):tab === "portfolio_access"?(
            <AccessPortfolio searchInp={searchInp}/>
        ):""}
      </ModalBody>
    </Modal>
    
  );
};

export default ShowAuditLogModal;
