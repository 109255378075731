import React, { useState, useEffect, useRef } from "react";
import { useParams,useNavigate } from "react-router-dom";
import store from "../../store";
import { updateHeaderData } from "../../User";
import { convertParamsToArr, FetchData,indexOf} from "../../CommonFunctions";
import { CustomDateTimePicker } from "../../components/DateTimePicker/DatePicker";
import TextInput from "../../components/TextInput/TextInput";
import GoogleChart from "../../components/Chart/chart";
import Spinner from "../../components/Spinner/Spinner";
import Typography from "../../components/Typography/Typography";
import IPList from "./IPList";
import { 
  fetchpublicLinksShow,
  confirmExpireNowView,
  updateExpiryDateView,
  confirmRemovePasswordView,
  updateLinkPasswordView,
  confirmRemoveExpiryDateView
} from "./LinkManagementFunction";
import moment from 'moment';
import CustomLoader from "../../components/Loader/CustomLoader";
import LinkEmpty from "./LinkEmpty";

const LinkView = () => {
  let paramsData = useParams();
  paramsData = convertParamsToArr({ params: paramsData });

  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState(paramsData);
  const [publickLinkData, setPublickLinkData] = useState<FetchData>();
  const [isSubmitting,setIsSubmitting] = useState<number[]>([]);
  const [isPasswordSubmitting,setIsPasswordSubmitting] = useState<number[]>([]);
  const [passwordError,setPasswordError] = useState<any>();
  const [password,setPassword] = useState<any>("");
  const [graphData,setGraphData] = useState<any>([]);
  let expiryData = "";

  const expiryDateRef = useRef<any>([]);
  const expiryDateFiledRef = useRef<any>([]);
  const expiryDateColRef = useRef<any>([]);

  const passwordRef = useRef<any>([]);
  const passwordFiledRef = useRef<any>([]);
  const passwordColRef = useRef<any>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (publickLinkData !== undefined) {
      setIsLoading(false);

      if(publickLinkData.status === "success"){
        const data = {
          url:publickLinkData.data.url,
          portfolio_title:publickLinkData.data.portfolio_title,
          navigate:navigate
        }
        store.dispatch(
          updateHeaderData(data),
        );
      }
    }
  }, [publickLinkData]);

  useEffect(() => {
    loadData();
  }, [params]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const expireNow = async (id:number) => {
    await confirmExpireNowView(id,setPublickLinkData,publickLinkData);
  };

  const updatePassword = async (id:number) => {
    if(password === undefined){
      const error = "Password is required1";
      setPasswordError(error);

      return false;
    }else if(password !== undefined && password === ""){
      const error = "Password is required";
      setPasswordError(error);

      return false;
    }else if(password != undefined && password.length < 6){
      const error = "Password should have 6 characters";
      setPasswordError(error);

      return false;
    }else{
      setPasswordError("");
    }

    await updateLinkPasswordView(id,password,setPublickLinkData,publickLinkData,closePasswordFiled,showHidePasswordLoader);
  };

  const removePassword = async (id:number) => {
    await confirmRemovePasswordView(id,'',setPublickLinkData,publickLinkData,closePasswordFiled,showHidePasswordLoader);
  };

  const updateExpiry = async (id:number) => {
    await updateExpiryDateView(id,expiryData,setPublickLinkData,publickLinkData,closeExpiryFiled,showHideLoader);
  };

  const removeExpire = async (id:number) => {
    await confirmRemoveExpiryDateView(id,'',setPublickLinkData,publickLinkData,closeExpiryFiled,showHideLoader);
  };

  const loadData = async () => {
    setIsLoading(true);
    
    const data:FetchData = await fetchpublicLinksShow({params});

    if(data.status === "error" && data.error === "abort"){return false;}

    setPublickLinkData(data);
  };

  const expiryDateMenu = (id:number) => {
    expiryDateRef.current.className = "text-400 bg-white border border-gray-100 rounded-md shadow-200 absolute left-0 top-[24px] z-50";
  }

  const showExpireFiled = (item:any) => {
    const id = item.id;
    expiryData = item.has_token_expiry_date !==null && item.has_token_expiry_date !== ""?item.has_token_expiry_date:"";

    expiryDateFiledRef.current.className = "flex justify-start items-center gap-3";
    expiryDateColRef.current.className = "relative hidden";
  }

  const closeExpiryFiled = (id:number) => {
    expiryDateFiledRef.current.className = "flex justify-start items-center gap-3 hidden";
    expiryDateColRef.current.className = "relative";
  }

  const showHideLoader = (id:number,isShow:boolean) => {
    if(isShow){
      setIsSubmitting(oldArray=>[...oldArray,id]);
    }else{
      const newArray:number[] = [];

      for (let i = 0; i < isSubmitting.length; i++) {
        if (isSubmitting[i] !== id) {
          newArray.push(isSubmitting[i]);
        }
      }
      setIsSubmitting(newArray);
    }
  }

  const passwordMenu = (id:number) => {
    passwordRef.current.className = "text-400 bg-white border border-gray-100 rounded-md shadow-200 absolute left-0 top-[24px] z-50";
  }

  const showPasswordFiled = (item:any) => {
    setPassword(item.password);
    passwordFiledRef.current.className = "flex justify-start items-center gap-3";
    passwordColRef.current.className = "relative hidden";
  }

  const closePasswordFiled = (id:number) => {
    passwordFiledRef.current.className = "flex justify-start items-center gap-3 hidden";
    passwordColRef.current.className = "relative";
  }

  const showHidePasswordLoader = (id:number,isShow:boolean) => {
    if(isShow){
      setIsPasswordSubmitting(oldArray=>[...oldArray,id]);
    }else{
      const newArray:number[] = [];

      for (let i = 0; i < isSubmitting.length; i++) {
        if (isPasswordSubmitting[i] !== id) {
          newArray.push(isPasswordSubmitting[i]);
        }
      }
      setIsPasswordSubmitting(newArray);
    }
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (expiryDateRef.current !== undefined) {
      for(const i in expiryDateRef.current){
        if(expiryDateRef.current !==null && !expiryDateRef.current.contains(event.target as Node)){
          expiryDateRef.current.className = "text-400 bg-white border border-gray-100 rounded-md shadow-200 absolute left-0 top-[24px] z-50 hidden";
        }
      }
    }

    if (passwordRef.current !== undefined) {
      for(const i in passwordRef.current){
        if(passwordRef.current !== null && !passwordRef.current.contains(event.target as Node)){
          passwordRef.current.className = "text-400 bg-white border border-gray-100 rounded-md shadow-200 absolute left-0 top-[24px] z-50 hidden";
        }
      }
    }
  };
  useEffect(() => {
    if(publickLinkData!=undefined)
      {
        const rawData = publickLinkData["data"].graphdata.length>0?publickLinkData["data"].graphdata:[];
        const formattedData = [["Date", "Value 1", "Value 2"], ...rawData];
        setGraphData(formattedData);
      }
  }, [publickLinkData]);

  return (
    <div className="pt-1 pb-1">
      {isLoading? (
        <div className="flex justify-center items-center w-full h-[calc(100vh-5rem)]">
        <CustomLoader/>
      </div>
        // <LinkViewLoader />
      ): publickLinkData !== undefined &&
        publickLinkData["status"] === "success"  ? (
          <>
            <div className="flex justify-between items-center">
              <div>
                <div className="flex justify-start items-center gap-8">
                  <div>
                    <span className="block text-400 text-gray-300 font-bold">
                      Generated On
                    </span>
                    <span className="block text-200 text-gray-700 font-medium mt-1">
                      {" "}
                      {publickLinkData["data"].created_at}
                    </span>
                  </div>
                  <div>
                    <span className="block text-400 text-gray-300 font-bold">
                      Generated By
                    </span>
                    <span className="block text-200 text-gray-700 font-medium mt-1">
                      {publickLinkData["data"].added_by}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex justify-end items-center gap-4">
                <div ref={el => expiryDateColRef.current = el} className="relative">
                  <div  
                    onClick={()=>{
                      if(publickLinkData["data"].status && publickLinkData["data"].expiry_date){
                        expiryDateMenu(publickLinkData["data"].id)
                      }else if(publickLinkData["data"].status){
                        showExpireFiled(publickLinkData["data"])
                      }
                    }}
                    className={`flex justify-start items-center gap-0.5 text-300 
                      ${!publickLinkData["data"].status?"opacity-70 text-primary-100":"text-gray-300 cursor-pointer"}`}
                  >
                    {publickLinkData["data"].expiry_date?(
                      <>
                        <span>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8 2V5"
                              stroke="#232323"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M16 2V5"
                              stroke="#232323"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M3.5 9.08997H20.5"
                              stroke="#232323"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                              stroke="#232323"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.9955 13.7H12.0045"
                              stroke="#232323"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.29431 13.7H8.30329"
                              stroke="#232323"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M8.29431 16.7H8.30329"
                              stroke="#232323"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </span>
                        <span className="border-b border-gray-700">{publickLinkData["data"].expiry_date?publickLinkData["data"].expiry_date:""}</span>
                      </>
                    ):(
                      <span className="border-b border-gray-700">Set Expiry Date</span>
                    )}
                  </div>
                  {publickLinkData["data"].status?(
                    <ul 
                      ref={el => expiryDateRef.current = el} className="text-400 bg-white border border-gray-100 rounded-md 
                      shadow-200 absolute left-0 top-[24px] z-50 hidden"
                    >
                      <li>
                        <button 
                          onClick={()=>{expireNow(publickLinkData["data"].id)}} 
                          className="text-400 px-6 py-2 relative font-medium text-gray-300 whitespace-nowrap hover:cursor-pointer
                           rounded-t-md hover:bg-gray-200 hover:text-gray-300 w-full"
                        >
                          Expire Now
                        </button>
                      </li>
                      <li>
                        <button 
                          onClick={()=>{showExpireFiled(publickLinkData["data"])}}
                          className="text-400 px-6 py-2 relative font-medium text-gray-300 whitespace-nowrap hover:cursor-pointer 
                            rounded-b-md hover:bg-gray-200 hover:text-gray-300 w-full"
                        >
                          Add Expiry
                        </button>
                      </li>
                      <li>
                        <button 
                          onClick={()=>{removeExpire(publickLinkData["data"].id)}}
                          className="text-400 px-6 py-2 relative font-medium text-gray-300 whitespace-nowrap hover:cursor-pointer 
                            rounded-b-md hover:bg-gray-200 hover:text-gray-300 w-full"
                        >
                          Remove Expiry
                        </button>
                      </li>
                    </ul>
                  ):""}
                </div>
                {publickLinkData["data"].status?(
                  <div 
                    ref={el => expiryDateFiledRef.current = el}
                    className="flex justify-start items-center gap-3 hidden"
                  >
                    <div className="w-[7.25rem]">
                      <CustomDateTimePicker
                        onChange={(date:Date|null,value: string|null) => {
                          expiryData = value !== null?moment(date).format("yyyy-MM-DD"):"";
                        }}
                        selectedDate={publickLinkData["data"].has_token_expiry_date}
                        minDate={moment(new Date()).format("yyyy-MM-DD")}
                        size="sm"
                      />
                    </div>
                    <div className="flex justify-start items-center gap-1.5">
                      <button disabled={indexOf(isSubmitting,publickLinkData["data"].id)} onClick={()=>{updateExpiry(publickLinkData["data"].id)}}>
                        {indexOf(isSubmitting,publickLinkData["data"].id)?<Spinner color="text-gray"/>:<CheckIcon />}
                      </button>
                      <button onClick={()=>{closeExpiryFiled(publickLinkData["data"].id)}}>
                        <svg width="23" height="23" viewBox="0 0 24 24" fill="#F15845" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#F15845" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M9.16998 14.83L14.83 9.17004" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M14.83 14.83L9.16998 9.17004" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                ):""}
                <div ref={el => passwordColRef.current = el} className="relative">
                  <div 
                    onClick={()=>{
                      if(publickLinkData["data"].status && publickLinkData["data"].password){passwordMenu(publickLinkData["data"].id)}
                      else if(publickLinkData["data"].status){showPasswordFiled(publickLinkData["data"])}
                    }}
                    className={`flex justify-start items-center gap-0.5 text-300
                      ${!publickLinkData["data"].status?"opacity-70 text-primary-100":"text-gray-300 cursor-pointer"}`}
                  >
                    <span>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17 22H7C3 22 2 21 2 17V15C2 11 3 10 7 10H17C21 10 22 11 22 15V17C22 21 21 22 17 22Z"
                          stroke="#232323"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M6 10V8C6 4.69 7 2 12 2C16.5 2 18 4 18 7"
                          stroke="#232323"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 18.5C13.3807 18.5 14.5 17.3807 14.5 16C14.5 14.6193 13.3807 13.5 12 13.5C10.6193 13.5 9.5 14.6193 9.5 16C9.5 17.3807 10.6193 18.5 12 18.5Z"
                          stroke="#232323"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span className="border-b border-gray-700">{publickLinkData["data"].password?"Edit Password":"Set Password"}</span>
                  </div>
                  {publickLinkData["data"].status?(
                    <ul 
                      ref={el => passwordRef.current = el} 
                      className="text-400 bg-white border border-gray-100 rounded-md shadow-200 absolute left-0 top-[24px] z-50 hidden"
                    >
                      <li>
                        <button 
                          onClick={()=>{showPasswordFiled(publickLinkData["data"])}}
                          className="text-400 px-6 py-2 relative font-medium text-gray-300 whitespace-nowrap hover:cursor-pointer 
                            rounded-t-md hover:bg-gray-200 hover:text-gray-300 w-full"
                        >
                          Change Password
                        </button>
                      </li>
                      <li>
                        <button onClick={()=>{removePassword(publickLinkData["data"].id)}} 
                          className="text-400 px-6 py-2 relative font-medium text-gray-300 whitespace-nowrap hover:cursor-pointer 
                            rounded-b-md hover:bg-gray-200 hover:text-gray-300 w-full"
                        >
                          Remove Password
                        </button>
                      </li>
                    </ul>
                  ):""}
                </div>
                {publickLinkData["data"].status?(
                  <div ref={el => passwordFiledRef.current = el} className="flex justify-start items-center gap-3 hidden">
                    <div className="w-[7.25rem]">
                      <TextInput
                        icon={true}
                        onChange={(event)=>{
                          setPassword(event.target.value);
                        }}
                        placeholder="Password"
                        type="password"
                        size='sm'
                        id={publickLinkData["data"].id}
                      />
                      {passwordError !== undefined && passwordError !== undefined?
                        <Typography variant="p" className="text-primary-100">{passwordError}</Typography>
                      :""}
                    </div>
                    <div className="flex justify-start items-center gap-1.5">
                      <button disabled={indexOf(isPasswordSubmitting,publickLinkData["data"].id)} onClick={()=>{updatePassword(publickLinkData["data"].id)}}>
                        {indexOf(isPasswordSubmitting,publickLinkData["data"].id)?<Spinner color="text-gray"/>:<CheckIcon />}
                      </button>
                      <button onClick={()=>{closePasswordFiled(publickLinkData["data"].id)}}>
                        <svg width="23" height="23" viewBox="0 0 24 24" fill="#F15845" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z" stroke="#F15845" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M9.16998 14.83L14.83 9.17004" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M14.83 14.83L9.16998 9.17004" stroke="#FFF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                ):""}
                <div>
                  <span className={`${publickLinkData["data"].status?"bg-pastel-300 text-blue-100":"bg-gray-600 text-primary-100"} inline-block py-1 px-2.5 text-200 font-medium rounded-full`}>
                    {publickLinkData["data"].status?"Active":"Expired"}
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-8 grid grid-cols-7 gap-4">
              <div className="col-span-1 h-full">
                <div className="bg-white rounded-md py-6 px-4 text-center h-[150px]">
                  <div className="text-400 text-gray-300 font-medium">
                    Total View Count
                  </div>
                  <div className="text-1000 text-gray-700 font-bold mt-7">{publickLinkData["data"].accessed}</div>
                </div>
              </div>
              <div className="col-span-1">
                <div className="bg-white rounded-md py-6 px-4 text-center h-[150px]">
                  <div className="text-400 text-gray-300 font-medium">
                    Total Viewer
                  </div>
                  <div className="text-1000 text-gray-700 font-bold mt-7">{publickLinkData["data"].viewer}</div>
                </div>
              </div>
              <div className="col-span-1">
                <div className="bg-white rounded-md py-6 px-4 text-center h-[150px]">
                  <div className="text-400 text-gray-300 font-medium">
                    Active Period
                  </div>
                  <div className="text-1000 text-gray-700 font-bold mt-7">{publickLinkData["data"].days}</div>
                </div>
              </div>
              <div className="col-span-4">
                <div className="rounded-md overflow-hidden">
                  {/* {graphData.length > 0?
                    <GoogleChart
                      chartType="LineChart"
                      data={graphData}
                      height="150px"
                      width="100%"
                      border-radius="6px"
                      options={{
                        chartArea: {
                            left: 40,
                            top: 20,
                            width: '80%',
                            height: '65%'
                        }
                    }}
                    />
                  :
                    <div className="mt-20 text-center">
                      <Typography variant="h5">
                        Data not found
                      </Typography>
                    </div>
                  } */}
                </div>
              </div>
            </div>
            <IPList link_id={params["link_id"]} />
          </>
      // ): publickLinkData !== undefined &&
      //   publickLinkData["status"] === "error" &&
      //   publickLinkData["error"] === 404 ? (
      //     <div className="mt-20 text-center">
      //       <Typography variant="h5">
      //         Link not found
      //       </Typography>
      //     </div>
      ): (
        // <div className="mt-20 text-center">
        //   <Typography variant="h5">Something went wrong!</Typography>
        // </div>
        <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
        <LinkEmpty/>
      </div>
      )}
    </div>
  );
};

const LinkViewLoader = () => {
  return(
    <div className="animate-pulse">
      <div className="flex justify-between items-center">
        <div>
          <div className="flex justify-start items-center gap-8">
            <div>
              <span className="block text-400 text-gray-300 font-bold">
                <div className="h-5 w-32 bg-gray-100 rounded" />
              </span>
              <span className="block text-200 text-gray-700 font-medium mt-1">
                {" "}
                <div className="h-2 w-32 bg-gray-100 rounded" />
              </span>
            </div>
            <div>
              <span className="block text-400 text-gray-300 font-bold">
                <div className="h-5 w-32 bg-gray-100 rounded" />
              </span>
              <span className="block text-200 text-gray-700 font-medium mt-1">
                <div className="h-2 w-32 bg-gray-100 rounded" />
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center gap-4">
          <div className="relative">
            <div className="flex justify-start items-center gap-0.5 cursor-pointer text-gray-300 text-300">
              <div className="h-5 w-32 bg-gray-100 rounded" />
            </div>
          </div>
          <div className="relative">
            <div className="flex justify-start items-center gap-0.5 cursor-pointer text-gray-300 text-300">
              <div className="h-5 w-32 bg-gray-100 rounded" />
            </div>
          </div>
          <div>
            <span className="h-5 w-24 text-200 text-white font-medium py-1.5 mt-2 px-4 rounded-full inline-block bg-gray-100">
            </span>
          </div>
        </div>
      </div>
      <div className="mt-10 grid grid-cols-7 gap-4">
        <div className="col-span-1 h-full">
          <div className="w-full h-[150px] bg-gray-100 rounded" />
        </div>
        <div className="col-span-1">
          <div className="w-full h-[150px] bg-gray-100 rounded" />
        </div>
        <div className="col-span-1">
          <div className="w-full h-[150px] bg-gray-100 rounded" />
        </div>
        <div className="col-span-4">
          <div className="w-full h-[150px] bg-gray-100 rounded" />
        </div>
      </div>
      <div className="mt-10 relative h-[26rem] border border-gray-100 rounded-md shadow-300">
        <div className="flex justify-between items-center bg-white shadow-300 py-4 px-5 relative w-full rounded-t-md">
          <div className="flex justify-start items-center gap-3">
            <div className="flex items-center gap-1 border border-gray-100 bg-gray-100 rounded-md px-4 py-2 h-8 w-24">
            </div>
            {/* <div className="h-5 w-32 bg-gray-100 rounded" /> */}
          </div>
          <div className="flex justify-end items-center gap-3">
            <div className="h-8 w-[14rem] bg-gray-100 rounded" />
            {/* <div className="h-5 w-32 bg-gray-100 rounded" /> */}
          </div>
        </div>
        <div className="overflow-auto h-[calc(26rem-4.5rem)] bg-white">
          <table className="min-w-full max-w-max">
            <thead className="sticky left-0 top-0 z-10">
              {Array.from({ length: 1 }).map((idx: any) => (
                <tr key={idx}>
                  {Array.from({ length: 6 }).map((idy: any) => (
                    <th key={idy} className="bg-gray-200 px-5 py-3 text-gray-700 text-300 font-bold text-left">
                      <div key={idy} className="animate-pulse">
                        <div className="flex-1 space-y-6">
                          <div className="h-5 bg-gray-100 rounded" />
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {Array.from({ length: 6 }).map((idx: any) => (
                <tr key={idx}>
                  {Array.from({ length: 6 }).map((idy: any) => (
                    <td key={idy} className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300">
                      <div className="animate-pulse">
                        <div className="flex-1 space-y-6">
                          <div className="h-5 bg-gray-100 rounded" />
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

const CheckIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
      fill="#2EB67D"
    />
  </svg>
);

export default LinkView;
