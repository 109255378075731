import React from "react";
import {
  FaFileAlt,
  FaFileAudio,
  FaFileCode,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFilePowerpoint,
  FaFileVideo,
  FaFileWord,
} from "react-icons/fa";

type Props = {
  fileName: string;
  style: any;
};
const FileIcon: React.FC<Props> = ({ fileName, style }) => {
  const extension = fileName.split(".").pop();
  switch (extension) {
    case "pdf":
      return <FaFilePdf style={style} />;
    case "doc":
    case "docx":
      return <FaFileWord style={style} />;
    case "xls":
    case "xlsx":
      return <FaFileExcel style={style} />;
    case "ppt":
    case "pptx":
      return <FaFilePowerpoint style={style} />;
    case "mp3":
    case "wav":
    case "ogg":
      return <FaFileAudio style={style} />;
    case "mp4":
    case "avi":
    case "wmv":
      return <FaFileVideo style={style} />;
    case "jpg":
    case "jpeg":
    case "png":
      return <FaFileImage style={style} />;
    case "js":
    case "css":
    case "html":
      return <FaFileCode style={style} />;
    default:
      return <FaFileAlt style={style} />;
  }
};

export default FileIcon;
