import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  convertParamsToArr,
  FetchData,
  pushArray,
  indexOf,
  showToastAlert,
} from "../../CommonFunctions";
import LinkManagementTopbar from "./LinkManagementTopbar";
import CheckBox2 from "../../components/CheckBox/CheckBox2";
import Icons from "../../components/Icons/Icon";
import Typography from "../../components/Typography/Typography";
import { CustomDateTimePicker } from "../../components/DateTimePicker/DatePicker";
import TextInput from "../../components/TextInput/TextInput";
import Button from "../../components/Button/Button";
import Spinner from "../../components/Spinner/Spinner";
import LinkManagementFilter from "./LinkManagementFilter";
import {
  fetchpublicLinks,
  confirmDeletepublicLinks,
  confirmBulckExpire,
  confirmExpireNow,
  updateExpiryDate,
  confirmRemovePassword,
  updateLinkPassword,
  confirmRemoveExpiryDate,
} from "./LinkManagementFunction";
import moment from "moment";
import LinkEmpty from "./LinkEmpty";
import Loader from "../../components/Loader/Loader";
import CustomLoader from "../../components/Loader/CustomLoader";

const LinkManagement = () => {
  let paramsData = useParams();
  paramsData = convertParamsToArr({ params: paramsData });
  paramsData["sort_by"] = ["created_at", "DESC"];
  paramsData["is_inactive"] = 0;
  paramsData["portfolios"] = [];
  paramsData["users"] = [];
  paramsData["status"] = [];
  paramsData["start_date"] = null;
  paramsData["end_date"] = null;
  paramsData["expiry_start_date"] = null;
  paramsData["expiry_end_date"] = null;
  paramsData["accessed_start_date"] = null;
  paramsData["accessed_end_date"] = null;
  paramsData["is_not_have_password"] = null;
  paramsData["is_not_have_expiry"] = 0;
  paramsData["page"] = 1;

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadMore, setIsLoadMore] = useState<Boolean>(false);
  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);
  const [publickLinkData, setPublickLinkData] = useState<FetchData>();
  const [searchInp, setSearchInp] = useState<any>(null);
  const [params, setParams] = useState(paramsData);
  const [publicLinkId, setPublicLinkId] = useState<number[]>([]);
  const [isSubmitting, setIsSubmitting] = useState<number[]>([]);
  const [isPasswordSubmitting, setIsPasswordSubmitting] = useState<number[]>(
    []
  );
  const [passwordError, setPasswordError] = useState<any>();
  const [password, setPassword] = useState<any>({});
  const [copiedItem, setCopiedItem] = useState<number>(0);
  const expiryData: any = {};
  const navigate = useNavigate();

  const expiryDateRef = useRef<any>([]);
  const expiryDateFiledRef = useRef<any>([]);
  const expiryDateColRef = useRef<any>([]);

  const passwordRef = useRef<any>([]);
  const passwordFiledRef = useRef<any>([]);
  const passwordColRef = useRef<any>([]);
  const [isFilterOpen, setIsFilterOpen] = useState<Boolean>(false);

  useEffect(() => {
    if (publickLinkData !== undefined) {
      setIsLoading(false);
    }

    setIsLoadMore(false);
  }, [publickLinkData]);

  useEffect(() => {
    setIsLoadMore(true);
    loadData(false);
  }, [params]);

  useEffect(() => {
    searchPublickLink();
  }, [searchInp]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const deletePublickLink = async (id: BigInteger) => {
    await confirmDeletepublicLinks(
      id,
      params,
      setPublickLinkData,
      publickLinkData
    );
  };

  const bulckExpire = async () => {
    if (publicLinkId.length <= 0) {
      showToastAlert("Please select at least one link");
      return false;
    }
    await confirmBulckExpire(
      publicLinkId,
      setPublicLinkId,
      setPublickLinkData,
      publickLinkData
    );
  };

  const expireNow = async (id: number, index: number) => {
    await confirmExpireNow(id, index, setPublickLinkData, publickLinkData);
  };

  const updatePassword = async (id: number) => {
    if (password[id] === undefined) {
      const error: any = {};
      error[id] = "Password is required1";
      setPasswordError(error);

      return false;
    } else if (password[id] !== undefined && password[id] === "") {
      const error: any = {};
      error[id] = "Password is required";
      setPasswordError(error);

      return false;
    } else if (password[id] != undefined && password[id].length < 6) {
      const error: any = {};
      error[id] = "Password should have 6 characters";
      setPasswordError(error);

      return false;
    } else {
      setPasswordError({});
    }

    await updateLinkPassword(
      id,
      password[id],
      setPublickLinkData,
      publickLinkData,
      closePasswordFiled,
      showHidePasswordLoader
    );
  };

  const removePassword = async (id: number) => {
    await confirmRemovePassword(
      id,
      "",
      setPublickLinkData,
      publickLinkData,
      closePasswordFiled,
      showHidePasswordLoader
    );
  };

  const updateExpiry = async (id: number, index: number) => {
    await updateExpiryDate(
      id,
      index,
      expiryData[id],
      setPublickLinkData,
      publickLinkData,
      closeExpiryFiled,
      showHideLoader
    );
  };

  const removeExpire = async (id: number, index: number) => {
    await confirmRemoveExpiryDate(
      id,
      index,
      "",
      setPublickLinkData,
      publickLinkData,
      closeExpiryFiled,
      showHideLoader
    );
  };

  const setSearchInpData = (e: any) => {
    setSearchInp(e.target.value);
  };

  const searchPublickLink = async () => {
    if (searchInp != null && (searchInp.length >= 1 || searchInp.length <= 0)) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        0: searchInp,
      });
    }
  };

  const loadData = async (isLoader = true) => {
    if (isLoader) {
      setIsLoading(true);
      setPublicLinkId([]);
      setCopiedItem(0);
      params["page"] = 1;

      expiryDateRef.current = [];
      expiryDateFiledRef.current = [];
      expiryDateColRef.current = [];
      passwordRef.current = [];
      passwordFiledRef.current = [];
      passwordColRef.current = [];
    }

    const data: FetchData = await fetchpublicLinks({ params });

    if (data.status === "error" && data.error === "abort") {
      return false;
    }

    // if (
    //   publickLinkData !== undefined &&
    //   params["page"] > 1 &&
    //   data.status === "success"
    // ) {
    //   let log = publickLinkData["data"];
    //   for (const i in data["data"]) {
    //     log = pushArray(log, data["data"][i]);
    //   }
    //   setPublickLinkData({
    //     ...publickLinkData,
    //     data: log,
    //   });
    // } else if (params["page"] === 1) {
    setPublickLinkData(data);
    // }
  };

  const selectPublicLink = (id: number) => {
    if (!indexOf(publicLinkId, id)) {
      setPublicLinkId((oldArray) => [...oldArray, id]);
    } else {
      const newArray: number[] = [];

      for (let i = 0; i < publicLinkId.length; i++) {
        if (publicLinkId[i] !== id) {
          newArray.push(publicLinkId[i]);
        }
      }
      setPublicLinkId(newArray);
    }
  };

  const selectAllPublicLink = () => {
    const newArray: number[] = [];

    if (!isAllSelected) {
      for (const i in publickLinkData?.data) {
        newArray.push(publickLinkData?.data[i].id);
      }
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }

    setPublicLinkId(newArray);
  };

  const expiryDateMenu = (id: number) => {
    expiryDateRef.current[id].className =
      "text-400 bg-white border border-gray-100 rounded-md shadow-200 absolute left-0 top-[24px] z-50";
  };

  const showExpireFiled = (item: any) => {
    const id = item.id;
    expiryData[id] =
      item.has_token_expiry_date !== null && item.has_token_expiry_date !== ""
        ? item.has_token_expiry_date
        : "";

    expiryDateFiledRef.current[id].className =
      "flex justify-start items-center gap-3";
    expiryDateColRef.current[id].className = "relative hidden";
  };

  const closeExpiryFiled = (id: number) => {
    expiryDateFiledRef.current[id].className =
      "flex justify-start items-center gap-3 hidden";
    expiryDateColRef.current[id].className = "relative";
  };

  const showHideLoader = (id: number, isShow: boolean) => {
    if (isShow) {
      setIsSubmitting((oldArray) => [...oldArray, id]);
    } else {
      const newArray: number[] = [];

      for (let i = 0; i < isSubmitting.length; i++) {
        if (isSubmitting[i] !== id) {
          newArray.push(isSubmitting[i]);
        }
      }
      setIsSubmitting(newArray);
    }
  };

  const passwordMenu = (id: number) => {
    passwordRef.current[id].className =
      "text-400 bg-white border border-gray-100 rounded-md shadow-200 absolute left-0 top-[24px] z-50";
  };

  const showPasswordFiled = (item: any) => {
    const id = item.id;
    password[item.id] = item.password;
    setPassword(password);
    passwordFiledRef.current[id].className =
      "flex justify-start items-center gap-3";
    passwordColRef.current[id].className = "relative hidden";
  };

  const closePasswordFiled = (id: number) => {
    passwordFiledRef.current[id].className =
      "flex justify-start items-center gap-3 hidden";
    passwordColRef.current[id].className = "relative";
  };

  const showHidePasswordLoader = (id: number, isShow: boolean) => {
    if (isShow) {
      setIsPasswordSubmitting((oldArray) => [...oldArray, id]);
    } else {
      const newArray: number[] = [];

      for (let i = 0; i < isSubmitting.length; i++) {
        if (isPasswordSubmitting[i] !== id) {
          newArray.push(isPasswordSubmitting[i]);
        }
      }
      setIsPasswordSubmitting(newArray);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (expiryDateRef.current !== undefined) {
      for (const i in expiryDateRef.current) {
        if (
          expiryDateRef.current[i] !== null &&
          !expiryDateRef.current[i].contains(event.target as Node)
        ) {
          expiryDateRef.current[i].className =
            "text-400 bg-white border border-gray-100 rounded-md shadow-200 absolute left-0 top-[24px] z-50 hidden";
        }
      }
    }

    if (passwordRef.current !== undefined) {
      for (const i in passwordRef.current) {
        if (
          passwordRef.current[i] !== null &&
          !passwordRef.current[i].contains(event.target as Node)
        ) {
          passwordRef.current[i].className =
            "text-400 bg-white border border-gray-100 rounded-md shadow-200 absolute left-0 top-[24px] z-50 hidden";
        }
      }
    }
  };

  const copyToClipBoard = async (id: number, copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopiedItem(id);
    } catch (err) {
      setCopiedItem(0);
    }
  };
  const setPagination = (page: number) => {
    setParams({
      ...params,
      page: page,
    });
  };
  const events = {
    loadData: loadData,
    setIsLoadMore: setIsLoadMore,
    setParams: setParams,
    deleteEvent: deletePublickLink,
    setSearchInpData: setSearchInpData,
    searchPublickLink: searchPublickLink,
    bulckExpire: bulckExpire,
  };

  return (
    <div className="flex h-[calc(100vh-7rem)] py-2 w-full overflow-auto relative flex-col">
      <div className="flex flex-grow overflow-auto w-full border border-gray-100 rounded-md shadow-300 relative">
        <LinkManagementFilter
          params={params}
          setIsLoading={setIsLoading}
          events={events}
          isFilterOpen={isFilterOpen}
          setIsFilterOpen={setIsFilterOpen}
        />
        {/* <div className="w-[calc(100%-3.75rem)] ms-[3.75rem] h-full bg-gray-200 rounded-e-md overflow-auto relative"> */}
        <div
          className={`${
            isFilterOpen
              ? `w-[calc(100%-12.125rem)] ms-[12.125rem]`
              : `w-[calc(100%-3.75rem)] ms-[3.75rem]`
          } h-full bg-gray-200 rounded-e-md overflow-auto`}
        >
          <div className="flex flex-col h-full">
            <div>
              <LinkManagementTopbar
                params={params}
                setIsLoading={setIsLoading}
                events={events}
                totalData={publickLinkData?.data?.length}
                meta={publickLinkData?.meta}
                setPagination={setPagination}
              />
            </div>
            <div className="flex flex-grow overflow-auto bg-white">
              <div className="w-full relative">
                <table className="w-full min-w-[1850px]">
                  <thead className="sticky left-0 top-0 bg-gray-200 z-10">
                    <tr>
                      <th className="bg-gray-200 px-5 py-3 ps-8 text-gray-700 text-300 font-bold text-left">
                        <CheckBox2
                          checked={isAllSelected}
                          onChange={selectAllPublicLink}
                          size="sm"
                        />
                      </th>
                      <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start w-[10rem] uppercase whitespace-nowrap">
                        Public URL
                      </th>
                      <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase whitespace-nowrap">
                        Portfolio
                      </th>
                      <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase whitespace-nowrap">
                        Generated On
                      </th>
                      <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase whitespace-nowrap">
                        Generated by
                      </th>
                      <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase whitespace-nowrap">
                        Active Period
                      </th>
                      <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase whitespace-nowrap">
                        Accessed On
                      </th>
                      <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase whitespace-nowrap">
                        Status
                      </th>
                      <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase whitespace-nowrap">
                        Expiry
                      </th>
                      <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase whitespace-nowrap">
                        Password
                      </th>
                      <th className="text-200 font-bold text-gray-700 px-5 py-4 pe-8 text-start uppercase whitespace-nowrap">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <div className="flex justify-center items-center w-full h-[calc(100vh-16.75rem)] absolute left-0">
                        <CustomLoader />
                      </div>
                    ) : // Array.from({ length: 10 }).map((idx: any) => (
                    //   <tr key={idx}>
                    //     {Array.from({ length: 11 }).map((idy: any) => (
                    //       <td
                    //         key={idy}
                    //         className="text-300 text-gray-300 font-normal px-5 py-4 ps-8 text-start border-b border-gray-400 group-hover:bg-gray-200"
                    //       >
                    //         <div className="animate-pulse">
                    //           <div className="flex-1 space-y-6">
                    //             <div className="h-5 bg-gray-100 rounded" />
                    //           </div>
                    //         </div>
                    //       </td>
                    //     ))}
                    //   </tr>
                    // ))
                    publickLinkData !== undefined &&
                      publickLinkData["status"] === "success" &&
                      publickLinkData.data.length > 0 ? (
                      publickLinkData["data"].map((item: any, idx: any) => (
                        <tr key={idx} className="group">
                          <td className="text-300 text-gray-300 font-normal px-5 py-4 ps-8 text-start border-b border-gray-400 group-hover:bg-gray-200">
                            <CheckBox2
                              checked={indexOf(publicLinkId, item.id)}
                              onChange={() => {
                                selectPublicLink(item.id);
                              }}
                              size="sm"
                            />
                          </td>
                          <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200 w-[25rem]">
                            <div
                              onClick={() => {
                                copyToClipBoard(item.id, item.url);
                              }}
                              className="flex justify-between items-center"
                            >
                              <div className="w-[20rem] truncate">
                                {item.url}
                              </div>
                              <button className="">
                                {copiedItem === item.id ? (
                                  <CheckIcon />
                                ) : (
                                  <Icons
                                    name="duplicate"
                                    variant="stroke"
                                    size={20}
                                  />
                                )}
                              </button>
                            </div>
                          </td>
                          <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                            {item.portfolio_title}
                          </td>
                          <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                            {item.created_at}
                          </td>
                          <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                            {item.added_by}
                          </td>
                          <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                            {item.days ? item.days : ""}
                            {item.days > 1 ? " days" : " day"}
                          </td>
                          <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                            {item.accessed_on}
                          </td>
                          <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                            <div>
                              <span
                                className={`${
                                  item.status
                                    ? "bg-pastel-300 text-blue-100"
                                    : "bg-gray-600 text-primary-100"
                                } inline-block py-1 px-2.5 text-200 font-medium rounded-full`}
                              >
                                {item.status ? "Active" : "Expired"}
                              </span>
                            </div>
                          </td>
                          <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200 w-[14.375rem]">
                            <div
                              ref={(el) =>
                                (expiryDateColRef.current[item.id] = el)
                              }
                              className="relative"
                            >
                              <div
                                onClick={() => {
                                  expiryDateMenu(item.id);
                                  // if(item.status && item.expiry_date){
                                  //   expiryDateMenu(item.id)
                                  // }else if(item.status){
                                  //   showExpireFiled(item)
                                  // }
                                }}
                                className={`flex justify-start items-center gap-0.5 
                            ${
                              !item.status
                                ? "opacity-70 text-primary-100"
                                : "cursor-pointer"
                            }`}
                              >
                                {item.expiry_date ? (
                                  <>
                                    <span>
                                      <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M8 2V5"
                                          stroke="#232323"
                                          strokeWidth="1.5"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M16 2V5"
                                          stroke="#232323"
                                          strokeWidth="1.5"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M3.5 9.08997H20.5"
                                          stroke="#232323"
                                          strokeWidth="1.5"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                                          stroke="#232323"
                                          strokeWidth="1.5"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M11.9955 13.7H12.0045"
                                          stroke="#232323"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M8.29431 13.7H8.30329"
                                          stroke="#232323"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M8.29431 16.7H8.30329"
                                          stroke="#232323"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </span>
                                    <span className="border-b border-gray-700">
                                      {item.expiry_date ? item.expiry_date : ""}
                                    </span>
                                  </>
                                ) : (
                                  <span className="border-b border-gray-700">
                                    Set Expiry Date
                                  </span>
                                )}
                              </div>
                              {item.status ? (
                                <ul
                                  ref={(el) =>
                                    (expiryDateRef.current[item.id] = el)
                                  }
                                  className="text-400 bg-white border border-gray-100 rounded-md shadow-200 absolute left-0 top-[24px] z-50 hidden"
                                >
                                  <li>
                                    <button
                                      onClick={() => {
                                        expireNow(item.id, idx);
                                      }}
                                      className="text-400 px-6 py-2 relative font-medium text-gray-300 whitespace-nowrap hover:cursor-pointer 
                                  rounded-t-md hover:bg-gray-200 hover:text-gray-300 w-full"
                                    >
                                      Expire Now
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      onClick={() => {
                                        showExpireFiled(item);
                                      }}
                                      className="text-400 px-6 py-2 relative font-medium text-gray-300 whitespace-nowrap 
                                  hover:cursor-pointer rounded-b-md hover:bg-gray-200 hover:text-gray-300 w-full"
                                    >
                                      Edit Expiry
                                    </button>
                                  </li>
                                  {item.expiry_date ? (
                                    <li>
                                      <button
                                        onClick={() => {
                                          removeExpire(item.id, idx);
                                        }}
                                        className="text-400 px-6 py-2 relative font-medium text-gray-300 whitespace-nowrap 
                                    hover:cursor-pointer rounded-b-md hover:bg-gray-200 hover:text-gray-300 w-full"
                                      >
                                        Remove Expiry
                                      </button>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                </ul>
                              ) : (
                                ""
                              )}
                            </div>
                            {item.status ? (
                              <div
                                ref={(el) =>
                                  (expiryDateFiledRef.current[item.id] = el)
                                }
                                className="flex justify-start items-center gap-3 hidden"
                              >
                                <div className="w-[7.25rem] small-datepicker">
                                  <CustomDateTimePicker
                                    onChange={(
                                      date: Date | null,
                                      value: string | null
                                    ) => {
                                      expiryData[item.id] =
                                        value !== null
                                          ? moment(date).format("yyyy-MM-DD")
                                          : "";
                                    }}
                                    selectedDate={item.has_token_expiry_date}
                                    minDate={moment(new Date()).format(
                                      "yyyy-MM-DD"
                                    )}
                                    size="sm"
                                  />
                                </div>
                                <div className="flex justify-start items-center gap-1.5">
                                  <button
                                    disabled={indexOf(isSubmitting, item.id)}
                                    onClick={() => {
                                      updateExpiry(item.id, idx);
                                    }}
                                  >
                                    {indexOf(isSubmitting, item.id) ? (
                                      <Spinner color="text-primary-100" />
                                    ) : (
                                      <CheckIcon />
                                    )}
                                  </button>
                                  <button
                                    onClick={() => {
                                      closeExpiryFiled(item.id);
                                    }}
                                  >
                                    <svg
                                      width="23"
                                      height="23"
                                      viewBox="0 0 24 24"
                                      fill="#F15845"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                        stroke="#F15845"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M9.16998 14.83L14.83 9.17004"
                                        stroke="#FFF"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M14.83 14.83L9.16998 9.17004"
                                        stroke="#FFF"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </td>
                          <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200 w-[13.875rem]">
                            <div
                              ref={(el) =>
                                (passwordColRef.current[item.id] = el)
                              }
                              className="relative"
                            >
                              <div
                                onClick={() => {
                                  if (item.status && item.password) {
                                    passwordMenu(item.id);
                                  } else if (item.status) {
                                    showPasswordFiled(item);
                                  }
                                }}
                                className={`flex justify-start items-center gap-0.5 
                            ${
                              !item.status
                                ? "opacity-70 text-primary-100"
                                : "cursor-pointer"
                            }`}
                              >
                                <span>
                                  <svg
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M17 22H7C3 22 2 21 2 17V15C2 11 3 10 7 10H17C21 10 22 11 22 15V17C22 21 21 22 17 22Z"
                                      stroke="#232323"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M6 10V8C6 4.69 7 2 12 2C16.5 2 18 4 18 7"
                                      stroke="#232323"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M12 18.5C13.3807 18.5 14.5 17.3807 14.5 16C14.5 14.6193 13.3807 13.5 12 13.5C10.6193 13.5 9.5 14.6193 9.5 16C9.5 17.3807 10.6193 18.5 12 18.5Z"
                                      stroke="#232323"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>
                                <span className="border-b border-gray-700">
                                  {item.password
                                    ? "Edit Password"
                                    : "Set Password"}
                                </span>
                              </div>
                              {item.status ? (
                                <ul
                                  ref={(el) =>
                                    (passwordRef.current[item.id] = el)
                                  }
                                  className="text-400 bg-white border border-gray-100 rounded-md shadow-200 absolute left-0 top-[24px] z-50 hidden"
                                >
                                  <li>
                                    <button
                                      onClick={() => {
                                        showPasswordFiled(item);
                                      }}
                                      className="text-400 px-6 py-2 relative font-medium text-gray-300 whitespace-nowrap 
                                  hover:cursor-pointer rounded-t-md hover:bg-gray-200 hover:text-gray-300 w-full"
                                    >
                                      Change Password
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      onClick={() => {
                                        removePassword(item.id);
                                      }}
                                      className="text-400 px-6 py-2 relative font-medium text-gray-300 whitespace-nowrap 
                                  hover:cursor-pointer rounded-b-md hover:bg-gray-200 hover:text-gray-300 w-full"
                                    >
                                      Remove Password
                                    </button>
                                  </li>
                                </ul>
                              ) : (
                                ""
                              )}
                            </div>
                            {item.status ? (
                              <div
                                ref={(el) =>
                                  (passwordFiledRef.current[item.id] = el)
                                }
                                className="flex justify-start items-center gap-3 hidden"
                              >
                                <div className="w-[7.25rem]">
                                  <TextInput
                                    icon={true}
                                    onChange={(event) => {
                                      password[item.id] = event.target.value;
                                      setPassword(password);
                                    }}
                                    placeholder="Password"
                                    type="password"
                                    size="sm"
                                    // value={password !== undefined && password[item.id] !== undefined?password[item.id]:""}
                                    id={item.id}
                                  />
                                  {passwordError !== undefined &&
                                  passwordError[item.id] !== undefined ? (
                                    <Typography
                                      variant="p"
                                      className="text-primary-100"
                                    >
                                      {passwordError[item.id]}
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="flex justify-start items-center gap-1.5">
                                  <button
                                    disabled={indexOf(
                                      isPasswordSubmitting,
                                      item.id
                                    )}
                                    onClick={() => {
                                      updatePassword(item.id);
                                    }}
                                  >
                                    {indexOf(isPasswordSubmitting, item.id) ? (
                                      <Spinner color="text-primary-100" />
                                    ) : (
                                      <CheckIcon />
                                    )}
                                  </button>
                                  <button
                                    onClick={() => {
                                      closePasswordFiled(item.id);
                                    }}
                                  >
                                    <svg
                                      width="23"
                                      height="23"
                                      viewBox="0 0 24 24"
                                      fill="#F15845"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                        stroke="#F15845"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M9.16998 14.83L14.83 9.17004"
                                        stroke="#FFF"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M14.83 14.83L9.16998 9.17004"
                                        stroke="#FFF"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </td>
                          <td className="text-300 text-gray-300 font-normal px-5 py-4 pe-8 text-start border-b border-gray-400 group-hover:bg-gray-200">
                            <div>
                              <Button
                                label="View"
                                size="small"
                                variant="primary"
                                className="!text-200"
                                onClick={() => {
                                  navigate("/links/" + item.id + "/view");
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      // ) : publickLinkData !== undefined &&
                      //   publickLinkData["status"] === "error" &&
                      //   publickLinkData["error"] === 404 ? (
                      // <tr>
                      //   <td
                      //     colSpan={7}
                      //     className="text-left py-4 px-5 text-gray-300 text-300"
                      //   >
                      //     <div className="flex justify-center items-center pt-36">
                      //       <div className="text-center">
                      //         <Typography
                      //           className="text-gray-700 font-medium"
                      //           variant="h6"
                      //         >
                      //           No link added
                      //         </Typography>
                      //       </div>
                      //     </div>
                      //   </td>
                      // </tr>
                      <div className="flex justify-center items-center w-full h-[calc(100vh-16.75rem)] absolute left-0">
                        <LinkEmpty />
                      </div>
                      // <tr>
                      //   <td
                      //     colSpan={11}
                      //     className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300"
                      //   >
                      //     <div className="mt-20 text-center">
                      //       <Typography variant="h5">
                      //         Something went wrong!
                      //       </Typography>
                      //     </div>
                      //   </td>
                      // </tr>
                    )}
                  </tbody>
                </table>
                {!isLoading ? (
                  <div className="w-full flex justify-center items-center py-6">
                    {isLoadMore ? (
                      <div className="flex justify-center items-center w-full h-[calc(100vh-4rem)]">
                        {/* <CustomLoader /> */}
                      </div>
                    ) : // <div className="flex mt-2">
                    //   <svg
                    //     width="32"
                    //     height="32"
                    //     viewBox="0 0 32 32"
                    //     fill="none"
                    //     xmlns="http://www.w3.org/2000/svg"
                    //     className="duration-200 animate-pulse-3"
                    //   >
                    //     <circle cx="12" cy="6" r="6" fill="#F15845" />
                    //   </svg>
                    //   <svg
                    //     width="32"
                    //     height="32"
                    //     viewBox="0 0 32 32"
                    //     fill="none"
                    //     xmlns="http://www.w3.org/2000/svg"
                    //     className="duration-300 animate-pulse-2"
                    //   >
                    //     <circle cx="12" cy="6" r="6" fill="#F15845" />
                    //   </svg>
                    //   <svg
                    //     width="32"
                    //     height="32"
                    //     viewBox="0 0 32 32"
                    //     fill="none"
                    //     xmlns="http://www.w3.org/2000/svg"
                    //     className="duration-400 animate-pulse"
                    //   >
                    //     <circle cx="12" cy="6" r="6" fill="#F15845" />
                    //   </svg>
                    // </div>
                    publickLinkData !== undefined &&
                      publickLinkData.status === "success" &&
                      params["page"] < publickLinkData.meta.last_page ? (
                        <></>
                      // <button
                      //   className="text-primary-100 text-300 font-semibold"
                      //   onClick={() => {
                      //     events.setParams({
                      //       ...params,
                      //       page: params["page"] + 1,
                      //     });
                      //   }}
                      // >
                      //   Load More...
                      // </button>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
                {/* <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
                  <LinkEmpty/>
                </div> */}
                {/* <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
                  <CustomLoader/>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CheckIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
      fill="#2EB67D"
    />
  </svg>
);

export default LinkManagement;
