import React from "react";
import { useNavigate } from "react-router-dom";
import Typography from "../../components/Typography/Typography";
import ActionDropdown from "../../components/ActionDropdown/ActionDropdown";
import productOneImage from "../../images/download.svg";
import { updateProjectStatus } from "./ProjectFunction";
import styled from 'styled-components';
import Icons from "../../components/Icons/Icon";
import { Link } from "react-router-dom";
import store from "../../store";
import { updateHeaderData } from "../../User";

export const ImgOverlay = styled.div`
  .block-area{
    position:relative;
    transition: 0.3s
  }
  .overLay{
    position:absolute;
    bottom: 0;
    left:0;
    height: 100%;
    width:100%;
    background: rgba(0,0,0,0.2);
    -webkit-transition: -webkit-transform 0.35s;
    transition: all .3s ease;
    display:none;
  }
  .block-area:hover .overLay{
    display:block;
  }
`;

const SingleProjectArchive = (props: any) => {
  const project = props.project;
  const navigate = useNavigate();
  const loadData = props.loadData;

  const UnarchiveProject = async (id: number) => {
    await updateProjectStatus(id, 1, loadData,"status");
  };

  const handlePortfolioClick = () => {
    const data = {
      project_title: project.project_title,
      navigate: navigate
    };
    store.dispatch(
      updateHeaderData(data),
    );
  };

  return (
    <div className="">
      <ImgOverlay>
      <Link
          to={"/projects/preview/" + project.id}
          className="block-area h-[12.25rem] w-full overflow-hidden flex justify-center items-center rounded-md relative hover:duration-700"
          onClick={handlePortfolioClick}
        >
        {/* <div className="block-area h-[12.25rem] w-full overflow-hidden flex justify-center items-center rounded-md relative hover:duration-700"> */}
          {project.cover !== "" ? (
            <img
              src={project.cover}
              alt={project.project_title}
              className="w-full h-full object-cover"
            />
          ) : (
            <img
              src={productOneImage}
              alt={project.project_title}
              className="w-full h-full object-cover"
            />
          )}
          <div className="absolute left-3 top-3 h-8 w-8 flex justify-center items-center bg-white rounded-full"><span><Icons name="archived-projects" size={16}/></span></div>
          <div className="overLay px-5 py-5">
              <div className="h-full w-full flex justify-start items-end">
                <div>
                  <Typography
                    className="text-white font-normal mt-1.5"
                    size="md"
                    variant="p"
                  >
                    {project.project_title}
                  </Typography>
                  <ul className="inline-block mt-1">
                    {project.tags !== "" && project.tags !== null
                    ? project.tags.split(",").map((item: any, idx: any) => (
                        <li key={idx} className="inline-block me-1 last:me-0">
                          <a
                            href="#"
                            className="text-200 font-light text-white bg-gray-700 px-2 py-0.5 rounded-full truncate max-w-[4.5rem] inline-block"
                          >
                            {item}
                          </a>
                        </li>
                      ))
                    : ""}
                  </ul>
                  <Typography
                    className="text-white font-light mt-1.5 line-clamp-2"
                    size="sm"
                    variant="p"
                  >
                    {project.project_description}
                  </Typography>
                  <Typography
                    className="text-white font-normal mt-1.5"
                    size="sm"
                    variant="p"
                  >
                    {project.added_by}
                  </Typography>
                  <Typography
                    className="text-white font-normal mt-1.5"
                    size="sm"
                    variant="p"
                  >
                    {project.created_at}
                  </Typography>
                </div>
              </div>
            </div>
        {/* </div> */}
        </Link>
      </ImgOverlay>
      <div className="flex justify-between items-start gap-2 mt-2">
        <div>
          <Typography variant="h6" className="text-primary-100 font-semibold line-clamp-1">
            {project.project_title}
          </Typography>
          {/* <Typography
            className="text-gray-300 font-bold mt-2.5"
            size="md"
            variant="p"
          >
            {project.added_by}
          </Typography> */}
        </div>
        <div>
          <ActionDropdown
            //defaultValue="View"
            defaultValue="Unarchive"
            onSelect={(value) => {
              if (value === "View") {
                navigate("/projects/preview/" + project.id);
              } else {
                UnarchiveProject(project.id);
              }
            }}
            options={[
              // {
              //   label: "Preview",
              //   value: "View",
              // },
              {
                label: "Unarchive",
                value: "Unarchive",
              },
            ]}
            value=""
          />
        </div>
      </div>
      {/* <ul className="inline-block">
        {project.tags !== "" && project.tags !== null
          ? project.tags.split(",").map((item: any, idx: any) => (
              <li key={idx} className="inline-block mt-2 me-2">
                <a
                  href="#"
                  className="text-200 font-bold text-white bg-gray-700 px-4 py-1 rounded-full"
                >
                  {item}
                </a>
              </li>
            ))
          : ""}
      </ul> */}
    </div>
  );
};

export default SingleProjectArchive;
