import React, { useState, useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  selectChangeHandler,
  inputChangeHandler,
  FetchData,
  array_search,
  updateAssocciativeArray,
  unsetAssocciativeArray
} from "../../CommonFunctions";
import { ErrorAPI500, ErrorAPI404 } from "../../components/Errors/Errors";
import { Modal } from "../../components/Modal/Modal";
import { ModalHeader } from "../../components/Modal/Modal";
import { ModalBody } from "../../components/Modal/Modal";
import { ModalFooter } from "../../components/Modal/Modal";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import TextInput from "../../components/TextInput/TextInput";
import Textarea from "../../components/TextArea/Textarea";
import TagInput from "../../components/TagInput/TagInput";
import CustomDropDown, {
  SelectOptions,
} from "../../components/Formik/CustomDropDown";
import { CustomDateTimePicker } from "../../components/DateTimePicker/DatePicker";
import Checkbox from "../../components/CheckBox/CheckBox";
import {
  fetchProjectShow,
  fetchCategories,
  InputFiled,
  saveProject,
  validationSchema,
} from "./ProjectFunction";
import Spinner from "../../components/Spinner/Spinner";
import moment from 'moment';
import Icons from "../../components/Icons/Icon";
import CustomLoader from "../../components/Loader/CustomLoader";

type Props = {
  onClose: () => void;
  project_id:number;
  navigate: any;
};

class CreateProjectModal extends React.Component {
  static async fire(id: number, navigate: any) {
    const container = document.createElement("div");
    container.id = "custom-project-container";
    const root = createRoot(container);
    document.body.appendChild(container);

    const close = () => {
      root.unmount();
      container.remove();
      return false;
    };

    root.render(
      <ModalComponent
        onClose={close}
        project_id={id}
        navigate={navigate}
      />,
    );
  }

  render() {
    return null;
  }
}

type CustomFiled = {
  label:string;
  value:string | null;
  required:boolean | null;
}[];

const ModalComponent: React.FC<Props> = ({
  onClose,
  project_id,
  navigate,
}) => {

  let formData: InputFiled = {
    project_title: "",
    project_description: "",
    category_id: "",
    client_name: "",
    tags: "",
    start_date: null,
    end_date: null,
    notes: "",
    industries: "",
    technologies: "",
    status: 0,
    custom_fields: [],
    is_featured: 0,
  };

  let categoriesDefualtValue: SelectOptions = { label: "Select Service Area", value: "" };

  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState<FetchData>();
  const [FormData, setFormData] = useState<InputFiled>(formData);
  const [categoriesValue, setCategoriesValue] = useState<SelectOptions>(categoriesDefualtValue);
  const [categories, setCategories] = useState([]);
  const [isFromSubmiting, setIsFromSubmiting] = useState(false);
  const [isAddNewFiled, setIsAddNewFiled] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const [addNewFiledInput,setAddNewFiledInput] = useState<string>('');
  const [addNewFiledCheckBox,setAddNewFiledCheckBox] = useState<boolean>(false);
  const [showError,setShowError] = useState<string>("");
  const [customFiledError,setCustomFiledError] = useState({} as any);
  const [customFields,setcustomFields] = useState<CustomFiled>(formData['custom_fields']);
  const [minDate, setMinDate] = useState<string | null>(null);
  const [technologiesError, setTechnologiesError] = useState<string>("");
  useEffect(() => {
    formData = {
      project_title:
        project !== undefined && project["data"] !== undefined ? project["data"].project_title : "",
      project_description:
        project !== undefined && project["data"] !== undefined ? project["data"].project_description : "",
      category_id:
        project !== undefined && project["data"] !== undefined ? project["data"].category_id : "",
      client_name:
        project !== undefined && project["data"] !== undefined ? project["data"].client_name : "",
      tags: project !== undefined && project["data"] !== undefined ? project["data"].tags : "",
      start_date: project !== undefined && project["data"] !== undefined ? project["data"].start_date : null,
      end_date: project !== undefined && project["data"] !== undefined ? project["data"].end_date : null,
      notes: project !== undefined && project["data"] !== undefined ? project["data"].notes : "",
      industries: project !== undefined && project["data"] !== undefined ? project["data"].industries : "",
      technologies:
        project !== undefined && project["data"] !== undefined ? project["data"].technologies : "",
      status: project !== undefined && project["data"] !== undefined ? project["data"].status : 0,
      custom_fields: project !== undefined && project["data"] !== undefined && project["data"].custom_fields !== null && project["data"].custom_fields !== "" ? project["data"].custom_fields : [],
      is_featured: 0,
    };

    categoriesDefualtValue =
    project !== undefined && project["data"] !== undefined && project["data"]["category_id"] !== null && project["data"]["category_id"] !== ""
      ? {
          label: project["data"].category_name,
          value: project["data"].category_id,
        }
      : { label: "Select Service Area", value: "" };

    setcustomFields(formData["custom_fields"]);
    setFormData(formData);
    setCategoriesValue(categoriesDefualtValue);
  }, [project]);

  useEffect(() => {
    if(isLoading === true){
      const loadData = async () => {
        if(project_id !== 0){
          setProject(await loadProject(project_id));
        }else{
          setProject({ status: "success" });
        }
        
        setCategories(await loadCategories());

        setIsLoading(false);
      };
      
      loadData();
    }
  },[isLoading]);

  useEffect(() => {
    setAddNewFiledInput('');
    setAddNewFiledCheckBox(false);
    setShowError('');
  }, [isAddNewFiled]);

  useEffect(() => {
    if(array_search(customFields,"label",addNewFiledInput)){
      setShowError('Filed name cannot be same');
    }else{
      setShowError('');
    }
  },[addNewFiledInput,addNewFiledCheckBox]);

  const loadProject = async (id: number) => {
    const params = {
      0: id,
    };

    return await fetchProjectShow({ params });
  };

  // const loadCategories = async () => {
  //   const fetchData:FetchData = await fetchCategories();
  //   const data = fetchData["status"] === "success" ? fetchData["data"] : [];

  //   const options: any = [];
  //   options[0] = { label: "Select Category", value: "" };
  //   let j = 1;
  //   for (const i in data) {
  //     options[j] = { label: data[i].category_name, value: data[i].id };
  //     j++;
  //   }

  //   return options;
  // };
  const loadCategories = async () => {
    const fetchData: FetchData = await fetchCategories();
    const data = fetchData["status"] === "success" ? fetchData["data"] : [];

    // Filter data where status === 1
    const filteredData = data.filter((item:any) => item.status === 1);

    const options: any = [];
    options[0] = { label: "Select Category", value: "" };
    let j = 1;
    for (const item of filteredData) {
      options[j] = { label: item.category_name, value: item.id };
      j++;
    }

    return options;
  };
  const addNewFiled = (setValues:any,formData:any) => {

    if(array_search(customFields,"label",addNewFiledInput)){
      setShowError('Filed name cannot be same');
      return false;
    }else if(addNewFiledInput === undefined || addNewFiledInput === "" || addNewFiledInput === null){
      setShowError('Field name required');
      return false;
    }else{
      setShowError('');
    }

    const filed = {
      label:addNewFiledInput,
      value:'',
      required:addNewFiledCheckBox,
    }
    customFiledError[addNewFiledInput as keyof typeof customFiledError] = false;

    setCustomFiledError(customFiledError);
    setcustomFields(oldcustomFields => [...oldcustomFields,filed]);
    setAddNewFiledInput('');
    setAddNewFiledCheckBox(false);
  };

  const submitHandler = async (values: InputFiled, { setErrors }: any) => {
    for(const i in customFields){
      if(
        (
          customFields[i].value === ""
          || customFields[i].value === null
          || customFields[i].value === undefined
        ) 
        && customFields[i]?.required
      ){
        customFiledError[customFields[i].label as keyof typeof customFiledError] = true;
      }else{
        customFiledError[customFields[i].label as keyof typeof customFiledError] = false;
      }
    }
    setCustomFiledError(customFiledError);

    for(const i in customFiledError){
      if(customFiledError[i]){
        return false;
      }
    }

    if(values["start_date"] !== "" && values["end_date"] !== ""){
      const dateLimit = moment(values["start_date"]);
      const now = moment(values["end_date"]);

      if(now.isBefore(dateLimit)){
        setErrors({"end_date":"End date can not be less than start date"});
        return false;
      }
    } 
    console.log(customFields)
    values["custom_fields"] = customFields
    await saveProject({
      setIsFromSubmiting: setIsFromSubmiting,
      projectsData: project,
      formData: values,
      setErrorMessage: setErrors,
      navigate: navigate,
      close: onClose,
    });
  };

  return (
    <>
      {isLoading === true ? (
        <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
        <CustomLoader/>
      </div>
        // <Modal
        //   isOpen
        //   onClose={onClose}
        //   size="medium"
        //   placement="right"
        // >
        //   <ModalHeader className="bg-gray-200">
        //     <div className="animate-pulse">
        //       <div className="flex-1 space-y-6">
        //         <div className="h-5 w-72 bg-gray-100 rounded" />
        //       </div>
        //     </div>
        //   </ModalHeader>
        //   <ModalBody>
        //     <div className="grid grid-cols-2 gap-16">
        //       <div>
        //         <div>
        //           <div className="animate-pulse">
        //             <div className="flex-1 space-y-2">
        //               <div className="h-5 w-40 bg-gray-100 rounded" />
        //               <div className="h-10 bg-gray-100 rounded" />
        //             </div>
        //           </div>
        //         </div>
        //         <div className="mt-7">
        //           <div className="animate-pulse">
        //             <div className="flex-1 space-y-2">
        //               <div className="h-5 w-40 bg-gray-100 rounded" />
        //               <div className="h-10 bg-gray-100 rounded" />
        //             </div>
        //           </div>
        //         </div>
        //         <div className="mt-7">
        //           <div className="animate-pulse">
        //             <div className="flex-1 space-y-2">
        //               <div className="h-5 w-40 bg-gray-100 rounded" />
        //               <div className="h-32 bg-gray-100 rounded" />
        //             </div>
        //           </div>
        //         </div>
        //         <div className="mt-7 grid grid-cols-2 gap-4">
        //           <div>
        //             <div className="animate-pulse">
        //               <div className="flex-1 space-y-2">
        //                 <div className="h-5 w-40 bg-gray-100 rounded" />
        //                 <div className="h-10 bg-gray-100 rounded" />
        //               </div>
        //             </div>
        //           </div>
        //           <div>
        //             <div className="animate-pulse">
        //               <div className="flex-1 space-y-2">
        //                 <div className="h-5 w-40 bg-gray-100 rounded" />
        //                 <div className="h-10 bg-gray-100 rounded" />
        //               </div>
        //             </div>
        //           </div>
        //         </div>
        //         <div className="mt-7">
        //           <div className="animate-pulse">
        //             <div className="flex-1 space-y-2">
        //               <div className="h-5 w-40 bg-gray-100 rounded" />
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //       <div>
        //         <div>
        //           <div className="animate-pulse">
        //             <div className="flex-1 space-y-2">
        //               <div className="h-5 w-40 bg-gray-100 rounded" />
        //               <div className="h-32 bg-gray-100 rounded" />
        //             </div>
        //           </div>
        //         </div>
        //         <div className="mt-7">
        //           <div className="animate-pulse">
        //             <div className="flex-1 space-y-2">
        //               <div className="h-5 w-40 bg-gray-100 rounded" />
        //               <div className="h-10 bg-gray-100 rounded" />
        //             </div>
        //           </div>
        //         </div>
        //         <div className="mt-7">
        //           <div className="animate-pulse">
        //             <div className="flex-1 space-y-2">
        //               <div className="h-5 w-40 bg-gray-100 rounded" />
        //               <div className="h-10 bg-gray-100 rounded" />
        //             </div>
        //           </div>
        //         </div>
        //         <div className="mt-7">
        //           <div className="animate-pulse">
        //             <div className="flex-1 space-y-2">
        //               <div className="h-5 w-40 bg-gray-100 rounded" />
        //               <div className="h-10 bg-gray-100 rounded" />
        //             </div>
        //           </div>
        //         </div>
        //         <div className="mt-7">
        //           <div className="animate-pulse">
        //             <div className="flex-1 space-y-2">
        //               <div className="h-5 w-40 bg-gray-100 rounded" />
        //               <div className="h-10 bg-gray-100 rounded" />
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </ModalBody>
        //   <ModalFooter>
        //     <div className="animate-pulse">
        //       <div className="flex gap-5">
        //         <div className="h-14 w-40 bg-gray-100 rounded" />
        //         <div className="h-14 w-40 bg-gray-100 rounded" />
        //       </div>
        //     </div>
        //   </ModalFooter>
        // </Modal>
      ) : project !== undefined && project["status"] === "success" ? (
        <Formik
          initialValues={FormData}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ setValues, values,setErrors,errors }) => (
            <Form>
              <Modal
                isOpen
                onClose={onClose}
                size="medium"
                placement="right"
              >
                <ModalHeader className="bg-gray-200">
                  <Typography className="font-bold" variant="h3">
                    {project["data"] !== undefined
                      ? project["data"]["project_title"]
                      : "Create a Project"}
                  </Typography>
                </ModalHeader>
                <ModalBody className="!px-8 !py-7">
                  <div className="grid grid-cols-2 gap-16">
                    <div className="w-[27.5rem]">
                      <div>
                        <label className="text-400 font-medium text-gray-300 block mb-2">
                          Name of Project
                          <span className="text-primary-100 ms-0.5 mt-1">*</span>
                        </label>
                        <TextInput
                          name="project_title"
                          onChange={(event) => {
                            inputChangeHandler(event.target.value, {
                              setValues: setValues,
                              key: "project_title",
                              formData: values,
                            });
                          }}
                          placeholder="Enter Project Name"
                          type="text"
                          value={values["project_title"]}
                        />
                        <ErrorMessage
                          name="project_title"
                          component="div"
                          className="error text-error text-200 mt-1"
                        />
                      </div>
                      <div className="mt-7">
                        <label className="text-400 font-medium text-gray-300 block mb-2">
                          Client Name<span className="text-primary-100 ms-0.5">*</span>
                        </label>
                        <TextInput
                          name="client_name"
                          onChange={(event) => {
                            inputChangeHandler(event.target.value, {
                              setValues: setValues,
                              key: "client_name",
                              formData: values,
                            });
                          }}
                          placeholder="Enter Client Name"
                          type="text"
                          value={values["client_name"]}
                        />
                        <ErrorMessage
                          name="client_name"
                          component="div"
                          className="error text-error text-200 mt-1"
                        />
                      </div>
                      <div className="mt-7">
                        <label className="text-400 font-medium text-gray-300 block mb-2">
                          Description
                        </label>
                        <Textarea
                          name="project_description"
                          onChange={(event) => {
                            const valued = event.target.value;
                            inputChangeHandler(valued, {
                              setValues: setValues,
                              key: "project_description",
                              formData: values,
                            });
                            if (valued.length > 1000) {
                              return;
                            }
                            setCharacterCount(values.project_description.length);
                          }}
                          placeholder="Enter text"
                          value={values["project_description"]}
                          maxCharacters={1000}
                          resize
                        />
                        <div className="text-right text-200">
                          {characterCount}/1000
                        </div>
                        {/* {values.project_description.length > 1000 && (
                          <div className="error text-error text-200 mt-1">
                            Please limit your description to 1000 characters.
                          </div>
                        )} */}
                        <ErrorMessage
                          name="project_description"
                          component="div"
                          className="error text-error text-200 mt-1"
                        />
                      </div>
                      <div className="mt-7 grid grid-cols-2 gap-4">
                        <div>
                          <label className="text-400 font-medium text-gray-300 block mb-2">
                            Project Start Date
                          </label>
                          <CustomDateTimePicker
                            name="start_date"
                            dateFormat="YYYY-MM-DD"
                            onChange={(date:Date|null,value: string|null) => {
                              setMinDate(value);
                              inputChangeHandler(value, {
                                setValues: setValues,
                                key: "start_date",
                                formData: values,
                              });
                            }}
                            selectedDate={values["start_date"]}
                            maxDate={moment(new Date()).format("yyyy-MM-DD")}
                          />
                          <ErrorMessage
                            name="start_date"
                            component="div"
                            className="error text-error text-200 mt-1"
                          />
                        </div>
                        <div>
                          <label className="text-400 font-medium text-gray-300 block mb-2">
                            Project End Date
                          </label>
                          <CustomDateTimePicker
                            name="end_date"
                            dateFormat="YYYY-MM-DD"
                            onChange={(date:Date|null,value: string|null) => {
                              inputChangeHandler(value, {
                                setValues: setValues,
                                key: "end_date",
                                formData: values,
                              });
                            }}
                            selectedDate={values["end_date"]}
                            minDate={values["start_date"] !== ""?values["start_date"]:minDate !== null?minDate:null}
                            maxDate={moment(new Date()).format("yyyy-MM-DD")}
                          />
                          <ErrorMessage
                            name="end_date"
                            component="div"
                            className="error text-error text-200 mt-1"
                          />
                        </div>
                      </div>
                      {customFields !== null && customFields !== undefined && customFields.length > 0?
                        customFields.map((item: any, idx: any) =>(
                          <div key={idx} className="mt-7">
                            <label className="text-400 font-medium text-gray-300 block mb-2">
                              {item.label}{item.required?<span className="text-primary-100">&nbsp;*</span>:""} 
                            </label>
                            <div className="flex gap-2">
                              <TextInput
                                onChange={(event) => {
                                  const fileds = updateAssocciativeArray(customFields,"label",item.label,"value",event.target.value);

                                  if(item.required && event.target.value.length <= 0){
                                    customFiledError[item.label as keyof typeof customFiledError] = true;
                                  }else{
                                    customFiledError[item.label as keyof typeof customFiledError] = false;
                                  }
                                  
                                  setcustomFields(fileds);
                                  setCustomFiledError(customFiledError);
                                  inputChangeHandler(JSON.stringify(customFields), {
                                    setValues: setValues,
                                    key: "custom_fields",
                                    formData: values,
                                  });
                                }}
                                placeholder={"Enter "+item.label}
                                type="text"
                                value={item.value}
                                className="!w-[33.2rem]"
                              />
                              <Button 
                                label={<Icons name="delete" />}
                                type="button"
                                variant="outline"
                                className="text-primary-100 pt-3"
                                onClick={()=>{
                                  const newArray:CustomFiled = [];

                                  for (let i = 0; i < customFields.length; i++) {
                                    if (customFields[i]["label"] !== item.label) {
                                      newArray.push(customFields[i]);
                                    }
                                  }
                                  customFiledError[item.label as keyof typeof customFiledError] = false;

                                  setcustomFields(newArray);
                                  setCustomFiledError(customFiledError);
                                }}
                              />
                            </div>
                            {customFiledError[item.label as keyof typeof customFiledError]?<Typography className="text-[14px] text-red-500" variant="p">{item.label} is required!</Typography>:""}
                          </div>
                        ))
                      :""}
                      {isAddNewFiled?(
                        <div className="mt-7 border-dashed border-2 border-gray-100 p-5">
                          <div className="grid grid-cols-2 gap-x-5 items-center">
                            <div>
                              <TextInput
                                onChange={(e)=>{setAddNewFiledInput(e.target.value)}}
                                placeholder="Enter text"
                                type="text"
                                value={addNewFiledInput}
                                size="sm"
                                className="!py-[9px]"
                              />
                            </div>
                            <div className="flex justify-start items-center gap-2.5">
                              <Checkbox
                                checked={addNewFiledCheckBox}
                                onCustomChange={(value)=>{
                                  setAddNewFiledCheckBox(value)
                                }}
                              />
                              <label className="text-gray-300 font-medium text-400">
                                is Required
                              </label>
                            </div>
                            {showError?<Typography className="text-200 text-error mt-1" variant="p">{showError}</Typography>:""}
                          </div>
                          <div className="flex justify-start items-center gap-3 mt-5">
                            <Button
                              type="button"
                              label="Add Field"
                              onClick={()=>{addNewFiled(setValues,values)}}
                              variant="primary"
                              size="small"
                            />
                            <Button
                              type="button"
                              label="Cancel"
                              onClick={()=>{setIsAddNewFiled(false)}}
                              variant="outline"
                              size="small"
                            />
                          </div>
                        </div>
                      ):(
                        <div className="mt-7">
                          <button className="text-blue-300 text-400 font-bold border-b border-blue-300" onClick={()=>{setIsAddNewFiled(true)}}>
                            + Add New Field
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="w-[27.5rem]">
                      <div>
                        <label className="text-400 font-medium text-gray-300 block mb-2">
                          Notes
                        </label>
                        <Textarea
                          name="notes"
                          onChange={(event) => {
                            inputChangeHandler(event.target.value, {
                              setValues: setValues,
                              key: "notes",
                              formData: values,
                            });
                          }}
                          placeholder="Enter text"
                          value={values["notes"]}
                          resize
                        />
                        <ErrorMessage
                          name="notes"
                          component="div"
                          className="error text-error text-200 mt-1"
                        />
                      </div>
                      <div className="mt-7">
                        <label className="text-400 font-medium text-gray-300 block mb-2">
                          Service Area
                          <span className="text-primary-100 ms-0.5">*</span>
                        </label>
                        <Field
                          name="category_id"
                          options={categories}
                          component={CustomDropDown}
                          type="box"
                          onChange={(
                            value: SelectOptions | SelectOptions[] | null,
                          ) => {
                            selectChangeHandler(value, {
                              setValues: setValues,
                              key: "category_id",
                              formData: values,
                            });
                          }}
                          className="custom-dropdown"
                          placeholder="Select Service Area"
                          defaultValue={categoriesValue}
                        />
                        <ErrorMessage
                          name="category_id"
                          component="div"
                          className="error text-error text-200 mt-1"
                        />
                      </div>
                      <div className="mt-7">
                        <label className="text-400 font-medium text-gray-300 block mb-2">
                          Industries
                          <span className="text-primary-100 ms-0.5">*</span>
                        </label>
                        <TextInput
                          name="industries"
                          onChange={(event) => {
                            inputChangeHandler(event.target.value, {
                              setValues: setValues,
                              key: "industries",
                              formData: values,
                            });
                          }}
                          placeholder="Enter Industries"
                          type="text"
                          value={values["industries"]}
                        />
                        <ErrorMessage
                          name="industries"
                          component="div"
                          className="error text-error text-200 mt-1"
                        />
                      </div>
                      <div className="mt-7">
                        <label className="text-400 font-medium text-gray-300 block mb-2">
                          Technologies (max 5)
                        </label>
                        <TagInput
                        placeholder="Type & enter"
                          value={
                            values["technologies"] !== undefined &&
                            values["technologies"] !== "" &&
                            values["technologies"] !== null
                              ? values["technologies"].split(",")
                              : []
                          }
                          onTagsChange={(tags,errorMsg) => {
                            let tag = "";
                            for (const i in tags) {
                              const newTag = tags[i];
                              tag =
                                tag.trim() !== null && tag.trim() !== ""
                                  ? tag + ","
                                  : tag;
                              tag = tag + newTag;
                            }
                            inputChangeHandler(tag, {
                              setValues: setValues,
                              key: "technologies",
                              formData: values,
                            });

                            if(errorMsg !==undefined && errorMsg !== ""){
                              setTechnologiesError(errorMsg);
                            }else{
                              setTechnologiesError('');
                            }
                          }}
                          name="Technologies"
                          max={5}
                        />
                        {technologiesError?
                          <Typography variant="p" className="error text-error text-200 mt-1">{technologiesError}</Typography>
                        :""}
                        <ErrorMessage
                          name="technologies"
                          component="div"
                          className="error text-error text-200 mt-1"
                        />
                      </div>
                      <div className="mt-7">
                        <label className="text-400 font-medium text-gray-300 block mb-2">
                          Tags
                          <span className="text-primary-100 ms-0.5">*</span>
                        </label>
                        <TagInput
                        placeholder="Type & enter"
                          value={
                            values["tags"] !== "" && values["tags"] !== null
                              ? values["tags"].split(",")
                              : []
                          }
                          onTagsChange={(tags) => {
                            let tag = "";
                            for (const i in tags) {
                              const newTag = tags[i];
                              tag =
                                tag.trim() !== null && tag.trim() !== ""
                                  ? tag + ","
                                  : tag;
                              tag = tag + newTag;
                            }
                            inputChangeHandler(tag, {
                              setValues: setValues,
                              key: "tags",
                              formData: values,
                            });
                          }}
                        />
                        <ErrorMessage
                          name="tags"
                          component="div"
                          className="error text-error text-200 mt-1"
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className="flex justify-start gap-5">
                    <Button
                      type="submit"
                      label={
                        isFromSubmiting ? (
                          <Spinner labal="Processing..." />
                        ) :project["data"] !== undefined? (
                          "Save"
                        ): (
                          "Next"
                        )
                      }
                      isDisabled={isFromSubmiting}
                      variant="primary"
                      size="large"
                    />
                    <Button
                      type="button"
                      label="Cancel"
                      onClick={onClose}
                      variant="outline"
                      size="large"
                    />
                  </div>
                </ModalFooter>
              </Modal>
            </Form>
          )}
        </Formik>
      ) : project !== undefined && project["status"] === "error" && project["error"] === 404 ? (
        <Modal
          isOpen
          onClose={onClose}
          size="medium"
          placement="right"
        >
          <ModalHeader className="bg-gray-200">
            {""}
          </ModalHeader>
          <ModalBody>
            <ErrorAPI404 />
          </ModalBody>
          <ModalFooter>
            <div className="flex justify-start gap-5">
              <Button
                type="button"
                label="Cancel"
                onClick={onClose}
                variant="outline"
                size="large"
              />
            </div>
          </ModalFooter>
        </Modal>
      ) : (
        <Modal
          isOpen
          onClose={onClose}
          size="medium"
          placement="right"
        >
          <ModalHeader className="bg-gray-200">
            {""}
          </ModalHeader>
          <ModalBody>
            <ErrorAPI500 />
          </ModalBody>
          <ModalFooter>
            <div className="flex justify-start gap-5">
              <Button
                type="button"
                label="Cancel"
                onClick={onClose}
                variant="outline"
                size="large"
              />
            </div>
          </ModalFooter>
        </Modal>
      )}
    </>        
  );
};

export default CreateProjectModal;
