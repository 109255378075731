import React, { useState, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from "../../components/Button/Button";
import CustomField from "../../components/Formik/CustomField";
import Spinner from "../../components/Spinner/Spinner";

import { InputFiled, saveLinks, validationSchema } from "./AddLinksFunction";
import CustomLoader from "../../components/Loader/CustomLoader";

const AddFiles = (props: any) => {
  const loadData = props.loadData;
  const setAsset = props.setAsset;
  const setIsLoading = props.setIsLoading;
  const isLoading = props.isLoading;
  const asset = props.asset;

  const [isFromSubmiting, setIsFromSubmiting] = useState(false);
  const form = useRef(null);

  const FormData: InputFiled = {
    name:
      asset !== undefined && asset !== null && asset.name !== undefined
        ? asset.name
        : "",
    url:
      asset !== undefined && asset !== null && asset.url !== undefined
        ? asset.url
        : "",
    file_type: "link",
  };

  const submitHandler = async (
    values: InputFiled,
    { setErrors, resetForm }: any,
  ) => {
    await saveLinks({
      setIsFromSubmiting: setIsFromSubmiting,
      setErrorMessage: setErrors,
      resetForm: resetForm,
      formData: values,
      asset: asset,
      params: props.params,
      loadData: loadData,
      setAsset: setAsset,
      setIsLoading: setIsLoading,
    });
  };

  return (
    <>
      {isLoading?(
        <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
        <CustomLoader/>
      </div>
        // <div className="animate-pulse mt-10">
        //   <div className="flex-1 space-y-6">
        //     <div className="h-10 bg-gray-100 rounded" />
        //     <div className="h-10 bg-gray-100 rounded" />
        //   </div>
        //   <div className="flex mt-5 justify-start gap-2.5">
        //     <div className="h-14 w-40 bg-gray-100 rounded" />
        //     <div className="h-14 w-40 bg-gray-100 rounded" />
        //   </div>
        // </div>
      ):(
        <Formik
          initialValues={FormData}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ setValues, values }) => (
            <Form ref={form}>
              <div className="hide">
                <Field
                  label="File Type"
                  name="file_type"
                  component={CustomField}
                  type="hidden"
                  placeholder="Enter File Type"
                  defaultValue="link"
                />
                <ErrorMessage
                  name="file_type"
                  component="div"
                  className="error text-error text-200 mt-1"
                />
              </div>
              <div className="">
                <Field
                  label="Link Title"
                  name="name"
                  component={CustomField}
                  type="text"
                  placeholder="Enter link title"
                  defaultValue={values["name"]}
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="error text-error text-200 mt-1"
                />
              </div>
              <div className="mt-5">
                <Field
                  label="Enter URL"
                  name="url"
                  component={CustomField}
                  type="text"
                  placeholder="Enter URL"
                  defaultValue={values["url"]}
                />
                <ErrorMessage
                  name="url"
                  component="div"
                  className="error text-error text-200 mt-1"
                />
              </div>
              <div className="mt-5 flex justify-start gap-2.5">
                <Button
                  type="submit"
                  variant="primary"
                  isDisabled={isFromSubmiting}
                  label={
                    isFromSubmiting ? <Spinner labal="Processing..." /> : "Save"
                  }
                  size="large"
                />
                <Button
                  type="button"
                  variant="outline"
                  label="Cancel"
                  isDisabled={isFromSubmiting?isFromSubmiting:values.name == "" || values.url === ""?true:false}
                  onClick={() => {
                    setIsLoading(true);
                    if (asset === null) {
                      setAsset(undefined);
                    } else {
                      setAsset(null);
                    }
                  }}
                  size="large"
                />
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default AddFiles;
