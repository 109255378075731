import React, { useEffect, useState } from "react";
import { ErrorAPI500, ErrorAPI404 } from "../../components/Errors/Errors";
import {
  FetchData,
  fetchRequests,
  pushArray,
  updateAssocciativeArray,
} from "../../CommonFunctions";
import Button from "../../components/Button/Button";
import ApproveModalConfirm from "./ApproveModalConfirm";
import DeclineModalConfirm from "./DeclineModalConfirm";
import CustomLoader from "../../components/Loader/CustomLoader";
import PortfolioEmpty from "../portfolios/PortfolioEmpty";
import AccessPortfolioEmpty from "./AccessPortfolioEmpty";

interface AccessPortfolioProp {
  searchInp: string;
}

const AccessPortfolio = ({ searchInp }: AccessPortfolioProp) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadMore, setIsLoadMore] = useState<Boolean>(false);
  const [requests, setRequests] = useState<FetchData>();
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (requests !== undefined) {
      setIsLoading(false);
    }

    setIsLoadMore(false);
  }, [requests]);

  useEffect(() => {
    setIsLoadMore(true);
    loadRequests();
  }, [page]);

  useEffect(() => {
    searchCategories();
  }, [searchInp]);

  const searchCategories = async () => {
    if (searchInp != null && (searchInp.length >= 1 || searchInp.length <= 0)) {
      setIsLoading(true);
      setPage(1);
      loadRequests();
    }
  };

  const loadRequests = async () => {
    const data: FetchData = await fetchRequests({
      page: page,
      searchInp: searchInp,
    });

    if (data.status === "error" && data.error === "abort") {
      return false;
    }

    if (requests !== undefined && page > 1 && data.status === "success") {
      let log = requests["data"];
      for (const i in data["data"]) {
        log = pushArray(log, data["data"][i]);
      }
      setRequests({
        ...requests,
        data: log,
      });
    } else if (page === 1) {
      setRequests(data);
      setIsLoading(false);
    }
  };

  const updateData = (id: number, status: any, reason: string) => {
    let requestsData = updateAssocciativeArray(
      requests?.data,
      "id",
      id,
      "status",
      status
    );
    requestsData = updateAssocciativeArray(
      requestsData,
      "id",
      id,
      "reason",
      reason
    );

    console.log(requestsData);
    console.log(id);
    console.log(status);

    setRequests({
      ...requests,
      data: requestsData,
    });
  };

  const openApproveConfirm = (id: number) => {
    ApproveModalConfirm.fire(id, updateData);
  };
  const openDeclineConfirm = (id: number) => {
    DeclineModalConfirm.fire(id, updateData);
  };

  return (
    <div className="">
      {requests !== undefined && requests["error"] === 404 ? (
        <div className="flex justify-center items-center w-full h-[calc(100vh-8rem)]">
        <AccessPortfolioEmpty/>
      </div>
      ) : (
        <table className="w-full">
          <thead className="sticky left-0 top-0 bg-gray-200 z-10">
            <tr>
              <th className="text-200 font-bold text-gray-700 px-5 py-[0.75rem] ps-8 text-start uppercase">
                Name
              </th>
              <th className="text-200 font-bold text-gray-700 px-5 py-[0.75rem] text-start uppercase">
                Email ID
              </th>
              <th className="text-200 font-bold text-gray-700 px-5 py-[0.75rem] text-start uppercase">
                Company Name
              </th>
              <th className="text-200 font-bold text-gray-700 px-5 py-[0.75rem] text-start uppercase">
                Portfolio
              </th>
              <th className="text-200 font-bold text-gray-700 px-5 py-[0.75rem] text-start uppercase">
                Shared on
              </th>
              <th className="text-200 font-bold text-gray-700 px-5 py-[0.75rem] pe-8 text-start uppercase">
                ACTIONS
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading === true ? (
              <div className="flex justify-center items-center w-full h-[calc(100vh-11rem)] absolute left-0">
              <CustomLoader/>
            </div>
              // Array.from({ length: 10 }).map((_, idx) => (
              //   <tr key={idx}>
              //     <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300 ps-8">
              //       <div key={idx} className="animate-pulse">
              //         <div className="flex-1 space-y-6">
              //           <div className="h-5 bg-gray-100 rounded" />
              //         </div>
              //       </div>
              //     </td>
              //     <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300 ps-8">
              //       <div key={idx} className="animate-pulse">
              //         <div className="flex-1 space-y-6">
              //           <div className="h-5 bg-gray-100 rounded" />
              //         </div>
              //       </div>
              //     </td>
              //     <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300 ps-8">
              //       <div key={idx} className="animate-pulse">
              //         <div className="flex-1 space-y-6">
              //           <div className="h-5 bg-gray-100 rounded" />
              //         </div>
              //       </div>
              //     </td>
              //     <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300 ps-8">
              //       <div key={idx} className="animate-pulse">
              //         <div className="flex-1 space-y-6">
              //           <div className="h-5 bg-gray-100 rounded" />
              //         </div>
              //       </div>
              //     </td>
              //     <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300 ps-8">
              //       <div key={idx} className="animate-pulse">
              //         <div className="flex-1 space-y-6">
              //           <div className="h-5 bg-gray-100 rounded" />
              //         </div>
              //       </div>
              //     </td>
              //     <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300 ps-8">
              //       <div key={idx} className="animate-pulse">
              //         <div className="flex-1 space-y-6">
              //           <div className="h-5 bg-gray-100 rounded" />
              //         </div>
              //       </div>
              //     </td>
              //   </tr>
              // ))
            ) : requests !== undefined && requests["status"] === "success" && requests.data.length > 0 ? (
              <>
                {requests["data"].map((item: any, idx: any) => (
                  <tr key={idx} className="group">
                    <td className="text-300 text-gray-300 font-normal px-5 py-4 ps-8 text-start border-b border-gray-400 group-hover:bg-gray-200">
                      {item.client_name}
                    </td>
                    <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                      {item.email}
                    </td>
                    <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                      {item.company_name}
                    </td>
                    <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                      {item.portfolio_title}
                    </td>
                    <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                      {item.shared_on}
                    </td>
                    <td className="text-300 text-gray-300 font-normal px-5 py-4 pe-8 text-start border-b border-gray-400 group-hover:bg-gray-200">
                      {item.status === 0 ? (
                        <div className="flex justify-start items-center gap-2">
                          <Button
                            type="button"
                            label="Approve"
                            size="small"
                            onClick={() => openApproveConfirm(item.id)}
                            variant="primary"
                            className="!text-200"
                          />
                          <Button
                            type="button"
                            onClick={() => openDeclineConfirm(item.id)}
                            label="Decline"
                            variant="outline"
                            size="small"
                            className="!text-200"
                          />
                        </div>
                      ) : item.status === 1 ? (
                        <div className="font-semibold text-green-100">
                          Request Approved
                        </div>
                      ) : (
                        <>
                          <div className="font-semibold text-primary-100">
                            Request Declined
                          </div>
                          <div className="font-normal text-gray-700 text-200">
                            {item.reason}
                          </div>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="border-0" colSpan={6}>
                    <div className="flex w-100 justify-center text-center items-center align-middle mt-5">
                      {isLoadMore ? (
                        <div className="flex mt-2">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="duration-200 animate-pulse-3"
                          >
                            <circle cx="12" cy="6" r="6" fill="#F15845" />
                          </svg>
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="duration-300 animate-pulse-2"
                          >
                            <circle cx="12" cy="6" r="6" fill="#F15845" />
                          </svg>
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="duration-400 animate-pulse"
                          >
                            <circle cx="12" cy="6" r="6" fill="#F15845" />
                          </svg>
                        </div>
                      ) : page < requests.meta.last_page ? (
                        <button
                          className="text-200 font-bold text-blue-500 border-b inline-block"
                          onClick={() => {
                            setPage(page + 1);
                          }}
                        >
                          <span className="">Load More</span>
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </td>
                </tr>
              </>
            // ) : requests !== undefined &&
            //   requests["status"] === "error" &&
            //   requests !== undefined &&
            //   requests["error"] === 404 ? (
            //   <div className="w-[1160px]">{/* <ErrorAPI404 /> */}</div>
            ) : (
              <div className="flex justify-center items-center w-full h-[calc(100vh-8rem)]">
                <AccessPortfolioEmpty/>
              </div>
              // <ErrorAPI500 />
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AccessPortfolio;
