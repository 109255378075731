import React, { useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router";
import { Formik, Form, ErrorMessage } from "formik";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import {
  convertParamsToArr,
  unsetArray,
  FetchData,
  in_array,
  inputChangeHandler,
  pushArray,
} from "../../CommonFunctions";
import { useLoaderData, useParams } from "react-router-dom";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import Icons from "../../components/Icons/Icon";
import InlineTag from "../../components/Tags/InlineTag";
import TextInput from "../../components/TextInput/TextInput";
import { Dropdown } from "../../components/DropDown/DropDown";
import ListLoader from "../../components/ListLoader/ListLoader";
import CreateProjectModal from "../projects/CreateProjectModal";
import Spinner from "../../components/Spinner/Spinner";
import CreateAssetModal from "./CreateAssetModal";
import { CustomDateTimePicker } from "../../components/DateTimePicker/DatePicker";
import moment from "moment";
import {
  fetchProjectShow,
  fetchRepository,
  deleteProjectAsset,
  saveAsset,
  saveTag,
  removeTag,
  updateProjectCover,
  updateAssetCaption,
  captionValidationSchema,
  updateAssetOrder,
} from "./ProjectFunction";
import SearchAsset from "../assets/SearchAsset";
import styled from "styled-components";
import { PaginationComponent } from "../../components/Pagination/Pagination";
import Tooltip from "../../components/Tooltip/Tooltip";
import CustomLoader from "../../components/Loader/CustomLoader";
import AssetsEmpty from "../assets/AssetsEmpty";
import store from "../../store";
import { updateHeaderData } from "../../User";

export const CustomRightToolTip = styled.div`
  .tooltipBottom {
    position: relative;
    display: inline-block;
    z-index: 99;
    cursor: default;
    width: 100%;
  }
  .tooltipBottom .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #fff;
    color: #232323;
    text-align: center;
    border-radius: 6px 0 6px 6px;
    padding: 5px 4px;
    position: absolute;
    z-index: 99;
    top: 125%;
    right: 0;
    margin-left: -60px;
    filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.12));
    font-size: 10px;
    font-weight: 500;
  }
  .tooltipBottom .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 99%;
    right: 0;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }

  .tooltipBottom:hover .tooltiptext {
    visibility: visible;
  }
  .tooltipRight {
    position: relative;
    display: inline-block;
    z-index: 99;
    cursor: default;
    width: 100%;
  }
  .tooltipRight .tooltiptext {
    visibility: hidden;
    min-height:20px;
    min-width: 180px;
    background-color: #fff;
    color: #232323;
    text-align: center;
    padding: 8px 6px;
    position: absolute;
    z-index: 99;
    top: 125%;
    left: 0;
    filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.12));
    font-size: 11px;
    font-weight: 500;
    border-radius:0px 4px 4px 4px;
  }
  .tooltipRight .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 99%;
    left: 12px;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent white transparent;
  }

  .tooltipRight:hover .tooltiptext {
    visibility: visible;
  }
`;

const ShowProjects = () => {
  let paramsData = useParams();
  paramsData = convertParamsToArr({ params: paramsData });
  paramsData["tab"] = "All";
  paramsData["group_by"] = "all";
  paramsData["sort_by"] = ["id", "DESC"];
  paramsData["start_date"] = null;
  paramsData["end_date"] = null;
  paramsData["page"] = 1;

  let fetchData: FetchData;

  const [projectsData] = useState<FetchData>(useLoaderData());
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [isLoadingList, setIsLoadingList] = useState<Boolean>(true);
  const [searchInp, setSearchInp] = useState("");
  const [params, setParams] = useState(paramsData);
  const [files, setFiles] = useState<FetchData>();
  const [isActive, setIsActive] = useState("All");
  const [groupBy, setGroupBy] = useState(paramsData["group_by"]);
  const [orderBy, setOrderBy] = useState(paramsData["sort_by"]);
  const [startDate, setStartDate] = useState(paramsData["start_date"]);
  const [endDate, setEndDate] = useState(paramsData["end_date"]);
  const [minDate, setMinDate] = useState<string | null>(null);
  const [isMinDateError, setIsMinDateError] = useState<Boolean>(false);
  const [projectAssets, setProjectAssets] = useState(undefined);
  const [assets, setAssets] = useState(undefined);
  const [counter, setCounter] = useState({
    All: 0,
    Documents: 0,
    Images: 0,
    Videos: 0,
    Links: 0,
  });
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoadingList(false);
    if (files !== undefined && files.data !== undefined) {
      setCounter(files.data.counter);
      setAssets(files.data.asset);
    }
  }, [files]);

  useEffect(() => {
    setIsLoading(false);
    if (
      projectsData !== undefined &&
      projectsData.data !== undefined &&
      projectsData.data.projectAsset !== undefined
    ) {
      let asp = projectsData.data.projectAsset.map((tmpAsset: any) => {
        var caption = tmpAsset.caption;
        tmpAsset = tmpAsset.asset;
        tmpAsset['caption'] = caption;
        return tmpAsset;
      });
      setProjectAssets(asp);
    }
  }, [projectsData]);

  useEffect(() => {
    searchFiles();
  }, [searchInp]);

  useEffect(() => {
    loadData();
  }, [params]);

  useEffect(() => {
    setIsLoadingList(true);
    const data = params;
    data["tab"] = isActive;
    setParams({
      ...params,
      page: 1,
      tab: isActive,
    });
  }, [isActive]);

  useEffect(() => {
    setIsLoadingList(true);
    params["group_by"] = groupBy;
    setParams({
      ...params,
      page: 1,
      group_by: groupBy,
    });
  }, [groupBy]);

  useEffect(() => {
    setIsLoadingList(true);
    params["sort_by"] = orderBy;
    setParams({
      ...params,
      page: 1,
      sort_by: orderBy,
    });
  }, [orderBy]);

  useEffect(() => {
    setMinDate(startDate);
    filterByDate();
  }, [startDate]);

  useEffect(() => {
    filterByDate();
  }, [endDate]);

  const loadData = async () => {
    fetchData = await fetchRepository({ params });

    if (fetchData.status === "error" && fetchData.error === "abort") {
      return false;
    }

    if (fetchData !== undefined && fetchData["status"] === "success") {
      setFiles(fetchData);
      if (params["page"] === 1) {
        setCounter(fetchData.data.counter);
      } else {
        setCounter({
          All: 0,
          Documents: 0,
          Images: 0,
          Videos: 0,
          Links: 0,
        });
      }
    }
  };

  const CreateProject = (id: number) => {
    CreateProjectModal.fire(id, navigate);
  };

  const setActiveTab = (tab: string) => {
    setIsActive(tab);
  };

  const deleteAsset = async (id: BigInteger) => {
    const oldassets: [] =
      projectAssets !== null && projectAssets !== undefined
        ? projectAssets
        : [];
    const oldFiles: [] = assets !== undefined ? assets : [];
    const oldCounter = counter !== undefined ? counter : [];

    await deleteProjectAsset(
      projectsData.data.id,
      id,
      oldassets,
      oldFiles,
      oldCounter,
      updateData
    );
  };

  const updateCover = async (id: BigInteger, is_cover: number) => {
    updateProjectCover({
      project_id: projectsData.data.id,
      id: id,
      oldassets: projectAssets,
      setProjectAssets: setProjectAssets,
      is_cover: is_cover,
    });
  };

  const setSearchInpData = (e: any) => {
    setSearchInp(e.target.value);
  };

  const searchFiles = async () => {
    if (searchInp != null && (searchInp.length >= 1 || searchInp.length <= 0)) {
      setIsLoadingList(true);
      setParams({
        ...params,
        page: 1,
        1: searchInp,
      });
    }
  };

  const updateData = (assets: any, fileData: any, counterData: any) => {
    setProjectAssets(assets);
    setAssets(fileData);
    // loadData(false);
    setCounter(counterData);
  };

  const addAsset = (item: any) => {
    if (!in_array(projectAssets, "id", item.id)) {
      const oldassets: [] =
        projectAssets !== null && projectAssets !== undefined
          ? projectAssets
          : [];
      const oldFiles: [] = assets !== undefined ? assets : [];
      const oldCounter = counter !== undefined ? counter : [];

      saveAsset({
        project_id: projectsData.data.id,
        id: item.id,
        type: item.type,
        oldassets: oldassets,
        oldFiles: oldFiles,
        oldCounter: oldCounter,
        updateData: updateData,
        item: item,
      });
    }
  };

  const filterByDate = () => {
    setIsMinDateError(false);

    if (startDate === null && endDate === null) {
      setIsLoadingList(true);
      setParams({
        ...params,
        page: 1,
        start_date: startDate,
        end_date: endDate,
      });
    }

    if (startDate === null || endDate === null) {
      return false;
    }

    const dateLimit = moment(startDate);
    const now = moment(endDate);

    if (now.isBefore(dateLimit)) {
      setIsMinDateError(true);
    } else if (startDate !== null && endDate !== null) {
      setIsLoadingList(true);
      setParams({
        ...params,
        page: 1,
        start_date: startDate,
        end_date: endDate,
      });
    }
  };

  const setPagination = (page: number) => {
    setParams({
      ...params,
      page: page,
    });
  };
  const events = {
    setSearchInpData: setSearchInpData,
    searchFiles: searchFiles,
    setActiveTab: setActiveTab,
    deleteAsset: deleteAsset,
    updateCover: updateCover,
    loadData: loadData,
    setParams: setParams,
    setGroupBy: setGroupBy,
    setOrderBy: setOrderBy,
    setStartDate: setStartDate,
    setEndDate: setEndDate,
    setIsLoadingList: setIsLoadingList,
    addAsset: addAsset,
    setPagination: setPagination,
  };

  return (
    <>
      {isLoading === true ? (
        <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
        <CustomLoader/>
      </div>
        // <ListLoader />
      ) : projectsData["status"] === "success" ? (
        <>
          <div className="grid grid-cols-2 gap-0 h-[calc(100vh-11.5rem)] border border-gray-400 rounded-t-md">
            <div className="border-r border-gray-400 p-4 pe-0 h-full overflow-auto bg-white rounded-tl-md">
              <ProjectDetails
                projectsData={projectsData}
                assets={projectAssets}
                CreateProject={CreateProject}
                setProjectAssets={setProjectAssets}
                events={events}
              />
            </div>
            <div className="">
              <Assets
                params={params}
                searchInp={searchInp}
                events={events}
                isLoading={isLoadingList}
                files={files}
                assets={assets}
                counter={counter}
                project={projectsData.data}
                projectAssets={projectAssets}
                startDate={startDate}
                endDate={endDate}
                minDate={minDate}
                isMinDateError={isMinDateError}
              />
            </div>
          </div>
          <div className="flex justify-start items-center gap-5 border border-t-0 border-gray-400 px-4 py-2.5 rounded-b-md">
            <Button
              type="submit"
              label="Preview"
              variant="primary"
              size="large"
              isDisabled={
                projectAssets !== null &&
                projectAssets !== undefined &&
                projectAssets !== ""
                  ? false
                  : true
              }
              onClick={() => {
                navigate("/projects/preview/" + projectsData.data.id);
              }}
            />
            {/* <Button
              type="button"
              label="Cancel"
              variant="outline"
              size="large"
              onClick={() => {
                navigate("/projects");
              }}
            /> */}
            <Typography
              variant="p"
              className="font-medium text-300 text-primary-100"
            >
              Your changes will be auto-saved!
            </Typography>
          </div>
        </>
      ) : projectsData["status"] === "error" &&
        projectsData["error"] === 404 ? (
        <div className="mt-20 text-center">
          <Typography variant="h5">Project not found!</Typography>
        </div>
      ) : (
        <div className="mt-20 text-center">
          <Typography variant="h5">Something went wrong!</Typography>
        </div>
      )}
    </>
  );
};

const ProjectDetails = ({
  projectsData,
  assets,
  CreateProject,
  setProjectAssets,
  events,
}: any) => {
  const project = projectsData["data"];
  const [tags, setTags] = useState(projectsData["data"]["tags"]);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [deletedAsset, setDeletedAsset] = useState(null);
  const [assetItem, setAssetItem] = useState(null);
  const [isShowCaption, setIsShowCaption] = useState(false);
  const [caption, setCaption] = useState("");
  const [isFromSubmiting, setIsFromSubmiting] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isShowCaptionSection, setIsShowCaptionSection] = useState(true);
  let size = [0, 0];
  const navigate = useNavigate();

  const useWindowSize = () => {
    const [wSize, setwSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setwSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);

    size = wSize;
    return wSize[0];
  };

  const onChange = async (
    sourceId: any,
    sourceIndex: any,
    targetIndex: any,
    targetId: any
  ) => {
    setIsDragging(true);
    const nextState = swap(assets, sourceIndex, targetIndex);
    await updateAssetOrder({
      project_id: project["id"],
      oldAssets: assets,
      newProjects: nextState,
      setProjectAssets: setProjectAssets,
    });
  };

  useEffect(() => {
    setIsShowCaption(false);
  }, [selectedAsset]);

  useEffect(() => {
    if (deletedAsset !== null && deletedAsset == selectedAsset) {
      setAssetItem(null);
      setSelectedAsset(null);
      setDeletedAsset(null);
    }
  }, [deletedAsset]);

  useEffect(() => {
    if (projectsData !== undefined) {
     // setIsLoading(false);

      if(projectsData.status === "success"){
        const data = {
          client_name:projectsData.data.client_name,
          project_title:projectsData.data.project_title,
          project_description:projectsData.data.project_description,
          navigate:navigate
        }
        store.dispatch(
          updateHeaderData(data),
        );
      }
    }
  }, [projectsData]);
  
  const handleAddCaptionClick = () => {
    setIsShowCaption(true);
  };

  const handleCloseAsset = () => {
    setAssetItem(null);
    setSelectedAsset(null);
  };
  return (
    <>
      <div className="flex justify-between gap-2 items-start pe-4">
        {/* <div className="flex justify-start items-center gap-2"> */}
          {/* <Typography className="text-seconday-200 font-semibold" variant="h4">
            {project.project_title}
          </Typography>
          <CustomRightToolTip>
            <div className="tooltipRight">
              <div className="w-full">
                <Icons
                  name="info-circle"
                  variant="bold"
                  className="-mb-0.5 ms-1"
                  size={15}
                  color="#232323"
                />
              </div>
              <div className="tooltiptext">
                {project.project_description}
              </div>
            </div>
          </CustomRightToolTip> */}
        {/* </div> */}
        <div className="">
        <InlineTag
          // is_new={true}
          tags={
            tags !== "" && tags !== null && tags !== undefined
              ? tags.split(",")
              : []
          }
          // onRemove={(value: string, tagsData: []) => {
          //   if (tagsData.length > 0) {
          //     tagsData = unsetArray(tagsData, value);
          //   }
          //   setTags(tagsData.join(","));
          //   removeTag({
          //     tag: value,
          //     project_id: project.id,
          //     setTags: setTags,
          //   });
          // }}
          // callback={(value: string, input: HTMLInputElement) => {
          //   const tagData =
          //     tags !== "" && tags !== null && tags !== undefined
          //       ? tags + "," + value
          //       : value;
          //   setTags(tagData);
          //   input.value = "";

          //   saveTag({
          //     tag: value,
          //     project_id: project.id,
          //     setTags: setTags,
          //   });
          // }}
        />
      </div>
        <div className="">
          <button
            className="flex justify-end items-center gap-2 text-400 text-secondary-100 font-bold"
            onClick={() => {
              CreateProject(project.id);
            }}
          >
            <span className="inline-block">
              <svg
                width="18"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.26 3.59997L5.04997 12.29C4.73997 12.62 4.43997 13.27 4.37997 13.72L4.00997 16.96C3.87997 18.13 4.71997 18.93 5.87997 18.73L9.09997 18.18C9.54997 18.1 10.18 17.77 10.49 17.43L18.7 8.73997C20.12 7.23997 20.76 5.52997 18.55 3.43997C16.35 1.36997 14.68 2.09997 13.26 3.59997Z"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.89 5.05005C12.32 7.81005 14.56 9.92005 17.34 10.2"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3 22H21"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            Edit
          </button>
        </div>
      </div>
      <div>
        {/* <Typography
          className="mt-2 text-gray-300 font-medium"
          size="md"
          variant="p"
        >
          {project.client_name}
        </Typography> */}
        {/* <Typography
          className="mt-2 text-gray-300 font-normal"
          size="lg"
          variant="p"
        >
          {project.project_description}
        </Typography> */}
      </div>
     
      {assetItem !== null && isShowCaptionSection ? (
        <div
          className={`h-[18rem] w-[97%] bg-white rounded-md mt-2 flex justify-center items-center overflow-hidden
                  text-center border border-gray-400 relative group`}
        >
          <button
            className="absolute right-4 top-4 z-20 bg-white/[.7] h-5 w-5 rounded-sm"
            onClick={handleCloseAsset}
          >
            <Icons name="cross" variant="stroke" size={16} />
          </button>

          {assetItem["type"] === "Images" ? (
            <img
              src={assetItem["url"]}
              alt={assetItem["name"]}
              className="w-full h-full object-cover"
            />
          ) : (
            <iframe className="w-full h-full" src={assetItem["url"]}></iframe>
          )}

          {selectedAsset !== null && !isShowCaption && (
            <button
              className="absolute left-0 bottom-0 text-300 text-white font-semibold flex justify-center items-center p-1 bg-black/[.5] rounded-bl-md max-w-[14rem] truncate overflow-clip"
              onClick={handleAddCaptionClick}
            >
              {assetItem !== null && assetItem["caption"] ? (
                assetItem["caption"]
              ) : (
                <>
                  <Icons
                    name="plus"
                    variant="stroke"
                    size={14}
                    className="[&>svg>path]:stroke-white"
                  />
                  <span className="ms-0.5">Add a Caption</span>
                </>
              )}
            </button>
          )}
        </div>
      ) : null}

      <div>
        {isShowCaption ? (
          <Formik
            initialValues={{
              caption: caption,
            }}
            validationSchema={captionValidationSchema}
            onSubmit={async (values: any, { setErrors }: any) => {
              await updateAssetCaption({
                formData: values,
                project_id: project.id,
                id: selectedAsset,
                setIsFromSubmiting: setIsFromSubmiting,
                oldassets: assets,
                assetItem: assetItem,
                setProjectAssets: setProjectAssets,
                setIsShowCaption: setIsShowCaption,
                setCaption: setCaption,
              });
            }}
          >
            {({ setValues, values }) => (
              <Form>
                <div className="mt-2 flex items-start gap-2.5 pe-4">
                  <div className="grow">
                    <TextInput
                      name="caption"
                      onChange={(event) => {
                        inputChangeHandler(event.target.value, {
                          setValues: setValues,
                          key: "caption",
                          formData: values,
                        });
                      }}
                      placeholder="Enter caption"
                      type="text"
                      value={values["caption"]}
                      size="sm"
                      className="!py-2"
                    />
                    <ErrorMessage
                      name="caption"
                      component="div"
                      className="error text-error text-200 mt-1"
                    />
                  </div>
                  <div className="flex gap-1">
                    <Button
                      type="submit"
                      label={
                        isFromSubmiting ? (
                          <Spinner labal="Processing..." />
                        ) : (
                          "Save"
                        )
                      }
                      isDisabled={isFromSubmiting}
                      variant="primary"
                      size="small"
                      className="!text-200"
                    />
                    <Button
                      label="Cancel"
                      onClick={() => {
                        setIsShowCaption(false);
                      }}
                      variant="outline"
                      size="small"
                      className="!text-200"
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          ""
        )}
      </div>
      <GridContextProvider onChange={onChange}>
        <div>
          <GridDropZone
            id="projectAsset"
            boxesPerRow={useWindowSize() >= 980 ? 4 : size[0] >= 796 ? 3 : 1}
            rowHeight={110}
            // className="grid grid-cols-4 gap-3 mt-4 pb-6 overflow-x-hidden overflow-y-auto"
            // className="grid grid-cols-4 gap-3 pb-2 mt-2"
            className="grid grid-cols-3 h-[calc(100vh-300px)] gap-4 overflow-x-hidden overflow-y-auto pe-2"
          >
            {assets !== null && assets !== undefined
              ? assets.map((item: any, idx: any) => (
                  <GridItem
                    key={item.id}
                    style={{
                      width:
                        size[0] >= 1532
                          ? "10rem"
                          : size[0] >= 1250
                          ? "8rem"
                          : size[0] >= 796
                          ? "6rem"
                          : "10rem",
                      height: "6rem",
                    }}
                    className=""
                  >
                    <ProjectAssetsItem
                      item={item}
                      events={events}
                      setSelectedAsset={setSelectedAsset}
                      setAssetItem={setAssetItem}
                      setCaption={setCaption}
                      isDragging={isDragging}
                      setIsDragging={setIsDragging}
                      isShowCaptionSection={isShowCaptionSection}
                      setIsShowCaptionSection={setIsShowCaptionSection}
                      setDeletedAsset={setDeletedAsset}
                      isSelected={selectedAsset === item.id ? true : false}
                    />
                  </GridItem>
                ))
              : ""}
          </GridDropZone>
        </div>
      </GridContextProvider>
    </>
  );
};

const Assets = (prop: any) => {
  const isLoading = prop.isLoading;
  const setSearchInpData = prop.events.setSearchInpData;
  const setActiveTab = prop.events.setActiveTab;
  const loadData = prop.events.loadData;
  const searchInp = prop.searchInp;
  const files = prop.files;
  const isActive = prop.params["tab"];
  const counter = prop.counter;
  const assets = prop.assets;
  const projectAssets = prop.projectAssets;
  const params = prop.params;
  const startDate = prop.startDate;
  const endDate = prop.endDate;
  const minDate = prop.minDate;
  const isMinDateError = prop.isMinDateError;

  const [isShowFilters, setIsShowFilters] = useState(false);
  const [isClearFilter, setIsClearFilter] = useState<Boolean>(false);

  useEffect(() => {
    setIsClearFilter(false);
  }, [startDate, endDate]);

  useEffect(() => {
    if (isClearFilter) {
      setIsClearFilter(false);
    }
  }, [isClearFilter]);

  const clearFilter = () => {
    setIsClearFilter(true);
    prop.events.setStartDate(null);
    prop.events.setEndDate(null);
    prop.events.setIsLoading(true);
    prop.events.setParams({
      ...params,
      tab: "All",
      group_by: "all",
      sort_by: ["id", "DESC"],
      start_date: null,
      end_date: null,
      page: 1,
    });
  };

  return (
    <div className="relative">
      <div className="p-4 border-b border-gray-400">
        <div className="flex justify-between items-center">
          <div>
            <Typography className="text-seconday-200 font-bold" variant="h4">
              <PaginationComponent
                handlePagination={prop.events.setPagination}
                page={Number(files?.meta?.current_page ?? 0)}
                totalPages={Number(files?.meta?.last_page)}
              />
            </Typography>
          </div>
          <div className="flex items-center justify-end gap-2.5">
            <div className="w-[12rem]">
              <SearchAsset setSearchInpData={setSearchInpData} />
            </div>
            <Button
              type="button"
              label="Add New Asset"
              variant="primary"
              size="medium"
              onClick={() => {
                CreateAssetModal.fire(loadData);
              }}
            />
          </div>
        </div>
        <div className="flex justify-end items-center gap-4 mt-4">
          <div className="w-[8rem]">
            <Dropdown
              onChange={(item: any) => {
                prop.events.setGroupBy(item.value);
              }}
              options={[
                {
                  label: "All Assets",
                  value: "all",
                },
                {
                  label: "Unassigned Assets",
                  value: "unassigned",
                },
                {
                  label: "Group By Project",
                  value: "project",
                },
              ]}
              type="smallBox"
              isSearchable={false}
              placeholder="Select"
              defaultValue={{ label: "All Assets", value: "all" }}
            />
          </div>
          {/* <button onClick={()=>{
                    const sort_by = 
                    params["sort_by"] !== undefined &&
                    params["sort_by"][1] === "DESC"
                      ? ["id", "ASC"]
                      : ["id", "DESC"];
                    prop.events.setOrderBy(sort_by);
                  }}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className={`
                          ${
                            params["sort_by"][1] === "DESC"
                              ? "stroke-primary-100"
                              : "stroke-gray-300"
                        }`}
                        d="M3 7H21"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        className={`
                          ${
                            params["sort_by"][1] === "DESC"
                              ? "stroke-primary-100"
                              : "stroke-gray-300"
                        }`}
                        d="M6 12H18"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        className={` 
                          ${
                            params["sort_by"][1] === "DESC"
                              ? "stroke-primary-100"
                              : "stroke-gray-300"
                        }`}
                        d="M10 17H14"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button> */}
          <button
            onClick={() => {
              const sort_by =
                params["sort_by"] !== undefined &&
                params["sort_by"][1] === "DESC"
                  ? ["id", "ASC"]
                  : ["id", "DESC"];
              prop.events.setOrderBy(sort_by);
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.45 6.71997L6.72998 3L3.01001 6.71997"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6.72998 21V3"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13.55 17.28L17.2701 21L20.9901 17.28"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.27 3V21"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {/* <div className="relative">
                    <button 
                      className="flex items-center" 
                      onClick={()=>{setIsShowFilters(!isShowFilters)}}
                    >
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            className={`group-hover:stroke-gray-300 ${
                        isShowFilters?"stroke-gray-300":"stroke-gray-300"}`}
                            d="M5.40002 2.09998H18.6C19.7 2.09998 20.6 2.99998 20.6 4.09998V6.29998C20.6 7.09998 20.1 8.09998 19.6 8.59998L15.3 
                                12.4C14.7 12.9 14.3 13.9 14.3 14.7V19C14.3 19.6 13.9 20.4 13.4 20.7L12 21.6C10.7 22.4 8.90002 21.5 8.90002 
                                19.9V14.6C8.90002 13.9 8.50002 13 8.10002 12.5L4.30002 8.49998C3.80002 7.99998 3.40002 7.09998 
                                3.40002 6.49998V4.19998C3.40002 2.99998 4.30002 2.09998 5.40002 2.09998Z"
                            stroke="#292D32"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            className={`group-hover:stroke-gray-300 ${
                        isShowFilters?"stroke-gray-300":"stroke-gray-300"}`}
                            d="M10.93 2.09998L6 9.99998"
                            stroke="#292D32"
                            strokeWidth="1.5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    </button>
                    {isShowFilters?(
                      <div className={`absolute top-[34px] right-[0px] h-[27rem] w-[18rem] rounded-md bg-white border py-5 ps-5 pe-2 border-gray-100 shadow-100 overflow-auto`}>
                        <div
                          className={`w-full`}
                        >
                          <div className="mb-2 pe-5">
                            <div className="flex gap-5 justify-between items-center">
                              <div className="text-200 font-bold text-gray-300">FILTER</div>
                              <button className="text-200 font-medium text-primary-100" onClick={()=>{clearFilter()}}>
                                Clear All
                              </button>
                            </div>
                          </div>
                          <div className="h-full pe-3">
                          {!isClearFilter?(
                            <div className="">
                            <div className="">
                              <label className="text-gray-300 font-medium text-300 mb-1 inline-block">
                                Start Date
                              </label>
                              <CustomDateTimePicker
                                size="sm"
                                dateFormat="YYYY-MM-DD"
                                onChange={(date:Date|null,value: string|null) => {
                                  prop.events.setStartDate(value);
                                }}
                                selectedDate={startDate}
                                maxDate={moment(new Date()).format("yyyy-MM-DD")}
                              />
                              {startDate === null && endDate !== null?(
                                <Typography
                                  className="text-primary-100 font-normal"
                                  variant="p"
                                  size="sm"
                                >Please select start date</Typography>
                              ):""}
                            </div>
                            <div className="mt-1">
                              <label className="text-gray-300 font-medium text-300 mb-1 inline-block">End Date</label>
                              <CustomDateTimePicker
                                size="sm"
                                dateFormat="YYYY-MM-DD"
                                onChange={(date:Date|null,value: string|null) => {
                                  prop.events.setEndDate(value);
                                }}
                                selectedDate={endDate}
                                minDate={minDate !== null?minDate:null}
                                maxDate={moment(new Date()).format("yyyy-MM-DD")}
                              />
                              {startDate !== null && endDate === null?(
                                <Typography
                                  className="text-primary-100 font-normal"
                                  variant="p"
                                  size="sm"
                                >Please select end date</Typography>
                              ):isMinDateError?(
                                <Typography
                                  className="text-primary-100 font-normal"
                                  variant="p"
                                  size="sm"
                                >End date can not be less than start date</Typography>
                              ):""}
                            </div>
                          </div>
                          ):""}
                          </div>
                        </div>
                      </div>
                    ):""}
                  </div> */}
        </div>
      </div>
      <div className="mt-3 ps-4">
        <div className="flex gap-3 text-gray-300 text-200">
          {Object.keys(counter).map((item: any, idx: any) => (
            <div
              key={idx}
              className={`cursor-pointer font-medium tab pb-3 
                        ${
                          isActive === item
                            ? "active !font-bold text-gray-300 border-primary-100 border-b-2"
                            : ""
                        }`}
              onClick={() => {
                setActiveTab(item);
              }}
            >
              {item}({counter[item as keyof typeof counter]})
            </div>
          ))}
        </div>
        <div className="pt-3">
          <div className="tab-pane active">
            <div className="">
              <div className="overflow-auto h-[calc(100vh-23rem)] pe-4">
                {isLoading ? (
                  <div className="flex justify-center items-center w-full h-[calc(100%-5rem)]">
                  <CustomLoader/>
                </div>
                  // <Assetloader />
                ) : files !== undefined && files["status"] === "success" ? (
                  files["data"]["group_by"] === "project" ? (
                    <div className="mt-2">
                      {Object.keys(assets).map((item: any, idx: any) => (
                        <div key={idx}>
                          <Typography
                            variant="h6"
                            className={`font-semibold text-gray-300 ${
                              idx !== 0 ? "my-6" : "mb-6"
                            }`}
                          >
                            {assets[item].group}
                          </Typography>
                          <AssetItem
                            events={prop.events}
                            assets={assets[item].asset}
                            projectAssets={projectAssets}
                            group={assets[item].group}
                            meta={files.meta}
                            params={params}
                          />
                        </div>
                      ))}
                      <div className="col-span-4 w-100 justify-center text-center items-center align-middle mt-5">
                        {/* <div className="flex mt-2">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="duration-200 animate-pulse-3"
                          >
                            <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                          </svg>
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="duration-300 animate-pulse-2"
                          >
                            <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                          </svg>
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="duration-400 animate-pulse"
                          >
                            <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                          </svg>
                        </div> */}
                      </div>
                    </div>
                  ) : (
                    <AssetItem
                      events={prop.events}
                      assets={assets}
                      projectAssets={projectAssets}
                      group="all"
                      meta={files.meta}
                      params={params}
                    />
                  )
                // ) : files !== undefined &&
                //   files["status"] === "error" &&
                //   files["error"] === 404 ? (
                //   <div className="mt-20 text-center">
                //     <Typography variant="h5">No record found</Typography>
                //   </div>
                ) : (
                  // <div className="mt-20 text-center">
                  //   <Typography variant="h5">Something went wrong!</Typography>
                  // </div>
                  <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
                  <AssetsEmpty/>
                </div>
                
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AssetItem = (prop: any) => {
  const assets = prop.assets;
  const projectAssets = prop.projectAssets;
  const group = prop.group;
  const params = prop.params;
  const meta = prop.meta;
  const addAsset = prop.events.addAsset;

  return (
    <>
      {assets.length === 0 ? ( 
        <div className="flex justify-center items-center w-full h-[calc(100%-5rem)]">
          <AssetsEmpty />
        </div>
      ) : (
        <div className="grid grid-cols-4 gap-4">
          {assets.length > 0 ? (
            assets.map((item: any, idx: any) => (
            <div
              key={idx}
              className="cursor-pointer"
              onClick={() => addAsset(item)}
            >
              {item.type === "Images" ? (
                <Image
                  type={item.type}
                  name={item.name}
                  url={item.url}
                  is_selected={
                    item.is_disabled !== undefined
                      ? item.is_disabled
                      : in_array(projectAssets, "id", item.id)
                  }
                />
              ) : item.type === "Documents" ? (
                <Document
                  type={item.type}
                  name={item.name}
                  is_selected={
                    item.is_disabled !== undefined
                      ? item.is_disabled
                      : in_array(projectAssets, "id", item.id)
                  }
                />
              ) : item.type === "Videos" ? (
                <Video
                  type={item.type}
                  name={item.name}
                  is_selected={
                    item.is_disabled !== undefined
                      ? item.is_disabled
                      : in_array(projectAssets, "id", item.id)
                  }
                />
              ) : item.type === "Links" ? (
                <Link
                  type={item.type}
                  name={item.name}
                  is_selected={
                    item.is_disabled !== undefined
                      ? item.is_disabled
                      : in_array(projectAssets, "id", item.id)
                  }
                />
              ) : (
                ""
              )}
            </div>
           ))
          ) : (
            <div className="flex justify-center items-center w-full h-[120px] relative">
              <AssetsEmpty />
            </div>
          )}
          {group === "all" || group === "Unassigned" ? (
            <div className="col-span-4 w-full justify-center text-center items-center align-middle place-self-center mt-5">
              {/* <div className="flex justify-center items-center w-full h-[calc(100%-5rem)]">
                  <AssetsEmpty/>
                </div> */}
              {/* <div className="flex justify-center items-center w-full mt-2">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="duration-200 animate-pulse-3"
              >
                <circle cx="12" cy="6" r="6" fill="#3b82f6" />
              </svg>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="duration-300 animate-pulse-2"
              >
                <circle cx="12" cy="6" r="6" fill="#3b82f6" />
              </svg>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="duration-400 animate-pulse"
              >
                <circle cx="12" cy="6" r="6" fill="#3b82f6" />
              </svg>
            </div> */}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};


const Assetloader = (prop: any) => {
  return (
    <div className="grid grid-cols-4 gap-2">
      {Array.from({ length: 12 }).map((_, idx) => (
        <div key={idx} className="animate-pulse">
          <div className="flex-1 space-y-6">
            <div className="h-24 bg-gray-100 rounded" />
          </div>
        </div>
      ))}
    </div>
  );
};

const Image = (prop: any) => {
  return (
    <div
      className={`w-full h-24 rounded-md bg-white overflow-hidden relative group  
            ${
              prop.is_selected
                ? `border border-dashed border-primary-100 opacity-50 cursor-default`
                : `border border-gray-400`
            }`}
    >
      <img
        src={prop.url}
        alt={prop.name}
        className="w-full h-full object-cover"
      />
      <div className="absolute top-0 left-0 px-4 py-4 w-full h-full invisible ease-linear group-hover:visible bg-black/[.7] flex justify-center items-end">
        <Typography
          className="text-white font-medium line-clamp-3 text-center break-words"
          size="xs"
          variant="p"
        >
          {prop.name}
        </Typography>
      </div>
    </div>
  );
};

const Document = (prop: any) => {
  return (
    <div
      className={`w-full h-24 rounded-md bg-white overflow-hidden flex justify-center items-center relative group  
            ${
              prop.is_selected
                ? `border border-dashed border-primary-100 opacity-50 cursor-default`
                : `border border-gray-400`
            }`}
    >
      <div className="text-center">
        <span className="flex justify-center items-center mb-1">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
              stroke="#292D32"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.75 9H8.25"
              stroke="#292D32"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.75 15H8.25"
              stroke="#292D32"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      <div className="absolute top-0 left-0 px-4 py-4 w-full h-full invisible ease-linear group-hover:visible bg-black/[.7] flex justify-center items-end">
        <Typography
          className="text-white font-medium line-clamp-3 text-center break-words"
          size="xs"
          variant="p"
        >
          {prop.name}
        </Typography>
      </div>
    </div>
  );
};

const Video = (prop: any) => {
  return (
    <div
      className={`w-full h-24 rounded-md bg-white overflow-hidden flex justify-center items-center relative group 
            ${
              prop.is_selected
                ? `border border-dashed border-primary-100 opacity-50 cursor-default`
                : `border border-gray-400`
            }`}
    >
      <div className="text-center">
        <span className="flex justify-center items-center mb-1">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 11.9999V8.43989C4 4.01989 7.13 2.2099 10.96 4.4199L14.05 6.1999L17.14 7.9799C20.97 10.1899 20.97 13.8099 17.14 16.0199L14.05 17.7999L10.96 19.5799C7.13 21.7899 4 19.9799 4 15.5599V11.9999Z"
              stroke="#292D32"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      <div className="absolute top-0 left-0 px-4 py-4 w-full h-full invisible ease-linear group-hover:visible bg-black/[.7] flex justify-center items-end">
        <Typography
          className="text-white font-medium line-clamp-3 text-center break-words"
          size="xs"
          variant="p"
        >
          {prop.name}
        </Typography>
      </div>
    </div>
  );
};

const Link = (prop: any) => {
  return (
    <div
      className={`w-full h-24 rounded-md bg-white overflow-hidden flex justify-center items-center relative group 
            ${
              prop.is_selected
                ? `border border-dashed border-primary-100 opacity-50`
                : `border border-gray-400`
            }`}
    >
      <div className="text-center">
        <span className="flex justify-center items-center mb-1">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5 12C13.5 15.18 10.93 17.75 7.75 17.75C4.57 17.75 2 15.18 2 12C2 8.82 4.57 6.25 7.75 6.25"
              stroke="#292D32"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10 12C10 8.69 12.69 6 16 6C19.31 6 22 8.69 22 12C22 15.31 19.31 18 16 18"
              stroke="#292D32"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </div>
      <div className="absolute top-0 left-0 px-4 py-4 w-full h-full invisible ease-linear group-hover:visible bg-black/[.7] flex justify-center items-end">
        <Typography
          className="text-white font-medium line-clamp-3 text-center break-words"
          size="xs"
          variant="p"
        >
          {prop.name}
        </Typography>
      </div>
    </div>
  );
};

const ProjectAssetsItem = (prop: any) => {
  const item = prop.item;
  const isSelected = prop.isSelected;
  const setSelectedAsset = prop.setSelectedAsset;
  const setAssetItem = prop.setAssetItem;
  const setCaption = prop.setCaption;
  const isDragging = prop.isDragging;
  const setIsDragging = prop.setIsDragging;
  const setDeletedAsset = prop.setDeletedAsset;

  const setSelected = (id: BigInteger | null, item: any) => {
    if (!isSelected && !isDragging) {
      setAssetItem(item);
      setSelectedAsset(id);
      setCaption(item.caption);
    } else if (!isDragging) {
      setAssetItem(null);
      setSelectedAsset(null);
      setCaption("");
    } else {
      setIsDragging(false);
    }
  };

  return (
    <>
      {item.type === "Images" ? (
        <div className="group relative">
          <div
            className={`w-full h-24 rounded-md bg-white overflow-hidden cursor-move
              ${
                isSelected
                  ? "border border-dashed border-primary-100 shadow-100"
                  : "border border-gray-400"
              }
            `}
            onClick={() => {
              setSelected(item.id, item);
            }}
          >
            <img
              src={item.url}
              alt={item.name}
              className="w-full h-full object-cover unselectable"
              unselectable="on"
            />
          </div>
          <div className="absolute w-[98%] bottom-0 left-[1px] bg-black/[.5] px-1 py-1 rounded-b-md invisible ease-linear group-hover:visible">
            <Typography
              className="text-seondary-200 font-medium line-clamp-2 text-white text-center"
              size="xs"
              variant="p"
            >
              {item.name}
            </Typography>
          </div>
          <div className="absolute left-2 top-1 flex justify-between items-center w-[90%] invisible group-hover:visible">
            <button
              className="p-1 bg-white/[.7] rounded-sm"
              onClick={async () => {
                setDeletedAsset(item.id);
                prop.events.deleteAsset(item.id);
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                  stroke="#232323"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.50012 4.97L8.72012 3.66C8.88012 2.71 9.00012 2 10.6901 2H13.3101C15.0001 2 15.1301 2.75 15.2801 3.67L15.5001 4.97"
                  stroke="#232323"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.8494 9.14014L18.1994 19.2101C18.0894 20.7801 17.9994 22.0001 15.2094 22.0001H8.78941C5.99941 22.0001 5.90941 20.7801 5.79941 19.2101L5.14941 9.14014"
                  stroke="#232323"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.3301 16.5H13.6601"
                  stroke="#232323"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.49988 12.5H14.4999"
                  stroke="#232323"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <CustomRightToolTip>
              <div className="tooltipBottom">
                <div className="w-full">
                  <button
                    className="p-1 bg-white/[.7] rounded-sm relative"
                    onClick={() => {
                      prop.events.updateCover(item.id, item.is_cover);
                    }}
                  >
                    {item.is_cover === 0 ? (
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 10.75C7.48 10.75 6.25 9.52 6.25 8C6.25 6.48 7.48 5.25 9 5.25C10.52 5.25 11.75 6.48 11.75 8C11.75 9.52 10.52 10.75 9 10.75ZM9 6.75C8.31 6.75 7.75 7.31 7.75 8C7.75 8.69 8.31 9.25 9 9.25C9.69 9.25 10.25 8.69 10.25 8C10.25 7.31 9.69 6.75 9 6.75Z"
                          fill="#232323"
                        />
                        <path
                          d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H13C13.41 1.25 13.75 1.59 13.75 2C13.75 2.41 13.41 2.75 13 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V10C21.25 9.59 21.59 9.25 22 9.25C22.41 9.25 22.75 9.59 22.75 10V15C22.75 20.43 20.43 22.75 15 22.75Z"
                          fill="#232323"
                        />
                        <path
                          d="M21.25 5.75H15.75C15.34 5.75 15 5.41 15 5C15 4.59 15.34 4.25 15.75 4.25H21.25C21.66 4.25 22 4.59 22 5C22 5.41 21.66 5.75 21.25 5.75Z"
                          fill="#232323"
                        />
                        <path
                          d="M18.5 8.5C18.09 8.5 17.75 8.16 17.75 7.75V2.25C17.75 1.84 18.09 1.5 18.5 1.5C18.91 1.5 19.25 1.84 19.25 2.25V7.75C19.25 8.16 18.91 8.5 18.5 8.5Z"
                          fill="#232323"
                        />
                        <path
                          d="M2.66932 19.7001C2.42932 19.7001 2.18932 19.5801 2.04932 19.3701C1.81932 19.0301 1.90932 18.5601 2.24932 18.3301L7.17932 15.0201C8.25932 14.3001 9.74932 14.3801 10.7293 15.2101L11.0593 15.5001C11.5593 15.9301 12.4093 15.9301 12.8993 15.5001L17.0593 11.9301C18.1293 11.0201 19.7893 11.0201 20.8593 11.9301L22.4893 13.3301C22.7993 13.6001 22.8393 14.0701 22.5693 14.3901C22.2993 14.7001 21.8293 14.7401 21.5093 14.4701L19.8793 13.0701C19.3793 12.6401 18.5293 12.6401 18.0393 13.0701L13.8793 16.6401C12.8193 17.5501 11.1493 17.5501 10.0793 16.6401L9.74932 16.3501C9.28932 15.9601 8.52932 15.9201 8.01932 16.2701L3.09932 19.5801C2.95932 19.6601 2.80932 19.7001 2.66932 19.7001Z"
                          fill="#232323"
                        />
                      </svg>
                    ) : (
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.97 1H18.03C17.16 1 16.52 1.36 16.23 2C16.07 2.29 16 2.63 16 3.03V5.97C16 7.24 16.76 8 18.03 8H20.97C21.37 8 21.71 7.93 22 7.77C22.64 7.48 23 6.84 23 5.97V3.03C23 1.76 22.24 1 20.97 1ZM21.91 4.93C21.81 5.03 21.66 5.1 21.5 5.11H20.09V5.62L20.1 6.5C20.09 6.67 20.03 6.81 19.91 6.93C19.81 7.03 19.66 7.1 19.5 7.1C19.17 7.1 18.9 6.83 18.9 6.5V5.1L17.5 5.11C17.17 5.11 16.9 4.83 16.9 4.5C16.9 4.17 17.17 3.9 17.5 3.9L18.38 3.91H18.9V2.51C18.9 2.18 19.17 1.9 19.5 1.9C19.83 1.9 20.1 2.18 20.1 2.51L20.09 3.22V3.9H21.5C21.83 3.9 22.1 4.17 22.1 4.5C22.09 4.67 22.02 4.81 21.91 4.93Z"
                          fill="#ECB22E"
                        />
                        <path
                          d="M9.00109 10.3811C10.3155 10.3811 11.3811 9.31553 11.3811 8.00109C11.3811 6.68666 10.3155 5.62109 9.00109 5.62109C7.68666 5.62109 6.62109 6.68666 6.62109 8.00109C6.62109 9.31553 7.68666 10.3811 9.00109 10.3811Z"
                          fill="#ECB22E"
                        />
                        <path
                          d="M20.97 8H20.5V12.61L20.37 12.5C19.59 11.83 18.33 11.83 17.55 12.5L13.39 16.07C12.61 16.74 11.35 16.74 10.57 16.07L10.23 15.79C9.52 15.17 8.39 15.11 7.59 15.65L3.85 18.16C3.63 17.6 3.5 16.95 3.5 16.19V7.81C3.5 4.99 4.99 3.5 7.81 3.5H16V3.03C16 2.63 16.07 2.29 16.23 2H7.81C4.17 2 2 4.17 2 7.81V16.19C2 17.28 2.19 18.23 2.56 19.03C3.42 20.93 5.26 22 7.81 22H16.19C19.83 22 22 19.83 22 16.19V7.77C21.71 7.93 21.37 8 20.97 8Z"
                          fill="#ECB22E"
                        />
                      </svg>
                    )}
                  </button>
                </div>
                <span className="tooltiptext">
                  Marked will be used as a cover image in this project.
                </span>
              </div>
            </CustomRightToolTip>
          </div>
        </div>
      ) : (
        <div className="group relative">
          <div
            className={`w-full h-24 rounded-md bg-white overflow-hidden cursor-move flex justify-center items-center
                          ${
                            isSelected
                              ? "border border-dashed border-primary-100 shadow-100"
                              : "border border-gray-400"
                          }
                      `}
            onClick={() => {
              setSelected(item.id, item);
            }}
          >
            <div className="text-center">
              {item.type === "Documents" ? (
                <span className="flex justify-center items-center mb-1">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                      stroke="#292D32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.75 9H8.25"
                      stroke="#292D32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.75 15H8.25"
                      stroke="#292D32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              ) : item.type === "Videos" ? (
                <span className="flex justify-center items-center mb-1">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 11.9999V8.43989C4 4.01989 7.13 2.2099 10.96 4.4199L14.05 6.1999L17.14 7.9799C20.97 10.1899 20.97 13.8099 17.14 16.0199L14.05 17.7999L10.96 19.5799C7.13 21.7899 4 19.9799 4 15.5599V11.9999Z"
                      stroke="#292D32"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              ) : item.type === "Links" ? (
                <span className="flex justify-center items-center mb-1">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.5 12C13.5 15.18 10.93 17.75 7.75 17.75C4.57 17.75 2 15.18 2 12C2 8.82 4.57 6.25 7.75 6.25"
                      stroke="#292D32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10 12C10 8.69 12.69 6 16 6C19.31 6 22 8.69 22 12C22 15.31 19.31 18 16 18"
                      stroke="#292D32"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              ) : (
                ""
              )}
              <div className="absolute w-[98%] bottom-0 left-[1px] bg-black/[.5] px-1 py-1 rounded-b-md invisible ease-linear group-hover:visible">
                <Typography
                  className="text-seondary-200 font-medium line-clamp-2 text-white text-center"
                  size="xs"
                  variant="p"
                >
                  {item.name}
                </Typography>
              </div>
            </div>
          </div>
          <div className="absolute left-2 top-1 flex justify-between items-center w-[90%] invisible group-hover:visible">
            <button
              className="bg-white/[.7] p-1 rounded-sm"
              onClick={() => {
                setDeletedAsset(item.id);
                prop.events.deleteAsset(item.id);
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                  stroke="#232323"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.50012 4.97L8.72012 3.66C8.88012 2.71 9.00012 2 10.6901 2H13.3101C15.0001 2 15.1301 2.75 15.2801 3.67L15.5001 4.97"
                  stroke="#232323"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.8494 9.14014L18.1994 19.2101C18.0894 20.7801 17.9994 22.0001 15.2094 22.0001H8.78941C5.99941 22.0001 5.90941 20.7801 5.79941 19.2101L5.14941 9.14014"
                  stroke="#232323"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.3301 16.5H13.6601"
                  stroke="#232323"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.49988 12.5H14.4999"
                  stroke="#232323"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export const ShowProjetLoader = fetchProjectShow;

export default ShowProjects;
