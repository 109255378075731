import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "../../components/DropDown/DropDown";
import Checkbox from "../../components/CheckBox/CheckBox";
import Button from "../../components/Button/Button";
import CreatePortfolioModal from "./CreatePortfolioModal";
import SearchPortfolio from "./SearchPortfolio";
import { PaginationComponent } from "../../components/Pagination/Pagination";
import { useSelector } from "react-redux";
import { hasPermission } from "../../helpers/accessHelper";

const PortfoliosTopbar = (prop:any) => {
  const params = prop.params;
  const setParams = prop.setParams;
  const setIsLoading = prop.setIsLoading;
  const setSearchInpData = prop.setSearchInpData;
  const navigate = useNavigate();
  const CreatePortfolio = () => {
    CreatePortfolioModal.fire(0, navigate);
  };
  const [role, setRole] = useState<string>("");
  const User = useSelector((state: any) => state.user);
  useEffect(()=>{
    setRole(User.role)
  }, [User])
  return (
    <div className="flex justify-between items-center bg-white shadow-300 py-4 px-5 sticky left-0 top-0 z-[1] w-full">
      <div className="flex justify-start items-center gap-3">
        {/* <button
          className="flex items-center gap-2.5 border border-gray-100 rounded-md px-4 py-2 group"
          onClick={() => {
            setIsLoading(true);
            setParams({
              ...params,
              page:1,
              sort_by:
                params["sort_by"] !== undefined &&
                params["sort_by"][1] === "DESC"
                  ? ["id", "ASC"]
                  : ["id", "DESC"],
            });
          }}
        >
          <label
            className={`font-medium text-400 inline-block group-hover:text-blue-300
                  ${
                    params["sort_by"][1] === "DESC"
                      ? "text-blue-300"
                      : "text-gray-300"
                  }
                `}
          >
            Recent
          </label>
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className={`group-hover:stroke-blue-300 
                      ${
                        params["sort_by"][1] === "DESC"
                          ? "stroke-blue-300"
                          : "stroke-gray-300"
                      }`}
                d="M3 7H21"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                className={`group-hover:stroke-blue-300 
                      ${
                        params["sort_by"][1] === "DESC"
                          ? "stroke-blue-300"
                          : "stroke-gray-300"
                      }`}
                d="M6 12H18"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                className={`group-hover:stroke-blue-300 
                      ${
                        params["sort_by"][1] === "DESC"
                          ? "stroke-blue-300"
                          : "stroke-gray-300"
                      }`}
                d="M10 17H14"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </span>
        </button> */}
        {/* <div className="flex items-center gap-2">
          <label className="text-gray-300 font-medium text-400 inline-block">
            Sort By
          </label>
          <div className="w-[6.25rem]">
            <Dropdown
              onChange={(value:any)=>{
                setIsLoading(true);
                setParams({
                  ...params,
                  page:1,
                  sort_by: value["value"] === "recent"?["created_at", "DESC"]:["created_at", "ASC"]
                });
              }}
              options={[
                {
                  label: "Recent",
                  value: "recent",
                },
                {
                  label: "Oldest",
                  value: "name",
                }
              ]}
              type="smallBox"
              defaultValue={{label:"Recent","value":"recent"}}
              isSearchable={false}
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <div className="w-[6.25rem]">
            <Dropdown
              onChange={(value:any)=>{
                setIsLoading(true);
                setParams({
                  ...params,
                  page:1,
                  status: value["value"] === "Published"?1:value["value"] === "Archived"?2:value["value"] === "Draft"?0:""
                });
              }}
              options={[
                {
                  label: "All",
                  value: "All",
                },
                {
                  label: "Published",
                  value: "Published",
                },
                {
                  label: "Archived",
                  value: "Archived",
                },
                {
                  label: "Draft",
                  value: "Draft",
                }
              ]}
              type="smallBox"
              defaultValue={{label:"All","value":"All"}}
              isSearchable={false}
            />
          </div>
        </div> */}
        {/* <div className="flex items-center gap-2.5">
          <Checkbox 
            checked={params["status"] === 2 ? true : false}
            onCustomChange={(value) => {
              setIsLoading(true);
              const status = value ? 2 : 1;
              setParams({
                ...params,
                page:1,
                status: status,
              });
            }} 
          />
          <label className="text-gray-300 font-medium text-400">
            Show Archive
          </label>
        </div> */}
        {/* <div className="flex items-center gap-2.5">
          <Checkbox 
            checked={params["is_private"] === 0 ? false : true}
            onCustomChange={(value) => {
              setIsLoading(true);
              const is_private = value ? 1 : 0;
              setParams({
                ...params,
                page:1,
                is_private: is_private,
              });
            }}
          />
          <label className="text-gray-300 font-medium text-400">
            Show Private
          </label>
        </div> */}
        <div className="flex justify-start items-center text-gray-300 font-normal text-400">
          <PaginationComponent handlePagination={prop.setPagination} page={Number(prop?.meta?.current_page??0)} totalPages={Number(prop?.meta?.last_page)}/>
        </div>
      </div>
      <div className="flex justify-end gap-4 items-center">
        <div className="w-[18rem]">
          <SearchPortfolio setSearchInpData={setSearchInpData} type='Portfolios'/>
        </div>
        {
          hasPermission(role, 'Portfolios', 'Write') &&
          <Button
            label="Add New Portfolio"
            onClick={() => CreatePortfolio()}
            size="medium"
            variant="primary"
          />
        }
      </div>
    </div>
  );
};

export default PortfoliosTopbar;
