import React, {useState} from "react";
import { useCookies } from 'react-cookie'
import {
  callClientFetch,
  showToastAlert
} from "./CommonFunctions";
import LogClient from "./views/viewPortfolio/LogClient";
import ErrorLogClient from "./views/viewPortfolio/ErrorLogClient";

interface CheckPasswordProps {
  setIsPassword:any;
}

export const CheckPassword = ({ setIsPassword }: CheckPasswordProps) => {
  const [cookies, setCookie] = useCookies(['acp']); 
  let authenticateController:any;

  const [isFromSubmiting, setIsFromSubmiting] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isHide, setIsHide] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [password, setPassword] = useState<string>("");

  const setPasswordShow = () => {
    setIsHide(!isHide);
  }

  const setPasswordData = (value:string) => {
    setPassword(value);
  }

  const submitHandler = async (values: any, { setErrors }: any) => {
    if(values["password"].length <= 0){
      setIsError(true);
      setErrors({
        "password": "Plase enter the password"
      });
      setErrorMessage("Plase enter the password");

      return false;
    }
    
    setIsFromSubmiting(true);

    const url = process.env.REACT_APP_API_URL + "clientView/validatePassword";

    if(authenticateController){
      const reason = new DOMException('signal timed out', 'TimeoutError');
      await authenticateController.abort(reason);
    }

    authenticateController = new AbortController();

    await callClientFetch({
      url: url,
      method: "POST",
      body: JSON.stringify({ password }),
      headers: {
        "Content-Type": "application/json",
      },
      controller: authenticateController,
      callback: async (json: any) => {
        setIsFromSubmiting(false);

        if (json.status == "success") {
          setIsPassword(false);
          let expires = new Date()
          expires.setTime(expires.getTime() + (1*1*60*60*1000))
          setCookie('acp', true, { path: '/',  expires});
        } else if (json.status == "error" && json.error == "validation_errors") {
          setIsError(true);
          setErrors({
            "password": json.validation_errors["password"][0]
          });
          setErrorMessage(json.validation_errors["password"][0]);
        } else {
          showToastAlert(json.message);
        }
      },
    });

  }

  return (
    <>
        {isError?(
          <ErrorLogClient 
            submitHandler={submitHandler} 
            isFromSubmiting={isFromSubmiting} 
            errorMessage={errorMessage} 
            setPasswordData={setPasswordData}
            password={password}
            setPasswordShow={setPasswordShow}
            isHide={isHide}
          />
        ):(
          <LogClient 
            submitHandler={submitHandler} 
            isFromSubmiting={isFromSubmiting}
            setPasswordData={setPasswordData}
            password={password}
            setPasswordShow={setPasswordShow}
            isHide={isHide}
          />
        )}
    </>
  );
};

export default CheckPassword;