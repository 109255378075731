import React, { useState, useEffect } from "react";
import {
  convertParamsToArr,
  fetchAuditLog,
  FetchData,
  pushArray,
} from "../../CommonFunctions";
import Typography from "../../components/Typography/Typography";
import { CustomDateTimePicker } from "../../components/DateTimePicker/DatePicker";
import { SearchInput } from "./SearchLog";
import { useParams } from "react-router-dom";
import moment from "moment";
import CustomLoader from "../../components/Loader/CustomLoader";
import CategoryEmpty from "../categories/CategoryEmpty";
import AuditEmpty from "./AuditEmpty";

const Auditlog = () => {
  let paramsData = useParams();
  paramsData = convertParamsToArr({ params: paramsData });

  paramsData["start_date"] = null;
  paramsData["end_date"] = null;
  paramsData["page"] = 1;
  paramsData["sort_by"] = ["created_at", "DESC"];

  const search = paramsData[1] !== undefined ? paramsData[1] : "";

  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [isLoadMore, setIsLoadMore] = useState<Boolean>(false);
  const [auditLog, setAuditLog] = useState<FetchData>();
  const [params, setParams] = useState(paramsData);
  const [searchInp, setSearchInp] = useState(search);
  const [isMinDateError, setIsMinDateError] = useState<Boolean>(false);
  const [minDate, setMinDate] = useState<string | null>(null);
  const [startDate, setStartDate] = useState(paramsData["start_date"]);
  const [endDate, setEndDate] = useState(paramsData["end_date"]);

  // useEffect(() => {
  //   if (auditLog !== undefined) {
  //     setIsLoading(false);
  //   }
  //   setIsLoadMore(false);
  // }, [auditLog]);

  useEffect(() => {
    if (params) {
      setIsLoading(true);
      setIsLoadMore(false);
      searchAuditLog();
    }
  }, [params]);

  // useEffect(() => {
  //   setIsLoadMore(true);
  //   searchAuditLog();
  // }, [params]);

  const searchAuditLog = async () => {
    const data: FetchData = await fetchAuditLog(params);

    if (data.status === "error" && data.error === "abort") {
      setIsLoading(false);
      setIsLoadMore(true);

      return false;
    }

    // if (auditLog !== undefined && data.status === "success") {
    //   let log = auditLog["data"];
    //   for (const i in data["data"]) {
    //     log = pushArray(log, data["data"][i]);
    //   }
    //   setAuditLog({
    //     data: log,
    //   });
    // } else if (auditLog === undefined) {
    setAuditLog(data);
    // }
    setIsLoading(false);
    setIsLoadMore(true);
  };

  const setSearchInpData = (e: any) => {
    setSearchInp(e.target.value);
  };

  const filterByDate = () => {
    setIsMinDateError(false);

    if (startDate === null && endDate === null) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        start_date: startDate,
        end_date: endDate,
      });
    }

    if (startDate === null || endDate === null) {
      return false;
    }

    const dateLimit = moment(startDate);
    const now = moment(endDate);

    if (now.isBefore(dateLimit)) {
      setIsMinDateError(true);
    } else if (startDate !== null && endDate !== null) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        start_date: startDate,
        end_date: endDate,
      });
    }
  };

  useEffect(() => {
    if (startDate !== null) {
      setMinDate(startDate);
      filterByDate();
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate !== null) {
      filterByDate();
    }
  }, [endDate]);

  useEffect(() => {
    if (searchInp) {
      setParams({
        ...params,
        page: 1,
        1: searchInp,
      });
    }
  }, [searchInp]);

  const clearFilter = () => {
    setStartDate(null);
    setEndDate(null);
    setSearchInp("");
    setParams({
      ...params,
      page: 1,
      start_date: null,
      end_date: null,
      1: "",
    });
  };

  return (
    <div className="px-8 pb-8">
      <div className="flex justify-start item-center gap-4 mt-6">
        <div className="flex justify-start item-center gap-4">
          <div className="w-[10rem]">
            <CustomDateTimePicker
              size="md"
              dateFormat="YYYY-MM-DD"
              maxDate={moment(new Date()).format("yyyy-MM-DD")}
              onChange={(date: Date | null, value: string | null) => {
                setStartDate(value);
              }}
              selectedDate={startDate}
              placeholder="Start Date"
            />
            {startDate === null && endDate !== null ? (
              <Typography
                className="text-primary-100 font-normal"
                variant="p"
                size="sm"
              >
                Please select start date
              </Typography>
            ) : (
              ""
            )}
          </div>
          <div className="w-[10rem]">
            <CustomDateTimePicker
              size="md"
              placeholder="End Date"
              dateFormat="YYYY-MM-DD"
              onChange={(date: Date | null, value: string | null) => {
                setEndDate(value);
              }}
              selectedDate={endDate}
              minDate={minDate !== null ? minDate : null}
              maxDate={moment(new Date()).format("yyyy-MM-DD")}
            />
            {startDate !== null && endDate === null ? (
              <Typography
                className="text-primary-100 font-normal"
                variant="p"
                size="sm"
              >
                Please select end date
              </Typography>
            ) : isMinDateError ? (
              <Typography
                className="text-primary-100 font-normal"
                variant="p"
                size="sm"
              >
                End date can not be less than start date
              </Typography>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="flex justify-start item-center gap-4">
          <SearchInput>
            <input
              type="text"
              placeholder="Search Auditlog"
              className="outline-none w-full bg-white border border-gray-100 ps-10 pe-3 py-[0.563rem] text-400 font-normal 
              rounded-md text-gray-300 placeholder:font-normal
              focus:border-secondary-100 focus:shadow-200
              disabled:bg-gray-200 read-only:!shadow-0 read-only:!border-gray-100"
              onChange={setSearchInpData}
              value={searchInp}
            />
          </SearchInput>
        </div>
        <div className="flex justify-start item-center">
          <button
            className="text-300 font-semibold text-primary-100"
            onClick={() => {
              clearFilter();
            }}
          >
            Clear All
          </button>
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center items-center w-full h-[calc(100vh-11rem)]">
          <CustomLoader />
        </div>
      ) : // <div className="animate-pulse">
      //   {Array.from({ length: 2 }).map((_, idx) => (
      //     <div key={idx} className="pb-2 border-b border-gray-100">
      //       <div className="space-y-6 py-1 mt-8">
      //         <div className="h-3 w-24 bg-gray-100 rounded mb-2" />
      //       </div>
      //       {Array.from({ length: 4 }).map((_, id) => (
      //         <div
      //           key={id}
      //           className="flex justify-start items-center relative after:absolute pb-6 after:left-[16px]
      //           after:bottom-0 after:h-[24px] after:w-[1px] after:bg-gray-100"
      //         >
      //           <div className="w-8 h-8 bg-gray-400 rounded-full flex justify-center items-center">
      //             <div className="rounded-full bg-slate-200 h-8 w-8" />
      //           </div>
      //           <div className="text-gray-500 font-normal text-400 ms-3">
      //             <div className="h-3 w-12 bg-gray-100 rounded" />
      //           </div>
      //           <div className="w-8 h-8 bg-gray-400 rounded-full flex justify-center items-center ms-10">
      //             <div className="rounded-full bg-slate-200 h-8 w-8" />
      //           </div>
      //           <div className="flex justify-start items-center text-500 font-normal text-gray-300 ms-3">
      //             <div className="h-3 w-72 bg-gray-100 rounded" />
      //           </div>
      //         </div>
      //       ))}
      //     </div>
      //   ))}
      // </div>
      auditLog?.status === "success" && auditLog.data.length > 0 ? (
        <>
          {auditLog?.data.map((item: any, idx: any) => (
            <div key={idx} className="pb-2 border-b border-gray-100">
              <Typography
                className="text-gray-300 font-bold mb-8 mt-8"
                size="lg"
                variant="p"
              >
                {item.logKey}
              </Typography>
              {/* {item["item"].map((logItem: any, logid: any) => ( */}
              {item["item"].sort((a:any,b:any)=> b.id-a.id ).map((logItem: any, logid: any) => (
                <div
                  key={logid}
                  className="flex justify-start items-center relative after:absolute pb-6 after:left-[16px] after:bottom-0 after:h-[24px] after:w-[1px] after:bg-gray-100 after:last:hidden"
                >
                  <div className="w-8 h-8 bg-gray-400 rounded-full flex justify-center items-center">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M14.6663 7.99967C14.6663 11.6797 11.6797 14.6663 7.99967 14.6663C4.31967 14.6663 1.33301 11.6797 1.33301 7.99967C1.33301 4.31967 4.31967 1.33301 7.99967 1.33301C11.6797 1.33301 14.6663 4.31967 14.6663 7.99967Z"
                          stroke="#888888"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.4729 10.1202L8.40626 8.88684C8.04626 8.6735 7.75293 8.16017 7.75293 7.74017V5.00684"
                          stroke="#888888"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                  <div className="text-gray-500 font-normal text-400 ms-3">
                    {logItem.time}
                  </div>
                  <div className="w-8 h-8 bg-gray-400 rounded-full flex justify-center items-center ms-10">
                    <span className="text-200 font-medium text-gray-700">
                      {logItem.short_action_by}
                    </span>
                  </div>
                  <div className="flex justify-start items-center text-500 font-normal text-gray-300 ms-3">
                    <span className="inline-block font-medium">
                      {logItem.short_action}
                    </span>
                    {/* <span className="font-normal text-gray-500 inline-block">
                      edited
                    </span> */}
                    &nbsp;
                    {logItem.auditlog}
                    {/* <a
                      href="#"
                      className="border-dotted border-b border-gray-300 inline-block font-medium"
                    >
                      Sequence: HR Onboarding
                    </a> */}
                  </div>
                </div>
              ))}
            </div>
          ))}
          {/* <div className="flex w-100 justify-center text-center items-center align-middle mt-4">
            {isLoadMore ? (
              <div className="flex mt-2">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="duration-200 animate-pulse-3"
                >
                  <circle cx="12" cy="6" r="6" fill="#F15845" />
                </svg>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="duration-300 animate-pulse-2"
                >
                  <circle cx="12" cy="6" r="6" fill="#F15845" />
                </svg>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="duration-400 animate-pulse"
                >
                  <circle cx="12" cy="6" r="6" fill="#F15845" />
                </svg>
              </div>
            ) : params["page"] < auditLog.meta.last_page ? (
              <button
                className="text-primary-100 font-medium text-400 border-b border-primary-100 inline-block"
                onClick={() => {
                  setParams({
                    ...params,
                    page: params["page"] + 1,
                  });
                }}
              >
                <span className="">Load More</span>
              </button>
            ) : (
              ""
            )}
          </div> */}
        </>
      ) : (
        // <div className="mt-20 text-center">
        //   <Typography variant="h5">No Data Found</Typography>
        // </div>
        <div className="flex justify-center items-center w-full h-[calc(100vh-11rem)]">
          <AuditEmpty />
        </div>
      )}
    </div>
  );
};

export default Auditlog;
