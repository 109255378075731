import React from "react";
import * as Yup from "yup";
import {
  convertParamsToArr,
  showConfirm,
  showToastAlert,
  ShowPageLoader,
  HidePageLoader,
  Params,
  callFetch,
  unsetAssocciativeArray,
  updateAssocciativeArray,
  pushArray,
  indexOf,
} from "../../CommonFunctions";

const controller = new AbortController();
let portfolioController:any;
let assetController:any;
let clientController:any;
let categoriesController:any;
let portfolioShowController:any;
let portfolioFiltersController:any;
let assetFiltersController:any;
let internalUsersController:any;
let portfoliosController:any;

export interface data {
  assetsData: any;
  categories?: any;
  events?: any;
}

export interface InputFiled {
  portfolio_title: any;
  domain: any;
  category_id: any;
  status: any;
  client_name: any;
  tags: any;
  is_public: any;
}

export const ErrorMessages: InputFiled = {
  portfolio_title: "",
  domain: "",
  category_id: "",
  status: "",
  client_name: "",
  tags: "",
  is_public: 0,
};

export interface StatusOption {
  label: string;
  value: number;
}

export const statusOptions: StatusOption[] = [
  { label: "UnArchived", value: 1 },
  { label: "Archived", value: 2 },
  // {label:'Draft',value:0},
];

export async function fetchPortfolios({ params }: Params) {
  params = convertParamsToArr({ params });
  let queryString = "";
  queryString = params[0] !== undefined ? "filter=" + params[0] : "";
  queryString +=
    params["sort_by"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["sort_by"] !== undefined ? "sort_by=" + params["sort_by"] : "";
  queryString += params["tags"] !== undefined && queryString !== "" ? "&" : "";
  queryString += params["tags"] !== undefined ? "tags=" + params["tags"] : "";
  queryString +=
    params["status"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["status"] !== undefined ? "status=" + params["status"] : "";
  queryString +=
    params["is_private"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["is_private"] !== undefined ? "is_private=" + params["is_private"] : "";
  queryString +=
    params["start_date"] !== undefined && params["start_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["start_date"] !== undefined && params["start_date"] !== null ? "start_date=" + params["start_date"] : "";
  queryString +=
    params["end_date"] !== undefined && params["end_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["end_date"] !== undefined && params["end_date"] !== null ? "end_date=" + params["end_date"] : "";
  queryString +=
    params["categories"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["categories"] !== undefined ? "categories=" + params["categories"] : "";
  queryString +=
    params["page"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["page"] !== undefined ? "page=" + params["page"] : "";

  if(portfolioController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await portfolioController.abort(reason);
  }

  portfolioController = new AbortController();

  return await callFetch({
    url: process.env.REACT_APP_API_URL + "portfolios?" + queryString,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: portfolioController,
    callback: async (json) => {
      return json;
    },
  });
}

export async function fetchAssets(portfolio_id: number, { params }: Params) {
  params = convertParamsToArr({ params });

  let queryString = "";
  queryString = params[1] !== undefined ? "search=" + params[1] : "";
  queryString += params["tab"] !== undefined && queryString !== "" ? "&" : "";
  queryString += params["tab"] !== undefined ? "file_type=" + params["tab"] : "";  
  queryString += params["sort_by"] !== undefined && queryString !== "" ? "&" : "";
  queryString += params["sort_by"] !== undefined ? "sort_by=" + params["sort_by"] : "";
  queryString += params["start_date"] !== undefined && params["start_date"] !== null && queryString !== "" ? "&" : "";
  queryString += params["start_date"] !== undefined && params["start_date"] !== null ? "start_date=" + params["start_date"] : "";
  queryString += params["end_date"] !== undefined && params["end_date"] !== null && queryString !== "" ? "&" : "";
  queryString += params["end_date"] !== undefined && params["end_date"] !== null ? "end_date=" + params["end_date"] : "";
  
  queryString += params["page"] !== undefined && queryString !== "" ? "&" : "";
  queryString += params["page"] !== undefined ? "page=" + params["page"] : "";

  if(assetController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await assetController.abort(reason);
  }

  assetController = new AbortController();
  
  return await callFetch({
    url:
      process.env.REACT_APP_API_URL +
      "portfolios/" +
      portfolio_id +
      "/assets/all?" +
      queryString,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: assetController,
    callback: async (json) => {
      return json;
    },
  });
}

export async function fetchClients(portfolio_id: BigInteger) {
  if(clientController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await clientController.abort(reason);
  }

  clientController = new AbortController();
  
  return await callFetch({
    url:
      process.env.REACT_APP_API_URL +
      "portfolios/" +
      portfolio_id +
      "/clients/notSelectedClientsList?",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: clientController,
    callback: async (json) => {
      return json;
    },
  });
}

export async function fetchCategories() {
  if(categoriesController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await categoriesController.abort(reason);
  }

  categoriesController = new AbortController();
  
  return await callFetch({
    url:
      process.env.REACT_APP_API_URL +
      "categories/all/data",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: categoriesController,
    callback: async (json) => {
      return json;
    },
  });
}

export async function fetchInvitaions({ params }: Params) {
  params = convertParamsToArr({ params });
  let queryString = "";
  queryString = params[1] !== undefined ? "search=" + params[1] : "";
  queryString +=
    params["sort_by"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["sort_by"] !== undefined ? "sort_by=" + params["sort_by"] : "";
  queryString +=
    params[0] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params[0] !== undefined ? "portfolio_id=" + params[0] : "";
  queryString +=
    params["page"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["page"] !== undefined ? "page=" + params["page"] : "";

  if(portfolioController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await portfolioController.abort(reason);
  }

  portfolioController = new AbortController();

  return await callFetch({
    url: process.env.REACT_APP_API_URL + "clientinvitation?" + queryString,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: portfolioController,
    callback: async (json) => {
      return json;
    },
  });
}

export async function fetchPortfolioShow({ params }: Params) {
  params = convertParamsToArr({ params });
  if (params[0] !== undefined && params[0] != "") {
    if(portfolioShowController){
      const reason = new DOMException('signal timed out', 'TimeoutError');
      await portfolioShowController.abort(reason);
    }

    portfolioShowController = new AbortController();

    return await callFetch({
      url: process.env.REACT_APP_API_URL + "portfolios/" + params[0],
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      controller: portfolioShowController,
      callback: async (json) => {
        return json;
      },
    });
  }

  return { status: "error", error: 404 };
}

export async function fetchPortfolioFilters() {
  if(portfolioFiltersController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await portfolioFiltersController.abort(reason);
  }

  portfolioFiltersController = new AbortController();
  
  return await callFetch({
    url: process.env.REACT_APP_API_URL + "portfolios/filters",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: portfolioFiltersController,
    callback: async (json) => {
      return json;
    },
  });
}

export async function fetchAssetFilters() {
  if(assetFiltersController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await assetFiltersController.abort(reason);
  }

  assetFiltersController = new AbortController();
  
  return await callFetch({
    url: process.env.REACT_APP_API_URL + "portfolios/filters",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: assetFiltersController,
    callback: async (json) => {
      return json;
    },
  });
}

export async function fetchSharedAssets(portfolio_id:number,client_id:number,page:number) {
  if (client_id && portfolio_id) {
    if(portfoliosController){
      const reason = new DOMException('signal timed out', 'TimeoutError');
      await portfoliosController.abort(reason);
    }

    portfoliosController = new AbortController();

    return await callFetch({
      url:
        process.env.REACT_APP_API_URL +
        "clients/" + client_id + "/portfolios/"+portfolio_id+"/projects?page="+page,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      controller: portfoliosController,
      callback: async (json) => {
        return json;
      },
    });
  }

  return { status: "error",error:404};
}

export const confirmDeletePortfolio = async (
  id: BigInteger,
  params: any,
  setPortfoliosData: any,
) => {
  await showConfirm({
    title: "Are you sure you want to delete this portfolio?",
    message: "The portfolio information will be deleted and can’t be undone. Click Yes, if you still want to continue.",
    type: "warning",
    confirm: async () => {
      await deletePortfolio(id, params, setPortfoliosData);
    },
    cancel: () => {},
  });
};

const deletePortfolio = async (
  id: BigInteger,
  params: any,
  setPortfoliosData: any,
) => {
  return await callFetch({
    url: process.env.REACT_APP_API_URL + "portfolios/" + id,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json) => {
      if (json.status == "success") {
        showToastAlert(json.message, 1);
        const data = await fetchPortfolios({ params });
        setPortfoliosData(data);
      } else {
        showToastAlert(json.message);
      }
      return json;
    },
  });
};

export const deletePortfolioAsset = async (
  portfolio_id: BigInteger,
  id: BigInteger,
  oldPortfolioAssets: any,
  oldAssets: any,
  oldCounter: any,
  updateData: any,
  loadData: any
) => {
  if(oldPortfolioAssets.length <= 1){
    showToastAlert("At least one asset must be added to the portfolio.");
    return false;
  }

  updateData(
    unsetAssocciativeArray(oldPortfolioAssets, "id", id),
    updateAssocciativeArray(oldAssets,"id", id,"is_disabled",false),
    oldCounter
  );

  return await callFetch({
    url:
      process.env.REACT_APP_API_URL +
      "portfolios/" +
      portfolio_id +
      "/assets/" +
      id,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json) => {
      if (json.status == "success") {
        // showToastAlert(json.message, 1);
        loadData();
      } else {
        updateData(
          oldPortfolioAssets,
          updateAssocciativeArray(oldAssets,"id", id,"is_disabled",true),
          oldCounter
        );
        showToastAlert(json.message);
      }

      return json;
    },
  });
};

export const validationPasswordSchema = Yup.object({
  // password: Yup.string()
  //   .required("Password")
  //   .min(2, "Too Short!")
  //   .max(254, "Too Long!"),
});

export const validationExpiryDateSchema = Yup.object({
  expiry_date: Yup.string()
    .required("Expiry date is required")
});

export const validationSchema = Yup.object({
  portfolio_title: Yup.string()
    .required("Portfolio title is required")
    .min(2, "Too Short!")
    .max(254, "Too Long!"),
  // client_name: Yup.string().required("Client name is required").min(2, 'Too Short!').max(254, 'Too Long!'),
});

export const savePortfolio = async (props: any) => {
  props.setIsFromSubmiting(true);
  const errorMessage = ErrorMessages;

  let url = process.env.REACT_APP_API_URL + "portfolios";
  url =
    props.portfoliosData["data"] !== undefined
      ? url + "/" + props.portfoliosData["data"].id
      : url;

    for (const key in props.formData) {
      if (Object.prototype.hasOwnProperty.call(props.formData, key)) {
          const value = props.formData[key];
          if(value === "" || value === null) {
            delete props.formData[key];
          }
          if (typeof value === 'object' && value !== null) {
            if(value.length > 0) {
              props.formData[key]= JSON.stringify(value);
            } else {
              delete props.formData[key];
            }
          }
      }
    }
  return await callFetch({
    url: url,
    method: props.portfoliosData["data"] !== undefined ? "PUT" : "POST",
    body: JSON.stringify(props.formData),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      props.setIsFromSubmiting(false);

      if (json.status == "success") {
        // showToastAlert(json.message, 1);
        props.close();
        props.navigate("/portfolios/show/" + json.data.id);
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          errorMessage[name as keyof InputFiled] =
            json.validation_errors[name][0];
        }

        props.setErrorMessage(errorMessage);
      } else {
        showToastAlert(json.message);
      }

      return json;
    },
  });
};

export const updatePortfolioStatus = async (
  id: number,
  status: any,
  loadData: any,
  isShowLoader:any = true,
  from:any = "preview",
) => {
  if(isShowLoader){
    ShowPageLoader();
  }

  const url = process.env.REACT_APP_API_URL + "portfolios/updateStatus/" + id;

  return await callFetch({
    url: url,
    method: "PUT",
    body: JSON.stringify({ status, from }),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      if(isShowLoader){
        HidePageLoader();
      }

      if (json.status == "success") {
        showToastAlert(json.message, 1);
        loadData();
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          showToastAlert(json.validation_errors[name][0]);
        }
      } else {
        showToastAlert(json.message);
      }

      return json;
    },
  });
};

export const updatePortfolioPublicStatus = async (id:BigInteger,isDisabled:any,setIsDisabled:any) => {
  const url = process.env.REACT_APP_API_URL + "portfolios/updatePublicStatus/" + id;

  const is_public = isDisabled?0:1;
  setIsDisabled(isDisabled);

  return await callFetch({
    url: url,
    method: "PUT",
    body: JSON.stringify({ is_public}),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      if (json.status == "success") {
        // showToastAlert(json.message, 1);
      } else if (json.status == "error" && json.error == "validation_errors") {
        setIsDisabled(is_public === 1?false:true);
        for (const name in json.validation_errors) {
          showToastAlert(json.validation_errors[name][0]);
        }
      } else {
        setIsDisabled(is_public === 1?false:true);
        showToastAlert(json.message);
      }

      return json;
    },
  });
};

export const saveAsset = async (props: any) => {
  const url =
    process.env.REACT_APP_API_URL +
    "portfolios/" +
    props.portfolio_id +
    "/assets";

  return await callFetch({
    url: url,
    method: "POST",
    body: JSON.stringify({
      asset_id: props.id,
      asset_type: props.item.type
    }),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      HidePageLoader();

      if (json.status == "success") {
        // showToastAlert(json.message, 1);
        props.loadData(json);
        props.item.id=json.data.id
        props.updateData(
          pushArray(props.oldPortfolioAssets, props.item),
          updateAssocciativeArray(props.oldAssets,"id", json.data.id,"is_disabled",true),
          props.oldCounter,
        );
      } else if (json.status == "error" && json.error == "validation_errors") {
        props.updateData(
          unsetAssocciativeArray(props.oldPortfolioAssets, "id", props.id),
          updateAssocciativeArray(props.oldAssets,"id", props.id,"is_disabled",false),
          props.oldCounter,
        );

        for (const name in json.validation_errors) {
          showToastAlert(json.validation_errors[name][0]);
        }
      } else {
        props.updateData(
          unsetAssocciativeArray(props.oldPortfolioAssets, "id", props.id),
          updateAssocciativeArray(props.oldAssets,"id", props.id,"is_disabled",false),
        );

        showToastAlert(json.message);
      }

      return json;
    },
  });
};

export const sharePortfolio = async (props: any) => {
  props.setIsShareing(true);

  const url =
    process.env.REACT_APP_API_URL +
    "portfolios/" +
    props.portfolio_id +
    "/clients/share";

  return await callFetch({
    url: url,
    method: "POST",
    body: JSON.stringify({
      sharedClients: JSON.stringify(props.sharedClients),
      deletedClients: JSON.stringify(props.deletedClients),
      sharedUsers: JSON.stringify(props.sharedUsers),
      deletedUsers: JSON.stringify(props.deletedUsers)
    }),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      props.setIsShareing(false);
      if (json.status == "success") {
        props.onClose();
        showToastAlert(json.message, 1);
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          showToastAlert(json.validation_errors[name][0]);
        }
      } else {
        showToastAlert(json.message);
      }

      return json;
    },
  });
};

export const generateLink = async (props: any) => {
  const url =
    process.env.REACT_APP_API_URL +
    "portfolios/" +
    props.portfolio_id +
    "/publicLinks";

  return await callFetch({
    url: url,
    method: "POST",
    body: "",
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      if (json.status == "success") {
        props.setPublicLink(json.data);
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          showToastAlert(json.validation_errors[name][0]);
        }
        props.setPublicLink(null);
      } else {
        showToastAlert(json.message);
        props.setPublicLink(null);
      }

      return json;
    },
  });
};

export async function getInternalUsers(portfolio_id:BigInteger) {
  if(internalUsersController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await internalUsersController.abort(reason);
  }

  internalUsersController = new AbortController();
  
  return await callFetch({
    url:
      process.env.REACT_APP_API_URL +
      "portfolios/" +
      portfolio_id +
      "/clients/notSelectedInternalUsersList",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: internalUsersController,
    callback: async (json) => {
      return json;
    },
  });
}

export const saveTag = async (props: any) => {
  const url =
    process.env.REACT_APP_API_URL +
    "portfolios/" +
    props.portfolio_id +
    "/tags";

  return await callFetch({
    url: url,
    method: "POST",
    body: JSON.stringify({ tag: props.tag}),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      if (json.data !== undefined) {
        props.setTags(json.data);
      }

      if (json.status == "success") {
        // showToastAlert(json.message, 1);
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          showToastAlert(json.validation_errors[name][0]);
        }
      } else {
        showToastAlert(json.message);
      }

      return json;
    },
  });
};

export const removeTag = async (props: any) => {
  const url =
    process.env.REACT_APP_API_URL +
    "portfolios/" +
    props.portfolio_id +
    "/tags/" +
    props.tag;

  return await callFetch({
    url: url,
    method: "DELETE",
    body: "",
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      if (json.data !== undefined) {
        props.setTags(json.data);
      }

      if (json.status == "success") {
        // showToastAlert(json.message, 1);
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          showToastAlert(json.validation_errors[name][0]);
        }
      } else {
        showToastAlert(json.message);
      }

      return json;
    },
  });
};

export const updatePassword = async (prop:any) => {
  prop.setIsPasswordFromSubmiting(true);
  const url = process.env.REACT_APP_API_URL + "portfolios/"+prop.portfolio_id+"/publicLinks/updatePassword/"+prop.id+"";

  return await callFetch({
    url: url,
    method: "PUT",
    body: JSON.stringify(prop.formData),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      prop.setIsPasswordFromSubmiting(false);
      if (json.status == "success") {
        // showToastAlert(json.message, 1);
        showToastAlert(json.message === "PublicLink updated successfully" ? "Public Link updated successfully" : "", 1);
        prop.setShowPassword(false);
        prop.setPublicLink(json.data);
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          showToastAlert(json.validation_errors[name][0]);
        }
      } else {
        showToastAlert(json.message);
      }

      return json;
    },
  });
};

export const updateExpiryDate = async (prop:any) => {
  prop.setIsExpiryDateFromSubmiting(true);
  const url = process.env.REACT_APP_API_URL + "portfolios/"+prop.portfolio_id+"/publicLinks/updateExpiryDate/"+prop.id+"";

  return await callFetch({
    url: url,
    method: "PUT",
    body: JSON.stringify(prop.formData),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      prop.setIsExpiryDateFromSubmiting(false);
      if (json.status == "success") {
        showToastAlert(json.message === "PublicLink updated successfully" ? "Public Link updated successfully" : "", 1);
        prop.setShowExpiryDate(false);
        prop.setPublicLink(json.data);
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          showToastAlert(json.validation_errors[name][0]);
        }
      } else {
        showToastAlert(json.message);
      }

      return json;
    },
  });
};

export const updateAssetOrder = async (props: any) => {
  props.setPortfolioAssets(props.newAssets);

  const url =
    process.env.REACT_APP_API_URL +
    "portfolios/" +
    props.portfolio_id +
    "/assets/sequenceReorder"

    if(portfolioShowController){
      const reason = new DOMException('signal timed out', 'TimeoutError');
      await portfolioShowController.abort(reason);
    }

    portfolioShowController = new AbortController();
  return await callFetch({
    url: url,
    method: "PUT",
    body: JSON.stringify(props.newAssets),
    headers: {
      "Content-Type": "application/json",
    },
    controller: portfolioShowController,
    callback: async (json: any) => {
      if (json.status == "success") {
        // showToastAlert(json.message, 1);
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          showToastAlert(json.validation_errors[name][0]);
        }
        props.setPortfolioAssets(props.oldAssets);
      } else {
        showToastAlert(json.message);
        props.setPortfolioAssets(props.oldAssets);
      }

      return json;
    },
  });
};

export const saveClients = async (props: any) => {
  props.setIsFromSubmiting(true);
  const errorMessage = {
    name: "",
    email: "",
    company_name: "",
    status: 1,
    is_converted: 0,
  };

  const url = process.env.REACT_APP_API_URL + "clients";

  return await callFetch({
    url: url,
    method: "POST",
    body: JSON.stringify(props.formData),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      props.setIsFromSubmiting(false);
      const errorMessage = ErrorMessages;

      if (json.status == "success") {
        props.loadData(json);
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          errorMessage[name as keyof InputFiled] =
            json.validation_errors[name][0];
        }

        props.setErrorMessage(errorMessage);
      } else {
        showToastAlert(json.message);
      }

      return json;
    },
  });
};

export const confirmrevokeAccessBulk = async (
  publicLinkId: number[],
  setPublicLinkId: any,
  setpublicLinksData: any,
  publicLinksData:any
) => {
  await showConfirm({
    title: "Are you sure you want to revoke the access?",
    // message: "The data will remain intact and you can unarchive the account anytime.",
    type: "warning",
    confirm: async () => {
      await revokeAccessBulk(publicLinkId, setPublicLinkId, setpublicLinksData,publicLinksData);
    },
    cancel: () => {},
  });
};

const revokeAccessBulk = async (
  publicLinkId: any,
  setPublicLinkId: any,
  setpublicLinksData: any,
  publicLinksData:any
) => {
  const newArray = publicLinksData["data"];
  for(const i in newArray){
    if(indexOf(publicLinkId,newArray[i]['id'])){
      newArray[i]['status'] = 0;
    }
  }

  setpublicLinksData({
    ...publicLinksData,
    data:newArray
  });

  return await callFetch({
    url: process.env.REACT_APP_API_URL + "clientinvitation/revokeAccessBulk",
    method: "PUT",
    body: JSON.stringify({ publicLinkId:publicLinkId.join(',') }),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json) => {
      if (json.status == "success") {
        showToastAlert(json.message, 1);
        setPublicLinkId([]);
      } else {
        showToastAlert(json.message);
        for(const i in newArray){
          if(indexOf(publicLinkId,newArray[i]['id'])){
            newArray[i]['status'] = 1;
          }
        }
        setpublicLinksData({
          ...publicLinksData,
          data:newArray
        });
      }
      return json;
    },
  });
};

export const confirmRevokeAccess = async (
  id: number,
  setpublicLinksData: any,
  publicLinksData:any
) => {
  await showConfirm({
    title: "Are you sure you want to revoke the access?",
    // message: "The data will remain intact and you can unarchive the account anytime.",
    type: "warning",
    confirm: async () => {
      await revokeAccess(id, setpublicLinksData,publicLinksData);
    },
    cancel: () => {},
  });
};

const revokeAccess = async (
  id: number,
  setpublicLinksData: any,
  publicLinksData:any
) => {
  setpublicLinksData({
    ...publicLinksData,
    data:updateAssocciativeArray(publicLinksData["data"],'id',id,'status',0)
  });

  return await callFetch({
    url: process.env.REACT_APP_API_URL + "clientinvitation/revokeAccess/"+id,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json) => {
      if (json.status == "success") {
        showToastAlert(json.message === "ClientInvitation revoked successfully" ? "Client Invitation revoked successfully": "", 1);
        let data = updateAssocciativeArray(publicLinksData["data"],'id',id,'expiry_date',json.data.expiry_date);
        data = updateAssocciativeArray(data,'id',id,'days',json.data.days);
        data = updateAssocciativeArray(data,'id',id,'status',json.data.status);
        data = updateAssocciativeArray(data,'id',id,'has_token_expiry_date',json.data.has_token_expiry_date);
        setpublicLinksData({
          ...publicLinksData,
          data:data 
        });
      } else {
        showToastAlert(json.message);
        setpublicLinksData({
          ...publicLinksData,
          data:updateAssocciativeArray(publicLinksData["data"],'id',id,'status',1)
        });
      }
      return json;
    },
  });
};
