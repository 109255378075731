import React  from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Unauthorized from "./views/unauthorized/Unauthorized";
import { indexOf } from "./CommonFunctions";
import Loader from "./components/Loader/Loader";
import {rolesGroup} from "./CommonFunctions";

interface CheckerPermissionProps {
  children: React.ReactNode;
}

const CheckerPermission = ({ children }: CheckerPermissionProps) => {
  const User = useSelector((state: any) => state.user);
  let isAuthorized = false;

  const location = useLocation();
  const pathname = location.pathname.split("/");
  console.log('Bapu', User)
  if(rolesGroup[User.role as keyof typeof rolesGroup] !== undefined){
    if(indexOf(rolesGroup[User.role as keyof typeof rolesGroup],pathname[1])){
      isAuthorized = true;
    }
  }

  return (
    <>
      {isAuthorized && User.role !== "" ? (
        <>
          {children}
        </>
      ):User.role == "" ? (
        <Loader />
      ):(
        <Unauthorized />
      )}
    </>
  );
};

export default CheckerPermission;
