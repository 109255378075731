import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { useLoaderData, useParams } from "react-router-dom";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import {
  convertParamsToArr,
  unsetArray,
  FetchData,
  pushArray,
} from "../../CommonFunctions";
import Button from "../../components/Button/Button";
import Typography from "../../components/Typography/Typography";
import Icons from "../../components/Icons/Icon";
import InlineTag from "../../components/Tags/InlineTag";
import productOneImage from "../../images/download.svg";
import CreatePortfolioModal from "./CreatePortfolioModal";
import InviteClientsModal from "./InviteClientsModal";
import ListLoader from "../../components/ListLoader/ListLoader";
import { CustomDateTimePicker } from "../../components/DateTimePicker/DatePicker";
import moment from "moment";
import {
  fetchPortfolioShow,
  fetchAssets,
  saveAsset,
  deletePortfolioAsset,
  fetchAssetFilters,
  updateAssetOrder,
} from "./PortfolioFunction";
import SearchProject from "../projects/SearchAsset";
import styled from "styled-components";
import porfolioTwoImage from "../../images/portfolioTwo.png";
import videoCircleImage from "../../images/video-circle.svg";
import Accordion from "../../components/Accordion/Accordion";
import Checkbox from "../../components/CheckBox/CheckBox";
import Tabs from "../../components/Tabs/Tab";
import CreateAssetModal from "../projects/CreateAssetModal";
import AssetFilter from "./AssetFilter";
import { PaginationComponent } from "../../components/Pagination/Pagination";
import CustomLoader from "../../components/Loader/CustomLoader";
import store from "../../store";
import { updateHeaderData } from "../../User";
import AssetsEmpty from "../assets/AssetsEmpty";
import PortfolioAssetsEmpty from "./PortfolioAssetsEmpty";

export const ImgOverlay = styled.div`
  .block-area {
    position: relative;
    transition: 0.3s;
  }
  .overLay {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    -webkit-transition: -webkit-transform 0.35s;
    transition: all 0.3s ease;
    display: none;
  }
  .block-area:hover .overLay {
    display: block;
  }
`;

const ShowPortfolio = () => {
  let paramsData = useParams();
  paramsData = convertParamsToArr({ params: paramsData });
  paramsData["page"] = 1;
  paramsData["sort_by"] = ["id", "DESC"];
  paramsData["tab"] = paramsData[0] !== undefined ? paramsData[0] : "All";

  let fetchData: FetchData;
  console.log(paramsData, "paramsData");
  const [portfoliosData] = useState<FetchData>(useLoaderData());
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [isLoadingList, setIsLoadingList] = useState<Boolean>(true);
  const [isLoadMore, setIsLoadMore] = useState<Boolean>(false);
  const [searchInp, setSearchInp] = useState("");
  const [params, setParams] = useState(paramsData);
  const [assetsList, setAssetsList] = useState<FetchData>();
  const [portfolioAssets, setPortfolioAssets] = useState(undefined);
  const [assets, setAssets] = useState(undefined);
  const [isActive, setIsActive] = useState("All");
  const navigate = useNavigate();
  const [counter, setCounter] = useState({
    All: 0,
    Documents: 0,
    Images: 0,
    Videos: 0,
    Links: 0,
  });

  useEffect(() => {
    setIsLoadingList(false);
    setIsLoadMore(false);
  }, [assetsList, assets, counter]);

  useEffect(() => {
    setIsLoading(false);
    if (
      portfoliosData !== undefined &&
      portfoliosData.data !== undefined &&
      portfoliosData.data.assets !== undefined
    ) {
      setPortfolioAssets(portfoliosData.data.assets);
    }
  }, [portfoliosData]);

  useEffect(() => {
    if (portfoliosData !== undefined) {
      setIsLoading(false);

      if (portfoliosData.status === "success") {
        const data = {
          portfolio_title: portfoliosData.data.portfolio_title,
          navigate: navigate,
        };
        store.dispatch(updateHeaderData(data));
      }
    }
  }, [portfoliosData]);

  useEffect(() => {
    searchAsset();
  }, [searchInp]);

  useEffect(() => {
    setIsLoadMore(true);
    loadData();
  }, [params]);

  const loadData = async () => {
    fetchData = await fetchAssets(paramsData[0], { params });

    if (fetchData.status === "error" && fetchData.error === "abort") {
      return false;
    }

    setCounter(fetchData.data.counter);
    setAssets(fetchData.data.assets);

    setAssetsList(fetchData);
  };

  const sortAsset = () => {
    setIsLoadingList(true);
    setParams({
      ...params,
      page: 1,
      sort_by:
        params["sort_by"] !== undefined && params["sort_by"][1] === "DESC"
          ? ["id", "ASC"]
          : ["id", "DESC"],
    });
  };

  const CreatePortfolio = (id: number) => {
    CreatePortfolioModal.fire(id, navigate);
  };

  const InviteClients = () => {
    InviteClientsModal.fire(portfoliosData.data);
  };

  const deleteAsset = async (id: BigInteger, item: any) => {
    const oldPortfolioAssets: [] =
      portfolioAssets !== null && portfolioAssets !== undefined
        ? portfolioAssets
        : [];
    const oldAssets: [] = assets !== undefined ? assets : [];
    const oldCounter = counter !== undefined ? counter : [];

    await deletePortfolioAsset(
      portfoliosData.data.id,
      item.id,
      oldPortfolioAssets,
      oldAssets,
      oldCounter,
      updateData,
      loadData
    );
  };

  const setSearchInpData = (e: any) => {
    setSearchInp(e.target.value);
  };

  useEffect(() => {
    setIsLoadingList(true);
    const data = params;
    data["tab"] = isActive;
    setParams({
      ...params,
      page: 1,
      tab: isActive,
    });
  }, [isActive]);

  const setActiveTab = (tab: string) => {
    setIsActive(tab);
  };

  const searchAsset = async () => {
    if (searchInp != null && (searchInp.length >= 1 || searchInp.length <= 0)) {
      setIsLoadingList(true);
      setParams({
        ...params,
        page: 1,
        1: searchInp,
      });
    }
  };

  const updateData = (assets: any, fileData: any, counterData: any) => {
    setPortfolioAssets(assets);
    setAssets(fileData);
    setCounter(counterData);
  };

  const addAsset = (item: any) => {
    if (item.is_disabled !== undefined && !item.is_disabled) {
      const oldPortfolioAssets: [] =
        portfolioAssets !== null && portfolioAssets !== undefined
          ? portfolioAssets
          : [];
      const oldAssets: [] = assets !== undefined ? assets : [];
      const oldCounter = counter !== undefined ? counter : [];

      saveAsset({
        portfolio_id: portfoliosData.data.id,
        id: item.id,
        oldPortfolioAssets: oldPortfolioAssets,
        oldAssets: oldAssets,
        oldCounter: oldCounter,
        updateData: updateData,
        item: item,
        loadData: loadData,
      });
    }
  };
  const setPagination = (page: number) => {
    setParams({
      ...params,
      page: page,
    });
  };

  const events = {
    setSearchInpData: setSearchInpData,
    searchAsset: searchAsset,
    deleteAsset: deleteAsset,
    sortAsset: sortAsset,
    loadData: loadData,
    setParams: setParams,
    InviteClients: InviteClients,
    setIsLoadingList: setIsLoadingList,
    addAsset: addAsset,
    setActiveTab: setActiveTab,
    setPagination: setPagination,
  };

  return (
    <>
      {isLoading === true ? (
        <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
          <CustomLoader />
        </div>
      ) : // <ListLoader />
      portfoliosData["status"] === "success" ? (
        <div className="mt-1">
          <div className="grid grid-cols-12 h-[calc(100vh-12.5rem)] border border-gray-100 rounded-t-md">
            <div className="col-span-7">
              <PortfolioDetails
                portfoliosData={portfoliosData}
                portfolioAssets={portfolioAssets}
                setPortfolioAssets={setPortfolioAssets}
                CreatePortfolio={CreatePortfolio}
                events={events}
              />
            </div>
            <div className="col-span-5">
              <Assets
                params={params}
                searchInp={searchInp}
                events={events}
                isLoading={isLoadingList}
                isLoadMore={isLoadMore}
                assetsList={assetsList}
                assets={assets}
                portfolio={portfoliosData.data}
                portfolioAssets={portfolioAssets}
                counter={counter}
              />
            </div>
          </div>
          <div className="flex justify-start items-center gap-5 border border-gray-100 p-4 border-t-0 rounded-b-md">
            <Button
              type="submit"
              label="Preview"
              variant="primary"
              size="large"
              isDisabled={
                portfolioAssets !== null &&
                portfolioAssets !== undefined &&
                portfolioAssets != ""
                  ? false
                  : true
              }
              onClick={() => {
                navigate("/portfolios/preview/" + portfoliosData.data.id);
              }}
            />
            <Typography
              variant="p"
              className="font-medium text-300 text-primary-100"
            >
              Your changes will be auto-saved!
            </Typography>
          </div>
          {/* <Button
            label="Cancel"
            onClick={function noRefCheck() {}}
            variant="outline"
            size="large"
          /> */}
        </div>
      ) : portfoliosData["status"] === "error" &&
        portfoliosData["error"] === "404" ? (
        <div className="mt-20 text-center">
          <Typography variant="h5">Portfolio not found!</Typography>
        </div>
      ) : (
        <div className="mt-20 text-center">
          <Typography variant="h5">Something went wrong!</Typography>
        </div>
      )}
    </>
  );
};

const PortfolioDetails = ({
  portfoliosData,
  portfolioAssets,
  setPortfolioAssets,
  CreatePortfolio,
  events,
}: any) => {
  const InviteClients = events.InviteClients;
  const portfolio = portfoliosData["data"];
  const [tags, setTags] = useState(portfoliosData["data"]["tags"]);
  let size = [0, 0];

  const useWindowSize = () => {
    const [wSize, setwSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setwSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);

    size = wSize;
    return wSize[0];
  };

  const onChange = async (
    sourceId: any,
    sourceIndex: any,
    targetIndex: any,
    targetId: any
  ) => {
    const nextState = swap(portfolioAssets, sourceIndex, targetIndex);
    await updateAssetOrder({
      portfolio_id: portfoliosData["data"]["id"],
      oldAssets: portfolioAssets,
      newAssets: nextState,
      setPortfolioAssets: setPortfolioAssets,
    });
  };

  return (
    <>
      <div className="h-full bg-white rounded-tl-md">
        <div className="flex justify-start gap-3 items-start ps-4 pe-6 py-4 overflow-auto h-[80px]">
          <div className="w-[calc(100%-80px)]">
            <InlineTag
              tags={
                tags !== "" && tags !== null && tags !== undefined
                  ? tags.split(",")
                  : []
              }
            />
          </div>
          <div className="flex justify-end">
            <button
              className="flex justify-end items-center text-400 text-secondary-100 font-bold"
              onClick={() => CreatePortfolio(portfolio.id)}
            >
              <Icons name="edit" variant="stroke" size={21} className="me-1" />
              Edit
            </button>
            {/* {portfolio.status === 1 ? (
              <button className="flex justify-end items-center text-400 text-secondary-100 font-bold"
                onClick={() => {
                  InviteClients();
                }}
              >
                <span className="me-1">
                  <svg
                    width="20"
                    height="20"
                    className="mt-0.5"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.96 6.17004C18.96 7.56004 20.34 9.77004 20.62 12.32"
                      stroke="#232323"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.48999 12.37C3.74999 9.82997 5.10999 7.61997 7.08999 6.21997"
                      stroke="#232323"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.19 20.9399C9.35 21.5299 10.67 21.8599 12.06 21.8599C13.4 21.8599 14.66 21.5599 15.79 21.0099"
                      stroke="#232323"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.06 7.70001C13.5954 7.70001 14.84 6.45537 14.84 4.92001C14.84 3.38466 13.5954 2.14001 12.06 2.14001C10.5247 2.14001 9.28003 3.38466 9.28003 4.92001C9.28003 6.45537 10.5247 7.70001 12.06 7.70001Z"
                      stroke="#232323"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M4.82999 19.92C6.36534 19.92 7.60999 18.6753 7.60999 17.14C7.60999 15.6046 6.36534 14.36 4.82999 14.36C3.29464 14.36 2.04999 15.6046 2.04999 17.14C2.04999 18.6753 3.29464 19.92 4.82999 19.92Z"
                      stroke="#232323"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19.17 19.92C20.7054 19.92 21.95 18.6753 21.95 17.14C21.95 15.6046 20.7054 14.36 19.17 14.36C17.6347 14.36 16.39 15.6046 16.39 17.14C16.39 18.6753 17.6347 19.92 19.17 19.92Z"
                      stroke="#232323"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                Share
              </button>
            ) : (
              ""
            )} */}
          </div>
        </div>
        <GridContextProvider onChange={onChange}>
          <div className="ps-4 mt-3">
            <GridDropZone
              id="portfolioAsset"
              boxesPerRow={useWindowSize() >= 980 ? 3 : size[0] >= 796 ? 2 : 1}
              rowHeight={205}
              className="grid grid-cols-3 h-[calc(100vh-300px)] gap-4 overflow-x-hidden overflow-y-auto pe-2"
            >
              {portfolioAssets !== undefined && portfolioAssets !== null
                ? portfolioAssets.map((item: any) => (
                    <GridItem
                      key={item.id}
                      className={`cursor-move`}
                      style={{
                        width:
                          size[0] >= 1900
                            ? "19.5rem"
                            : size[0] >= 1800
                            ? "18.5rem"
                            : size[0] >= 1700
                            ? "17.5rem"
                            : size[0] >= 1600
                            ? "16.5rem"
                            : size[0] >= 1500
                            ? "15.5rem"
                            : size[0] >= 1400
                            ? "14rem"
                            : size[0] >= 1300
                            ? "13.25rem"
                            : size[0] >= 1200
                            ? "12rem"
                            : size[0] >= 1030
                            ? "18rem"
                            : size[0] >= 890
                            ? "15rem"
                            : "18rem",
                        height: "auto",
                      }}
                    >
                      <div className="">
                        <ImgOverlay>
                          <div className="block-area h-40 w-full overflow-hidden flex justify-center items-center rounded-md relative hover:duration-700">
                            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
                              {item.type === "Documents" ? (
                                <svg
                                  width="59"
                                  height="59"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                    stroke="#F15845"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M15.75 9H8.25"
                                    stroke="#F15845"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M15.75 15H8.25"
                                    stroke="#F15845"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              ) : item.type === "Projects" ? (
                                <svg
                                  width="59"
                                  height="59"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.5 7.67001V6.70001C7.5 4.45001 9.31 2.24001 11.56 2.03001C14.24 1.77001 16.5 3.88001 16.5 6.51001V7.89001"
                                    stroke="#F15845"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M8.99999 22H15C19.02 22 19.74 20.39 19.95 18.43L20.7 12.43C20.97 9.99 20.27 8 16 8H7.99999C3.72999 8 3.02999 9.99 3.29999 12.43L4.04999 18.43C4.25999 20.39 4.97999 22 8.99999 22Z"
                                    stroke="#F15845"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M15.4955 12H15.5045"
                                    stroke="#F15845"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M8.49451 12H8.50349"
                                    stroke="#F15845"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              ) : item.type === "Images" ? (
                                <svg
                                  width="59"
                                  height="59"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M21.6799 16.9599L18.5499 9.64988C17.4899 7.16988 15.5399 7.06988 14.2299 9.42988L12.3399 12.8399C11.3799 14.5699 9.58993 14.7199 8.34993 13.1699L8.12993 12.8899C6.83993 11.2699 5.01993 11.4699 4.08993 13.3199L2.36993 16.7699C1.15993 19.1699 2.90993 21.9999 5.58993 21.9999H18.3499C20.9499 21.9999 22.6999 19.3499 21.6799 16.9599Z"
                                    stroke="#F15845"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M6.96997 8C8.62682 8 9.96997 6.65685 9.96997 5C9.96997 3.34315 8.62682 2 6.96997 2C5.31312 2 3.96997 3.34315 3.96997 5C3.96997 6.65685 5.31312 8 6.96997 8Z"
                                    stroke="#F15845"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              ) : item.type === "Videos" ? (
                                <img
                                  src={videoCircleImage}
                                  alt={videoCircleImage}
                                  className="w-[84px] h-[84px] object-cover relative block"
                                />
                              ) : item.type === "Links" ? (
                                <svg
                                  width="59"
                                  height="59"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15.4101 18.5899C15.0101 18.5899 14.6801 18.2599 14.6801 17.8599C14.6801 17.4599 15.0101 17.1299 15.4101 17.1299C18.2301 17.1299 20.5301 14.8299 20.5301 12.0099C20.5301 9.18992 18.2301 6.88992 15.4101 6.88992C12.5901 6.88992 10.2901 9.18992 10.2901 12.0099C10.2901 12.4099 9.96008 12.7399 9.56008 12.7399C9.16008 12.7399 8.83008 12.4099 8.83008 12.0099C8.83008 8.37992 11.7801 5.41992 15.4201 5.41992C19.0601 5.41992 22.0001 8.36992 22.0001 11.9999C22.0001 15.6299 19.0501 18.5899 15.4101 18.5899Z"
                                    fill="#F15845"
                                  />
                                  <path
                                    d="M8.59 5.40991C8.99 5.40991 9.32 5.73991 9.32 6.13991C9.32 6.53991 8.99 6.86991 8.59 6.86991C5.77 6.86991 3.47 9.16991 3.47 11.9899C3.47 14.8099 5.77 17.1099 8.59 17.1099C11.41 17.1099 13.71 14.8099 13.71 11.9899C13.71 11.5899 14.04 11.2599 14.44 11.2599C14.84 11.2599 15.17 11.5899 15.17 11.9899C15.17 15.6199 12.22 18.5799 8.58 18.5799C4.94 18.5799 2 15.6299 2 11.9999C2 8.36991 4.95 5.40991 8.59 5.40991Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              ) : (
                                <></>
                              )}
                            </div>
                            {item.type === "Projects" ? (
                              item.cover !== "" ? (
                                <img
                                  src={item.cover}
                                  alt={item.project_title}
                                  className="w-full h-full object-cover relative block"
                                />
                              ) : (
                                <img
                                  src={porfolioTwoImage}
                                  alt={item.name}
                                  className="w-full h-full object-cover relative block"
                                />
                              )
                            ) : item.type === "Images" ? (
                              <img
                                src={item.url}
                                alt={item.name}
                                className="w-full h-full object-cover relative block"
                              />
                            ) : (
                              <img
                                src={porfolioTwoImage}
                                alt={item.name}
                                className="w-full h-full object-cover relative block"
                              />
                            )}
                            <div className="overLay px-5 py-5">
                              <div className="absolute right-4 top-4">
                                <button
                                  className="relative bg-white/[.7] px-1 rounded-sm"
                                  onClick={() =>
                                    events.deleteAsset(item.id, item)
                                  }
                                >
                                  <Icons
                                    name="delete"
                                    variant="stroke"
                                    size={16}
                                    className="[&>svg>path]:stroke-primary-100 [&>svg>path]:stroke-2"
                                  />
                                </button>
                              </div>
                              <div className="absolute left-4 top-4">
                                <button
                                  className="relative bg-white/[.7] px-1 rounded-sm"
                                  // onClick={() =>
                                  //   events.deleteAsset(item.id, item)
                                  // }
                                >
                                  <Icons
                                    name="star2"
                                    variant="stroke"
                                    size={16}
                                    className="[&>svg>path]:stroke-primary-100 [&>svg>path]:fill-white"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>
                        </ImgOverlay>
                        <Typography
                          variant="h6"
                          className="text-gray-300 font-medium line-clamp-1 mt-2"
                        >
                          {item.type === "Projects"
                            ? item.project_title
                            : item.name}
                        </Typography>
                      </div>
                    </GridItem>
                  ))
                : ""}
              <GridItem className={`!w-[31%] flex-grow-1`}>
                <div className="h-40 border border-dashed border-gray-1300 bg-gray-200 rounded-md flex justify-center items-center cursor-pointer">
                  <div className="text-center text-blue-400 opacity-60 text-200 font-bold">
                    Select the assets from right to <br></br>add in the portfolio
                  </div>
                </div>
                <Typography
                  variant="h6"
                  className="text-gray-300 font-medium line-clamp-1 mt-2"
                >
                  &nbsp;
                </Typography>
              </GridItem>
            </GridDropZone>
          </div>
        </GridContextProvider>
      </div>
    </>
  );
};

const Assets = (prop: any) => {
  const isLoading = prop.isLoading;
  const isLoadMore = prop.isLoadMore;
  const setSearchInpData = prop.events.setSearchInpData;
  const searchInp = prop.searchInp;
  const assetsList = prop.assetsList;
  const assets = prop.assets;
  const portfolio = prop.portfolio;
  const params = prop.params;
  const setParams = prop.events.setParams;
  const setIsLoadingList = prop.events.setIsLoadingList;
  const addAsset = prop.events.addAsset;
  const isActive = prop.params["tab"];
  const setActiveTab = prop.events.setActiveTab;
  const counter = prop.counter;
  const loadData = prop.events.loadData;

  const [isShowFilters, setIsShowFilters] = useState<Boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isFilterOpen = prop.isFilterOpen;

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsShowFilters(false);
    }
  };

  return (
    <>
      <div className="col-span-3">
        <div className="w-full h-full flex justify-start items-start relative">
          <AssetFilter
            params={params}
            setParams={setParams}
            setIsLoading={setIsLoadingList}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsShowFilters}
          />
          <div className="w-[calc(100%-3.75rem)] ms-[3.75rem] h-full">
            <div className="pt-5 pb-3 px-4 flex justify-between items-center">
              <SearchProject
                setSearchInpData={setSearchInpData}
                style={{ width: "14.313rem" }}
                type="portfolio"
              />
              <Button
                label="Upload Asset"
                onClick={() => {
                  CreateAssetModal.fire(loadData);
                }}
                variant="outline"
                size="medium"
                className="!py-[9px] !text-secondary-100"
              />
            </div>
            <div className="pb-5 px-4 flex justify-start items-center">
              <PaginationComponent
                handlePagination={prop.events.setPagination}
                page={Number(assetsList?.meta?.current_page ?? 0)}
                totalPages={Number(assetsList?.meta?.last_page)}
              />
            </div>
            <div className="ps-4 [&>.sm-size>.cssUseOnly>.cssUseOnlyAdd.active]:!font-bold [&>.sm-size>.cssUseOnly>.cssUseOnlyAdd.active]:!text-gray-300 [&>.sm-size>.cssUseOnly>.cssUseOnlyAdd]:!text-400">
              <Tabs
                onClick={(value) => setActiveTab(value)}
                size="sm"
                currentTab={isActive}
                tabs={
                  counter
                    ? Object.keys(counter).map((item: any, idx: any) => {
                        return {
                          key: idx,
                          content: (
                            <div
                              key={idx}
                              className="overflow-auto h-[calc(100vh-374px)] pe-4 mt-2.5 pb-5"
                            >
                              {isLoading ? (
                                <div className="flex justify-center items-center w-full h-[calc(100%-4rem)]">
                                  <CustomLoader />
                                </div>
                              ) : item === "All" ? (
                                <>
                                  {Object.keys(counter)
                                    .filter((c) => c !== "All")
                                    .map((item1: any, idy: any) => (
                                      <>
                                        <div className="flex justify-between items-center my-2.5">
                                          <div className="text-gray-1100 text-400 font-bold">
                                            {item1}
                                          </div>
                                          <button
                                            className="text-secondary-100 text-400 font-bold"
                                            onClick={() => {
                                              setActiveTab(item1);
                                            }}
                                          >
                                            <span className="">See All</span>
                                          </button>
                                        </div>
                                        <div className="grid grid-cols-2 gap-4">
                                          {assetsList !== undefined &&
                                          assetsList["status"] === "success" ? (
                                            <>
                                              {assets && assets.length > 0 ? (
                                                assets
                                                  .filter(
                                                    (asset: any) =>
                                                      asset.type === item1
                                                  )
                                                  .map(
                                                    (item: any, idx: any) => (
                                                      <div
                                                        key={idx}
                                                        className={
                                                          item.is_disabled !==
                                                            undefined &&
                                                          item.is_disabled
                                                            ? "opacity-70  cursor-default"
                                                            : "cursor-default"
                                                        }
                                                        onClick={() =>
                                                          addAsset(item)
                                                        }
                                                      >
                                                        <ImgOverlay>
                                                          <div className="block-area h-40 w-full overflow-hidden flex justify-center items-center rounded-md relative hover:duration-700">
                                                            {item.cover !==
                                                              "" &&
                                                            (item.type ===
                                                              "Projects" ||
                                                              item.type ===
                                                                "Images") ? (
                                                              <img
                                                                src={
                                                                  item.cover ||
                                                                  item.url
                                                                }
                                                                alt={
                                                                  item.project_title
                                                                }
                                                                className="w-full h-full object-cover relative block"
                                                              />
                                                            ) : (
                                                              <img
                                                                src={
                                                                  porfolioTwoImage
                                                                }
                                                                alt={
                                                                  item.project_title
                                                                }
                                                                className="w-full h-full object-cover relative block"
                                                              />
                                                            )}
                                                            <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
                                                              {item.type ===
                                                                "Documents" &&
                                                                !item.cover && (
                                                                  <svg
                                                                    width="59"
                                                                    height="59"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                  >
                                                                    <path
                                                                      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                                                      stroke="#F15845"
                                                                      strokeWidth="1.5"
                                                                      strokeLinecap="round"
                                                                      strokeLinejoin="round"
                                                                    />
                                                                    <path
                                                                      d="M15.75 9H8.25"
                                                                      stroke="#F15845"
                                                                      strokeWidth="1.5"
                                                                      strokeLinecap="round"
                                                                      strokeLinejoin="round"
                                                                    />
                                                                    <path
                                                                      d="M15.75 15H8.25"
                                                                      stroke="#F15845"
                                                                      strokeWidth="1.5"
                                                                      strokeLinecap="round"
                                                                      strokeLinejoin="round"
                                                                    />
                                                                  </svg>
                                                                )}
                                                              {item.type ===
                                                                "Links" &&
                                                                !item.cover && (
                                                                  <svg
                                                                    width="59"
                                                                    height="59"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                  >
                                                                    <path
                                                                      d="M15.4101 18.5899C15.0101 18.5899 14.6801 18.2599 14.6801 17.8599C14.6801 17.4599 15.0101 17.1299 15.4101 17.1299C18.2301 17.1299 20.5301 14.8299 20.5301 12.0099C20.5301 9.18992 18.2301 6.88992 15.4101 6.88992C12.5901 6.88992 10.2901 9.18992 10.2901 12.0099C10.2901 12.4099 9.96008 12.7399 9.56008 12.7399C9.16008 12.7399 8.83008 12.4099 8.83008 12.0099C8.83008 8.37992 11.7801 5.41992 15.4201 5.41992C19.0601 5.41992 22.0001 8.36992 22.0001 11.9999C22.0001 15.6299 19.0501 18.5899 15.4101 18.5899Z"
                                                                      fill="#F15845"
                                                                    />
                                                                    <path
                                                                      d="M8.59 5.40991C8.99 5.40991 9.32 5.73991 9.32 6.13991C9.32 6.53991 8.99 6.86991 8.59 6.86991C5.77 6.86991 3.47 9.16991 3.47 11.9899C3.47 14.8099 5.77 17.1099 8.59 17.1099C11.41 17.1099 13.71 14.8099 13.71 11.9899C13.71 11.5899 14.04 11.2599 14.44 11.2599C14.84 11.2599 15.17 11.5899 15.17 11.9899C15.17 15.6199 12.22 18.5799 8.58 18.5799C4.94 18.5799 2 15.6299 2 11.9999C2 8.36991 4.95 5.40991 8.59 5.40991Z"
                                                                      fill="#F15845"
                                                                    />
                                                                  </svg>
                                                                )}
                                                              {item.type ===
                                                                "Videos" &&
                                                                !item.cover && (
                                                                  <img
                                                                    src={
                                                                      videoCircleImage
                                                                    }
                                                                    alt={
                                                                      videoCircleImage
                                                                    }
                                                                    className="w-[84px] h-[84px] object-cover relative block"
                                                                  />
                                                                )}
                                                            </div>
                                                          </div>
                                                        </ImgOverlay>
                                                        <Typography
                                                          variant="p"
                                                          size="sm"
                                                          className="text-black font-normal line-clamp-1 mt-2"
                                                        >
                                                          {item.project_title ||
                                                            item.name}
                                                        </Typography>
                                                      </div>
                                                    )
                                                  )
                                              ) : (
                                                <div className="flex justify-center items-center w-full h-[120px] relative">
                                                  <PortfolioAssetsEmpty />
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </>
                                    ))}
                                </>
                              ) : (
                                <>
                                  <div className="grid grid-cols-2 gap-4">
                                    {assetsList !== undefined &&
                                    assetsList["status"] === "success" ? (
                                      <>
                                        {assets.length > 0 ? (
                                          assets.map((item: any, idx: any) => (
                                            <div
                                              key={idx}
                                              className={
                                                item.is_disabled !==
                                                  undefined && item.is_disabled
                                                  ? "opacity-70 cursor-pointer"
                                                  : "cursor-pointer"
                                              }
                                              onClick={() => addAsset(item)}
                                            >
                                              <ImgOverlay>
                                                <div className="block-area h-40 w-full overflow-hidden flex justify-center items-center rounded-md relative hover:duration-700">
                                                  <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 z-10">
                                                    {item.type ===
                                                    "Documents" ? (
                                                      <svg
                                                        width="59"
                                                        height="59"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                                          stroke="#F15845"
                                                          strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        />
                                                        <path
                                                          d="M15.75 9H8.25"
                                                          stroke="#F15845"
                                                          strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        />
                                                        <path
                                                          d="M15.75 15H8.25"
                                                          stroke="#F15845"
                                                          strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        />
                                                      </svg>
                                                    ) : item.type ===
                                                      "Projects" ? (
                                                      <svg
                                                        width="59"
                                                        height="59"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          d="M7.5 7.67001V6.70001C7.5 4.45001 9.31 2.24001 11.56 2.03001C14.24 1.77001 16.5 3.88001 16.5 6.51001V7.89001"
                                                          stroke="#F15845"
                                                          strokeWidth="1.5"
                                                          strokeMiterlimit="10"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        />
                                                        <path
                                                          d="M8.99999 22H15C19.02 22 19.74 20.39 19.95 18.43L20.7 12.43C20.97 9.99 20.27 8 16 8H7.99999C3.72999 8 3.02999 9.99 3.29999 12.43L4.04999 18.43C4.25999 20.39 4.97999 22 8.99999 22Z"
                                                          stroke="#F15845"
                                                          strokeWidth="1.5"
                                                          strokeMiterlimit="10"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        />
                                                        <path
                                                          d="M15.4955 12H15.5045"
                                                          stroke="#F15845"
                                                          strokeWidth="2"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        />
                                                        <path
                                                          d="M8.49451 12H8.50349"
                                                          stroke="#F15845"
                                                          strokeWidth="2"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        />
                                                      </svg>
                                                    ) : item.type ===
                                                      "Images" ? (
                                                      <svg
                                                        width="59"
                                                        height="59"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          d="M21.6799 16.9599L18.5499 9.64988C17.4899 7.16988 15.5399 7.06988 14.2299 9.42988L12.3399 12.8399C11.3799 14.5699 9.58993 14.7199 8.34993 13.1699L8.12993 12.8899C6.83993 11.2699 5.01993 11.4699 4.08993 13.3199L2.36993 16.7699C1.15993 19.1699 2.90993 21.9999 5.58993 21.9999H18.3499C20.9499 21.9999 22.6999 19.3499 21.6799 16.9599Z"
                                                          stroke="#F15845"
                                                          strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        />
                                                        <path
                                                          d="M6.96997 8C8.62682 8 9.96997 6.65685 9.96997 5C9.96997 3.34315 8.62682 2 6.96997 2C5.31312 2 3.96997 3.34315 3.96997 5C3.96997 6.65685 5.31312 8 6.96997 8Z"
                                                          stroke="#F15845"
                                                          strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                        />
                                                      </svg>
                                                    ) : item.type ===
                                                      "Videos" ? (
                                                      <img
                                                        src={videoCircleImage}
                                                        alt={videoCircleImage}
                                                        className="w-[84px] h-[84px] object-cover relative block"
                                                      />
                                                    ) : item.type ===
                                                      "Links" ? (
                                                      <svg
                                                        width="59"
                                                        height="59"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <path
                                                          d="M15.4101 18.5899C15.0101 18.5899 14.6801 18.2599 14.6801 17.8599C14.6801 17.4599 15.0101 17.1299 15.4101 17.1299C18.2301 17.1299 20.5301 14.8299 20.5301 12.0099C20.5301 9.18992 18.2301 6.88992 15.4101 6.88992C12.5901 6.88992 10.2901 9.18992 10.2901 12.0099C10.2901 12.4099 9.96008 12.7399 9.56008 12.7399C9.16008 12.7399 8.83008 12.4099 8.83008 12.0099C8.83008 8.37992 11.7801 5.41992 15.4201 5.41992C19.0601 5.41992 22.0001 8.36992 22.0001 11.9999C22.0001 15.6299 19.0501 18.5899 15.4101 18.5899Z"
                                                          fill="#F15845"
                                                        />
                                                        <path
                                                          d="M8.59 5.40991C8.99 5.40991 9.32 5.73991 9.32 6.13991C9.32 6.53991 8.99 6.86991 8.59 6.86991C5.77 6.86991 3.47 9.16991 3.47 11.9899C3.47 14.8099 5.77 17.1099 8.59 17.1099C11.41 17.1099 13.71 14.8099 13.71 11.9899C13.71 11.5899 14.04 11.2599 14.44 11.2599C14.84 11.2599 15.17 11.5899 15.17 11.9899C15.17 15.6199 12.22 18.5799 8.58 18.5799C4.94 18.5799 2 15.6299 2 11.9999C2 8.36991 4.95 5.40991 8.59 5.40991Z"
                                                          fill="#F15845"
                                                        />
                                                      </svg>
                                                    ) : (
                                                      <></>
                                                    )}
                                                  </div>
                                                  {item.type === "Projects" ? (
                                                    item.cover !== "" ? (
                                                      <img
                                                        src={item.cover}
                                                        alt={item.project_title}
                                                        className="w-full h-full object-cover relative block"
                                                      />
                                                    ) : (
                                                      <img
                                                        src={porfolioTwoImage}
                                                        alt={item.name}
                                                        className="w-full h-full object-cover relative block"
                                                      />
                                                    )
                                                  ) : item.type === "Images" ? (
                                                    <img
                                                      src={item.url}
                                                      alt={item.name}
                                                      className="w-full h-full object-cover relative block"
                                                    />
                                                  ) : (
                                                    <img
                                                      src={porfolioTwoImage}
                                                      alt={item.name}
                                                      className="w-full h-full object-cover relative block"
                                                    />
                                                  )}
                                                </div>
                                              </ImgOverlay>
                                              <Typography
                                                variant="p"
                                                size="sm"
                                                className="text-black font-normal line-clamp-1 mt-2"
                                              >
                                                {item.type === "Projects"
                                                  ? item.project_title
                                                  : item.name}
                                              </Typography>
                                            </div>
                                          ))
                                        ) : (
                                          <div className="flex justify-center items-center w-full h-[120px] relative">
                                            <PortfolioAssetsEmpty tab={"tab"}/>
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          ),
                          id: item,
                          label: `${item}(${
                            counter[item as keyof typeof counter]
                          })`,
                        };
                      })
                    : []
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
};

export const ShowPortfolioLoader = fetchPortfolioShow;

export default ShowPortfolio;
