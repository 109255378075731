import React from "react";
import {
  convertParamsToArr,
  showConfirm,
  showToastAlert,
  Params,
  callFetch,
  unsetAssocciativeArray,
  updateAssocciativeArray,
  indexOf
} from "../../CommonFunctions";

const controller = new AbortController();
let publicLinksController:any;
let categoryShowController:any;
let publicLinkFiltersController:any;
let publicLinkviwerIPController:any;
let portfoliosController:any;
let publicLinkviewFiltersController:any;

export async function fetchpublicLinks({ params }: Params) {
  params = convertParamsToArr({ params });
  
  let queryString = "";
  queryString = params[0] !== undefined ? "search=" + params[0] : "";
  queryString +=
    params["sort_by"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["sort_by"] !== undefined ? "sort_by=" + params["sort_by"] : "";
  queryString +=
    params["portfolios"] !== undefined && params["portfolios"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["portfolios"] !== undefined && params["portfolios"] !== null ? "portfolios=" + params["portfolios"] : "";
  queryString +=
    params["users"] !== undefined && params["users"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["users"] !== undefined && params["users"] !== null ? "users=" + params["users"] : "";
  queryString +=
    params["status"] !== undefined && params["status"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["status"] !== undefined && params["status"] !== null ? "status=" + params["status"] : "";
  queryString +=
    params["start_date"] !== undefined && params["start_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["start_date"] !== undefined && params["start_date"] !== null ? "start_date=" + params["start_date"] : "";
  queryString +=
    params["end_date"] !== undefined && params["end_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["end_date"] !== undefined && params["end_date"] !== null ? "end_date=" + params["end_date"] : "";
  queryString +=
    params["expiry_start_date"] !== undefined && params["expiry_start_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["expiry_start_date"] !== undefined && params["expiry_start_date"] !== null ? "expiry_start_date=" + params["expiry_start_date"] : "";
  queryString +=
    params["expiry_end_date"] !== undefined && params["expiry_end_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["expiry_end_date"] !== undefined && params["expiry_end_date"] !== null ? "expiry_end_date=" + params["expiry_end_date"] : "";
  queryString +=
    params["accessed_start_date"] !== undefined && params["accessed_start_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["accessed_start_date"] !== undefined && params["accessed_start_date"] !== null ? "accessed_start_date=" + params["accessed_start_date"] : "";
  queryString +=
    params["accessed_end_date"] !== undefined && params["accessed_end_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["accessed_end_date"] !== undefined && params["accessed_end_date"] !== null ? "accessed_end_date=" + params["accessed_end_date"] : "";
  queryString +=
    params["is_not_have_password"] !== undefined && params["is_not_have_password"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["is_not_have_password"] !== undefined && params["is_not_have_password"] !== null ? "is_not_have_password=" + params["is_not_have_password"] : "";
  queryString +=
    params["is_not_have_expiry"] !== undefined && params["is_not_have_expiry"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["is_not_have_expiry"] !== undefined && params["is_not_have_expiry"] !== null ? "is_not_have_expiry=" + params["is_not_have_expiry"] : "";
  queryString +=
    params["page"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["page"] !== undefined ? "page=" + params["page"] : "";
  
  if(publicLinksController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await publicLinksController.abort(reason);
  }

  publicLinksController = new AbortController();

  return await callFetch({
    url:
      process.env.REACT_APP_API_URL +
      "publicLinks?" + queryString,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: publicLinksController,
    callback: async (json) => {
      return json;
    },
  });
}

export async function fetchpublicLinksIPList({ params }: Params) {
  params = convertParamsToArr({ params });
  
  let queryString = "";
  queryString = params[0] !== undefined ? "search=" + params[0] : "";
  queryString +=
    params["sort_by"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["sort_by"] !== undefined ? "sort_by=" + params["sort_by"] : "";
  queryString +=
    params["type"] !== undefined && params["type"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["type"] !== undefined && params["type"] !== null ? "type=" + params["type"] : "";
  queryString +=
    params["device"] !== undefined && params["device"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["device"] !== undefined && params["device"] !== null ? "device=" + params["device"] : "";
  queryString +=
    params["browser"] !== undefined && params["browser"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["browser"] !== undefined && params["browser"] !== null ? "browser=" + params["browser"] : "";
  queryString +=
    params["view_rate"] !== undefined && params["view_rate"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["view_rate"] !== undefined && params["view_rate"] !== null ? "view_rate=" + params["view_rate"] : "";
  queryString +=
    params["start_date"] !== undefined && params["start_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["start_date"] !== undefined && params["start_date"] !== null ? "start_date=" + params["start_date"] : "";
  queryString +=
    params["end_date"] !== undefined && params["end_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["end_date"] !== undefined && params["end_date"] !== null ? "end_date=" + params["end_date"] : "";
  queryString +=
    params["page"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["page"] !== undefined ? "page=" + params["page"] : "";
  
  if(publicLinkviwerIPController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await publicLinkviwerIPController.abort(reason);
  }

  publicLinkviwerIPController = new AbortController();

  return await callFetch({
    url:
      process.env.REACT_APP_API_URL +
      "publicLinks/"+params["link_id"]+"/views?"+ queryString,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: publicLinkviwerIPController,
    callback: async (json) => {
      return json;
    },
  });
}

export async function fetchProjects(ip:any,publick_link_id:number,page:number) {
  if (ip && publick_link_id) {
    if(portfoliosController){
      const reason = new DOMException('signal timed out', 'TimeoutError');
      await portfoliosController.abort(reason);
    }

    portfoliosController = new AbortController();

    return await callFetch({
      url:
        process.env.REACT_APP_API_URL +
        "publicLinks/"+ip+"/"+publick_link_id+"/projects?page="+page,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      controller: portfoliosController,
      callback: async (json) => {
        return json;
      },
    });
  }

  return { status: "error",error:404};
}

export async function fetchpublicLinksShow({ params }: Params) {
  params = convertParamsToArr({ params });
  if (params["link_id"] !== undefined && params["link_id"] != "") {
    if(categoryShowController){
      const reason = new DOMException('signal timed out', 'TimeoutError');
      await categoryShowController.abort(reason);
    }

    categoryShowController = new AbortController();

    return await callFetch({
      url:
        process.env.REACT_APP_API_URL +
        "publicLinks/" + params["link_id"],
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      controller: categoryShowController,
      callback: async (json) => {
        return json;
      },
    });
  }

  return { status: "success" };
}

export async function fetchPublickLinkFilters() {
  if(publicLinkFiltersController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await publicLinkFiltersController.abort(reason);
  }

  publicLinkFiltersController = new AbortController();
  
  return await callFetch({
    url: process.env.REACT_APP_API_URL + "publicLinks/filters",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: publicLinkFiltersController,
    callback: async (json) => {
      return json;
    },
  });
}

export async function fetchPublickLinkViewFilters(link_id:number) {
  if(publicLinkviewFiltersController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await publicLinkviewFiltersController.abort(reason);
  }

  publicLinkviewFiltersController = new AbortController();
  
  return await callFetch({
    url: process.env.REACT_APP_API_URL + "publicLinks/filters/"+link_id,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: publicLinkviewFiltersController,
    callback: async (json) => {
      return json;
    },
  });
}

export const confirmDeletepublicLinks = async (
  id: BigInteger,
  params: any,
  setpublicLinksData: any,
  publicLinksData:any,
) => {
  await showConfirm({
    title: "Are you sure you want to delete this Link?",
    message: "The Link information will be deleted and can’t be undone. Click Yes, if you still want to continue.",
    type: "warning",
    confirm: async () => {
      await deleteLink(id, params, setpublicLinksData,publicLinksData);
    },
    cancel: () => {},
  });
};

const deleteLink = async (
  id: BigInteger,
  params: any,
  setpublicLinksData: any,
  publicLinksData:any
) => {
  return await callFetch({
    url: process.env.REACT_APP_API_URL + "publicLinks/" + id,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json) => {
      if (json.status == "success") {
        showToastAlert(json.message, 1);
        setpublicLinksData({
          ...publicLinksData,
          data:unsetAssocciativeArray(publicLinksData["data"], "id", id)
        });
      } else {
        showToastAlert(json.message);
      }
      return json;
    },
  });
};

export const confirmBulckExpire = async (
  publicLinkId: number[],
  setPublicLinkId: any,
  setpublicLinksData: any,
  publicLinksData:any
) => {
  await showConfirm({
    title: "Are you sure you want to expire this links?",
    message: "The links will be expired and can’t be undone. Click Yes, if you still want to continue.",
    type: "warning",
    confirm: async () => {
      await bulckExpire(publicLinkId, setPublicLinkId, setpublicLinksData,publicLinksData);
    },
    cancel: () => {},
  });
};

const bulckExpire = async (
  publicLinkId: any,
  setPublicLinkId: any,
  setpublicLinksData: any,
  publicLinksData:any
) => {
  const newArray = publicLinksData["data"];
  for(const i in newArray){
    if(indexOf(publicLinkId,newArray[i]['id'])){
      newArray[i]['status'] = 0;
    }
  }

  setpublicLinksData({
    ...publicLinksData,
    data:newArray
  });

  return await callFetch({
    url: process.env.REACT_APP_API_URL + "publicLinks/bulkExpire",
    method: "PUT",
    body: JSON.stringify({ publicLinkId }),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json) => {
      if (json.status == "success") {
        showToastAlert(json.message,1);
        setPublicLinkId([]);
      } else {
        showToastAlert(json.message);
        for(const i in newArray){
          if(indexOf(publicLinkId,newArray[i]['id'])){
            newArray[i]['status'] = 1;
          }
        }
        setpublicLinksData({
          ...publicLinksData,
          data:newArray
        });
      }
      return json;
    },
  });
};

export const confirmExpireNow = async (
  id: number,
  index:number,
  setpublicLinksData: any,
  publicLinksData:any
) => {
  await showConfirm({
    title: "Are you sure you want to expire this link?",
    message: "The link will be expired and can’t be undone. Click Yes, if you still want to continue.",
    type: "warning",
    confirm: async () => {
      await expireNow(id,index, setpublicLinksData,publicLinksData);
    },
    cancel: () => {},
  });
};

const expireNow = async (
  id: number,
  index:number,
  setpublicLinksData: any,
  publicLinksData:any
) => {
  publicLinksData["data"][index]["status"] = 0;
  setpublicLinksData({
    ...publicLinksData,
    data:publicLinksData["data"] 
  });

  return await callFetch({
    url: process.env.REACT_APP_API_URL + "publicLinks/"+id+"/expiryNow",
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json) => {
      if (json.status == "success") {
        showToastAlert(json.message,1);
        publicLinksData["data"][index]["expiry_date"] = json.data.expiry_date;
        publicLinksData["data"][index]["days"] = json.data.days;
        publicLinksData["data"][index]["status"] = json.data.status;
        publicLinksData["data"][index]["has_token_expiry_date"] = json.data.has_token_expiry_date;
        setpublicLinksData({
          ...publicLinksData,
          data:publicLinksData["data"] 
        });
      } else {
        showToastAlert(json.message);
        publicLinksData["data"][index]["status"] = 1;
        setpublicLinksData({
          ...publicLinksData,
          data:publicLinksData["data"] 
        });
      }
      return json;
    },
  });
};

export const confirmRemoveExpiryDate = async (
  id: number,
  index:number,
  password: string,
  setpublicLinksData: any,
  publicLinksData:any,
  closeExpiryFiled:any,
  showHideLoader:any,
) => {
  await showConfirm({
    title: "Are you sure you want to remove this expiry date?",
    message: "The expiry date will be removed and can’t be undone. Click Yes, if you still want to continue.",
    type: "warning",
    confirm: async () => {
      await updateExpiryDate(id,index,password, setpublicLinksData,publicLinksData,closeExpiryFiled,showHideLoader);
    },
    cancel: () => {},
  });
};

export const updateExpiryDate = async (
  id: number,
  index:number,
  expiry_date: string,
  setpublicLinksData: any,
  publicLinksData:any,
  closeExpiryFiled:any,
  showHideLoader:any,
) => {
  showHideLoader(id,true);

  return await callFetch({
    url: process.env.REACT_APP_API_URL + "publicLinks/"+id+"/updateExpiryDate",
    method: "PUT",
    body: JSON.stringify({ expiry_date }),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json) => {
      showHideLoader(id,false);
      if (json.status == "success") {
        showToastAlert(json.message,1);
        publicLinksData["data"][index]["expiry_date"] = json.data.expiry_date;
        publicLinksData["data"][index]["days"] = json.data.days;
        publicLinksData["data"][index]["status"] = json.data.status;
        publicLinksData["data"][index]["has_token_expiry_date"] = json.data.has_token_expiry_date;
        setpublicLinksData({
          ...publicLinksData,
          data:publicLinksData["data"] 
        });
        closeExpiryFiled(id);
      } else {
        showToastAlert(json.message);
      }
      return json;
    },
  });
};

export const confirmRemovePassword = async (
  id: number,
  password: string,
  setpublicLinksData: any,
  publicLinksData:any,
  closePasswordFiled:any,
  showHidePasswordLoader:any,
) => {
  await showConfirm({
    title: "Are you sure you want to remove this password?",
    message: "The password will be removed and can’t be undone. Click Yes, if you still want to continue.",
    type: "warning",
    confirm: async () => {
      await updateLinkPassword(id,password, setpublicLinksData,publicLinksData,closePasswordFiled,showHidePasswordLoader);
    },
    cancel: () => {},
  });
};

export const updateLinkPassword = async (
  id: number,
  password: string,
  setpublicLinksData: any,
  publicLinksData:any,
  closePasswordFiled:any,
  showHidePasswordLoader:any,
) => {
  showHidePasswordLoader(id,true);

  return await callFetch({
    url: process.env.REACT_APP_API_URL + "publicLinks/"+id+"/updatePassword",
    method: "PUT",
    body: JSON.stringify({ password }),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json) => {
      showHidePasswordLoader(id,false);
      if (json.status == "success") {
        showToastAlert(json.message,1);
        setpublicLinksData({
          ...publicLinksData,
          data:updateAssocciativeArray(publicLinksData["data"],'id',id,'password',json.data.password)
        });
        closePasswordFiled(id);
      } else {
        showToastAlert(json.message);
      }
      return json;
    },
  });
};

export const confirmExpireNowView = async (
  id: number,
  setpublicLinksData: any,
  publicLinksData:any
) => {
  await showConfirm({
    title: "Are you sure you want to expire this link?",
    message: "The link will be expired and can’t be undone. Click Yes, if you still want to continue.",
    type: "warning",
    confirm: async () => {
      await expireNowView(id,setpublicLinksData,publicLinksData);
    },
    cancel: () => {},
  });
};

const expireNowView = async (
  id: number,
  setpublicLinksData: any,
  publicLinksData:any
) => {
  publicLinksData["data"]["status"] = 0;
  setpublicLinksData({
    ...publicLinksData,
    data:publicLinksData["data"] 
  });

  return await callFetch({
    url: process.env.REACT_APP_API_URL + "publicLinks/"+id+"/expiryNow",
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json) => {
      if (json.status == "success") {
        showToastAlert(json.message,1);
        publicLinksData["data"]["expiry_date"] = json.data.expiry_date;
        publicLinksData["data"]["days"] = json.data.days;
        publicLinksData["data"]["status"] = json.data.status;
        publicLinksData["data"]["has_token_expiry_date"] = json.data.has_token_expiry_date;
        setpublicLinksData({
          ...publicLinksData,
          data:publicLinksData["data"] 
        });
      } else {
        showToastAlert(json.message);
        publicLinksData["data"]["status"] = 1;
        setpublicLinksData({
          ...publicLinksData,
          data:publicLinksData["data"] 
        });
      }
      return json;
    },
  });
};

export const confirmRemoveExpiryDateView = async (
  id: number,
  password: string,
  setpublicLinksData: any,
  publicLinksData:any,
  closeExpiryFiled:any,
  showHideLoader:any,
) => {
  await showConfirm({
    title: "Are you sure you want to remove this expiry date?",
    message: "The expiry date will be removed and can’t be undone. Click Yes, if you still want to continue.",
    type: "warning",
    confirm: async () => {
      await updateExpiryDateView(id,password, setpublicLinksData,publicLinksData,closeExpiryFiled,showHideLoader);
    },
    cancel: () => {},
  });
};

export const updateExpiryDateView = async (
  id: number,
  expiry_date: string,
  setpublicLinksData: any,
  publicLinksData:any,
  closeExpiryFiled:any,
  showHideLoader:any,
) => {
  showHideLoader(id,true);

  return await callFetch({
    url: process.env.REACT_APP_API_URL + "publicLinks/"+id+"/updateExpiryDate",
    method: "PUT",
    body: JSON.stringify({ expiry_date }),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json) => {
      showHideLoader(id,false);
      if (json.status == "success") {
        showToastAlert(json.message,1);
        publicLinksData["data"]["expiry_date"] = json.data.expiry_date;
        publicLinksData["data"]["days"] = json.data.days;
        publicLinksData["data"]["status"] = json.data.status;
        publicLinksData["data"]["has_token_expiry_date"] = json.data.has_token_expiry_date;
        setpublicLinksData({
          ...publicLinksData,
          data:publicLinksData["data"] 
        });
        closeExpiryFiled(id);
      } else {
        showToastAlert(json.message);
      }
      return json;
    },
  });
};

export const confirmRemovePasswordView = async (
  id: number,
  password: string,
  setpublicLinksData: any,
  publicLinksData:any,
  closePasswordFiled:any,
  showHidePasswordLoader:any,
) => {
  await showConfirm({
    title: "Are you sure you want to remove this password?",
    message: "The password will be removed and can’t be undone. Click Yes, if you still want to continue.",
    type: "warning",
    confirm: async () => {
      await updateLinkPasswordView(id,password, setpublicLinksData,publicLinksData,closePasswordFiled,showHidePasswordLoader);
    },
    cancel: () => {},
  });
};

export const updateLinkPasswordView = async (
  id: number,
  password: string,
  setpublicLinksData: any,
  publicLinksData:any,
  closePasswordFiled:any,
  showHidePasswordLoader:any,
) => {
  showHidePasswordLoader(id,true);

  return await callFetch({
    url: process.env.REACT_APP_API_URL + "publicLinks/"+id+"/updatePassword",
    method: "PUT",
    body: JSON.stringify({ password }),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json) => {
      showHidePasswordLoader(id,false);
      if (json.status == "success") {
        showToastAlert(json.message,1);
        publicLinksData["data"]["password"] = json.data.password;
        setpublicLinksData({
          ...publicLinksData,
          data:publicLinksData["data"] 
        });
        closePasswordFiled(id);
      } else {
        showToastAlert(json.message);
      }
      return json;
    },
  });
};