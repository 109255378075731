import React from "react";
import * as Yup from "yup";
import {
  convertParamsToArr,
  showConfirm,
  showToastAlert,
  Params,
  callFetch,
  unsetAssocciativeArray,
} from "../../CommonFunctions";

const controller = new AbortController();
let categoriesController:any;
let categoryShowController:any;

export interface data {
  categoriesData: any;
  events?: any;
}

export const CategoriesData = {};

export interface InputFiled {
  category_name: any;
  status: any;
}

export const ErrorMessages: InputFiled = {
  category_name: "",
  status: "",
};

export interface StatusOption {
  label: string;
  value: number;
}

export const statusOptions: StatusOption[] = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 0 },
];

export async function fetchCategories({ params }: Params) {
  params = convertParamsToArr({ params });
  
  let queryString = "";
  queryString = params[0] !== undefined ? "search=" + params[0] : "";
  queryString +=
    params["sort_by"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["sort_by"] !== undefined ? "sort_by=" + params["sort_by"] : "";
  queryString += params["tags"] !== undefined && queryString !== "" ? "&" : "";
  queryString += params["tags"] !== undefined ? "tags=" + params["tags"] : "";
  queryString +=
    params["status"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["status"] !== undefined ? "status=" + params["status"] : "";
  queryString +=
    params["start_date"] !== undefined && params["start_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["start_date"] !== undefined && params["start_date"] !== null ? "start_date=" + params["start_date"] : "";
  queryString +=
    params["end_date"] !== undefined && params["end_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["end_date"] !== undefined && params["end_date"] !== null ? "end_date=" + params["end_date"] : "";
  queryString +=
    params["page"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["page"] !== undefined ? "page=" + params["page"] : "";
  
  if(categoriesController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await categoriesController.abort(reason);
  }

  categoriesController = new AbortController();

  return await callFetch({
    url:
      process.env.REACT_APP_API_URL +
      "categories?" + queryString,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: categoriesController,
    callback: async (json) => {
      return json;
    },
  });
}

export async function fetchCategoriesShow({ params }: Params) {
  params = convertParamsToArr({ params });
  if (params[0] !== undefined && params[0] != "") {
    if(categoryShowController){
      const reason = new DOMException('signal timed out', 'TimeoutError');
      await categoryShowController.abort(reason);
    }

    categoryShowController = new AbortController();

    return await callFetch({
      url:
        process.env.REACT_APP_API_URL +
        "categories/" + params[0],
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      controller: categoryShowController,
      callback: async (json) => {
        return json;
      },
    });
  }

  return { status: "success" };
}

export const confirmDeleteCategories = async (
  id: BigInteger,
  params: any,
  setCategoriesData: any,
  categoriesData:any,
) => {
  await showConfirm({
    title: "Are you sure you want to delete this service area?",
    message: "The service area information will be deleted and can’t be undone. Click Yes, if you still want to continue.",
    type: "warning",
    confirm: async () => {
      await deleteCategory(id, params, setCategoriesData,categoriesData);
    },
    cancel: () => {},
  });
};

const deleteCategory = async (
  id: BigInteger,
  params: any,
  setCategoriesData: any,
  categoriesData:any
) => {
  return await callFetch({
    url: process.env.REACT_APP_API_URL + "categories/" + id,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json) => {
      if (json.status == "success") {
        showToastAlert(json.message==="Category deleted successfully"?"Service area deleted successfully":"", 1);
        setCategoriesData({
          ...categoriesData,
          data:unsetAssocciativeArray(categoriesData["data"], "id", id)
        });
      } else {
        showToastAlert(json.message);
      }
      return json;
    },
  });
};

export const validationSchema = Yup.object({
  category_name: Yup.string()
    .required("Service Area is required")
    .min(2, "Too Short!")
    .max(254, "Too Long!"),
  status: Yup.string().required("Status is required"),
});

export const saveCategories = async (props: any) => {
  props.setIsFromSubmiting(true);
  const errorMessage = ErrorMessages;

  let url = process.env.REACT_APP_API_URL + "categories";
  url =
    props.categorieData["data"] !== undefined
      ? url + "/" + props.categorieData["data"].id
      : url;

  return await callFetch({
    url: url,
    method: props.categorieData["data"] !== undefined ? "PUT" : "POST",
    body: JSON.stringify(props.formData),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      props.setIsFromSubmiting(false);

      if (json.status == "success") {
        showToastAlert(json.message ==="Category created successfully" ? "Service area created successfully" : "Category updated successfully"?"Service area updated successfully":"" , 1);
        props.close();
        props.navigate();
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          errorMessage[name as keyof InputFiled] =
            json.validation_errors[name][0];
        }

        props.setErrorMessage(errorMessage);
      } else {
        showToastAlert(json.message);
      }

      return json;
    },
  });
};
