import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { FetchData } from "../../CommonFunctions";
import { fetchPortfolioAssets, viewAsset } from "./ClientViewFunction";
import Typography from "../../components/Typography/Typography";
import clarieniLogoSmall from "../../images/clarieniLogoSmall.png";
import Icons from "../../components/Icons/Icon";
import ProjectViewSlide from "./ProjectViewSlide";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import CustomLoader from "../../components/Loader/CustomLoader";

export const ClientView = () => {
  let paramsData = useParams();

  const clientToken = useSelector((state: any) => state.clientToken);
  const Portfolio = clientToken.portfolio;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [assetsData, setAssetsData] = useState<FetchData>();
  const [asset, setAsset] = useState<any>(undefined);
  const [params, setParams] = useState(paramsData);
  const id = Number(paramsData["id"]);
  const navigate = useNavigate();

  useEffect(() => {
    if (assetsData !== undefined) {
      setIsLoading(false);
    }
  }, [assetsData]);

  useEffect(() => {
    loadData();
  }, [params]);

  const loadData = async () => {
    setIsLoading(true);
    const data: FetchData = await fetchPortfolioAssets({ params });
    if (data.status === "error" && data.error === "abort") {
      return false;
    }
    setAssetsData(data);
    const dt: any[] = data.data;
    setAsset(dt.find((asset) => asset.id === id));
  };

  useEffect(() => {
    const uploadData = async () => {
      if (asset !== undefined) {
        await viewAsset(asset.id);
      }
    };
    uploadData();
  }, [asset]);

  return (
    <div className="w-full bg-gray-400">
      <div className="h-[4.625rem] w-full bg-gray-400 fixed left-0 top-0 z-10">
        <div className="flex justify-between items-center h-full ps-[1.875rem] pe-[2.625rem] max-xl:px-5">
          <div className="flex justify-start items-center">
            <button
              className="me-3"
              onClick={() => {
                navigate("/client/portfolio");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.35363 2.31319C6.5489 2.50846 6.5489 2.82504 6.35363 3.0203L1.37385 8.00008L6.35363 12.9799C6.5489 13.1751 6.5489 13.4917 6.35363 13.687C6.15837 13.8822 5.84179 13.8822 5.64653 13.687L0.313195 8.35363C0.219426 8.25987 0.166748 8.13269 0.166748 8.00008C0.166748 7.86747 0.219426 7.7403 0.313195 7.64653L5.64653 2.31319C5.84179 2.11793 6.15837 2.11793 6.35363 2.31319Z"
                  fill="#232323"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.166748 8C0.166748 7.72386 0.390606 7.5 0.666748 7.5L15.3334 7.5C15.6096 7.5 15.8334 7.72386 15.8334 8C15.8334 8.27614 15.6096 8.5 15.3334 8.5H0.666748C0.390606 8.5 0.166748 8.27614 0.166748 8Z"
                  fill="#232323"
                />
              </svg>
            </button>
            <button className="me-3 flex mt-0.5 lg:hidden" onClick={() => setIsOpen(!isOpen)}>
              <Icons name="filterLines" variant="stroke" size={22} />
            </button>
            <div>
              <img
                src={clarieniLogoSmall}
                alt={clarieniLogoSmall}
                className="max-w-full max-sm:w-20"
              />
            </div>

            {/* <Typography
              variant="h4"
              className="text-gray-300 font-bold opacity-30 mt-1.5 ms-2 max-lg:text-400 max-md:text-300 max-sm:text-200"
            >
              Portfolio
            </Typography> */}
            
            <Typography
              variant="h4"
              className="text-gray-1200 font-medium opacity-80 mt-1.5 ms-10 relative max-xl:m-0 max-xl:absolute max-xl:left-1/2 max-xl:-translate-x-1/2 max-xl:top-[74px] max-md:text-200 max-xl:font-medium max-md:top-[80px]"
            >
              {Portfolio.portfolio_title}
            </Typography>
          </div>
          <div className="flex gap-3">
            {clientToken.expire_date ? (
              <span className="flex justify-end items-center text-200 font-medium max-sm:!text-[8px] max-sm:font-medium max-sm:absolute max-sm:top-14 max-sm:right-5">
                {/* <Icons variant="bold" name="calendar" size={20} color="#4A154B" /> */}
                Expires On:&nbsp;
                <span className="font-semibold max-sm:font-normal">{clientToken.expire_date}</span>
              </span>
            ) : (
              ""
            )}
            <button
              className="flex justify-start items-center px-[1.313rem] py-2.5 bg-white rounded-full text-black 
                text-400 font-semibold shadow-400 max-xl:m-0 max-xl:p-2 max-md:p-1.5"
              onClick={() => {
                navigate("/client/contactus");
              }}
            >
              <span className="me-2 max-xl:m-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="18"
                  viewBox="0 0 30 26"
                  fill="none"
                  className="max-xl:h-5 max-xl:w-6 max-md:w-[14px] max-md:h-[12px]"
                >
                  <path
                    d="M22.5 0H7.5C3 0 0 2.25 0 7.5V18C0 23.25 3 25.5 7.5 25.5H22.5C27 25.5 30 23.25 30 18V7.5C30 2.25 27 0 22.5 0ZM23.205 9.135L18.51 12.885C17.52 13.68 16.26 14.07 15 14.07C13.74 14.07 12.465 13.68 11.49 12.885L6.795 9.135C6.315 8.745 6.24 8.025 6.615 7.545C7.005 7.065 7.71 6.975 8.19 7.365L12.885 11.115C14.025 12.03 15.96 12.03 17.1 11.115L21.795 7.365C22.275 6.975 22.995 7.05 23.37 7.545C23.76 8.025 23.685 8.745 23.205 9.135Z"
                    fill="#4A154B"
                  />
                </svg>
              </span>
              <span className="max-xl:hidden">Get in Touch</span>
            </button>
          </div>
        </div>
      </div>
      <div className="mt-[4.625rem] w-full min-h-[calc(100vh-4.625rem)] flex justify-start items-start max-xl:inline-block max-xl:bg-gray-400">
        {/* <div className={`${isOpen?"w-[16rem]":"w-[5.75rem]"} h-[calc(100vh-4.625rem)] fixed left-0 top-[4.625rem] bg-gray-400 pt-4 ps-7`}> */}
        <div className="h-[calc(100vh-4.625rem)] fixed left-0 top-[4.625rem] pt-4 ps-7 w-[5.75rem] bg-primary-400 max-xl:w-auto max-xl:h-auto max-xl:min-h-0 max-xl:bg-gray-400 max-xl:z-10 max-xl:top-[31px] max-xl:left-[20px] max-xl:p-0">
          <button onClick={() => setIsOpen(!isOpen)} className="max-lg:hidden">
            <Icons name="filterLines" variant="stroke" size={22} />
          </button>
        </div>
        <div
          onClick={() => setIsOpen(!isOpen)}
          className={`${
            !isOpen
              ? "bg-green-100 fixed left-0 top-[4.625rem] w-full bg-trans-100 min-h-[calc(100vh-4.625rem)] max-xl:inline-block"
              : "w-0 max-xl:hidden"
          } overflow-hidden transition-all duration-200 ease-in-out z-50`}
        >
          <div
            onClick={(event) => {
              event.stopPropagation();
            }}
            className="bg-gray-200 w-[18.75rem] h-[calc(100vh-4.625rem)] border-gray-100 transition-all duration-200 ease-in-out"
          >
            <div className="px-4 pt-6 pb-10 flex justify-start items-center">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="py-1 pe-2 ps-1"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.1792 15.1341C11.3847 14.911 11.3847 14.5492 11.1792 14.326L5.93733 8.63485L11.1792 2.94367C11.3847 2.72052 11.3847 2.35871 11.1792 2.13555C10.9737 1.91239 10.6404 1.91239 10.4349 2.13555L4.82084 8.23079C4.72214 8.33795 4.66669 8.48329 4.66669 8.63485C4.66669 8.7864 4.72214 8.93174 4.82084 9.03891L10.4349 15.1341C10.6404 15.3573 10.9737 15.3573 11.1792 15.1341Z"
                      fill="#232323"
                    />
                  </svg>
                </span>
              </button>
              <Typography variant="h5" className="font-bold text-gray-300">
                All Content{" "}
              </Typography>
            </div>
            {assetsData !== undefined && assetsData["status"] === "success" ? (
              <ul className="list-none h-[calc(100vh-10.625rem)] overflow-auto">
                {assetsData.data.map((item: any, idx: any) => (
                  <li
                    key={idx}
                    className={`p-4 border-b border-gray-100 cursor-pointer hover:text-secondary-100 hover:font-medium text-200 flex justify-start items-center gap-2 group ${
                      item.id == id
                        ? "text-secondary-100 font-medium"
                        : "text-gray-300 font-medium"
                    }`}
                    onClick={() => {
                      navigate("/client/project/" + item.id);
                      setParams({
                        ...params,
                        id: item.id,
                      });
                    }}
                  >
                  {item.type === "Videos" ? (
                    <Icons
                      name="video"
                      variant="stroke"
                      className="group-hover:[&>svg>path]:!stroke-secondary-100"
                    />
                  ) : item.type === "Images" ? (
                    <Icons
                      name="gallery"
                      variant="stroke"
                      className="group-hover:[&>svg>path]:!stroke-secondary-100"
                    />
                  ) : item.type === "Documents" ? (
                    <Icons
                      name="document"
                      variant="stroke"
                      className="group-hover:[&>svg>path]:!stroke-secondary-100"
                    />
                  ) : item.type === "Links" ? (
                    <Icons
                      name="link2"
                      variant="stroke"
                      className="group-hover:[&>svg>path]:!stroke-secondary-100"
                    /> 
                  ) : (
                    <Icons
                      name="bag"
                      variant="stroke"
                      className="group-hover:[&>svg>path]:!stroke-secondary-100"
                    />
                  )}
{/* <FileIcon fileName={item.filename??''} style=''/>} */}
                    {/* <Icons name="bag-active" variant="stroke" className="group-hover:[&>svg>path]:!stroke-secondary-100" /> */}
                    {/* {idx + 1}.{" "} */}
                    <span className="w-[calc(100%-24px)]">
                      {item.type === "Projects"
                        ? item.project_title
                        : item.name}
                    </span>
                  </li>
                ))}
              </ul>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* <div className={`${isOpen?"w-[calc(100%-16rem)] ms-[16rem]":" ms-[5.75rem]"} min-h-[calc(100vh-4.625rem)] pe-[2.625rem]`}> */}
        <div className="h-[calc(100vh-4.625rem)] ms-[5.75rem] w-[calc(100%-8.25rem)] bg-white max-xl:w-full max-xl:ms-0 max-xl:mt-8 max-xl:bg-gray-400 max-xl:h-auto">
          {isLoading ? (
            <div className="flex justify-center items-center w-full h-[calc(100%-4.625rem)]">
            <CustomLoader/>
          </div>
            // <Loader />
          ) : assetsData !== undefined && assetsData["status"] === "success" ? (
            <ProjectViewSlide id={params["id"]} data={asset} />
          ) : assetsData !== undefined &&
            assetsData["status"] === "error" &&
            assetsData !== undefined &&
            assetsData["error"] === 404 ? (
            <div className="mt-20 text-center">
              <Typography className="text-gray-700 font-medium" variant="h4">
                No project found
              </Typography>
            </div>
          ) : (
            <div className="mt-20 text-center">
              <Typography variant="h5">Something went wrong!</Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
