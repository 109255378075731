import React, { ChangeEvent, useState, ReactNode } from "react";
import Select from "react-select";
import TextInput from "../TextInput/TextInput";

export interface DropdownOption {
  value: string;
  label: string | ReactNode;
  flag?: ReactNode;
}

export interface DropdownProps {
  options: DropdownOption[];
  // onSelect: (value: string) => void;
  onSelect: (option: DropdownOption | null) => void;
  icon?: boolean;
}

const InputWithDropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  icon,
}) => {
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(
    null,
  );

  const handleSelect = (option: DropdownOption | null) => {
    setSelectedOption(option);
    // onSelect(option?.value || '');
    onSelect(option);
  };

  const handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    // onSelect(text);
    onSelect(null);
  };

  return (
    <div className="relative">
      <TextInput
        placeholder="Enter the value"
        onChange={handleTextChange}
        className={`ps-28 ${icon ? "ps-[10rem]" : ""}`}
        type="number"
      />
      <div
        className={`w-24 absolute left-0 top-0 h-full border border-l-0 border-y-0 border-gray-100 py-1.5 ${
          icon ? "w-36" : ""
        }`}
      >
        <Select
          options={options}
          value={selectedOption}
          onChange={(option) => handleSelect(option)}
          placeholder="Select"
          unstyled
          classNamePrefix="select"
          // className='w-100'
          classNames={{
            input: () => "py-2",
            singleValue: () =>
              `relative pt-0 ${
                icon
                  ? "ps-7 ms-[-0.25rem] [&>img]:absolute [&>img]:left-0 [&>img]:mt-[-0.1rem] [&>img]:w-6 [&>img]:h-6 [&>img]:object-cover"
                  : ""
              }`,
            control: (state) =>
              `px-3 text-400 text-gray-300 ${
                state.isFocused ? "rounded-b-0" : ""
              } ${state.menuIsOpen ? "" : ""}`,
            placeholder: () => "text-gray-500 pl-1 py-0.5",
            dropdownIndicator: () => "text-gray-500 pl-1 py-0.5",
            menu: () =>
              "bg-white border border-gray-100 rounded-md rounded-t-0 mt-1 shadow-100 min-w-[10rem]",
            option: ({
              isFocused,
              isSelected,
            }) => `px-3 py-2 whitespace-nowrap overflow-clip overflow-ellipsis relative text-gray-500 hover:cursor-pointer hover:bg-gray-200 hover:text-gray-500 
          ${
            icon
              ? "ps-7 [&>img]:absolute [&>img]:left-1 [&>img]:top-2 [&>img]:w-6 [&>img]:h-6 [&>img]:object-cover"
              : ""
          }
          ${
            isSelected
              ? "bg-gray-200 text-gray-500 hover:bg-gray-200 hover:text-gray-500 active:bg-gray-200 active:text-gray-500 after:ml-2 after:absolute after:right-2 after:content-['']"
              : ""
          }
          ${isFocused ? "bg-gray-200 text-gray-500 hover:bg-gray-200" : ""} 
          `,
            noOptionsMessage: () =>
              "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm",
          }}
          isSearchable={false}
          // menuIsOpen
        />
      </div>
    </div>
  );
};

export default InputWithDropdown;
