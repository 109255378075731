import React, { useState } from "react";
import { indexOf } from "../../CommonFunctions";
import SearchBar from "../SearchBar/SearchBar";
import Checkbox from "../CheckBox/CheckBox";
import Icons from "../Icons/Icon";
import SearchBarSmall from "../SearchBar/SearchBarSmall";

export type Item = {
  id: BigInteger|number;
  value: string;
}

export interface FilterProp {
  filter: [] | Item[];
  type: "single" | "key";
  count: number;
  isSearchBar?: boolean;
  onChange?: any;
  selected?: [];
}

const CustomFilters:React.FC<FilterProp> = ({
  filter,
  type,
  count,
  isSearchBar,
  onChange,
  selected,
}) => {

  const [isShowMore,setIsShowMore] = useState<Boolean>(false);
  const filterCount = count-1;
  const [inputText, setInputText] = useState("");

  const inputHandler = (element:any) => {
    //convert input text to lower case
    var lowerCase = element.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const handelChange = (checked: boolean,value:string|Item) => {
    if(onChange){
      onChange(checked,value);
    }
  }

  const filteredData = filter.filter((el:any) => {
      if (inputText === '') {
          return el;
      }
      else {
        if(type === "single" && typeof el === "string"){
          return el.toLowerCase().includes(inputText);
        }else{
          return el.value.toLowerCase().includes(inputText)
        }
      }
  });

  const filterLength = filteredData.length - count;

  return (
    <>
      {isSearchBar?
        <div className="mt-3">
          <SearchBarSmall
            onChange={inputHandler}
            value={inputText}
            placeholder="Search"
            icon={<Icons name="search" variant="stroke" className="[&>svg>path]:!stroke-gray-700" size={14} />}
          />
        </div>
      :""}
      {type === "single"?(
        filteredData.map((item: any, idx: any) => (
          idx <= filterCount?
            <div key={idx} className="flex justify-start items-center gap-2 mt-3">
              <Checkbox checked={selected !== undefined && indexOf(selected,item)?true:false} onCustomChange={(checked) => {handelChange(checked,item)}} size="sm" />
              <label title={item.value} className="text-gray-500 font-normal text-200 line-clamp-1 w-[8.5rem]">
                {item.value ? item.value : item}
              </label>
            </div>
          :isShowMore?
            <div key={idx} className="flex justify-start items-center gap-2 mt-3">
              <Checkbox checked={selected !== undefined && indexOf(selected,item)?true:false} onCustomChange={(checked) => {handelChange(checked,item)}} size="sm" />
              <label title={item.value} className="text-gray-500 font-normal text-200 line-clamp-1 w-[8.5rem]">
              {item.value ? item.value : item}
              </label>
            </div>
          :""
        ))
      ):(
        filteredData.map((item: any, idx: any) => (
          idx <= filterCount?
            <div key={idx} className="flex justify-start items-center gap-2 mt-3">
              <Checkbox checked={selected !== undefined && indexOf(selected,item.id)?true:false} onCustomChange={(checked) => {handelChange(checked,item)}} size="sm" />
              <label className="text-gray-500 font-normal text-200 line-clamp-1 w-[8.5rem]">
                {item.value}
              </label>
            </div>
          :isShowMore?
            <div key={idx} className="flex justify-start items-center gap-2 mt-3">
              <Checkbox checked={selected !== undefined && indexOf(selected,item.id)?true:false} onCustomChange={(checked) => {handelChange(checked,item)}} size="sm" />
              <label className="text-gray-500 font-normal text-200 line-clamp-1 w-[8.5rem]">
                {item.value}
              </label>
            </div>
          :""
        ))
      )}
      {filteredData.length > 0?
        isShowMore?(
          <div className="text-300 text-blue-300 font-medium mt-3 cursor-pointer" onClick={()=>{setIsShowMore(false)}}>
           - Show less
          </div>
        ):filterLength > 0?(
          <div className="text-300 text-blue-300 font-medium mt-3 cursor-pointer" onClick={()=>{setIsShowMore(true)}}>
            + {filterLength} More
          </div>
        ):""
      :""}
    </>
  );
};

export default CustomFilters;
