import React,{useEffect, useState} from "react";
import Typography from "../../components/Typography/Typography";
import Accordion from "../../components/Accordion/Accordion";
import { CustomDateTimePicker } from "../../components/DateTimePicker/DatePicker";
import moment from 'moment';
import Checkbox from "../../components/CheckBox/CheckBox";
import RadioButton from "../../components/Radio button/RadioButton";

const ClientsFilter = (prop: any) => {
  const params = prop.params;
  const setParams = prop.events.setParams;
  const setIsLoading = prop.setIsLoading;
  const isFilterOpen = prop.isFilterOpen;
  const setIsFilterOpen = prop.setIsFilterOpen;

  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [minDate, setMinDate] = useState<string | null>(null);
  const [isMinDateError,setIsMinDateError] = useState<Boolean>(false);
  const [isClearFilter,setIsClearFilter] = useState<Boolean>(false);
  const [searchByRecent, setSearchByRecent] = useState<string>("recent");
  const [searchByStatus, setSearchByStatus] = useState(new Set([0, 1]));
  const [converted, setConverted] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    setParams({
      ...params,
      page:1,
      sort_by: searchByRecent === "recent" ? ["created_at", "DESC"] : ["created_at", "ASC"]
    })
  }, [searchByRecent]);

  useEffect(() => {
    setIsLoading(true);
    setParams({
      ...params,
      page:1,
      status: Array.from(searchByStatus).join(",")
    })
  }, [searchByStatus]);

  const updateStatusFilter = (value: number) => {
      if(searchByStatus.has(value)) {
        const newSet = new Set(searchByStatus);
        newSet.delete(value);
        setSearchByStatus(newSet);
      } else {
        setSearchByStatus(new Set([...Array.from(searchByStatus), value]));
      }
  }

  useEffect(() => {
    setIsLoading(true);
    setParams({
      ...params,
      page:1,
      is_converted: converted ? 1 : 0
    })
  }, [converted]);

  useEffect(()=>{
    setIsClearFilter(false);
  },[params]);

  useEffect(()=>{
    setMinDate(startDate);
    filterByDate();
  },[startDate]);

  useEffect(()=>{
    filterByDate();
  },[endDate]);

  const clearFilter = () => {
    setIsClearFilter(true);
    setIsLoading(true);
    setStartDate(null);
    setEndDate(null);
    setSearchByStatus(new Set([0,1]));
    setSearchByRecent("recent")
    setConverted(false);
    setParams({
      ...params,
      page: 1,
      status: '0,1',
      is_converted: 0,
      sort_by: ['id','DESC'],
      start_date: null,
      end_date: null,
    });
  }

  const filterByDate = () => {
    setIsMinDateError(false);

    if(startDate === null && endDate === null){
      setIsLoading(true);
      setParams({
        ...params,
        page:1,
        start_date:startDate,
        end_date:endDate,
      });
    }

    if(startDate === null || endDate === null){return false;}
    
    const dateLimit = moment(startDate);
    const now = moment(endDate);
    
    if(now.isBefore(dateLimit)){
      setIsMinDateError(true);
    }else if(startDate !== null && endDate !== null){
      setIsLoading(true);
      setParams({
        ...params,
        page:1,
        start_date:startDate,
        end_date:endDate,
      });
    }
  }
  
  return (
    <div className={`px-5 py-8 pt-0 h-full group absolute left-0 top-0 z-[20] border-r
     border-gray-100 bg-gray-200 rounded-s-md ease-in duration-300 overflow-y-auto overflow-x-hidden flex flex-1 
      ${isFilterOpen?`w-[12.125rem]`:`w-[3.75rem] hover:w-[12.125rem]`}`}>
     <div className="w-full">
      <div className="pt-8 pb-4 mb-3 flex justify-start items-center gap-2.5 relative after:bg-gray-200 after:h-[5rem] after:absolute after:-right-[1.3rem] after:top-0 after:w-9 after:-z[10]">
          <div className="flex gap-1.5 justify-start items-center order-2">
            <div className="text-200 font-bold text-gray-500">
              FILTER
            </div>
            <div className="text-gray-500 text-100">|</div>
            <button className="text-200 font-normal text-primary-100 whitespace-nowrap" onClick={()=>{clearFilter()}}>
              Clear All
            </button>
          </div>
          <button className="relative bg-gray-100 order-1 block p-0 me-4" onClick={()=>{setIsFilterOpen(!isFilterOpen)}}>
            {!isFilterOpen?
              <span className="absolute left-0 -top-1.5">
                <svg width="1rem" height="0.625rem" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M14.8753 0.875C15.2895 0.875 15.6253 1.21079 15.6253 1.625C15.6253 2.03921 15.2895 2.375 14.8753 2.375H1.12494C0.710726 2.375 0.374939 2.03921 0.374939 1.625C0.374939 1.21079 0.710726 0.875 1.12494 0.875H14.8753ZM15.6253 5.19667C15.6253 4.78246 15.2895 4.44667 14.8753 4.44667H1.12494C0.710726 4.44667 0.374939 4.78246 0.374939 5.19667C0.374939 5.61088 0.710726 5.94667 1.12494 5.94667H14.8753C15.2895 5.94667 15.6253 5.61088 15.6253 5.19667ZM15.6253 8.76833C15.6253 8.35411 15.2895 8.01833 14.8753 8.01833H1.12494C0.710726 8.01833 0.374939 8.35411 0.374939 8.76833C0.374939 9.18254 0.710726 9.51833 1.12494 9.51833H14.8753C15.2895 9.51833 15.6253 9.18254 15.6253 8.76833Z" fill="#888888"></path></svg>
              </span>
            :
              <span className="absolute left-0 -top-2.5">
                <svg width="1.25rem" height="1.25rem" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.9751 4.94165L2.91677 9.99998L7.9751 15.0583" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path><path d="M17.0835 10H3.0585" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path></svg>
              </span>
            }
          </button>
        </div>
      {!isClearFilter?(
        <>
        <Accordion
            isFilterOpen={isFilterOpen}
            count={1}
            subTitle=""
            time=""
            title="Sort By"
            icon={
              <svg
                width="16"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 7H21"
                  stroke="#888888"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M6 12H18"
                  stroke="#888888"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M10 17H14"
                  stroke="#888888"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            }
          >
            <div
              className={`pb-3 ${
                isFilterOpen
                  ? `inline-block`
                  : `hidden group-hover:inline-block`
              }`}
            >
              <div className="flex justify-start items-center gap-2 mt-3">
                {/* <Checkbox
                  checked={searchByRecent}
                  onChange={(value) => setSearchByRecent(value.target.checked)}
                  size="sm"
                /> */}
                 <RadioButton
                  selectedValue={searchByRecent}
                  name="searchByRecent"
                  onChange={(value) => setSearchByRecent(value)}
                  size="sm"
                  value="recent"
                />
                <label className="text-gray-500 font-normal text-200">
                  Recent
                </label>
              </div>
              <div className="flex justify-start items-center gap-2 mt-3">
                {/* <Checkbox
                  checked={!searchByRecent}
                  onChange={(value) => setSearchByRecent(!value.target.checked)}
                  size="sm"
                /> */}
                 <RadioButton
                  selectedValue={searchByRecent} 
                  name="searchByOldest"
                  onChange={(value) => setSearchByRecent(value)}
                  size="sm"
                  value="oldest"
                />
                <label className="text-gray-500 font-normal text-200">
                  Oldest
                </label>
              </div>
            </div>
          </Accordion>
          {/* <Accordion
          isFilterOpen={isFilterOpen}
          count={2}
          subTitle=""
          time=""
          title="Status"
          icon={
            <svg
              width="16"
              height="16"
              viewBox="0 0 18 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7691 14.1923H4.84606C2.76914 14.1923 1.38452 13.1539 1.38452 10.7308V5.88463C1.38452 3.46156 2.76914 2.4231 4.84606 2.4231H11.7691C13.8461 2.4231 15.2307 3.46156 15.2307 5.88463V10.7308C15.2307 13.1539 13.8461 14.1923 11.7691 14.1923Z"
                stroke="#888888"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M11.7693 6.23077L9.60234 7.96154C8.88927 8.52924 7.71927 8.52924 7.00619 7.96154L4.84619 6.23077"
                stroke="#888888"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M13.8463 15.923C15.7502 15.923 17.3078 14.3653 17.3078 12.4615C17.3078 10.5576 15.7502 8.99994 13.8463 8.99994C11.9425 8.99994 10.3848 10.5576 10.3848 12.4615C10.3848 14.3653 11.9425 15.923 13.8463 15.923Z"
                fill="#F8F8F8"
                stroke="#888888"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M12.375 12.4614L13.3546 13.441L15.3173 11.4818"
                stroke="#888888"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          }
        >
          <div
            className={`${
              isFilterOpen
                ? `inline-block`
                : `hidden group-hover:inline-block`
            }`}
          >
            <div className="flex justify-start items-center gap-2 mt-3">
              <Checkbox
               checked={searchByStatus.has(1)}
               onChange={() => updateStatusFilter(1)}
                size="sm"
              />
              <label className="text-gray-500 font-normal text-200">
                Active
              </label>
            </div>
            <div className="flex justify-start items-center gap-2 mt-3">
              <Checkbox
                checked={searchByStatus.has(0)}
                onChange={() => updateStatusFilter(0)}
                size="sm"
              />
              <label className="text-gray-500 font-normal text-200">
                InActive
              </label>
            </div>
            <div className="flex justify-start items-center gap-2 mt-3">
              <Checkbox
                checked={converted}
                onChange={(value) => setConverted(value.target.checked)}
                size="sm"
              />
              <label className="text-gray-500 font-normal text-200">
                Converted
              </label>
            </div>
          </div>
        </Accordion> */}
        <Accordion isFilterOpen={isFilterOpen} count={4} subTitle="" time="" title="Date"
          icon={
            <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 2V5" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M16 2V5" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M3.5 9.09H20.5" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M15.6947 13.7H15.7037" stroke="#888888" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M15.6947 16.7H15.7037" stroke="#888888" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M11.9955 13.7H12.0045" stroke="#888888" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M11.9955 16.7H12.0045" stroke="#888888" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8.29431 13.7H8.30329" stroke="#888888" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M8.29431 16.7H8.30329" stroke="#888888" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          }
        >
          <div className={`pb-3 ${isFilterOpen?`inline-block`:`hidden group-hover:inline-block`}`}>
            <div className="mt-3 small-datepicker">
              <label className="text-gray-700 font-normal text-300">
                Start
              </label>
              <CustomDateTimePicker
                dateFormat="YYYY-MM-DD"
                size="sm"
                onChange={(date:Date|null,value: string|null) => {
                  setStartDate(value);
                }}
                selectedDate={startDate}
                maxDate={moment(new Date()).format("yyyy-MM-DD")}
              />
              {startDate === null && endDate !== null?(
                <Typography
                  className="text-primary-100 font-normal"
                  variant="p"
                  size="sm"
                >Please select start date</Typography>
              ):""}
            </div>
            <div className="mt-3 small-datepicker">
              <label className="text-gray-700 font-normal text-300">End</label>
              <CustomDateTimePicker
                dateFormat="YYYY-MM-DD"
                size="sm"
                onChange={(date:Date|null,value: string|null) => {
                  setEndDate(value);
                }}
                selectedDate={endDate}
                minDate={minDate !== null?minDate:null}
                maxDate={moment(new Date()).format("yyyy-MM-DD")}
              />
              {startDate !== null && endDate === null?(
                <Typography
                  className="text-primary-100 font-normal"
                  variant="p"
                  size="sm"
                >Please select end date</Typography>
              ):isMinDateError?(
                <Typography
                  className="text-primary-100 font-normal"
                  variant="p"
                  size="sm"
                >End date can not be less than start date</Typography>
              ):""}
            </div>
          </div>
        </Accordion>
        </>
      ):""}
      </div>
    </div>
  );
};

export default ClientsFilter;
