import React from "react";
import { FieldProps } from "formik";
import Textarea, { TextareaProps } from "../TextArea/Textarea";

interface CustomTextAreaProps extends FieldProps, TextareaProps {
  label: string;
}

const CustomTextArea: React.FC<CustomTextAreaProps> = ({
  field,
  form,
  label,
  placeholder,
  ...props
}) => {
  const { name } = field;

  return (
    <div>
      <label
        className="text-400 font-medium text-gray-300 block mb-2"
        htmlFor={name}
      >
        {label}
      </label>
      <Textarea placeholder={placeholder} {...field} {...props} />
    </div>
  );
};

export default CustomTextArea;
