import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  selectChangeHandler,
  inputChangeHandler,
  FetchData,
} from "../../CommonFunctions";
import { ErrorAPI500, ErrorAPI404 } from "../../components/Errors/Errors";
import { Modal } from "../../components/Modal/Modal";
import { ModalHeader } from "../../components/Modal/Modal";
import { ModalBody } from "../../components/Modal/Modal";
import { ModalFooter } from "../../components/Modal/Modal";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import TextInput from "../../components/TextInput/TextInput";
import TagInput from "../../components/TagInput/TagInput";
import Checkbox from "../../components/CheckBox/CheckBox";
import CustomDropDown, {
  SelectOptions,
} from "../../components/Formik/CustomDropDown";
import {
  fetchPortfolioShow,
  fetchCategories,
  InputFiled,
  savePortfolio,
  validationSchema,
} from "./PortfolioFunction";
import Spinner from "../../components/Spinner/Spinner";
import ListLoader from "../../components/ListLoader/ListLoader";
import CustomLoader from "../../components/Loader/CustomLoader";

type Props = {
  onClose: () => void;
  portfolio_id:number;
  navigate: any;
};

class CreatePortfolioModal extends React.Component {
  static async fire(id: number, navigate: any) {
    const container = document.createElement("div");
    container.id = "custom-portfolio-container";
    const root = createRoot(container);
    document.body.appendChild(container);

    const close = () => {
      root.unmount();
      container.remove();
      return false;
    };

    root.render(
      <ModalComponent
        onClose={close}
        portfolio_id={id}
        navigate={navigate}
      />,
    );
  }

  render() {
    return null;
  }
}

const ModalComponent: React.FC<Props> = ({
  onClose,
  portfolio_id,
  navigate,
}) => {
  let formData:InputFiled = {
    portfolio_title:"",
    domain: "",
    category_id:"",
    client_name: "",
    tags: "",
    status: 0,
    is_public: 1,
  }
  let categoriesDefualtValue:SelectOptions = { label: "Select Service Area", value: "" };

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [portfolio, setPortfolio] = useState<FetchData>();
  const [FormData, setFormData] = useState<InputFiled>(formData);
  const [categoriesValue, setCategoriesValue] = useState<SelectOptions>(categoriesDefualtValue);
  const [categories, setCategories] = useState([]);
  const [isFromSubmiting, setIsFromSubmiting] = useState<boolean>(false);
  const [isPublic,setPublic] = useState<boolean>(true);

  useEffect(()=>{
    formData = {
      portfolio_title:
        portfolio !== undefined && portfolio["data"] !== undefined ? portfolio["data"].portfolio_title : "",
      domain: portfolio !== undefined && portfolio["data"] !== undefined ? portfolio["data"].domain : "",
      category_id:
        portfolio !== undefined && portfolio["data"] !== undefined ? portfolio["data"].category_id : "",
      client_name:
        portfolio !== undefined && portfolio["data"] !== undefined ? portfolio["data"].client_name : "",
      tags: portfolio !== undefined && portfolio["data"] !== undefined ? portfolio["data"].tags : "",
      status: portfolio !== undefined && portfolio["data"] !== undefined ? portfolio["data"].status : 0,
      is_public:
        portfolio !== undefined && portfolio["data"] !== undefined ? portfolio["data"].is_public : 1,
    };

    categoriesDefualtValue =
      portfolio !== undefined && portfolio["data"] !== undefined
        ? {
            label: portfolio["data"].category_name,
            value: portfolio["data"].category_id,
          }
        : { label: "Select Service Area", value: "" };

    const is_public = formData["is_public"] ? true : false;
    setPublic(is_public);
    setFormData(formData);
    setCategoriesValue(categoriesDefualtValue);

  },[portfolio]);

  useEffect(() => {
    if(isLoading === true){
      const loadData = async () => {
        if(portfolio_id !== 0){
          setPortfolio(await loadPortfolio(portfolio_id));
        }else{
          setPortfolio({ status: "success" });
        }
        
        setCategories(await loadCategories());

        setIsLoading(false);
      };
      
      loadData();
    }
  },[isLoading]);

  const loadPortfolio = async (id: number) => {
    const params = {
      0: id,
    };

    return await fetchPortfolioShow({ params });
  };

  // const loadCategories = async () => {
    
  //   const fetchData: FetchData = await fetchCategories();
  //   const data = fetchData["status"] === "success" ? fetchData["data"] : [];

  //   const options: any = [];
  //   options[0] = { label: "Select Category", value: "" };
  //   let j = 1;
  //   for (const i in data) {
  //     options[j] = { label: data[i].category_name, value: data[i].id };
  //     j++;
  //   }

  //   return options;
  // };
  const loadCategories = async () => {
    const fetchData: FetchData = await fetchCategories();
    const data = fetchData["status"] === "success" ? fetchData["data"] : [];

    const filteredData = data.filter((item:any) => item.status === 1);

    const options: any = [];
    options[0] = { label: "Select Category", value: "" };
    let j = 1;
    for (const item of filteredData) {
      options[j] = { label: item.category_name, value: item.id };
      j++;
    }

    return options;
  };

  const submitHandler = async (values: InputFiled, { setErrors }: any) => {
    await savePortfolio({
      setIsFromSubmiting: setIsFromSubmiting,
      portfoliosData: portfolio,
      formData: values,
      setErrorMessage: setErrors,
      navigate: navigate,
      close: onClose,
    });
  };

  return (
    <>
      {isLoading === true ? (
        <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
        <CustomLoader/>
      </div>
        // <Modal
        //   isOpen
        //   onClose={onClose}
        //   size="medium"
        //   placement="right"
        // >
        //   <ModalHeader className="bg-gray-200">
        //     <div className="animate-pulse">
        //       <div className="flex-1 space-y-6">
        //         <div className="h-5 w-72 bg-gray-100 rounded" />
        //       </div>
        //     </div>
        //   </ModalHeader>
        //   <ModalBody>
        //     <div className="grid grid-cols-2 gap-16">
        //       <div>
        //         <div className="mt-7">
        //           <div className="animate-pulse">
        //             <div className="flex-1 space-y-2">
        //               <div className="h-5 w-40 bg-gray-100 rounded" />
        //               <div className="h-10 bg-gray-100 rounded" />
        //             </div>
        //           </div>
        //         </div>
        //         <div className="mt-7">
        //           <div className="animate-pulse">
        //             <div className="flex-1 space-y-2">
        //               <div className="h-5 w-40 bg-gray-100 rounded" />
        //               <div className="h-10 bg-gray-100 rounded" />
        //             </div>
        //           </div>
        //         </div>
        //         <div className="mt-7">
        //           <div className="animate-pulse">
        //             <div className="flex-1 space-y-2">
        //               <div className="h-5 w-40 bg-gray-100 rounded" />
        //               <div className="h-10 bg-gray-100 rounded" />
        //             </div>
        //           </div>
        //         </div>
        //         <div className="flex items-center gap-2.5 mt-7">
        //           <div className="animate-pulse">
        //             <div className="flex-1 space-y-2">
        //               <div className="h-5 w-40 bg-gray-100 rounded" />
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </ModalBody>
        //   <ModalFooter>
        //     <div className="animate-pulse">
        //       <div className="flex gap-5">
        //         <div className="h-14 w-40 bg-gray-100 rounded" />
        //         <div className="h-14 w-40 bg-gray-100 rounded" />
        //       </div>
        //     </div>
        //   </ModalFooter>
        // </Modal>
      ) : portfolio !== undefined && portfolio["status"] === "success" ?(
        <Formik
          initialValues={FormData}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ setValues, values }) => (
            <Form>
              <Modal
                isOpen
                onClose={onClose}
                size="medium"
                placement="right"
              >
                <ModalHeader className="bg-gray-200">
                  <Typography className="font-bold" variant="h3">
                    {portfolio["data"] !== undefined
                      ? portfolio["data"]["portfolio_title"]
                      : "Create a Portfolio"}
                  </Typography>
                </ModalHeader>
                <ModalBody>
                  <div className="grid grid-cols-2 gap-16">
                    <div className="w-[27.5rem]">
                      <div className="">
                        <label className="text-400 font-medium text-gray-300 block mb-2">
                          Name of Portfolio
                          <span className="text-primary-100 ms-0.5">*</span>
                        </label>
                        <TextInput
                          name="portfolio_title"
                          onChange={(event) => {
                            inputChangeHandler(event.target.value, {
                              setValues: setValues,
                              key: "portfolio_title",
                              formData: values,
                            });
                          }}
                          placeholder="Enter portfolio name"
                          type="text"
                          value={values["portfolio_title"]}
                        />
                        <ErrorMessage
                          name="portfolio_title"
                          component="div"
                          className="error text-error text-200 mt-1"
                        />
                      </div>
                      <div className="mt-7 hidden">
                        <label className="text-400 font-medium text-gray-300 block mb-2">
                          Client Name <span className="text-primary-100">*</span>
                        </label>
                        <TextInput
                          name="client_name"
                          onChange={(event) => {
                            inputChangeHandler(event.target.value, {
                              setValues: setValues,
                              key: "client_name",
                              formData: values,
                            });
                          }}
                          placeholder="Enter Client Name"
                          type="text"
                          value={values["client_name"]}
                        />
                        <ErrorMessage
                          name="client_name"
                          component="div"
                          className="error text-error text-200 mt-1"
                        />
                      </div>
                      <div className="mt-7">
                        <label className="text-400 font-medium text-gray-300 block mb-2">
                          Service Area
                        </label>
                        <Field
                          name="category_id"
                          options={categories}
                          component={CustomDropDown}
                          type="box"
                          onChange={(
                            value: SelectOptions | SelectOptions[] | null,
                          ) => {
                            selectChangeHandler(value, {
                              setValues: setValues,
                              key: "category_id",
                              formData: values,
                            });
                          }}
                          className="custom-dropdown"
                          placeholder="Select Service Area"
                          defaultValue={categoriesValue}
                        />
                        <ErrorMessage
                          name="category_id"
                          component="div"
                          className="error text-error text-300 mt-1"
                        />
                      </div>
                      <div className="mt-7 hidden">
                        <label className="text-400 font-medium text-gray-300 block mb-2">
                          Domain
                        </label>
                        <TextInput
                          name="domain"
                          onChange={(event) => {
                            inputChangeHandler(event.target.value, {
                              setValues: setValues,
                              key: "domain",
                              formData: values,
                            });
                          }}
                          placeholder="Enter Domain"
                          type="text"
                          value={values["domain"]}
                        />
                        <ErrorMessage
                          name="domain"
                          component="div"
                          className="error text-error text-300 mt-1"
                        />
                      </div>
                      <div className="mt-7">
                        <label className="text-400 font-medium text-gray-300 block mb-2">
                          Tags
                        </label>
                        <TagInput
                          placeholder="Type & enter"
                          value={
                            values["tags"] !== "" && values["tags"] !== null
                              ? values["tags"].split(",")
                              : []
                          }
                          onTagsChange={(tags) => {
                            let tag = "";
                            for (const i in tags) {
                              const newTag = tags[i];
                              tag =
                                tag.trim() !== null && tag.trim() !== ""
                                  ? tag + ","
                                  : tag;
                              tag = tag + newTag;
                            }
                            inputChangeHandler(tag, {
                              setValues: setValues,
                              key: "tags",
                              formData: values,
                            });
                          }}
                        />
                        <ErrorMessage
                          name="tags"
                          component="div"
                          className="error text-error text-200 mt-1"
                        />
                      </div>
                      <div className="flex items-center gap-2.5 mt-7">
                        <Checkbox
                          checked={isPublic?false:true}
                          onCustomChange={(value) => {
                            const is_public = value ? 0 : 1;
                            inputChangeHandler(is_public, {
                              setValues: setValues,
                              key: "is_public",
                              formData: values,
                            });
                          }}
                          name="is_public"
                        />
                        <label className="text-gray-300 font-medium text-400">
                          Private Portfolio
                        </label>
                        <ErrorMessage
                          name="is_public"
                          component="div"
                          className="error text-error text-200 mt-1"
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className="flex justify-start gap-5">
                    <Button
                      type="submit"
                      label={
                        isFromSubmiting ? (
                          <Spinner labal="Processing..." />
                        ) :portfolio["data"] !== undefined? (
                          "Save"
                        ):(
                          "Next"
                        )
                      }
                      isDisabled={isFromSubmiting}
                      variant="primary"
                      size="large"
                    />
                    <Button
                      type="button"
                      label="Cancel"
                      onClick={onClose}
                      variant="outline"
                      size="large"
                    />
                  </div>
                </ModalFooter>
              </Modal>
            </Form>
          )}
        </Formik>
      ) : portfolio !== undefined && portfolio["status"] === "error" && portfolio["error"] === 404 ? (
        <Modal
          isOpen
          onClose={onClose}
          size="medium"
          placement="right"
        >
          <ModalHeader className="bg-gray-200">
            {""}
          </ModalHeader>
          <ModalBody>
            <ErrorAPI404 />
          </ModalBody>
          <ModalFooter>
            <div className="flex justify-start gap-5">
              <Button
                type="button"
                label="Cancel"
                onClick={onClose}
                variant="outline"
                size="large"
              />
            </div>
          </ModalFooter>
        </Modal>
      ) : (
        <Modal
          isOpen
          onClose={onClose}
          size="medium"
          placement="right"
        >
          <ModalHeader className="bg-gray-200">
            {""}
          </ModalHeader>
          <ModalBody>
            <ErrorAPI500 />
          </ModalBody>
          <ModalFooter>
            <div className="flex justify-start gap-5">
              <Button
                type="button"
                label="Cancel"
                onClick={onClose}
                variant="outline"
                size="large"
              />
            </div>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default CreatePortfolioModal;
