import React,{useEffect, useState} from "react";
import { CustomDateTimePicker } from '../../components/DateTimePicker/DatePicker'
import Accordion from '../../components/Accordion/Accordion'
import CustomFilters from "../../components/Filters/customFilters";
import Typography from "../../components/Typography/Typography";

const UserFilter = (prop:any) => {
  const isFilterOpen = prop.isFilterOpen;
  const setIsFilterOpen = prop.setIsFilterOpen;
  return (
    <div className={`px-5 py-8 pt-0 h-full group absolute left-0 top-0 z-[11] border-r
     border-gray-100 bg-gray-200 rounded-s-md ease-in duration-300 overflow-y-auto overflow-x-hidden flex flex-1 
      ${isFilterOpen?`w-[12.125rem]`:`w-[3.75rem] hover:w-[12.125rem]`}`}>
      <div className="w-full">
        <div className="pt-8 pb-4 mb-3 flex justify-start items-center gap-2.5 relative after:bg-gray-200 after:h-[5rem] after:absolute after:-right-[1.3rem] after:top-0 after:w-9 after:-z[10]">
          <div className="flex gap-1.5 justify-start items-center order-2">
            <div className="text-200 font-bold text-gray-500">
              FILTER
            </div>
            <div className="text-gray-500 text-100">|</div>
            <button className="text-200 font-normal text-primary-100 whitespace-nowrap">
              Clear All
            </button>
          </div>
          <button className="relative bg-gray-100 order-1 block p-0 me-4">
            {!isFilterOpen?
              <span className="absolute left-0 -top-1.5">
                <svg width="1rem" height="0.625rem" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M14.8753 0.875C15.2895 0.875 15.6253 1.21079 15.6253 1.625C15.6253 2.03921 15.2895 2.375 14.8753 2.375H1.12494C0.710726 2.375 0.374939 2.03921 0.374939 1.625C0.374939 1.21079 0.710726 0.875 1.12494 0.875H14.8753ZM15.6253 5.19667C15.6253 4.78246 15.2895 4.44667 14.8753 4.44667H1.12494C0.710726 4.44667 0.374939 4.78246 0.374939 5.19667C0.374939 5.61088 0.710726 5.94667 1.12494 5.94667H14.8753C15.2895 5.94667 15.6253 5.61088 15.6253 5.19667ZM15.6253 8.76833C15.6253 8.35411 15.2895 8.01833 14.8753 8.01833H1.12494C0.710726 8.01833 0.374939 8.35411 0.374939 8.76833C0.374939 9.18254 0.710726 9.51833 1.12494 9.51833H14.8753C15.2895 9.51833 15.6253 9.18254 15.6253 8.76833Z" fill="#888888"></path></svg>
              </span>
            :
              <span className="absolute left-0 -top-2.5">
                <svg width="1.25rem" height="1.25rem" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.9751 4.94165L2.91677 9.99998L7.9751 15.0583" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path><path d="M17.0835 10H3.0585" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path></svg>
              </span>
            }
          </button>
        </div>
        <div>
        <Accordion isFilterOpen={isFilterOpen} count={1} subTitle="" time="" title="Date" 
            icon={
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 18" fill="none">
                    <g clipPath="url(#clip0_1519_1966)">
                    <path d="M6.98242 11.0254L8.10742 12.1504L11.1074 9.15039" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M7.5 4.5H10.5C12 4.5 12 3.75 12 3C12 1.5 11.25 1.5 10.5 1.5H7.5C6.75 1.5 6 1.5 6 3C6 4.5 6.75 4.5 7.5 4.5Z" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12 3.01562C14.4975 3.15063 15.75 4.07312 15.75 7.50063V12.0006C15.75 15.0006 15 16.5006 11.25 16.5006H6.75C3 16.5006 2.25 15.0006 2.25 12.0006V7.50063C2.25 4.08063 3.5025 3.15063 6 3.01562" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1519_1966">
                    <rect width="18" height="18" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
            }
        >
            <div className={`${isFilterOpen?`inline-block`:`hidden group-hover:inline-block`}`}>
            <div className="mt-3 small-datepicker">
                  <label className="text-gray-300 font-normal text-200 mb-1.5">Start date</label>
                  <CustomDateTimePicker
                    onChange={function noRefCheck(){}}
                    selectedDate={null}
                    size="sm"
                    />
                    <Typography
                      className="text-primary-100 font-normal mt-0.5 hidden"
                      variant="p"
                      size="sm"
                    >Please select start date</Typography>
                </div>
                <div className="mt-2 small-datepicker">
                  <label className="text-gray-300 font-normal text-200 mb-1.5">End date</label>
                  <CustomDateTimePicker
                    onChange={function noRefCheck(){}}
                    selectedDate={null}
                    size="sm"
                    />
                    <Typography
                      className="text-primary-100 font-normal mt-0.5 hidden"
                      variant="p"
                      size="sm"
                    >Please select end date</Typography>
                    <Typography
                      className="text-primary-100 font-normal mt-0.5 hidden"
                      variant="p"
                      size="sm"
                    >End date can not be less than start date</Typography>
                </div>
              </div>
        </Accordion>
        </div>
      </div>
    </div>
  )
}

export default UserFilter