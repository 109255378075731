import React, { useEffect, useState } from "react";
import SearchUser from "./SearchUser";
import Button from "../../components/Button/Button";
import ArchivedConfirmModal from "./ArchivedConfirmModal";
import AddNewUserModal from "./AddNewUserModal";
import PasswordResetRequestModal from "./PasswordResetRequestModal";
import PasswordUpdatedConfirm from "./PasswordUpdatedConfirm";
const UserTopbar = () => {
  const [archivedConfirm, setArchivedConfirm] = useState<boolean>(false);
  const [addNewUser, setAddNewUser] = useState<boolean>(false);
  return (
    <div className="flex justify-between items-center bg-white py-5 px-5 sticky left-0 top-0 z-[1] w-full">
      <div className="flex justify-start items-center text-gray-300 font-normal text-400">
        Sales Admin
        <span className="ms-2">1 to 50</span>
        <div className="flex justify-start items-center gap-3 ps-1">
          <button className="p-1 opacity-50 cursor-default">
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 19.9201L8.47997 13.4001C7.70997 12.6301 7.70997 11.3701 8.47997 10.6001L15 4.08008"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button className="p-1">
            <svg
              width="16"
              height="16"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.91003 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91003 4.08008"
                stroke="#292D32"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="flex justify-end items-center gap-4">
        <button className="text-400 font-bold text-primary-100" onClick={() => setArchivedConfirm(true)}>Archive</button>
        <button className="text-400 font-bold text-primary-100 opacity-60 cursor-default">UnArchive</button>
        <div className="w-[18.125rem]"><SearchUser/></div>
        <div>
            <Button
              label="Add new user"
              onClick={() => setAddNewUser(true)}
              size="medium"
              variant="primary"
            />
        </div>
      </div>
      {archivedConfirm && (
          <ArchivedConfirmModal onClose={() => setArchivedConfirm(false)} />
      )}
      {addNewUser && (
          <AddNewUserModal onClose={() => setAddNewUser(false)} />
      )}
    </div>
  );
};

export default UserTopbar;
