import React,{useEffect, useState} from "react";
import { createRoot } from "react-dom/client";
import {
  updateProjectStatus
} from "../../CommonFunctions";
import {
  Modal,
  ModalBody,
  ModalFooter
} from "../../components/Modal/Modal";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import Textarea from "../../components/TextArea/Textarea";
import Spinner from "../../components/Spinner/Spinner";

type Props = {
  onClose: () => void;
  id:number;
  updateData:any;
};

class DeclineModalConfirm extends React.Component {
  static async fire(id:number,updateData:any) {
    const container = document.createElement("div");
    container.id = "custom-decline-container"+id;
    const root = createRoot(container);
    document.body.appendChild(container);

    const close = () => {
      root.unmount();
      container.remove();
      return false;
    };

    root.render(
      <ModalComponent
        onClose={close}
        id={id}
        updateData={updateData}
      />,
    );
  }

  render() {
    return null;
  }
}

const ModalComponent: React.FC<Props> = ({ onClose,id,updateData }) => {
  const [isFromSubmiting, setIsFromSubmiting] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [reason, setReason] = useState<string>();

  useEffect(()=>{
    if(reason !== undefined){
      setError('');
      if(reason.length <= 0){
        setError('Reason is required');
      }
    }
  },[reason]);

  const dicline = () => {
    setError('');
    if(reason === undefined){
      setError('Reason is required');
      return false;
    }
    if(reason !== undefined && reason.length <= 0){
      setError('Reason is required');
      return false;
    }

    updateProjectStatus(id,2,reason,setIsFromSubmiting,updateData,onClose);
  }

  return (
    <Modal isOpen onClose={onClose} size="small" placement="center">
      <ModalBody className="!pt-12 !pb-8">
        <div className="flex justify-start items-center gap-3">
            <div>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 9V14" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12.0001 21.41H5.94005C2.47005 21.41 1.02005 18.93 2.70005 15.9L5.82006 10.28L8.76006 5.00003C10.5401 1.79003 13.4601 1.79003 15.2401 5.00003L18.1801 10.29L21.3001 15.91C22.9801 18.94 21.5201 21.42 18.0601 21.42H12.0001V21.41Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M11.9945 17H12.0035" stroke="#292D32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <Typography variant="h5" className="font-bold">
                Are you sure you want to Decline?
            </Typography>
        </div>
        <div className="mt-6">
            <label className="text-400 font-medium text-gray-300 block mb-2">
                Reason
                <span className="text-primary-100"> *</span>
            </label>
            <Textarea className="!h-20"
                onChange={(event)=>{
                  setReason(event.target.value);
                }}
                placeholder=""
                resize value={reason!==undefined?reason:""}                
            />
            {error?<Typography variant="p" className="text-red-500 text-200">{error}</Typography>:""}
        </div>
      </ModalBody>
      <ModalFooter className="flex justify-end items-center bg-gray-200 gap-5">
      <Button
            label="No, cancel" variant="outline" size="large" 
            onClick={onClose}
        />
        <Button
            label={isFromSubmiting?<Spinner labal="Processing..." />:"Yes, I'm sure"} 
            variant="primary" 
            size="large"
            isDisabled={isFromSubmiting}
            onClick={()=>{dicline()}}
        />
      </ModalFooter>
    </Modal>
  );
};

export default DeclineModalConfirm;
