import React, { useEffect, useState } from "react";
import { Outlet, useNavigation } from "react-router-dom";
import { useSelector } from "react-redux";
import { InteractionType } from "@azure/msal-browser";
import {
  MsalAuthenticationTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import Layout from "./components/Layout/Layout";
import Loader from "./components/Loader/Loader";
import Login from "./views/login/Login";
import CheckerPermission from "./CheckerPermission";

function PageWithLoadingPlaceholder() {
  const { state } = useNavigation();
  const [isLoading, setIsLoading] = useState(true);
  const User = useSelector((state: any) => state.user);

  useEffect(() => {
    if (User.id !== "" && User.id !== undefined && User.role !== "" && User.role !== undefined) {
      setIsLoading(false);
    }
  }, [User]);

  if (state === "loading" || isLoading) {
    return <Loader />;
  }

  return <Outlet />;
}

function App() {
  const { instance } = useMsal();

  const initializeSignIn = async () => {
    await instance.loginRedirect();
  };

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
        <CheckerPermission>
          <Layout>
            <PageWithLoadingPlaceholder />
          </Layout>
        </CheckerPermission>
      </MsalAuthenticationTemplate>
      <UnauthenticatedTemplate>
        <Login event={async () => await initializeSignIn()} />
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
