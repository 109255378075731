import { configureStore } from "@reduxjs/toolkit";
import User from "./User";
import Token from "./Token";
import ClientToken from "./ClientToken";

export default configureStore({
  reducer: {
    user: User,
    msToken: Token,
    clientToken: ClientToken,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
