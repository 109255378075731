import React, { useState } from "react";
import Chip from "../../components/Chips/Chip";
import Icons from "../../components/Icons/Icon";
import Tooltip from "../../components/Tooltip/Tooltip";

export interface InvitedClientsProp {
  InvitedClients: [];
  count: number;
  onDelete?: any;
}

const InvitedClients:React.FC<InvitedClientsProp> = ({
  InvitedClients,
  count,
  onDelete
}) => {

  const [isShowMore,setIsShowMore] = useState<Boolean>(false);
  const InvitedClientsCount = count-1;

  const InvitedClientsLength = InvitedClients.length - count;

  const deleteHandler = (id:BigInteger,item:any) => {
    onDelete(id,item);
  }

  return (
    <div className="grid grid-flow-col auto-cols-max gap-1 w-full">
      {InvitedClients.map((item: any, idx: any) => (
        idx <= InvitedClientsCount?
          <div key={idx}>
            <Tooltip
              size="large"
              content={item.name+" ["+item.email+"]"}
              placement="bottom"
              trigger
            >
              <Chip 
                label={item.name} 
                onDelete={()=>{
                  deleteHandler(item.id,item);
                }} 
                type="default" 
                icon={<Icons
                  name="team"
                  variant="stroke"
                  size={16}
                />}
              />
            </Tooltip>
          </div>
        :isShowMore?
          <div key={idx}>
            <Tooltip
              size="large"
              content={item.name+" ["+item.email+"]"}
              placement="bottom"
              trigger
            >
              <Chip 
                label={item.name} 
                onDelete={()=>{
                  deleteHandler(item.id,item);
                }}
                type="default" 
                icon={<Icons
                  name="team"
                  variant="stroke"
                  size={24}
                />}
              />
            </Tooltip>
          </div>
        :""
      ))}
      {InvitedClients.length > 0?
        isShowMore?(
          <div className="ml-1 text-300 text-blue-300 font-medium mt-3 cursor-pointer" onClick={()=>{setIsShowMore(false)}}>
            show less
          </div>
        ):InvitedClientsLength > 0?(
          <div className="ml-1 text-300 text-blue-300 font-medium mt-3 cursor-pointer" onClick={()=>{setIsShowMore(true)}}>
            + {InvitedClientsLength} more
          </div>
        ):""
      :""}
    </div>
  );
};

export default InvitedClients;
