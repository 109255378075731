import { createSlice } from "@reduxjs/toolkit";

export type TokenState = {
  token: string;
  linkToken: string;
  portfolio:any;
  client:any;
  is_password:boolean;
  expire_date:string;
};

export const tokenState: TokenState = {
  token: "",
  linkToken: "",
  portfolio: [],
  client: [],
  is_password: false,
  expire_date: "",
};

export const ClientToken = createSlice({
  name: "clientToken",
  initialState: tokenState,
  reducers: {
    ClientTokenUpdate: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      
      state.token = action.payload.token;
      state.linkToken = action.payload.linkToken;
    },
    ClientDetailsUpdate: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      
      state.portfolio = action.payload.portfolio;
      state.client = action.payload.client;
      state.is_password = action.payload.is_password;
      state.expire_date = action.payload.expire_date;
    },
  },
});

// Action creators are generated for each case reducer function
export const { ClientTokenUpdate,ClientDetailsUpdate } = ClientToken.actions;

export default ClientToken.reducer;
