import React,{ useEffect, useState }  from "react";
import Offline from "./views/offline/Offline";

interface NetworkStatusProps {
  children: React.ReactNode;
}

const NetworkStatus = ({ children }: NetworkStatusProps) => {
  const [isOnline, setNetwork] = useState(window.navigator.onLine);

  const updateNetwork = () => {
    setNetwork(window.navigator.onLine);
  };
  
  useEffect(() => {
    window.addEventListener("offline", updateNetwork);
    window.addEventListener("online", updateNetwork);
    return () => {
        window.removeEventListener("offline", updateNetwork);
        window.removeEventListener("online", updateNetwork);
    };
  });

  return (
    <>
      {isOnline ? (
        <>
          {children}
        </>
      ):(
        <Offline />
      )}
    </>
  );
};

export default NetworkStatus;
