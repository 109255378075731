import React from "react";
import * as Yup from "yup";
import {
  convertParamsToArr,
  showConfirm,
  showToastAlert,
  ShowPageLoader,
  HidePageLoader,
  Params,
  callFetch,
  updateAssocciativeArray,
  unsetAssocciativeArray,
  pushArray,
} from "../../CommonFunctions";

const controller = new AbortController();
let projectController:any;
let categoriesController:any;
let projectShowController:any;
let projectFiltersController:any;
let projectRepositoryController:any;

export interface data {
  projectsData: any;
  categories?: any;
  events?: any;
}

export interface InputFiled {
  project_title: any;
  project_description: any;
  category_id: any;
  status: any;
  client_name: any;
  tags: any;
  start_date: any;
  end_date: any;
  notes: any;
  industries: any;
  technologies: any;
  custom_fields: any[];
  is_featured: number;
}

export const ErrorMessages: InputFiled = {
  project_title: "",
  project_description: "",
  category_id: "",
  status: "",
  client_name: "",
  tags: "",
  start_date: "",
  end_date: "",
  notes: "",
  industries: "",
  technologies: "",
  custom_fields: [],
  is_featured: 0
};

export interface StatusOption {
  label: string;
  value: number;
}

export const statusOptions: StatusOption[] = [
  { label: "UnArchived", value: 1 },
  { label: "Archived", value: 2 },
  // {label:'Draft',value:0},
];

export async function fetchProjets({ params }: Params) {
  params = convertParamsToArr({ params });
  let queryString = "";
  queryString = params[0] !== undefined ? "search=" + params[0] : "";
  queryString +=
    params["sort_by"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["sort_by"] !== undefined ? "sort_by=" + params["sort_by"] : "";
  queryString += params["tags"] !== undefined && queryString !== "" ? "&" : "";
  queryString += params["tags"] !== undefined ? "tags=" + params["tags"] : "";
  queryString +=
    params["status"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["status"] !== undefined ? "status=" + params["status"] : "";
  queryString +=
    params["start_date"] !== undefined && params["start_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["start_date"] !== undefined && params["start_date"] !== null ? "start_date=" + params["start_date"] : "";
  queryString +=
    params["end_date"] !== undefined && params["end_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["end_date"] !== undefined && params["end_date"] !== null ? "end_date=" + params["end_date"] : "";
  queryString +=
    params["categories"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["categories"] !== undefined ? "categories=" + params["categories"] : "";
  queryString +=
    params["industries"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["industries"] !== undefined ? "industries=" + params["industries"] : "";
  queryString +=
    params["technologies"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["technologies"] !== undefined ? "technologies=" + params["technologies"] : "";
  queryString +=
    params["clients"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["clients"] !== undefined ? "clients=" + params["clients"] : "";
  queryString +=
    params["page"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["page"] !== undefined ? "page=" + params["page"] : "";

  if(projectController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await projectController.abort(reason);
  }

  projectController = new AbortController();

  return await callFetch({
    url: process.env.REACT_APP_API_URL + "projects?" + queryString,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: projectController,
    callback: async (json) => {
      return json;
    },
  });
}

export async function fetchRepository({ params }: Params) {
  params = convertParamsToArr({ params });

  let queryString = "";
  queryString = params[1] !== undefined ? "search=" + params[1] : "";
  queryString +=
    params["sort_by"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["sort_by"] !== undefined ? "sort_by=" + params["sort_by"] : "";
  queryString +=
    params["group_by"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["group_by"] !== undefined ? "group_by=" + params["group_by"] : "";
  queryString += params["tag"] !== undefined && queryString !== "" ? "&" : "";
  queryString += params["tag"] !== undefined ? "tag=" + params["tag"] : "";
  queryString +=
    params["start_date"] !== undefined && params["start_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["start_date"] !== undefined && params["start_date"] !== null ? "start_date=" + params["start_date"] : "";
  queryString +=
    params["end_date"] !== undefined && params["end_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["end_date"] !== undefined && params["end_date"] !== null ? "end_date=" + params["end_date"] : "";
  queryString += params["tab"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["tab"] !== undefined ? "file_type=" + params["tab"] : "";
  queryString +=
    params["page"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["page"] !== undefined ? "page=" + params["page"] : "";

  if(projectRepositoryController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await projectRepositoryController.abort(reason);
  }

  projectRepositoryController = new AbortController();

  return await callFetch({
    url: process.env.REACT_APP_API_URL + "assets?" + queryString,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: projectRepositoryController,
    callback: async (json) => {
      return json;
    },
  });
}

export async function fetchProjectShow({ params }: Params) {
  params = convertParamsToArr({ params });
  if (params[0] !== undefined && params[0] != "") {

    if(projectShowController){
      const reason = new DOMException('signal timed out', 'TimeoutError');
      await projectShowController.abort(reason);
    }

    projectShowController = new AbortController();

    return await callFetch({
      url: process.env.REACT_APP_API_URL + "projects/" + params[0],
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      controller: projectShowController,
      callback: async (json) => {
        return json;
      },
    });
  }

  return { status: "error", error: 404 };
}

export async function fetchProjectFilters() {

  if(projectFiltersController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await projectFiltersController.abort(reason);
  }

  projectFiltersController = new AbortController();

  return await callFetch({
    url: process.env.REACT_APP_API_URL + "projects/filters",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: projectFiltersController,
    callback: async (json) => {
      return json;
    },
  });
}

export async function fetchCategories() {
  if(categoriesController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await categoriesController.abort(reason);
  }

  categoriesController = new AbortController();
  
  return await callFetch({
    url:
      process.env.REACT_APP_API_URL +
      "categories/all/data",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: categoriesController,
    callback: async (json) => {
      return json;
    },
  });
}

export const confirmDeleteProject = async (
  id: BigInteger,
  params: any,
  setProjectsData: any,
) => {
  await showConfirm({
    title: "Are you sure you want to delete this project?",
    message: "The project information will be deleted and can’t be undone. Click Yes, if you still want to continue.",
    type: "warning",
    confirm: async () => {
      await deleteProject(id, params, setProjectsData);
    },
    cancel: () => {},
  });
};

const deleteProject = async (
  id: BigInteger,
  params: any,
  setProjectsData: any,
) => {
  return await callFetch({
    url: process.env.REACT_APP_API_URL + "projects/" + id,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json) => {
      if (json.status == "success") {
        showToastAlert(json.message, 1);
        const data = await fetchProjets({ params });
        setProjectsData(data);
      } else {
        showToastAlert(json.message);
      }
      return json;
    },
  });
};

export const deleteProjectAsset = async (
  project_id: BigInteger,
  id: BigInteger,
  oldassets: any,
  oldFiles: any,
  oldCounter: any,
  updateData: any,
) => {
  if(oldassets.length <= 1){
    showToastAlert("At least one asset must be added to the project.");
    return false;
  }

  updateData(
    unsetAssocciativeArray(oldassets, "id", id),
    updateAssocciativeArray(oldFiles, "id", id, "is_disabled", false),
    oldCounter,
  );

  return await callFetch({
    url:
      process.env.REACT_APP_API_URL +
      "projects/" +
      project_id +
      "/assets/" +
      id,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json) => {
      if (json.status == "success") {
        // showToastAlert(json.message, 1);
      } else {
        updateData(
          oldassets,
          updateAssocciativeArray(oldFiles, "id", id, "is_disabled", true),
          oldCounter,
        );
        showToastAlert(json.message);
      }

      return json;
    },
  });
};

export const validationSchema = Yup.object({
  project_title: Yup.string()
    .required("Project title is required")
    .min(2, "Too Short!")
    .max(254, "Too Long!"),
  client_name: Yup.string()
    .required("Client name is required")
    .min(2, "Too Short!")
    .max(254, "Too Long!"),
  category_id: Yup.string()
    .required("Service area is required"),
  industries: Yup.string()
    .required("Industries is required"),
  // technologies: Yup.string()
  //   .required("Technologies is required"),
  tags: Yup.string()
    .required("Tags is required"),
});

export const captionValidationSchema = Yup.object({
  caption: Yup.string()
    .required("Caption is required")
    .min(2, "Too Short!")
    .max(254, "Too Long!"),
});

export const saveProject = async (props: any) => {
  props.setIsFromSubmiting(true);
  const errorMessage = ErrorMessages;

  let url = process.env.REACT_APP_API_URL + "projects";
  url =
    props.projectsData["data"] !== undefined
      ? url + "/" + props.projectsData["data"].id
      : url;

      for (const key in props.formData) {
        if (Object.prototype.hasOwnProperty.call(props.formData, key)) {
            const value = props.formData[key];
            if(value === "" || value === null) {
              delete props.formData[key];
            }
            // if (typeof value === 'object' && value !== null) {
            //   if(value.length > 0) {
            //     props.formData[key]= JSON.stringify(value);
            //   } else {
            //     delete props.formData[key];
            //   }
            // }
        }
      }

  return await callFetch({
    url: url,
    method: props.projectsData["data"] !== undefined ? "PUT" : "POST",
    body: JSON.stringify(props.formData),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      props.setIsFromSubmiting(false);

      if (json.status == "success") {
        showToastAlert(json.message, 1);
        props.close();
        props.navigate("/projects/show/" + json.data.id);
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          errorMessage[name as keyof InputFiled] =
            json.validation_errors[name][0];
        }

        props.setErrorMessage(errorMessage);
      } else {
        showToastAlert(json.message);
      }

      return json;
    },
  });
};

export const updateProjectStatus = async (
  id: number,
  status: any,
  loadData: any,
  from:any = "preview",
) => {
  ShowPageLoader();

  const url = process.env.REACT_APP_API_URL + "projects/updateStatus/" + id;

  return await callFetch({
    url: url,
    method: "PUT",
    body: JSON.stringify({ status, from }),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      HidePageLoader();

      if (json.status == "success") {
        showToastAlert(json.message, 1);
        loadData();
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          showToastAlert(json.validation_errors[name][0]);
        }
      } else {
        showToastAlert(json.message);
      }

      return json;
    },
  });
};

export const updateProjectCover = async (props: any) => {
  const is_cover = props.is_cover ? 0 : 1;
  const is_cover1 = props.is_cover ? 0 : 1;
  props.setProjectAssets(
    updateAssocciativeArray(
      props.oldassets,
      "id",
      props.id,
      "is_cover",
      is_cover1,
    ),
  );

  const url =
    process.env.REACT_APP_API_URL +
    "projects/" +
    props.project_id +
    "/assets/updateCover/" +
    props.id;

  return await callFetch({
    url: url,
    method: "PUT",
    body: JSON.stringify({ is_cover }),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      if (json.status == "success") {
        // showToastAlert(json.message, 1);
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          showToastAlert(json.validation_errors[name][0]);
        }
        props.setProjectAssets(
          updateAssocciativeArray(
            props.oldassets,
            "id",
            props.id,
            "is_cover",
            props.is_cover,
          ),
        );
      } else {
        showToastAlert(json.message);
        props.setProjectAssets(
          updateAssocciativeArray(
            props.oldassets,
            "id",
            props.id,
            "is_cover",
            props.is_cover,
          ),
        );
      }

      return json;
    },
  });
};

export const updateAssetCaption = async (props: any) => {
  props.setIsFromSubmiting(true);
  const oldassets = props.assetItem;
  props.setProjectAssets(
    updateAssocciativeArray(
      props.oldassets,
      "id",
      props.id,
      "caption",
      props.formData["caption"],
    ),
  );
  props.setIsShowCaption(false);
  props.setCaption(props.formData["caption"]);

  const url =
    process.env.REACT_APP_API_URL +
    "projects/" +
    props.project_id +
    "/assets/updateCaption/" +
    props.id;

  return await callFetch({
    url: url,
    method: "PUT",
    body: JSON.stringify(props.formData),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      props.setIsFromSubmiting(false);
      if (json.status == "success") {
        // showToastAlert(json.message, 1);
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          showToastAlert(json.validation_errors[name][0]);
        }
        props.setProjectAssets(
          updateAssocciativeArray(
            props.oldassets,
            "id",
            props.id,
            "caption",
            oldassets["caption"],
          ),
        );

        props.setIsShowCaption(true);
        props.setCaption(oldassets["caption"]);
      } else {
        showToastAlert(json.message);
        props.setProjectAssets(
          updateAssocciativeArray(
            props.oldassets,
            "id",
            props.id,
            "caption",
            oldassets["caption"],
          ),
        );

        props.setIsShowCaption(true);
        props.setCaption(oldassets["caption"]);
      }

      return json;
    },
  });
};

export const saveAsset = async (props: any) => {
  props.updateData(
    pushArray(props.oldassets, props.item),
    updateAssocciativeArray(
      props.oldFiles,
      "id",
      props.id,
      "is_disabled",
      true,
    ),
    props.oldCounter,
  );

  const url =
    process.env.REACT_APP_API_URL + "projects/" + props.project_id + "/assets";

  return await callFetch({
    url: url,
    method: "POST",
    body: JSON.stringify({
      asset_id: props.id,
      asset_type: props.type
    }),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      if (json.status == "success") {
        // showToastAlert(json.message, 1);
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          showToastAlert(json.validation_errors[name][0]);
        }

        props.updateData(
          unsetAssocciativeArray(props.oldassets, "id", props.id),
          updateAssocciativeArray(
            props.oldFiles,
            "id",
            props.id,
            "is_disabled",
            false,
          ),
          props.oldCounter,
        );
      } else {
        showToastAlert(json.message);
        props.updateData(props.oldassets, props.oldFiles, props.oldCounter);
      }

      return json;
    },
  });
};

export const saveTag = async (props: any) => {
  const url =
    process.env.REACT_APP_API_URL + "projects/" + props.project_id + "/tags";

  return await callFetch({
    url: url,
    method: "POST",
    body: JSON.stringify({ tag: props.tag}),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      if (json.data !== undefined) {
        props.setTags(json.data);
      }

      if (json.status == "success") {
        // showToastAlert(json.message, 1);
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          showToastAlert(json.validation_errors[name][0]);
        }
      } else {
        showToastAlert(json.message);
      }

      return json;
    },
  });
};

export const removeTag = async (props: any) => {
  const url =
    process.env.REACT_APP_API_URL +
    "projects/" +
    props.project_id +
    "/tags/" +
    props.tag;


  return await callFetch({
    url: url,
    method: "DELETE",
    body: "",
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      if (json.data !== undefined) {
        props.setTags(json.data);
      }

      if (json.status == "success") {
        // showToastAlert(json.message, 1);
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          showToastAlert(json.validation_errors[name][0]);
        }
      } else {
        showToastAlert(json.message);
      }

      return json;
    },
  });
};

export const updateAssetOrder = async (props: any) => {
  props.setProjectAssets(props.newProjects);

  const url =
    process.env.REACT_APP_API_URL +
    "projects/" +
    props.project_id +
    "/assets/sequenceReorder"
    if(projectShowController){
      const reason = new DOMException('signal timed out', 'TimeoutError');
      await projectShowController.abort(reason);
    }

    projectShowController = new AbortController();
  return await callFetch({
    url: url,
    method: "PUT",
    body: JSON.stringify(props.newProjects),
    headers: {
      "Content-Type": "application/json",
    },
    controller: projectShowController,
    callback: async (json: any) => {
      if (json.status == "success") {
        // showToastAlert(json.message, 1);
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          showToastAlert(json.validation_errors[name][0]);
        }
        props.setProjectAssets(props.oldAssets);
      } else {
        showToastAlert(json.message);
        props.setProjectAssets(props.oldAssets);
      }

      return json;
    },
  });
};
