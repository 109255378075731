import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import ClientApp from "./ClientApp";
import { Error500,Error404 } from "./components/Errors/Errors";
import ViewPortfolio from "./views/viewPortfolio/ViewPortfolio";
import { ClientView } from "./views/viewPortfolio/ClientView";
import InputClientDetails from "./views/viewPortfolio/InputClientDetails";
import { WelcomeClient } from "./views/viewPortfolio/WelcomeClient";
import Loader from "./components/Loader/Loader";

const options = {
  basename: "/",
};

const ClientRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<ClientApp />} errorElement={<Error500 />}>
      <Route
        path="client/portfolio"
        element={<ViewPortfolio />}
        errorElement={<Error500 />}
      />
      <Route
        path="client/project/:id"
        element={<ClientView />}
        errorElement={<Error500 />}
      />
      <Route
        path="client/contactus"
        element={<InputClientDetails />}
        errorElement={<Error500 />}
      />
      <Route
        path="client/welcomeClient"
        element={<WelcomeClient />}
        errorElement={<Error500 />}
      />
      <Route
        path="client/authenticate/*"
        element={<Loader />}
        errorElement={<Error500 />}
      />
      <Route
        path="*"
        element={<Error404 />}
        errorElement={<Error500 />}
      ></Route>
    </Route>,
  ),
  options,
);

export default ClientRouter;
