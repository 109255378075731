import React, { useState,useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Formik, Form, ErrorMessage } from "formik";
import {
  inputChangeHandler,
  FetchData,
} from "../../CommonFunctions";
import { ErrorAPI500, ErrorAPI404 } from "../../components/Errors/Errors";
import { Modal } from "../../components/Modal/Modal";
import { ModalHeader } from "../../components/Modal/Modal";
import { ModalBody } from "../../components/Modal/Modal";
import { ModalFooter } from "../../components/Modal/Modal";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import TextInput from "../../components/TextInput/TextInput";
import {
  fetchClientsShow,
  InputFiled,
  saveClients,
  validationSchema
} from "./ClientsFunction";
import Spinner from "../../components/Spinner/Spinner";
import ClientEmpty from "./ClientEmpty";

type Props = {
  onClose: () => void;
  client_id: number;
  navigate: any;
};

class CreateProjectModal extends React.Component {
  static async fire(id: number, navigate: any) {
    const container = document.createElement("div");
    container.id = "custom-project-container";
    const root = createRoot(container);
    document.body.appendChild(container);

    const close = () => {
      root.unmount();
      container.remove();
      return false;
    };

    root.render(
      <ModalComponent
        onClose={close}
        client_id={id}
        navigate={navigate}
      />,
    );
  }

  render() {
    return null;
  }
}

const ModalComponent: React.FC<Props> = ({
  onClose,
  client_id,
  navigate,
}) => {
  let formData: InputFiled = {
    name: "",
    email: "",
    company_name: "",
    status: 1,
    is_converted: 0,
  };

  const [isFromSubmiting, setIsFromSubmiting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [client, setClient] = useState<FetchData>();
  const [FormData, setFormData] = useState<InputFiled>(formData);

  useEffect(() => {
    formData = {
      name: client !== undefined && client["data"] !== undefined ? client["data"].name : "",
      email: client !== undefined && client["data"] !== undefined ? client["data"].email : "",
      company_name:
        client !== undefined && client["data"] !== undefined ? client["data"].company_name : "",
      status: client !== undefined && client["data"] !== undefined ? client["data"].status : 1,
      is_converted:
        client !== undefined && client["data"] !== undefined ? client["data"].is_converted : 0,
    };

    setFormData(formData);

    if(client !== undefined){
      setIsLoading(false);
    }
  }, [client]);

  useEffect(() => {
    if(isLoading === true){
      const loadData = async () => {
        if(client_id !== 0){
          setClient(await loadClients(client_id));
        }else{
          setClient({ status: "success" });
        }        
      };
      
      loadData();
    }
  },[isLoading]);

  const loadClients = async (id: number) => {
    const params = {
      0: id,
    };

    return await fetchClientsShow({ params });
  };

  const submitHandler = async (values: InputFiled, { setErrors }: any) => {
    await saveClients({
      setIsFromSubmiting: setIsFromSubmiting,
      clientsData: client,
      formData: values,
      setErrorMessage: setErrors,
      navigate: navigate,
      close: onClose,
    });
  };

  return (
    <>
      {isLoading === true ? (
        <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
        <ClientEmpty/>
      </div>
        // <Modal
        //   isOpen
        //   onClose={onClose}
        //   size="medium"
        //   placement="right"
        // >
        //   <ModalHeader className="bg-gray-200">
        //     <div className="animate-pulse">
        //       <div className="flex-1 space-y-6">
        //         <div className="h-5 w-72 bg-gray-100 rounded" />
        //       </div>
        //     </div>
        //   </ModalHeader>
        //   <ModalBody>
        //     <div className="grid grid-cols-1 h-96">
        //       <div>
        //         <div className="animate-pulse">
        //           <div className="flex-1 space-y-2">
        //             <div className="h-5 w-40 bg-gray-100 rounded" />
        //             <div className="h-10 bg-gray-100 rounded" />
        //           </div>
        //         </div>
        //       </div>
        //       <div className="mt-7">
        //         <div className="animate-pulse">
        //           <div className="flex-1 space-y-2">
        //             <div className="h-5 w-40 bg-gray-100 rounded" />
        //             <div className="h-10 bg-gray-100 rounded" />
        //           </div>
        //         </div>
        //       </div>
        //       <div className="mt-7">
        //         <div className="animate-pulse">
        //           <div className="flex-1 space-y-2">
        //             <div className="h-5 w-40 bg-gray-100 rounded" />
        //             <div className="h-10 bg-gray-100 rounded" />
        //           </div>
        //         </div>
        //       </div>
        //       <div className="mt-7">
        //         <div className="animate-pulse">
        //           <div className="flex-1 space-y-2">
        //             <div className="h-5 w-40 bg-gray-100 rounded" />
        //             <div className="h-10 bg-gray-100 rounded" />
        //           </div>
        //         </div>
        //       </div>
        //       <div className="flex items-center gap-2.5 mt-7">
        //         <div className="animate-pulse">
        //           <div className="flex-1 space-y-2">
        //             <div className="h-5 w-40 bg-gray-100 rounded" />
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </ModalBody>
        //   <ModalFooter>
        //     <div className="animate-pulse">
        //       <div className="flex gap-5">
        //         <div className="h-14 w-24 bg-gray-100 rounded" />
        //         <div className="h-14 w-24 bg-gray-100 rounded" />
        //       </div>
        //     </div>
        //   </ModalFooter>
        // </Modal>
      ) : client !== undefined && client["status"] === "success" ? (
        <Formik
          initialValues={FormData}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ setValues, values }) => (
            <Form>
              <Modal
                isOpen
                onClose={onClose}
                size="medium"
                placement="right"
              >
                <ModalHeader className="bg-gray-200">
                  <Typography className="font-bold" variant="h3">
                    {client["data"] !== undefined
                      ? client["data"]["name"] 
                      : "Create a Client"}
                  </Typography>
                </ModalHeader>
                <ModalBody>
                  <div className="grid grid-cols-2">
                    <div className="w-[27.5rem]">
                    <div>
                      <label className="text-400 font-medium text-gray-300 block mb-2">
                        Name
                        <span className="text-primary-100 ms-0.5">*</span>
                      </label>
                      <TextInput
                        name="name"
                        onChange={(event) => {
                          inputChangeHandler(event.target.value, {
                            setValues: setValues,
                            key: "name",
                            formData: values,
                          });
                        }}
                        placeholder="Enter name"
                        type="text"
                        value={values["name"]}
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error text-error text-200 mt-1"
                      />
                    </div>
                    <div className="mt-8">
                      <label className="text-400 font-medium text-gray-300 block mb-2">
                        Email Id
                        <span className="text-primary-100 ms-0.5">*</span>
                      </label>
                      <TextInput
                        name="email"
                        onChange={(event) => {
                          inputChangeHandler(event.target.value, {
                            setValues: setValues,
                            key: "email",
                            formData: values,
                          });
                        }}
                        placeholder="Enter email id"
                        type="text"
                        value={values["email"]}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error text-error text-200 mt-1"
                      />
                    </div>
                    <div className="mt-8">
                      <label className="text-400 font-medium text-gray-300 block mb-2">
                        Company Name
                        <span className="text-primary-100 ms-0.5">*</span>
                      </label>
                      <TextInput
                        name="company_name"
                        onChange={(event) => {
                          inputChangeHandler(event.target.value, {
                            setValues: setValues,
                            key: "company_name",
                            formData: values,
                          });
                        }}
                        placeholder="Enter company name"
                        type="text"
                        value={values["company_name"]}
                      />
                      <ErrorMessage
                        name="company_name"
                        component="div"
                        className="error text-error text-200 mt-1"
                      />
                    </div>
                    {/* <div className="flex items-center gap-2.5 mt-7">
                      <Checkbox
                        checked={values['is_converted']?true:false}
                        onCustomChange={(value) => {
                          const is_converted = value ? 1 : 0;
                          inputChangeHandler(is_converted, {
                            setValues: setValues,
                            key: "is_converted",
                            formData: values,
                          });
                        }}
                        name="is_converted"
                      />
                      <label className="text-gray-300 font-medium text-400">
                        Converted
                      </label>
                      <ErrorMessage
                        name="is_converted"
                        component="div"
                        className="error text-error text-300 mt-1"
                      />
                    </div> */}
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className="flex justify-start gap-5">
                    <Button
                      type="submit"
                      label={
                        isFromSubmiting ? (
                          <Spinner labal="Processing..." />
                        ) : (
                          "Save"
                        )
                      }
                      isDisabled={isFromSubmiting}
                      variant="primary"
                      size="large"
                    />
                    <Button
                      type="button"
                      label="Cancel"
                      onClick={onClose}
                      variant="outline"
                      size="large"
                    />
                  </div>
                </ModalFooter>
              </Modal>
            </Form>
          )}
        </Formik>
      ) : client !== undefined && client["status"] === "error" && client["error"] === 404 ? (
        <Modal
          isOpen
          onClose={onClose}
          size="small"
          placement="right"
        >
          <ModalHeader className="bg-gray-200">
            {""}
          </ModalHeader>
          <ModalBody>
            <ErrorAPI404 />
          </ModalBody>
          <ModalFooter>
            <div className="flex justify-start gap-5">
              <Button
                type="button"
                label="Cancel"
                onClick={onClose}
                variant="outline"
                size="large"
              />
            </div>
          </ModalFooter>
        </Modal>
      ) : (
        <Modal
          isOpen
          onClose={onClose}
          size="small"
          placement="right"
        >
          <ModalHeader className="bg-gray-200">
            {""}
          </ModalHeader>
          <ModalBody>
            <ErrorAPI500 />
          </ModalBody>
          <ModalFooter>
            <div className="flex justify-start gap-5">
              <Button
                type="button"
                label="Cancel"
                onClick={onClose}
                variant="outline"
                size="large"
              />
            </div>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default CreateProjectModal;
