import React, { useState, useRef } from "react";
import Slider from "react-slick";
import Typography from "../../components/Typography/Typography";
import styled from "styled-components";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import LinkThamnailImg from "../../images/clientProject/linkThamnail.png";
import documentThamnailImg from "../../images/clientProject/documentThamnail.png";

export const AssetSliderStyle = styled.div`
  .slide2 .slick-track {
    width: auto !important;
    // display:flex;
    justify-content: flex-start;
    padding-left: 16px;
  }
  .slide2 .slick-slide {
    margin: 0 0.75rem 0.75rem 0;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    overflow: hidden;
  }
  .slide2 .slick-slide.slick-current {
    background: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12);
  }
  .slide2 {
    padding: 0px 0px !important;
  }
  .slide2 .slick-slider {
    width: 70%;
    display: inline-flex;
    overflow: auto;
    justify-content: flex-start;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
  .slide2 .slick-list {
    width: auto;
    display: flex;
    justify-content: flex-start;
  }
  .slide2 .slick-track {
    display: flex;
    justify-content: flex-start;
  }
  .slide1 .slick-slider .slick-slide {
    img {
      width: 100% !important;
      height: 100% !important;
      @media (max-width: 1024px) {
        width: 100%;
        height: 100%;
      }
    }
  }
  #header-bar {
    display: none;
  }
`;

export default function ProjectViewSlide(prop: any) {
  const sliderRef = useRef<any>();
  const data = prop.data;
  const [nav1, setNav1] = useState<HTMLElement>();
  const [nav2, setNav2] = useState<HTMLElement>();

  const docs = [
    { uri: data.url,
      fileName: data.filename,
      fileType: data.file_type, 
    }
  ];

  return (
    <AssetSliderStyle>
      <div className="w-full projectViewSlide">
        {data !== undefined ? (
          data.type !== "Projects" ? (
            <>
              <div className="slider1 h-[calc(100vh-156px)] max-xl:h-auto bg-white py-10 px-10 pe-6 max-xl:p-0 max-xl:shadow-0 shadow-500 rounded-b-md relative">
                <div className="grid grid-cols-4 gap-4 max-xl:gap-0">
                  <div className="col-span-3 pe-0 max-xl:pe-0 max-xl:col-span-4 max-xl:[mt]">
                    <Slider
                      asNavFor={nav2}
                      ref={(slider1: HTMLElement) => {
                        setNav1(slider1);
                        sliderRef.current = slider1;
                      }}
                      centerMode={true}
                      slidesToShow={1}
                      slidesToScroll={1}
                      fade={true}
                      dots={false}
                      arrows={false}
                      responsive={[
                        {
                          breakpoint: 1200,
                          settings: {
                            centerMode: false,
                          },
                        },
                      ]}
                    >
                      <div className="flex justify-center items-center max-h-[calc(100vh-190px)] max-xl:h- w-full overflow-hidden">
                        <div className="w-full h-[calc(100vh-190px)] max-xl:h-[350px] max-lg:h-[300px] max-md:h-[250px] flex justify-center items-center max-xl:p-5">
                          {data.type === "Links" ? (
                            // <img
                            //   src={documentThamnailImg}
                            //   alt={documentThamnailImg}
                            //   className="w-full h-full object-cover"
                            // />
                            <div className="relative w-full h-full flex justify-center items-center bg-[#E7E7E7]">
                              <div className="text-center">
                                <span className="flex justify-center pb-2">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="110"
                                    height="110"
                                    viewBox="0 0 110 110"
                                    fill="none"
                                  >
                                    <path
                                      d="M59.8547 50.1406C70.1672 60.4531 70.1672 77.1365 59.8547 87.4031C49.5422 97.6698 32.8589 97.7156 22.5922 87.4031C12.3255 77.0906 12.2797 60.4073 22.5922 50.1406"
                                      stroke="#F15845"
                                      stroke-width="4.8125"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M48.5437 61.4617C37.8187 50.7367 37.8187 33.3201 48.5437 22.5492C59.2687 11.7784 76.6854 11.8242 87.4563 22.5492C98.2271 33.2742 98.1813 50.6909 87.4563 61.4617"
                                      stroke="#F15845"
                                      stroke-width="4.8125"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>
                                </span>
                                <a
                                  href="https://millipixels.com/"
                                  target="_blank"
                                  className="text-gray-300 font-bold text-500"
                                  rel="noreferrer"
                                >
                                  https://millipixels.com/
                                </a>
                              </div>
                            </div>
                          ) : (
                            // <DocViewer
                            //   documents={[
                            //     {
                            //       uri: data.url,
                            //       fileName: data.filename,
                            //       fileType: data.file_type,
                            //     },
                            //   ]}
                            // />
                            <DocViewer
                            documents={docs}
                            pluginRenderers={DocViewerRenderers}
                            />
                          )}
                        </div>
                      </div>
                      {data.caption !== null && data.caption !== "" ? (
                        <Typography
                          className="text-center mt-1.5 text-secondary-200 font-medium"
                          size="md"
                          variant="p"
                        >
                          {data.caption}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Slider>
                  </div>
                  <div className="col-span-1 max-xl:col-span-4 max-xl:pt-5 max-xl:w-full max-xl:relative max-xl:left-0 max-xl:bg-gray-400 max-xl:ps-5 max-xl:pb-5">
                    <div>
                      <Typography
                        variant="h3"
                        className="text-gray-300 font-bold"
                      >
                        {data.name}
                      </Typography>
                      <Typography
                        size="lg"
                        variant="p"
                        className="text-gray-700 font-normal mt-1"
                      >
                        {data.category_name}
                      </Typography>
                    </div>
                  </div>
                </div>
                {/* <div className="w-[18.75rem] h-1 left-0 bottom-0 bg-green-200 absolute"></div> */}
              </div>

              <div className="mt-4 slide2 relative max-xl:bg-gray-400 max-xl:m-0">
                <Slider
                  asNavFor={nav1}
                  ref={(slider2: HTMLElement) => setNav2(slider2)}
                  slidesToShow={7}
                  slidesToScroll={1}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  arrows={false}
                  centerMode={false}
                  infinite={false}
                >
                  <div className="w-[4.625rem] h-12 p-1 bg-pastel-500 rounded-md shadow-100 overflow-hidden flex justify-center items-center content cursor-pointer">
                    {data.type === "Images" ? (
                      <img
                        src={data.url}
                        alt={data.url}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="text-center">
                        {data.type === "Documents" ? (
                          <span className="flex justify-center items-center mb-1">
                            <svg
                              aria-label="Document Icon"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M15.75 9H8.25"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M15.75 15H8.25"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        ) : data.type === "Videos" ? (
                          <span className="flex justify-center items-center mb-1">
                            <svg
                              aria-label="Video Icon"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4 11.9999V8.43989C4 4.01989 7.13 2.2099 10.96 4.4199L14.05 6.1999L17.14 7.9799C20.97 10.1899 20.97 13.8099 17.14 16.0199L14.05 17.7999L10.96 19.5799C7.13 21.7899 4 19.9799 4 15.5599V11.9999Z"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeMiterlimit="10"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        ) : data.type === "Links" ? (
                          <span className="flex justify-center items-center mb-1">
                            <svg
                              aria-label="Link Icon"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.5 12C13.5 15.18 10.93 17.75 7.75 17.75C4.57 17.75 2 15.18 2 12C2 8.82 4.57 6.25 7.75 6.25"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M10 12C10 8.69 12.69 6 16 6C19.31 6 22 8.69 22 12C22 15.31 19.31 18 16 18"
                                stroke="#292D32"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        ) : (
                          ""
                        )}
                        <Typography
                          className="text-seondary-200 font-medium line-clamp-1"
                          size="xs"
                          variant="p"
                        >
                          {data.name}
                        </Typography>
                      </div>
                    )}
                  </div>
                </Slider>
              </div>
            </>
          ) : data.assets !== undefined ? (
            <>
              <div className="slider1 h-[calc(100vh-156px)] max-xl:h-auto bg-white py-10 px-10 pe-6 max-xl:p-0 max-xl:shadow-0 shadow-500 rounded-b-md relative">
                <div className="grid grid-cols-4 gap-4 max-xl:gap-0">
                  <div className="col-span-3 pe-0 max-xl:pe-0 max-xl:col-span-4">
                    <Slider
                      asNavFor={nav2}
                      ref={(slider1: HTMLElement) => {
                        setNav1(slider1);
                        sliderRef.current = slider1;
                      }}
                      centerMode={true}
                      slidesToShow={1}
                      slidesToScroll={1}
                      fade={true}
                      dots={false}
                      arrows={false}
                      responsive={[
                        {
                          breakpoint: 1200,
                          settings: {
                            centerMode: false,
                          },
                        },
                      ]}
                    >
                      {data.assets.map((item: any, idx: any) => (
                        <div key={idx}>
                          <div className="flex justify-center items-center max-h-[calc(100vh-190px)] max-xl:max-h-auto w-full overflow-hidden">
                            <div className="w-full h-[calc(100vh-190px)] max-xl:h-[300px] max-md:h-[250px] flex justify-center items-center max-xl:p-5">
                              {item.type === "Images" ? (
                                <img
                                  src={item.url}
                                  alt={item.name}
                                  className="w-full h-full object-cover"
                                />
                              ) : item.type === "Documents" ? (
                                <iframe
                                  className="w-full h-full"
                                  src={item.url}
                                ></iframe>
                              ) : item.type === "Videos" ? (
                                <iframe
                                  className="w-full h-full"
                                  src={item.url}
                                ></iframe>
                              ) : item.type === "Links" ? (
                                <iframe
                                  className="w-full h-full"
                                  src={item.url}
                                ></iframe>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          {item.caption !== null && item.caption !== "" ? (
                            <Typography
                              className="text-center mt-1.5 text-secondary-200 font-medium"
                              size="md"
                              variant="p"
                            >
                              {item.caption}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                    </Slider>
                  </div>
                  <div className="col-span-1 max-xl:col-span-4 max-xl:pt-5 max-xl:w-full max-xl:relative max-xl:left-0 max-xl:bottom-[0px] max-xl:bg-gray-400 max-xl:ps-5 max-xl:pb-5">
                    <div>
                      <Typography
                        variant="h3"
                        className="text-gray-300 font-bold"
                      >
                        {data.type === "Projects"
                          ? data.project_title
                          : data.name}
                      </Typography>
                      <Typography
                        size="lg"
                        variant="p"
                        className="text-gray-700 font-normal mt-1"
                      >
                        {data.category_name}
                      </Typography>
                      <Typography
                        size="sm"
                        variant="p"
                        className="text-gray-700 font-normal mt-1"
                      >
                        {data.industries}
                      </Typography>
                      <Typography
                        size="sm"
                        variant="p"
                        className="text-gray-700 font-normal mt-3 line-clamp-[14]"
                      >
                        {data.project_description}
                      </Typography>
                    </div>
                  </div>
                </div>
                {/* <div className="w-[18.75rem] h-1 left-0 bottom-0 bg-green-200 absolute"></div> */}
              </div>

              <div className="mt-4 slide2 relative">
                <Slider
                  asNavFor={nav1}
                  ref={(slider2: HTMLElement) => setNav2(slider2)}
                  slidesToShow={7}
                  slidesToScroll={1}
                  swipeToSlide={true}
                  focusOnSelect={true}
                  arrows={false}
                  centerMode={false}
                  infinite={false}
                >
                  {data.assets.map((item: any, idx: any) => (
                    <div key={idx}>
                      <div className="w-[4.625rem] h-12 p-1 bg-pastel-500 rounded-md shadow-100 overflow-hidden flex justify-center items-center content cursor-pointer">
                        {item.type === "Images" ? (
                          <img
                            src={item.url}
                            alt={item.url}
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <div className="text-center">
                            {item.type === "Documents" ? (
                              <span className="flex justify-center items-center mb-1">
                                <svg
                                  aria-label="Document Icon"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M15.75 9H8.25"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M15.75 15H8.25"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            ) : item.type === "Videos" ? (
                              <span className="flex justify-center items-center mb-1">
                                <svg
                                  aria-label="Video Icon"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4 11.9999V8.43989C4 4.01989 7.13 2.2099 10.96 4.4199L14.05 6.1999L17.14 7.9799C20.97 10.1899 20.97 13.8099 17.14 16.0199L14.05 17.7999L10.96 19.5799C7.13 21.7899 4 19.9799 4 15.5599V11.9999Z"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            ) : item.type === "Links" ? (
                              <span className="flex justify-center items-center mb-1">
                                <svg
                                  aria-label="Link Icon"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.5 12C13.5 15.18 10.93 17.75 7.75 17.75C4.57 17.75 2 15.18 2 12C2 8.82 4.57 6.25 7.75 6.25"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M10 12C10 8.69 12.69 6 16 6C19.31 6 22 8.69 22 12C22 15.31 19.31 18 16 18"
                                    stroke="#292D32"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </span>
                            ) : (
                              ""
                            )}
                            <Typography
                              className="text-seondary-200 font-medium line-clamp-1"
                              size="xs"
                              variant="p"
                            >
                              {item.name}
                            </Typography>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </Slider>
                <div className="flex justify-between items-center gap-10 absolute right-4 top-1/2 -translate-y-1/2 max-xl:fixed max-xl:top-[86px] max-xl:left-0 max-xl:px-4 max-xl:w-full max-xl:bg-gray-400 max-xl:py-2">
                  <button
                    className="text-gray-700 text-200 font-normal flex justify-start items-center"
                    onClick={() => sliderRef?.current?.slickPrev()}
                  >
                    <span>
                      <svg
                        aria-label="LeftArrow Icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="23"
                        height="24"
                        viewBox="0 0 23 24"
                        fill="none"
                        className="max-md:h-4 max-md:w-4"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.5514 2.25105C15.8374 2.58579 15.8374 3.1285 15.5514 3.46323L8.25932 12L15.5514 20.5368C15.8374 20.8715 15.8374 21.4142 15.5514 21.749C15.2655 22.0837 14.8019 22.0837 14.516 21.749L6.70615 12.6061C6.56884 12.4453 6.4917 12.2273 6.4917 12C6.4917 11.7727 6.56884 11.5547 6.70615 11.3939L14.516 2.25105C14.8019 1.91632 15.2655 1.91632 15.5514 2.25105Z"
                          fill="#646464"
                        />
                      </svg>
                    </span>
                    <span className="max-sm:hidden">Previous</span>
                  </button>
                  <button
                    className="text-gray-700 text-200 font-normal flex justify-start items-center"
                    onClick={() => sliderRef?.current?.slickPrev()}
                  >
                    <span className="max-sm:hidden">Next</span>
                    <span>
                      <svg
                        aria-label="RightArrow Icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="23"
                        height="24"
                        viewBox="0 0 23 24"
                        fill="none"
                        className="max-md:h-4 max-md:w-4"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.44856 21.7489C7.16263 21.4142 7.16263 20.8715 7.44856 20.5368L14.7407 12L7.44856 3.46323C7.16263 3.1285 7.16263 2.58578 7.44856 2.25105C7.73449 1.91632 8.19808 1.91632 8.48401 2.25105L16.2939 11.3939C16.4312 11.5547 16.5083 11.7727 16.5083 12C16.5083 12.2273 16.4312 12.4453 16.2939 12.6061L8.48401 21.7489C8.19808 22.0837 7.73449 22.0837 7.44856 21.7489Z"
                          fill="#646464"
                        />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <div className="text-center flex justify-center items-center h-[calc(100vh-156px)]">
              <Typography className="text-gray-700 font-medium" variant="h4">
                No Assets Found
              </Typography>
            </div>
          )
        ) : (
          <div className="mt-20 text-center">
            <Typography className="text-gray-700 font-medium" variant="h4">
              No Asset found
            </Typography>
          </div>
        )}
      </div>
    </AssetSliderStyle>
  );
}
