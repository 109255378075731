import React, { ReactNode } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, loginRequest } from "./authConfig";
import { ShowToast, ShowLoader } from "./components/Errors/Errors";
import { CustomAlert } from "./components/Alert/Alert";
import { SelectOptions } from "./components/Formik/CustomDropDown";
import Tag from "./components/Tags/Tags";
import store from "./store";

export const getActiveMenu = (path: string) => {
  switch (path) {
    case "portfolios":
      return "Portfolios";
    case "projects":
      return "Projects";
    case "assets":
      return "Assets";
    case "clients":
      return "Client Management";
    case "categories":
      return "Service Areas";
    default:
      return "Portfolios";
    case "links":
      return "Link Management";
    case "users":
      return "User Management";
  }
};

export const rolesGroup:any = {
  "Millipixels Interactive LLP": [
    "",
    "categories",
    "projects",
    "portfolios",
    "clients",
    "assets",
    "links",
    "users"
  ],
  "EchoShow-Admin": [
    "",
    "categories",
    "projects",
    "portfolios",
    "clients",
    "assets",
    "links",
    "users"
  ],
  "EchoShow-Marketing": [
    "",
    "links",
    "portfolios",
    "clients"
  ]
};

export const convertParamsToArr = ({ params }: any) => {
  if (params["*"] !== undefined && params["*"].length > 0) {
    const data = params["*"].split("/");
    for (const i in data) {
      params[i] = data[i];
    }
  }
  return params;
};

export const ShowPageLoader = () => {
  ShowLoader.fire();
};

export const HidePageLoader = () => {
  ShowLoader.close();
};

export const showToastAlert = (message: string, type?: number) => {
  type ToastType = "info" | "success" | "warning" | "error";
  let variant: ToastType;
  if (type === 1) {
    variant = "success";
  } else if (type === 2) {
    variant = "warning";
  } else if (type === 3) {
    variant = "info";
  } else {
    variant = "error";
  }

  ShowToast.fire({
    variant: variant,
    message: message,
  });
};

export const showConfirm = (props: any) => {
  CustomAlert.fire({
    title: props.title,
    message: props.message,
    type: props.type,
    confirmText: "Yes, I am sure",
    cancelText: "No, Cancel it",
    onConfirm: async () => {
      props.confirm();
    },
    onCancel: async () => {
      props.cancel();
    },
  });
};

export const selectChangeHandler = (
  newValue: SelectOptions | SelectOptions[] | null,
  props: any,
) => {
  let value;
  if (newValue !== null) {
    if (Array.isArray(newValue)) {
      value = newValue.map((option) => option.value);
    } else {
      value = newValue.value;
    }
  }

  props.formData[props.key] = value;
  props.setValues(props.formData);
};

export const codeEditorChangeHandler = (value: any, props: any) => {
  props.formData[props.key] = value;
  props.setValues(props.formData);
};

export const inputChangeHandler = (value: any, props: any) => {
  props.formData[props.key] = value;
  props.setValues(props.formData);
};

export const changeFileHandler = (value: any, props: any) => {
  let fileNames = [];
  if (value !== null) {
    fileNames = Array.from(value).map((file: any) => file.name);
  }
  props.formData[props.key] = value !== null ? fileNames : "";
  props.setValues(props.formData);
};

export interface Params {
  request?: object;
  params: any;
}

export const getToken = async () => {
  const state = store.getState();
  let token = state.msToken.token;
  if(!token){
    token = await generateToken();
  }
  return token;
};

export const getClientToken = async () => {
  const state = store.getState();
  return state.clientToken.token;
};

export const generateToken = async () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();
  const accounts = msalInstance.getAllAccounts();
  try {
    return await msalInstance
      .acquireTokenSilent({
        scopes: loginRequest.scopes,
        account: accounts[0],
      })
      .then((response) => {
        return response.accessToken;
      });
  } catch (error) {
    return getToken();
  }
};
export const getUserPhoto = async() => {
  const accessToken = await getToken();

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;
  headers.append("Authorization", bearer);

  const options = {
      method: "GET",
      headers: headers
  };

  try {
      const response = await fetch('https://graph.microsoft.com/v1.0/me/photo/$value', options);
      if (!response.ok) {
          throw new Error('Failed to fetch user photo');
      }

      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      return imageUrl;
  } catch (error) {
      console.error(error);
      throw error;
  }
}
export const logout = async () => {
  const msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();
  const accounts = msalInstance.getAllAccounts();
  await msalInstance.logoutRedirect({ account: accounts[0]});
};

export const tags = (tagstr: string) => {
  const tags = tagstr !== "" && tagstr !== null ? tagstr.split(",") : [];
  let htmltags = <ReactNode>[];
  const htmltagsar = [];
  for (const i in tags) {
    htmltags = Tag({
      type: "secondary",
      text: tags[i],
      key: i,
    });

    htmltagsar.push(htmltags);
  }
  return htmltagsar;
};

export interface callFetchProps {
  url: string;
  method: string;
  controller: any;
  body?: any;
  headers?: any;
  callback: (json: FetchData) => {};
  isReload?:boolean;
}

export interface FetchData {
  status?: any | undefined;
  data?: any | undefined;
  error?: any | undefined;
  message?: any | undefined;
  meta?: any | undefined;
}

export const callFetch = async ({
  url,
  method,
  body,
  headers,
  controller,
  callback,
}: callFetchProps) => {
  const token = await getToken();
  headers["Authorization"] = "Bearer " + token;
  return await fetch(url, {
    method: method,
    body: body,
    headers: headers,
    signal: controller.signal,
  })
    .then(async (res) => res.json())
    .then(async (json) => {
      return await callback(json);
    })
    .catch((e) => {
      if (e !== "TimeoutError: signal timed out") {
        return {status:"error","error":"abort"};
      } else {
        return e;
      }
    });
};

export const callClientFetch = async ({
  url,
  method,
  body,
  headers,
  controller,
  callback,
  isReload = true,
}: callFetchProps) => {
  const token = await getClientToken();
  headers["Authorization"] = "Bearer " + token;
  return await fetch(url, {
    method: method,
    body: body,
    headers: headers,
    signal: controller.signal,
  })
    .then(async (res) => res.json())
    .then(async (json) => {
      if(json["status"] === "error" && json["error"] === "token_expired" && isReload === true) {
        window.location.reload();
      }else if(json["status"] === "error" && json["error"] === "password" && isReload === true) {
        window.location.reload();
      }else if(json["status"] === "error" && json["error"] === "expired" && isReload === true) {
        window.location.reload();
      }else if(json["status"] === "error" && json["error"] === "revok" && isReload === true) {
        window.location.reload();
      }
      
      return await callback(json);
    })
    .catch((e) => {
      console.log(e);
      if (e !== "TimeoutError: signal timed out") {
        return {status:"error","error":"abort"};
      } else {
        return e;
      }
    });
};

export const unsetArray = (array: [], n: string | number | BigInteger) => {
  const newArray: [] = [];

  for (let i = 0; i < array.length; i++) {
    if (array[i] !== n) {
      newArray.push(array[i]);
    }
  }
  return newArray;
};

export const unsetAssocciativeArray = (
  array: [],
  key: string | number,
  n: string | number | BigInteger,
) => {
  const newArray: [] = [];

  for (let i = 0; i < array.length; i++) {
    if (array[i][key] !== n) {
      newArray.push(array[i]);
    }
  }
  return newArray;
};

export const updateAssocciativeArray = (
  array: any,
  key: string | number,
  n: string | number | BigInteger,
  newKey: string,
  value: string | number | boolean | undefined | null,
) => {
  const newArray: any = [];

  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === n) {
      array[i][newKey as keyof typeof array] = value;
    }
    newArray.push(array[i]);
  }
  return newArray;
};

export const pushArray = (array: any, arr: any) => {
  array.push(arr);
  return array;
};

export const in_array = (
  array: any,
  key: string | number,
  n: string | number | BigInteger,
) => {
  if (array === null || array === undefined) {
    return false;
  }
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === n) {
      return true;
    }
  }
  return false;
};

export const indexOf = (
  array: any,
  n: string | number | BigInteger,
) => {
  if (array === null || array === undefined) {
    return false;
  }
  for (let i = 0; i < array.length; i++) {
    if (array[i] === n) {
      return true;
    }
  }
  return false;
};

export const array_search = (
  array: any,
  key: string | number,
  n: string | number | BigInteger,
) => {
  if (array === null || array === undefined) {
    return false;
  }
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] === n) {
      return array[i];
    }
  }
  return false;
};

export const updateCounter = (
  counterData: any,
  type: string,
  value: number,
) => {
  counterData[type] += value;
  counterData["All"] += value;
  return counterData;
};

let auditLogController:any = "";
export async function fetchAuditLog(params:any) {
  let queryString = "";
  queryString = params[1] !== undefined ? "search=" + params[1] : "";
  queryString +=
  params["sort_by"] !== undefined && queryString !== "" ? "&" : "";
queryString +=
  params["sort_by"] !== undefined ? "sort_by=" + params["sort_by"] : "";
  queryString +=
    params["start_date"] !== undefined && params["start_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["start_date"] !== undefined && params["start_date"] !== null ? "start_date=" + params["start_date"] : "";
  queryString +=
    params["end_date"] !== undefined && params["end_date"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["end_date"] !== undefined && params["end_date"] !== null ? "end_date=" + params["end_date"] : "";
  queryString +=
    params["page"] !== undefined && params["page"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["page"] !== undefined && params["page"] !== null ? "page=" + params["page"] : "";

  if(auditLogController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await auditLogController.abort(reason);
  }

  auditLogController = new AbortController();

  return await callFetch({
    url: process.env.REACT_APP_API_URL + "audit_logs?" + queryString,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: auditLogController,
    callback: async (json) => {
      return json;
    },
  });
}

let requestController:any = "";
export async function fetchRequests(params:any) {
  let queryString = "";
  queryString +=
    params["searchInp"] !== undefined && params["searchInp"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["searchInp"] !== undefined && params["searchInp"] !== null ? "search=" + params["searchInp"] : "";
  queryString +=
    params["page"] !== undefined && params["page"] !== null && queryString !== "" ? "&" : "";
  queryString +=
    params["page"] !== undefined && params["page"] !== null ? "page=" + params["page"] : "";

  if(requestController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await requestController.abort(reason);
  }

  requestController = new AbortController();

  return await callFetch({
    url: process.env.REACT_APP_API_URL + "request?" + queryString,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: requestController,
    callback: async (json) => {
      return json;
    },
  });
}

export const updateProjectStatus = async (
  id: number,
  status: any,
  reason: string,
  setIsFromSubmiting:any,
  loadData: any,
  close: any,
) => {
  setIsFromSubmiting(true);
  const controller = new AbortController();

  const url = process.env.REACT_APP_API_URL + "request/" + id;

  return await callFetch({
    url: url,
    method: "PUT",
    body: JSON.stringify({ status, reason }),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      setIsFromSubmiting(false);

      if (json.status == "success") {
        showToastAlert(json.message, 1);
        loadData(id,status,reason);
        close();
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          showToastAlert(json.validation_errors[name][0]);
        }
      } else {
        showToastAlert(json.message);
      }

      return json;
    },
  });
};

let authenticateController:any = "";

export async function authenticateToken() {
  if(authenticateController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await authenticateController.abort(reason);
  }

  authenticateController = new AbortController();

  return await callClientFetch({
    url: process.env.REACT_APP_API_URL + "clientView/authenticate",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: authenticateController,
    callback: async (json) => {
      return json;
    },
    isReload:false
  });
}

let doLoginController:any = "";

export async function dologin(linkToken:string) {
  if(doLoginController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await doLoginController.abort(reason);
  }

  doLoginController = new AbortController();

  const token = linkToken;
  const response = await fetch('https://api.ipify.org?format=json', {
    method: "GET"
  }).then(async (res) => res.json());
  const headers = {
    "Content-Type": "application/json",
    "Authorization": "Bearer " + token ,
    'X-Forwarded-For': response.ip
  };

  return await fetch(process.env.REACT_APP_API_URL + "clientView/dologin", {
    method: "GET",
    headers: headers,
    signal: doLoginController.signal,
  })
  .then(async (res) => res.json())
  .then(async (json) => {
    return json;
  })
  .catch((e) => {
    if (e !== "TimeoutError: signal timed out") {
      return {status:"error","error":"abort"};
    } else {
      return e;
    }
  });
}
