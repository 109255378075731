import React, { useState, useRef } from "react";
import Icons from "../Icons/Icon";

interface CustomTagProps {
  // is_new: boolean;
  // callback: any;
  // onRemove: any;
  tags: [];
}

const InlineTag: React.FC<CustomTagProps> = ({
  tags,
  // is_new,
  // callback,
  // onRemove,
}) => {
  const [showTagField, setTagField] = useState(false);
  const TagInput = useRef<HTMLInputElement>(null);

  const toggleTageFiled = () => {
    setTagField(!showTagField);
    setTimeout(() => {
      if (!showTagField === true) {
        TagInput.current?.focus();
      }
    }, 300);
  };

  return (
    <div className="w-full mb-2 inline-block align-middle">
      {tags.map((item: any, idx: any) => (

<span key={idx} className="inline-block px-2 py-1.5 rounded-md bg-gray-400 text-400 text-gray-300 font-medium me-2 mb-2">
<div className="flex justify-start items-center gap-1.5">
  <label className="m-0 inline-block">{item}</label>
  {/* <button>
    <Icons
      name="crossNew"
      variant="stroke"
      size={9}
      className=""
    />
  </button> */}
</div>
</span>
        // <li
        //   key={idx}
        //   className="text-200 font-medium text-white bg-gray-700 px-2.5 py-1 rounded-full inline-block mr-2.5 align-middle mb-1"
        // >
        //   <div className="flex justify-center items-center">
        //     <span className="max-w-[4rem] truncate">{item}</span>
        //     {/* <span
        //       className="flex ms-1.5 cursor-pointer"
        //       onClick={() => {
        //         onRemove(item, tags);
        //       }}
        //     >
        //       <svg
        //         className="w-2.5 h-2.5"
        //         width="24"
        //         height="24"
        //         viewBox="0 0 24 24"
        //         fill="none"
        //         xmlns="http://www.w3.org/2000/svg"
        //       >
        //         <path
        //           fillRule="evenodd"
        //           clipRule="evenodd"
        //           d="M20.7811 4.28033C21.074 3.98744 21.074 3.51256 20.7811 3.21967C20.4882 2.92678 20.0134 2.92678 19.7205 3.21967L12.0004 10.9397L4.28033 3.21967C3.98744 2.92678 3.51256 2.92678 3.21967 3.21967C2.92678 3.51256 2.92678 3.98744 3.21967 4.28033L10.9397 12.0004L3.21967 19.7205C2.92678 20.0134 2.92678 20.4882 3.21967 20.7811C3.51256 21.074 3.98744 21.074 4.28033 20.7811L12.0004 13.0611L19.7205 20.7811C20.0134 21.074 20.4882 21.074 20.7811 20.7811C21.074 20.4882 21.074 20.0134 20.7811 19.7205L13.0611 12.0004L20.7811 4.28033Z"
        //           fill="#fff"
        //         />
        //       </svg>
        //     </span> */}
        //   </div>
        // </li>
      ))}
      {/* {is_new ? (
        <>
          <li
            className={`text-200 font-bold text-gray-700 pe-1 border-b inline-block mr-2.5 align-middle ${
              showTagField ? "hidden" : ""
            }`}
          >
            <button
              className="flex justify-center items-center"
              onClick={toggleTageFiled}
            >
              <Icons name="plus" variant="stroke" size={14} />
              <span className="ms-0.5">Add New Tag</span>
            </button>
          </li>
          <li
            className={`text-200 font-bold text-gray-700 pe-1 inline-block mr-2.5 align-middle ${
              !showTagField ? "hidden" : ""
            }`}
          >
            <div className="flex justify-start items-center gap-2.5">
            <input
              type="text"
              onKeyUp={(event) => {
                if (event.key === "Enter") {
                  callback(TagInput.current?.value, TagInput.current);
                }
                if (event.key === "Escape") {
                  setTagField(false);
                }
              }}
              className="outline-none text-200 w-full bg-white border border-gray-100 px-3 py-2 font-normal rounded-md text-gray-300 placeholder:text-gray-700 focus:border-secondary-100 focus:shadow-200 disabled:bg-gray-200 read-only:!shadow-0 read-only:!border-gray-100"
              ref={TagInput}
              autoFocus
              placeholder="Type & enter"
            />
            <span
              className="border-b color-red cursor-pointer mr-2.5 text-red-500"
              onClick={toggleTageFiled}
            >
              Cancel
            </span>
            </div>
          </li>
        </>
      ) : (
        ""
      )} */}
    </div>
  );
};

export default InlineTag;
