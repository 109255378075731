import React, { useState, useEffect } from "react";
import { FetchData, pushArray } from "../../CommonFunctions";
import {
  fetchPortfolioAssets,
  fetchAssetFilters,
  updateFavoriteStatus,
} from "./ClientViewFunction";
import Typography from "../../components/Typography/Typography";
import SingleProject from "./SingleProject";
import SingleProjectLoader from "./SingleProjectLoader";
import LogoWhite from "../../images/logoWhite.svg";
import { BorderLessDropdown } from "../../components/DropDown/BorderLessDropdown";
import { SingleProjectsFeatured } from "./SingleProjectsFeatured";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Icons from "../../components/Icons/Icon";
import { useSelector } from "react-redux";
import CustomLoader from "../../components/Loader/CustomLoader";

export const MasonryBox = styled.div``;

const ViewPortfolio = () => {
  const paramsData: any = [];
  paramsData["category"] = "0";
  paramsData["search"] = "";
  paramsData["page"] = 1;

  const clientToken = useSelector((state: any) => state.clientToken);
  const Portfolio = clientToken.portfolio;

  const [isActive, setActiveTab] = useState<string>(paramsData["category"]);
  const [isLoadingFilter, setIsLoadingFilter] = useState<boolean>(true);
  const [categories, setCategories] = useState<FetchData>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadMore, setIsLoadMore] = useState<Boolean>(false);
  const [assetsData, setAssetsData] = useState<FetchData>();
  const [searchInp, setSearchInp] = useState<string | null>(null);
  const [params, setParams] = useState(paramsData);
  const navigate = useNavigate();

  useEffect(() => {
    if (assetsData !== undefined) {
      setIsLoading(false);
    }

    setIsLoadMore(false);
  }, [assetsData]);

  useEffect(() => {
    if (categories !== undefined) {
      setIsLoadingFilter(false);
    }
  }, [categories]);

  useEffect(() => {
    if (isLoadingFilter === true) {
      loadDataCategory();
    }
  }, [isLoadingFilter]);

  useEffect(() => {
    setIsLoading(true);
    setParams({
      ...params,
      category: isActive,
      page: 1,
    });
  }, [isActive]);

  useEffect(() => {
    setIsLoadMore(true);
    loadData();
  }, [params]);

  const loadData = async () => {
    const data: FetchData = await fetchPortfolioAssets({ params });

    if (data.status === "error" && data.error === "abort") {
      return false;
    }

    if (
      assetsData !== undefined &&
      params["page"] > 1 &&
      data.status === "success"
    ) {
      let log = assetsData["data"];
      for (const i in data["data"]) {
        log = pushArray(log, data["data"][i]);
      }
      setAssetsData({
        ...assetsData,
        data: log,
      });
    } else if (params["page"] === 1) {
      setAssetsData(data);
    }
  };

  const loadDataCategory = async () => {
    const data: FetchData = await fetchAssetFilters();
    if (data.status === "error" && data.error === "abort") {
      return false;
    }
    setCategories(data);
  };

  useEffect(() => {
    searchPortfolios();
  }, [searchInp]);

  const setSearchInpData = (e: any) => {
    setSearchInp(e.target.value);
  };

  const searchPortfolios = async () => {
    if (searchInp != null && (searchInp.length >= 1 || searchInp.length <= 0)) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        search: searchInp,
      });
    }
  };

  const updateData = (assets: any) => {
    setAssetsData({
      ...assetsData,
      data: assets,
    });
  };

  const updateFavorite = async (id: BigInteger, is_favorite: number) => {
    updateFavoriteStatus({
      project_id: id,
      oldProjects: assetsData !== undefined ? assetsData["data"] : [],
      updateData: updateData,
      is_favorite: is_favorite,
    });
  };

  const events = {
    updateFavorite: updateFavorite,
  };

  return (
    <div className="min-h-screen w-full bg-gray-400">
      <div className="h-[300px] w-full bg-bgviewportClient bg-no-repeat px-12 py-9 max-lg:h-auto max-md:py-6 max-lg:px-5 md:bg-[length:101%_101%] bg-auto bg-center">
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center gap-2.5">
            <div>
              <img src={LogoWhite} alt={LogoWhite} className="max-w-full" />
            </div>
            <Typography
              variant="h5"
              className="text-white font-bold opacity-30 mt-1 max-sm:hidden"
            >
              {Portfolio.portfolio_title}
            </Typography>
          </div>
          <div className="flex gap-4">
            {clientToken.expire_date ? (
              <span className="flex justify-end items-center text-white text-200 font-medium max-sm:hidden">
                {/* <Icons variant="bold" name="calendar" size={20} color="#4A154B" /> */}
                Expires On:&nbsp;
                <span className="font-semibold">{clientToken.expire_date}</span>
              </span>
            ) : (
              ""
            )}
            <button
              className="flex justify-start items-center px-3 py-2.5 max-sm:p-2 bg-white rounded-full text-black text-200 font-bold shadow-400"
              onClick={() => {
                navigate("/client/contactus");
              }}
            >
              <span className="me-1 max-sm:me-0">
                <svg
                  width="18"
                  height="16"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="max-sm:w-[16px] max-sm:h-[14px]"
                >
                  <path
                    d="M14.772 0.836914H5.1955C2.32254 0.836914 0.407227 2.3063 0.407227 5.73487V12.592C0.407227 16.0206 2.32254 17.49 5.1955 17.49H14.772C17.645 17.49 19.5603 16.0206 19.5603 12.592V5.73487C19.5603 2.3063 17.645 0.836914 14.772 0.836914ZM15.2221 6.80263L12.2247 9.25161C11.5926 9.77079 10.7882 10.0255 9.98377 10.0255C9.17934 10.0255 8.36533 9.77079 7.74286 9.25161L4.7454 6.80263C4.43895 6.54794 4.39107 6.07773 4.63048 5.76426C4.87947 5.45079 5.32957 5.39202 5.63602 5.64671L8.63348 8.09569C9.36129 8.69324 10.5967 8.69324 11.3245 8.09569L14.3219 5.64671C14.6284 5.39202 15.0881 5.441 15.3275 5.76426C15.5765 6.07773 15.5286 6.54794 15.2221 6.80263Z"
                    fill="#4A154B"
                  />
                </svg>
              </span>
              <span className="max-sm:hidden">Get in Touch</span>
            </button>
          </div>
        </div>
        <div className="flex justify-between items-center mt-2 sm:hidden">
          <Typography
            variant="h6"
            className="text-white font-semibold opacity-30 max-[340px]:text-200"
          >
            {Portfolio.portfolio_title}
          </Typography>
          {clientToken.expire_date ? (
            <span className="flex justify-end items-center text-white text-200 font-medium shadow-400">
              {/* <Icons variant="bold" name="calendar" size={20} color="#4A154B" /> */}
              Expires On:&nbsp;
              <span className="font-semibold">{clientToken.expire_date}</span>
            </span>
          ) : (
            ""
          )}
        </div>
        <div className="flex justify-center items-center mt-9">
          <div className="max-w-[43.125rem] text-center">
            <div className="text-white text-1000 font-bold max-md:text-800">
              Thank you for considering Clarient!
            </div>
            <div className="text-white text-400 font-normal px-[50px] mt-2 leading-[21px] max-lg:px-0">
              From some of the world’s largest companies to startups, we work
              with our clients to design, develop and deliver next-generation
              products and digital experiences! We’re glad to present a
              customized version of our portfolio for your review.
            </div>
          </div>
        </div>
      </div>
      <div className="w-full relative">
        {/* <div className="flex justify-start items-center gap-1 absolute left-12 top-1">
          <div className="text-200 font-normal text-black -mt-0.5">View by</div>
          <div className="w-auto">
            <BorderLessDropdown
              onChange={function noRefCheck() {}}
              options={[
                {
                  label: "Service Areas",
                  value: "1",
                },
                {
                  label: "Option 2",
                  value: "2",
                },
                {
                  label: "Option 3",
                  value: "3",
                },
              ]}
              isSearchable={false}
              defaultValue={{ label: "Service Areas", value: "1" }}
            />
          </div>
        </div> */}
        <div className="">
          <div className="text-200 font-normal text-black">
            {isLoadingFilter ? (
              <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
              <CustomLoader/>
            </div>
              // <div className="flex text-gray-300 text-200 bg-white h-11 pt-4 justify-center uppercase">
              //   {Array.from({ length: 6 }).map((_, idx) => (
              //     <div key={idx} className="animate-pulse">
              //       <div className="flex-1 space-y-6">
              //         <div className="h-2 w-20 bg-gray-100 rounded" />
              //       </div>
              //     </div>
              //   ))}
              // </div>
            ) : categories !== undefined && categories.status === "success" ? (
              <div className="flex text-gray-300 text-200 bg-white h-11 pt-4 px-4 justify-center uppercase gap-5 sticky left-0 top-0 z-[20] w-full whitespace-nowrap overflow-x-auto max-sm:justify-start">
                {Object.keys(categories["data"]["categories"]).map(
                  (item: any, idx: any) => (
                    <div
                      key={idx}
                      className={`cursor-pointer font-medium tab pb-6
                      ${
                        isActive === item
                          ? "active text-black border-secondary-100 !font-bold !border-b-[3px]"
                          : ""
                      }`}
                      onClick={() => {
                        setActiveTab(item);
                      }}
                    >
                      {categories["data"]["categories"][item]}
                    </div>
                  )
                )}
              </div>
            ) : (
              ""
            )}
            <div className="pt-0 ">
              <div className="tab-pane active">
                {isLoading === true ? (
                  <div className="bg-gray-1000 w-full px-12 pt-9 pb-0 max-sm:pb-6 overflow-auto max-lg:px-5 grid grid-cols-4 max-xl:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 gap-7">
                    {Array.from({ length: 8 }).map((_, idx) => (
                      <SingleProjectLoader key={idx} />
                    ))}
                  </div>
                ) : assetsData !== undefined &&
                  assetsData["status"] === "success" ? (
                  <div className="bg-gray-1000 w-full px-12 pt-9 pb-0 max-sm:pb-6 overflow-auto max-lg:px-5 grid grid-cols-4 max-xl:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 gap-7">
                    
                    {assetsData.data.map((item: any, idx: any) =>
                      item.status === 1 ? (
                        <div key={idx} className="box flex flex-col">
                          {item.is_featured ? (
                            <SingleProjectsFeatured
                              asset={item}
                              events={events}
                            />
                          ) : (
                            <SingleProject asset={item} events={events} />
                          )}
                        </div>
                      ) : (
                        ""
                      )
                    )}
                    {isLoadMore ? (
                      <div className="flex justify-center items-center mt-2">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="duration-200 animate-pulse-3"
                        >
                          <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                        </svg>
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="duration-300 animate-pulse-2"
                        >
                          <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                        </svg>
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="duration-400 animate-pulse"
                        >
                          <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                        </svg>
                      </div>
                    ) : params["page"] < assetsData.meta.last_page ? (
                      <div className="fixed bottom-8 left-1/2 -translate-x-1/2">
                        <button
                          className="bg-white py-2 px-4 text-400 text-black font-bold rounded-full border border-pastel-800"
                          onClick={() => {
                            setParams({
                              ...params,
                              page: params["page"] + 1,
                            });
                          }}
                        >
                          Show More
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : assetsData !== undefined &&
                  assetsData["status"] === "error" &&
                  assetsData !== undefined &&
                  assetsData["error"] === 404 ? (
                  <div className="mt-20 text-center">
                    <Typography
                      className="text-gray-700 font-medium"
                      variant="h4"
                    >
                      No project found
                    </Typography>
                  </div>
                ) : (
                  <div className="mt-20 text-center">
                    <Typography variant="h5">Something went wrong!</Typography>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPortfolio;
