import React, { useState, useEffect, useRef } from "react";
import {
  convertParamsToArr,
  showToastAlert,
  FetchData,
  pushArray,
} from "../../CommonFunctions";
import { useParams, useLoaderData } from "react-router-dom";
import Typography from "../../components/Typography/Typography";
import { Dropdown } from "../../components/DropDown/DropDown";
import AddFiles from "./AddFiles";
import AddLinks from "./AddLinks";
import ActionDropdown from "../../components/ActionDropdown/ActionDropdownSm";
import { CustomDateTimePicker } from "../../components/DateTimePicker/DatePicker";
import moment from "moment";
import {
  fetchRepository,
  fetchAsset,
  confirmDeleteFiles,
} from "./AssetsFunction";
import SearchAssets from "./SearchAssets";
import Checkbox from "../../components/CheckBox/CheckBox";
import { PaginationComponent } from "../../components/Pagination/Pagination";
import _ from "lodash";
import CustomLoader from "../../components/Loader/CustomLoader";
import AssetsEmpty from "./AssetsEmpty";
import RadioButton from "../../components/Radio button/RadioButton";

const Assets = () => {
  let paramsData = useParams();
  paramsData = convertParamsToArr({ params: paramsData });

  paramsData["tab"] = paramsData[0] !== undefined ? paramsData[0] : "All";
  paramsData["group_by"] = "all";
  paramsData["sort_by"] = ["id", "DESC"];
  paramsData["start_date"] = null;
  paramsData["end_date"] = null;
  paramsData["page"] = 1;
  const search = paramsData[1] !== undefined ? paramsData[1] : "";

  const [asset, setAsset] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [searchInp, setSearchInp] = useState(search);
  const [files, setFiles] = useState(useLoaderData());
  const [params, setParams] = useState(paramsData);
  const [isActive, setIsActive] = useState(paramsData["tab"]);
  const [isAddActive, setIsAddActive] = useState("Upload Asset");
  const [startDate, setStartDate] = useState(paramsData["start_date"]);
  const [endDate, setEndDate] = useState(paramsData["end_date"]);
  const [minDate, setMinDate] = useState<string | null>(null);
  const [isMinDateError, setIsMinDateError] = useState<Boolean>(false);
  const [counter, setCounter] = useState({
    All: 0,
    Documents: 0,
    Images: 0,
    Videos: 0,
    Links: 0,
  });

  let fetchData: FetchData;

  useEffect(() => {
    setIsLoadingList(false);

  }, [files]);

  useEffect(() => {
    setIsLoading(false);
  }, [asset]);

  useEffect(() => {
    searchFiles();
  }, [searchInp]);

  useEffect(() => {
    loadData();
  }, [params]);

  useEffect(() => {
    setIsLoadingList(true);
    setParams({
      ...params,
      page: 1,
      tab: isActive,
    });
  }, [isActive]);

 

  useEffect(() => {
    setMinDate(startDate);
    filterByDate();
  }, [startDate]);

  useEffect(() => {
    filterByDate();
  }, [endDate]);

  const loadData = async () => {
    setIsLoadingList(true);
    fetchData = await fetchRepository({ params });

    if (fetchData.status === "error" && fetchData.error === "abort") {
      setIsLoadingList(false);
      return false;
    }

    if (
      fetchData !== undefined &&
      fetchData["status"] === "success"
    ) {
      setFiles(fetchData);
        setCounter(fetchData.data.counter);
    }
    setIsLoadingList(false);

  };

  const setActiveTab = (tab: string) => {
    setIsActive(tab);
  };

  const setAddActiveTab = (tab: string) => {
    setAsset(null);
    setIsAddActive(tab);
  };

  const deleteFiles = async (id: BigInteger) => {
    await confirmDeleteFiles(id, loadData);
  };

  const editAsste = async (id: number, type: string) => {
    setIsLoading(true);

    if (type === "Links") {
      setIsAddActive("Upload Link");
    } else {
      setIsAddActive("Upload Asset");
    }

    fetchData = await fetchAsset(id);

    if (fetchData.status === "success") {
      setAsset(fetchData.data);
    } else {
      showToastAlert(fetchData.message);
    }
  };
  const setSearchInpData = _.debounce((e: any)=>setSearchInp(e.target.value), 600);
  // const setSearchInpData = (e: any) => {
  //   setSearchInp(e.target.value);
  // };

  const searchFiles = async () => {

    if (searchInp != null && (searchInp.length >= 1 || searchInp.length <= 0)) {
      setIsLoadingList(true);
      setParams({
        ...params,
        page: 1,
        1: searchInp,
      });
    }
  };

  const filterByDate = () => {
    setIsMinDateError(false);

    if (startDate === null && endDate === null) {
      setIsLoadingList(true);
      setParams({
        ...params,
        page: 1,
        start_date: startDate,
        end_date: endDate,
      });
    }

    if (startDate === null || endDate === null) {
      return false;
    }

    const dateLimit = moment(startDate);
    const now = moment(endDate);

    if (now.isBefore(dateLimit)) {
      setIsMinDateError(true);
    } else if (startDate !== null && endDate !== null) {
      setIsLoadingList(true);
      setParams({
        ...params,
        page: 1,
        start_date: startDate,
        end_date: endDate,
      });
    }
  };
  const setPagination=(page: number)=>{

    setParams({
      ...params,
      page:page,
    });
  };
  const events = {
    deleteEvent: deleteFiles,
    editAsste: editAsste,
    setSearchInpData: setSearchInpData,
    searchFiles: searchFiles,
    setActiveTab: setActiveTab,
    setParams: setParams,
    setStartDate: setStartDate,
    setEndDate: setEndDate,
    setIsLoadingList: setIsLoadingList,
  };

  return (
    <div className="grid grid-cols-2 border border-gray-400 rounded-md">
      <div className="border-r border-gray-400 bg-white p-4 pe-0 rounded-tl-md rounded-bl-md">
        {/* <Typography className="text-seconday-200 font-bold" variant="h3">
          Assets
        </Typography> */}
        <div className="">
          <AddAssets
            params={params}
            asset={asset}
            setAsset={setAsset}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            loadData={loadData}
            isAddActive={isAddActive}
            setAddActiveTab={setAddActiveTab}
          />
        </div>
      </div>
      <div className="">
        <AssetSection
          params={params}
          setParams={setParams}
          searchInp={searchInp}
          events={events}
          isLoading={isLoadingList}
          files={files}
          counter={counter}
          startDate={startDate}
          endDate={endDate}
          minDate={minDate}
          isMinDateError={isMinDateError}
          totalData={files?.data.length}
          meta={files?.meta}
          setPagination={setPagination}
        />
      </div>
    </div>
  );
};

export const AddAssets = (props: any) => {
  return (
    <div>
      <div className="flex gap-5 text-gray-300 text-400">
        {["Upload Asset", "Upload Link"].map((item: any, idx: any) => (
          <div
            key={idx}
            className={`cursor-pointer font-medium tab pb-3 
              ${
                props.isAddActive === item
                  ? "active !font-bold text-gray-300 border-primary-100 border-b-2"
                  : ""
              }`}
            onClick={() => {
              props.setAddActiveTab(item);
            }}
          >
            {item}
          </div>
        ))}
      </div>
      <div className="pt-3">
        <div className="tab-pane active">
          <div className="h-[calc(100vh-12rem)] overflow-auto pe-4">
            {props.isAddActive === "Upload Asset" ? (
              <AddFiles
                asset={props.asset}
                setAsset={props.setAsset}
                setIsLoading={props.setIsLoading}
                params={props.params}
                loadData={props.loadData}
                isLoading={props.isLoading}
              />
            ) : (
              <AddLinks
                asset={props.asset}
                setAsset={props.setAsset}
                setIsLoading={props.setIsLoading}
                params={props.params}
                loadData={props.loadData}
                isLoading={props.isLoading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const AssetSection = (prop: any) => {
  const isLoading = prop.isLoading;
  const isLoadMore = prop.isLoadMore;
  const setActiveTab = prop.events.setActiveTab;
  const setSearchInpData = prop.events.setSearchInpData;
  const files = prop.files;
  const isActive = prop.params["tab"];
  const counter = prop.counter;
  const searchInp = prop.searchInp;
  const params = prop.params;
  const setParams = prop.setParams;
  const startDate = prop.startDate;
  const endDate = prop.endDate;
  const minDate = prop.minDate;
  const isMinDateError = prop.isMinDateError;

  const [isShowFilters, setIsShowFilters] = useState(false);
  const [isClearFilter, setIsClearFilter] = useState<Boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [searchByRecent, setSearchByRecent] = useState<string>("recent");
  const [groupByProject, setGroupByProject] = useState<string>('all');

  useEffect(() => {

    setParams({
      ...params,
      page:1,
      sort_by: searchByRecent === "recent" ? ["created_at", "DESC"] : ["created_at", "ASC"]
    })
  }, [searchByRecent]);

  useEffect(() => {

    setParams({
      ...params,
      page: 1,
      group_by: groupByProject,
    });
  }, [groupByProject]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setIsClearFilter(false);
  }, [startDate, endDate]);

  useEffect(() => {
    if (isClearFilter) {
      setIsClearFilter(false);
    }
  }, [isClearFilter]);

  const clearFilter = () => {
    setIsClearFilter(true);
    prop.events.setStartDate(null);
    prop.events.setEndDate(null);
    setSearchByRecent("recent");
    setGroupByProject('all');
    prop.events.setParams({
      ...params,
      tab: "All",
      group_by: "all",
      sort_by: ["id", "DESC"],
      page: 1,
      start_date: null,
      end_date: null
    })
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsShowFilters(false);
    }
  };

  return (
    <>
      <div className="flex justify-between items-center gap-4 p-4 rounded-tr-md border-b border-gray-400">
        {/* <Typography className="text-seconday-200 font-bold" variant="h3">
          All Assets
        </Typography> */}
        <div className="flex justify-start items-center text-gray-300 font-normal text-400">
          <PaginationComponent handlePagination={prop.setPagination} page={Number(prop?.meta?.current_page??0)} totalPages={Number(prop?.meta?.last_page)}/>
        </div>
        <div className="flex justify-end items-center gap-4">
          <div className="w-[12rem]">
            <SearchAssets setSearchInpData={setSearchInpData} />
          </div>
          <div className="relative">
            <button
              className="flex justify-start items-center text-400 font-bold text-secondary-100 border border-gray-100 rounded-md py-[9px] px-4 bg-white"
              onClick={() => {
                setIsShowFilters(!isShowFilters);
              }}
            >
              <svg
                width="18"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  className={` ${
                    isShowFilters ? "stroke-primary-100" : "stroke-gray-300"
                  }`}
                  d="M5.40002 2.09998H18.6C19.7 2.09998 20.6 2.99998 20.6 4.09998V6.29998C20.6 
                          7.09998 20.1 8.09998 19.6 8.59998L15.3 12.4C14.7 12.9 14.3 13.9 14.3 14.7V19C14.3 
                          19.6 13.9 20.4 13.4 20.7L12 21.6C10.7 22.4 8.90002 21.5 8.90002 19.9V14.6C8.90002 
                          13.9 8.50002 13 8.10002 12.5L4.30002 8.49998C3.80002 7.99998 3.40002 7.09998 
                          3.40002 6.49998V4.19998C3.40002 2.99998 4.30002 2.09998 5.40002 2.09998Z"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  className={` ${
                    isShowFilters ? "stroke-primary-100" : "stroke-gray-300"
                  }`}
                  d="M10.93 2.09998L6 9.99998"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span
                className={` ${
                  isShowFilters
                    ? "text-primary-100 ms-1"
                    : "stroke-secondary-100 ms-1"
                }`}
              >
                Filter
              </span>
            </button>
            {isShowFilters ? (
              <div
                ref={dropdownRef}
                className={`absolute top-[40px] right-[0px] h-[27rem] w-[15rem] rounded-md bg-white border py-5 ps-5 pe-2 border-gray-100 shadow-100 overflow-auto z-10`}
              >
                <div className={`w-full`}>
                  <div className="mb-2 pe-5">
                    <div className="flex gap-5 justify-between items-center">
                      <div className="text-200 font-bold text-gray-300">
                        FILTER
                      </div>
                      <button
                        className="text-200 font-medium text-primary-100"
                        onClick={() => {
                          clearFilter();
                        }}
                      >
                        Clear All
                      </button>
                    </div>
                  </div>
                  <div className="h-full pe-3">
                    <>
                      {!isClearFilter ? (
                        <>
                          <div className="mt-3">
                            <div className="text-gray-300 font-semibold text-400 inline-block">
                              Date
                            </div>
                            <div className="small-datepicker">
                              <label className="text-gray-500 font-normal text-200 mb-1 inline-block">
                                Start Date
                              </label>
                              <CustomDateTimePicker
                                dateFormat="YYYY-MM-DD"
                                size="sm"
                                maxDate={moment(new Date()).format(
                                  "yyyy-MM-DD"
                                )}
                                onChange={(
                                  date: Date | null,
                                  value: string | null
                                ) => {
                                  prop.events.setStartDate(value);
                                }}
                                selectedDate={startDate}
                              />
                              {startDate === null && endDate !== null ? (
                                <Typography
                                  className="text-primary-100 font-normal"
                                  variant="p"
                                  size="sm"
                                >
                                  Please select start date
                                </Typography>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="mt-1 small-datepicker">
                              <label className="text-gray-500 font-medium text-200 mb-1 inline-block">
                                End Date
                              </label>
                              <CustomDateTimePicker
                                dateFormat="YYYY-MM-DD"
                                size="sm"
                                onChange={(
                                  date: Date | null,
                                  value: string | null
                                ) => {
                                  prop.events.setEndDate(value);
                                }}
                                selectedDate={endDate}
                                minDate={minDate !== null ? minDate : null}
                                maxDate={moment(new Date()).format(
                                  "yyyy-MM-DD"
                                )}
                              />
                              {startDate !== null && endDate === null ? (
                                <Typography
                                  className="text-primary-100 font-normal"
                                  variant="p"
                                  size="sm"
                                >
                                  Please select end date
                                </Typography>
                              ) : isMinDateError ? (
                                <Typography
                                  className="text-primary-100 font-normal"
                                  variant="p"
                                  size="sm"
                                >
                                  End date can not be less than start date
                                </Typography>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="mt-3">
                            <div className="text-gray-300 font-semibold text-400 inline-block">
                              Sort By
                            </div>
                            <div className="flex justify-start items-center gap-2 mt-3">
                              {/* <Checkbox
                                checked={searchByRecent}
                                onChange={(value) => setSearchByRecent(value.target.checked)}
                                size="sm"
                              /> */}
                              <RadioButton
                                selectedValue={searchByRecent}
                                name="searchByRecent"
                                onChange={(value) => setSearchByRecent(value)}
                                size="sm"
                                value="recent"
                              />
                              <label className="text-gray-500 font-normal text-200">
                                Recent
                              </label>
                            </div>
                            <div className="flex justify-start items-center gap-2 mt-3">
                              {/* <Checkbox
                                checked={!searchByRecent}
                                onChange={(value) => setSearchByRecent(!value.target.checked)}
                                size="sm"
                              /> */}
                               <RadioButton
                                selectedValue={searchByRecent} 
                                name="searchByOldest"
                                onChange={(value) => setSearchByRecent(value)}
                                size="sm"
                                value="oldest"
                              />
                              <label className="text-gray-500 font-normal text-200">
                                Oldest
                              </label>
                            </div>
                          </div>
                          <div className="mt-3">
                            <div className="text-gray-300 font-semibold text-400 inline-block">
                              Assets
                            </div>
                            <div className="flex justify-start items-center gap-2 mt-3">
                              <Checkbox
                                checked={groupByProject === 'all'}
                                onChange={(value) => value.target.checked ? setGroupByProject('all') : setGroupByProject('all')}
                                size="sm"
                              />
                              <label className="text-gray-500 font-normal text-200">
                                All Assets 
                              </label>
                            </div>
                            <div className="flex justify-start items-center gap-2 mt-3">
                              <Checkbox
                                checked={groupByProject === 'unassigned'}
                                onChange={(value) => value.target.checked ? setGroupByProject('unassigned') : setGroupByProject('all')}
                                size="sm"
                              />
                              <label className="text-gray-500 font-normal text-200">
                                Unassigned Assets 
                              </label>
                            </div>
                            <div className="flex justify-start items-center gap-2 mt-3">
                              <Checkbox
                                checked={groupByProject === 'project'}
                                onChange={(value) => value.target.checked ? setGroupByProject('project') : setGroupByProject('all')}
                                size="sm"
                              />
                              <label className="text-gray-500 font-normal text-200">
                                Group by projects
                              </label>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="mt-4 ps-4">
        <div className="mb-5 flex gap-5 text-gray-300 text-400">
          {Object.keys(counter).map((item: any, idx: any) => (
            <div
              key={idx}
              className={`cursor-pointer font-medium tab pb-3 
                ${
                  isActive === item
                    ? "active !font-bold text-gray-300 border-primary-100 border-b-2"
                    : ""
                }`}
              onClick={() => {
                setActiveTab(item);
              }}
            >
              {item}({counter[item as keyof typeof counter]})
            </div>
          ))}
        </div>
        <div className="">
          <div className="tab-pane active">
            <div className="relative">
              <div className="overflow-auto h-[calc(100vh-16.5rem)] pe-4">

              {isLoading || files === undefined ? (
                  <div className="flex justify-center items-center w-full h-[calc(100%-4rem)]">
                  <CustomLoader/>
                </div>
                  // <Assetloader />
                ) : files !== undefined && files["status"] === "success"  ? (
                  files["data"]["group_by"] === "project" ? (
                    <div>
                      {files.data.asset.length>0
                        ?"":
                        <div className="mt-20 text-center">
                          {/* <Typography variant="h5">No assets found</Typography> */}
                          <AssetsEmpty/>
                        </div>
                      }
                      {Object.keys(files.data.asset).map(
                        (item: any, idx: any) => (
                          <div key={idx}>
                            <Typography
                              variant="h6"
                              className={`font-semibold text-gray-300 ${
                                idx !== 0 ? "my-6" : "mb-6"
                              }`}
                            >
                              {files.data.asset[item].group}
                            </Typography>
                            <AssetItem
                              events={prop.events}
                              files={files.data.asset[item].asset}
                              group={files.data.asset[item].group}
                              meta={files.meta}
                              params={params}
                              isLoadMore={isLoadMore}
                            />
                          </div>
                        )
                      )}
                      {/* <div className="flex w-100 justify-center text-center items-center align-middle mt-5">
                          <div className="flex mt-2">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="duration-200 animate-pulse-3"
                            >
                              <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                            </svg>
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="duration-300 animate-pulse-2"
                            >
                              <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                            </svg>
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="duration-400 animate-pulse"
                            >
                              <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                            </svg>
                          </div>
                      </div> */}
                    </div>
                  ) : (
                    <AssetItem
                      events={prop.events}
                      files={files.data.asset}
                      group="all"
                      meta={files.meta}
                      params={params}
                      isLoadMore={isLoadMore}
                    />
                  )
                // ) : files !== undefined &&
                //   files["status"] === "error" &&
                //   files["error"] === 404 ? (
                //   <div className="mt-20 text-center">
                //     <Typography variant="h5">No assets added</Typography>
                //   </div>
                ) : (
                  <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
                <AssetsEmpty/>
              </div>
                  // <div className="mt-20 text-center">
                  //   <Typography variant="h5">Something went wrong!</Typography>
                  // </div>
                )}
              </div>
              <div className="absolute -top-[75px] right-0">
                <div className="flex justify-end gap-2">
                  {/* <div className="w-[8rem]">
                    <Dropdown
                      onChange={(item: any) => {
                        prop.events.setGroupBy(item.value);
                      }}
                      options={[
                        {
                          label: "All Assets",
                          value: "all",
                        },
                        {
                          label: "Group By Project",
                          value: "project",
                        },
                      ]}
                      type="smallBox"
                      isSearchable={false}
                      placeholder="Select"
                      defaultValue={{ label: "All Assets", value: "all" }}
                    />
                  </div> */}
                  {/* <button onClick={()=>{
                    const sort_by = 
                    params["sort_by"] !== undefined &&
                    params["sort_by"][1] === "DESC"
                      ? ["id", "ASC"]
                      : ["id", "DESC"];
                    prop.events.setSortBy(sort_by);
                  }}>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className={` 
                          ${
                            params["sort_by"][1] === "DESC"
                              ? "stroke-primary-100"
                              : "stroke-gray-300"
                        }`}
                        d="M3 7H21"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        className={` 
                          ${
                            params["sort_by"][1] === "DESC"
                              ? "stroke-primary-100"
                              : "stroke-gray-300"
                        }`}
                        d="M6 12H18"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        className={`
                          ${
                            params["sort_by"][1] === "DESC"
                              ? "stroke-primary-100"
                              : "stroke-gray-300"
                        }`}
                        d="M10 17H14"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button> */}
                  {/* <button
                    onClick={() => {
                      const sort_by =
                        params["sort_by"] !== undefined &&
                        params["sort_by"][1] === "DESC"
                          ? ["id", "ASC"]
                          : ["id", "DESC"];
                      prop.events.setSortBy(sort_by);
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className={` ${
                          params["sort_by"][1] === "ASC"
                            ? "stroke-primary-100"
                            : "stroke-gray-300"
                        }`}
                        d="M10.45 6.71997L6.72998 3L3.01001 6.71997"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        className={` ${
                          params["sort_by"][1] === "ASC"
                            ? "stroke-primary-100"
                            : "stroke-gray-300"
                        }`}
                        d="M6.72998 21V3"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        className={` ${
                          params["sort_by"][1] === "ASC"
                            ? "stroke-primary-100"
                            : "stroke-gray-300"
                        }`}
                        d="M13.55 17.28L17.2701 21L20.9901 17.28"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        className={` ${
                          params["sort_by"][1] === "ASC"
                            ? "stroke-primary-100"
                            : "stroke-gray-300"
                        }`}
                        d="M17.27 3V21"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    onClick={() => {
                      setIsShowFilters(!isShowFilters);
                    }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className={` ${
                          isShowFilters
                            ? "stroke-primary-100"
                            : "stroke-gray-300"
                        }`}
                        d="M5.40002 2.09998H18.6C19.7 2.09998 20.6 2.99998 20.6 4.09998V6.29998C20.6 
                          7.09998 20.1 8.09998 19.6 8.59998L15.3 12.4C14.7 12.9 14.3 13.9 14.3 14.7V19C14.3 
                          19.6 13.9 20.4 13.4 20.7L12 21.6C10.7 22.4 8.90002 21.5 8.90002 19.9V14.6C8.90002 
                          13.9 8.50002 13 8.10002 12.5L4.30002 8.49998C3.80002 7.99998 3.40002 7.09998 
                          3.40002 6.49998V4.19998C3.40002 2.99998 4.30002 2.09998 5.40002 2.09998Z"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        className={` ${
                          isShowFilters
                            ? "stroke-primary-100"
                            : "stroke-gray-300"
                        }`}
                        d="M10.93 2.09998L6 9.99998"
                        stroke="#292D32"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  {isShowFilters ? (
                    <div
                      ref={dropdownRef}
                      className={`absolute top-[40px] right-[0px] h-[27rem] w-[18rem] rounded-md bg-white border py-5 ps-5 pe-2 border-gray-100 shadow-100 overflow-auto`}
                    >
                      <div className={`w-full`}>
                        <div className="mb-2 pe-5">
                          <div className="flex gap-5 justify-between items-center">
                            <div className="text-200 font-bold text-gray-300">
                              FILTER
                            </div>
                            <button
                              className="text-200 font-medium text-primary-100"
                              onClick={() => {
                                clearFilter();
                              }}
                            >
                              Clear All
                            </button>
                          </div>
                        </div>
                        <div className="h-full pe-3">
                          <>
                            {!isClearFilter ? (
                              <div className="">
                                <div className="">
                                  <label className="text-gray-300 font-medium text-300 mb-1 inline-block">
                                    Start Date
                                  </label>
                                  <CustomDateTimePicker
                                    dateFormat="YYYY-MM-DD"
                                    size="sm"
                                    maxDate={moment(new Date()).format(
                                      "yyyy-MM-DD"
                                    )}
                                    onChange={(
                                      date: Date | null,
                                      value: string | null
                                    ) => {
                                      prop.events.setStartDate(value);
                                    }}
                                    selectedDate={startDate}
                                  />
                                  {startDate === null && endDate !== null ? (
                                    <Typography
                                      className="text-primary-100 font-normal"
                                      variant="p"
                                      size="sm"
                                    >
                                      Please select start date
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="mt-1">
                                  <label className="text-gray-300 font-medium text-300 mb-1 inline-block">
                                    End Date
                                  </label>
                                  <CustomDateTimePicker
                                    dateFormat="YYYY-MM-DD"
                                    size="sm"
                                    onChange={(
                                      date: Date | null,
                                      value: string | null
                                    ) => {
                                      prop.events.setEndDate(value);
                                    }}
                                    selectedDate={endDate}
                                    minDate={minDate !== null ? minDate : null}
                                    maxDate={moment(new Date()).format(
                                      "yyyy-MM-DD"
                                    )}
                                  />
                                  {startDate !== null && endDate === null ? (
                                    <Typography
                                      className="text-primary-100 font-normal"
                                      variant="p"
                                      size="sm"
                                    >
                                      Please select end date
                                    </Typography>
                                  ) : isMinDateError ? (
                                    <Typography
                                      className="text-primary-100 font-normal"
                                      variant="p"
                                      size="sm"
                                    >
                                      End date can not be less than start date
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const Assetloader = (prop: any) => {
  return (
    <div className="grid grid-cols-4 gap-1">
      {Array.from({ length: 16 }).map((_, idx) => (
        <div key={idx} className="animate-pulse">
          <div className="flex-1 space-y-6">
            <div className="h-24 bg-gray-100 rounded" />
          </div>
        </div>
      ))}
    </div>
  );
};

export const AssetItem = (prop: any) => {
  const files = prop.files;
  const group = prop.group;
  const params = prop.params;
  const meta = prop.meta;
  const isLoadMore = prop.isLoadMore;

  return (
    <>
     {files.length === 0 ? ( 
        <div className="flex justify-center items-center w-full h-[calc(100%-5rem)]">
          <AssetsEmpty />
        </div>
      ) : (
      <div className="grid grid-cols-4 gap-4">
        {files.map((item: any, idx: any) => (
          <div key={idx} className="group relative">
            {item.type === "Images" ? (
              <div
                className={`w-full h-24 rounded-md bg-white overflow-hidden border border-gray-400 relative group`}
              >
                <img
                  src={item.url}
                  alt={item.name}
                  className="w-full h-full object-cover"
                />
                <div className="absolute top-0 left-0 px-4 py-4 w-full h-full invisible ease-linear group-hover:visible bg-black/[.7] flex justify-center items-end">
                  <Typography
                    className="text-white font-medium line-clamp-3 text-center break-words"
                    size="xs"
                    variant="p"
                  >
                    {item.name}
                  </Typography>
                </div>
              </div>
            ) : (
              <div
                className={`w-full h-24 rounded-md bg-white overflow-hidden flex justify-center items-center relative group border border-gray-400`}
              >
                <div className="text-center">
                  {item.type === "Documents" ? (
                    <span className="flex justify-center items-center mb-1">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.75 9H8.25"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.75 15H8.25"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  ) : item.type === "Videos" ? (
                    <span className="flex justify-center items-center mb-1">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 11.9999V8.43989C4 4.01989 7.13 2.2099 10.96 4.4199L14.05 6.1999L17.14 7.9799C20.97 10.1899 20.97 13.8099 17.14 16.0199L14.05 17.7999L10.96 19.5799C7.13 21.7899 4 19.9799 4 15.5599V11.9999Z"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  ) : item.type === "Links" ? (
                    <span className="flex justify-center items-center mb-1">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.5 12C13.5 15.18 10.93 17.75 7.75 17.75C4.57 17.75 2 15.18 2 12C2 8.82 4.57 6.25 7.75 6.25"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10 12C10 8.69 12.69 6 16 6C19.31 6 22 8.69 22 12C22 15.31 19.31 18 16 18"
                          stroke="#292D32"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  ) : (
                    ""
                  )}
                  <div className="absolute top-0 left-0 px-4 py-4 w-full h-full invisible ease-linear group-hover:visible bg-black/[.7] flex justify-center items-end">
                    <Typography
                      className="text-white font-medium line-clamp-3 text-center break-words"
                      size="xs"
                      variant="p"
                    >
                      {item.name}
                    </Typography>
                  </div>
                </div>
              </div>
            )}
            <div className="absolute right-0 top-2 flex justify-between items-center invisible group-hover:visible">
              <ActionDropdown
                defaultValue="Edit"
                onSelect={(value) => {
                  if (value === "Edit") {
                    prop.events.editAsste(item.id, item.type);
                  } else if (value === "Delete") {
                    prop.events.deleteEvent(item.id);
                  }
                }}
                options={[
                  {
                    label: "Edit",
                    value: "Edit",
                  },
                  {
                    label: "Delete",
                    value: "Delete",
                  },
                ]}
                value=""
              />
            </div>
          </div>
        ))}
        {group === "all" || group === "unassigned" ? (
          // <div className="mt-20 text-center">
          //   <Typography variant="h5">No assets found</Typography>
          // </div>
        //   <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
        //   <AssetsEmpty/>
        // </div>
           ""
        ) : (
          ""
        )}
      </div>
      )}
    </>
  );
};

export default Assets;
