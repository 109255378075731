import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  convertParamsToArr,
  FetchData,
  pushArray,
} from "../../CommonFunctions";
import Tag from "../../components/Tags/Tags";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import { fetchCategories, confirmDeleteCategories } from "./CategoriesFunction";
import CategoriesTopbar from "./CategoriesTopbar";
import EmptyCategories from "./EmptyCategories";
import CreateCategoryModal from "./CreateCategoryModal";
import CategoriesFilter from "./CategoriesFilter";
import CategoriesEmpty from "./CategoriesEmpty";
import CustomLoader from "../../components/Loader/CustomLoader";
import CategoryEmpty from "./CategoryEmpty";

const Categories = () => {
  let paramsData = useParams();
  paramsData = convertParamsToArr({ params: paramsData });
  paramsData["sort_by"] = ["updated_at", "DESC"];
  paramsData["status"] = [0,1];
  paramsData["start_date"] = null;
  paramsData["end_date"] = null;
  paramsData["page"] = 1;

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadMore, setIsLoadMore] = useState<Boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState<Boolean>(false);
  const [categoriesData, setCategoriesData] = useState<FetchData>();
  const [searchInp, setSearchInp] = useState<any>(null);
  const [params, setParams] = useState(paramsData);

  useEffect(() => {
    if (categoriesData !== undefined) {
      setIsLoading(false);
    }

    setIsLoadMore(false);
  }, [categoriesData]);

  useEffect(() => {
    setIsLoadMore(true);
    loadData(false);
  }, [params]);

  useEffect(() => {
    searchCategories();
  }, [searchInp]);

  const deleteCategories = async (id: BigInteger) => {
    await confirmDeleteCategories(
      id,
      params,
      setCategoriesData,
      categoriesData
    );
  };

  const setSearchInpData = (e: any) => {
    setSearchInp(e.target.value);
  };

  const searchCategories = async () => {
    if (searchInp != null && (searchInp.length >= 1 || searchInp.length <= 0)) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        0: searchInp,
      });
    }
  };

  const loadData = async (isLoader = true) => {
    if (isLoader) {
      setIsLoading(true);
      params["page"] = 1;
    }

    const data: FetchData = await fetchCategories({ params });

    if (data.status === "error" && data.error === "abort") {
      return false;
    }

    // if (
    //   categoriesData !== undefined &&
    //   params["page"] > 1 &&
    //   data.status === "success"
    // ) {
    //   let log = categoriesData["data"];
    //   for (const i in data["data"]) {
    //     log = pushArray(log, data["data"][i]);
    //   }
    //   setCategoriesData({
    //     ...categoriesData,
    //     data: log,
    //   });
    // } else if (params["page"] === 1) {
      setCategoriesData(data);
    // }
  };

  const CreateCategory = (id: number) => {
    CreateCategoryModal.fire(id, loadData);
  };

  const setPagination=(page: number)=>{
    setParams({
      ...params,
      page:page,
    });
  };
  const events = {
    loadData: loadData,
    setIsLoadMore: setIsLoadMore,
    setParams: setParams,
    CreateCategory: CreateCategory,
    deleteEvent: deleteCategories,
    setSearchInpData: setSearchInpData,
    searchCategories: searchCategories,
  };

  return (
    <div className="py-2">
      <div className="relative h-[calc(100vh-8rem)] border border-gray-100 rounded-md shadow-300">
        <div className="flex h-full">
          <CategoriesFilter
            params={params}
            setIsLoading={setIsLoading}
            events={events}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
          />
          <div
            className={`${
              isFilterOpen
                ? `w-[calc(100%-12.125rem)] ms-[12.125rem]`
                : `w-[calc(100%-3.75rem)] ms-[3.75rem]`
            } h-full bg-gray-200 rounded-e-md overflow-auto`}
          >
            {/* <div className="w-full rounded-t-md overflow-hidden"> */}
            <CategoriesTopbar
              params={params}
              setIsLoading={setIsLoading}
              events={events}
              totalData={categoriesData?.data?.length}
              meta={categoriesData?.meta}
              setPagination={setPagination}
            />
            <div className="">
              {isLoading === true ? (
                <div className="flex justify-center items-center w-full h-[calc(100vh-12.75rem)]">
                <CustomLoader/>
              </div>
                // <CategoriesLoader />
              ) : categoriesData !== undefined &&
                categoriesData["status"] === "success" && categoriesData.data.length > 0 ? (
                <CategoriesItem
                  categoriesData={categoriesData}
                  isLoadMore={isLoadMore}
                  params={params}
                  events={events}
                />
              // ) : categoriesData !== undefined &&
              //   categoriesData["status"] === "error" &&
              //   categoriesData !== undefined &&
              //   categoriesData["error"] === 404 ? (
              //   <EmptyCategories events={events} />
              ) : (
                <div className="flex justify-center items-center w-full h-[calc(100vh-12.75rem)]">
            <CategoryEmpty />
          </div>
                // <div className="mt-20 text-center">
                //   <Typography variant="h5">Something went wrong!</Typography>
                // </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const CategoriesItem = (prop: any) => {
  const categoriesData = prop.categoriesData;
  const events = prop.events;
  const isLoadMore = prop.isLoadMore;
  const params = prop.params;

  return (
    <div className="overflow-auto h-[calc(100vh-12.6rem)] bg-white relative">
      <table className="w-full">
        <thead className="sticky left-0 top-0 bg-gray-200 z-10">
          <tr>
            <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start w-[10rem] uppercase ps-8">
              Service Area
            </th>
            <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start w-[10rem] uppercase ">
              Status
            </th>
            <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start w-[10rem] uppercase ">
              Created At
            </th>
            <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start w-[10rem] uppercase ">
              Updated At
            </th>
            <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start w-[10rem] uppercase pe-8">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {categoriesData["data"].map((item: any, idx: any) => (
            <tr key={idx} className="group">
              <td className="text-300 text-gray-300 font-normal px-5 py-4 ps-8 text-start border-b border-gray-400 group-hover:bg-gray-200">
                {item.category_name}
              </td>
              <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                {item.status == 1 ? (
                  <Tag text="Active" type="success" />
                ) : (
                  <Tag text="Inactive" type="primary" />
                )}
              </td>
              <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                {item.created_at}
              </td>
              <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                {item.updated_at}
              </td>
              <td className="text-300 text-gray-300 font-normal px-5 py-4 pe-8 text-start border-b border-gray-400 group-hover:bg-gray-200">
                <div className="flex justify-start items-center">
                  <Button
                    label="Edit"
                    onClick={() => events.CreateCategory(item.id)}
                    size="small"
                    variant="primary"
                    className="!text-200"
                  />
                  &nbsp;
                  <div className="vr" />
                  &nbsp;
                  <Button
                    type="button"
                    label="Delete"
                    variant="outline"
                    size="small"
                    className="!text-200"
                    onClick={() => {
                      events.deleteEvent(item.id);
                    }}
                  />
                </div>
              </td>
            </tr>
          ))}
          <tr>
            <td className="border-0" colSpan={6}>
              <div className="flex w-100 justify-center text-center items-center align-middle mt-5">
                {isLoadMore ? (
                  <div className="flex mt-2">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="duration-200 animate-pulse-3"
                    >
                      <circle cx="12" cy="6" r="6" fill="#F15845" />
                    </svg>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="duration-300 animate-pulse-2"
                    >
                      <circle cx="12" cy="6" r="6" fill="#F15845" />
                    </svg>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="duration-400 animate-pulse"
                    >
                      <circle cx="12" cy="6" r="6" fill="#F15845" />
                    </svg>
                  </div>
                ) : params["page"] < categoriesData.meta.last_page ? (
                  <button
                    className="text-200 font-bold text-blue-500 border-b inline-block"
                    onClick={() => {
                      events.setParams({
                        ...params,
                        page: params["page"] + 1,
                      });
                    }}
                  >
                    {/* <span className="">Load More</span> */}
                  </button>
                ) : (
                  ""
                )}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {/* <div className="flex justify-center items-center w-full h-[calc(100%-3rem)]">
        <CategoriesEmpty />
      </div> */}
      {/* <div className="flex justify-center items-center w-full h-[calc(100%-3rem)]">
        <CustomLoader />
      </div> */}
    </div>
  );
};

const CategoriesLoader = (prop: any) => {
  return (
    <div className="overflow-auto h-[calc(100vh-15.25rem)] bg-white">
      <table className="w-full">
        <thead className="sticky left-0 top-0 z-10">
          <tr>
            <th className="bg-gray-200 px-5 py-3 text-gray-700 text-300 font-bold text-left">
              Service Area
            </th>
            <th className="bg-gray-200 px-5 py-3 text-gray-700 text-300 font-bold text-left">
              Status
            </th>
            <th className="bg-gray-200 px-5 py-3 text-gray-700 text-300 font-bold text-left">
              Created At
            </th>
            <th className="bg-gray-200 px-5 py-3 text-gray-700 text-300 font-bold text-left">
              Updated At
            </th>
            <th className="bg-gray-200 px-5 py-3 text-gray-700 text-300 font-bold text-left">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 7 }).map((idx: any) => (
            <tr key={idx}>
              <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300">
                <div key={idx} className="animate-pulse">
                  <div className="flex-1 space-y-6">
                    <div className="h-5 bg-gray-100 rounded" />
                  </div>
                </div>
              </td>
              <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300">
                <div key={idx} className="animate-pulse">
                  <div className="flex-1 space-y-6">
                    <div className="h-5 bg-gray-100 rounded" />
                  </div>
                </div>
              </td>
              <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300">
                <div key={idx} className="animate-pulse">
                  <div className="flex-1 space-y-6">
                    <div className="h-5 bg-gray-100 rounded" />
                  </div>
                </div>
              </td>
              <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300">
                <div key={idx} className="animate-pulse">
                  <div className="flex-1 space-y-6">
                    <div className="h-5 bg-gray-100 rounded" />
                  </div>
                </div>
              </td>
              <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300">
                <div key={idx} className="animate-pulse">
                  <div className="flex-1 space-y-6">
                    <div className="h-5 bg-gray-100 rounded" />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Categories;
