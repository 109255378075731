import React, { useEffect, useState } from "react";
import { FetchData, unsetArray } from "../../CommonFunctions";
import Typography from "../../components/Typography/Typography";
import CustomFilters, {
  Item as FilterItem,
} from "../../components/Filters/customFilters";
import Accordion from "../../components/Accordion/Accordion";
import { CustomDateTimePicker } from "../../components/DateTimePicker/DatePicker";
import { fetchProjectFilters } from "./ProjectFunction";
import moment from "moment";
import Checkbox from "../../components/CheckBox/CheckBox";
import RadioButton from "../../components/Radio button/RadioButton";

const ProjectsFilter = (prop: any) => {
  const params = prop.params;
  const setParams = prop.setParams;
  const setIsLoading = prop.setIsLoading;
  const isFilterOpen = prop.isFilterOpen;
  const setIsFilterOpen = prop.setIsFilterOpen;

  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [minDate, setMinDate] = useState<string | null>(null);
  const [isMinDateError, setIsMinDateError] = useState<Boolean>(false);
  const [isLoadFilter, setLoadFilter] = useState<Boolean>(true);
  const [isClearFilter, setIsClearFilter] = useState<Boolean>(false);
  const [filters, setFilters] = useState<FetchData>();
  const [searchByRecent, setSearchByRecent] = useState<string>("recent");
  const [searchByStatus, setSearchByStatus] = useState([0, 1, 2]);

  useEffect(() => {
    setIsLoading(true);
    setParams({
      ...params,
      page:1,
      sort_by: searchByRecent === "recent" ? ["created_at", "DESC"] : ["created_at", "ASC"]
    })
  }, [searchByRecent]);

  useEffect(() => {
    setIsLoading(true);
    setParams({
      ...params,
      page:1,
      status: Array.from(searchByStatus).join(",")
    })
  }, [searchByStatus]);

  const updateStatusFilter = (value?: number|null) => {
    if(typeof value === 'number') {
      if(searchByStatus.includes(value)) {
        setSearchByStatus(searchByStatus.filter(num => num != value));
      } else {
        setSearchByStatus([...searchByStatus, value]);
      }
    } else {
      if ([0,1,2].every(element => searchByStatus.includes(element))) {
        setSearchByStatus([])
      } else {
        setSearchByStatus([0,1,2])
      }
    }

  }

  useEffect(() => {
    if (isLoadFilter === true) {
      loadFilters();
    }
  }, [isLoadFilter]);

  useEffect(() => {
    setIsClearFilter(false);
  }, [params]);

  useEffect(() => {
    setMinDate(startDate);
    filterByDate();
  }, [startDate]);

  useEffect(() => {
    filterByDate();
  }, [endDate]);

  const loadFilters = async () => {
    setLoadFilter(false);
    setIsClearFilter(false);
    const data = await fetchProjectFilters();
    setFilters(data);
  };

  const clearFilter = () => {
    setIsClearFilter(true);
    setIsLoading(true);
    setStartDate(null);
    setEndDate(null);
    setSearchByRecent("recent");
    setSearchByStatus([0, 1, 2]);
    setParams({
      ...params,
      page: 1,
      categories: [],
      industries: [],
      technologies: [],
      clients: [],
      tags: [],
      start_date: null,
      end_date: null,
      status: [0,1, 2]
    });
  };

  const filterByDate = () => {
    setIsMinDateError(false);

    if (startDate === null && endDate === null) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        start_date: startDate,
        end_date: endDate,
      });
    }

    if (startDate === null || endDate === null) {
      return false;
    }

    const dateLimit = moment(startDate);
    const now = moment(endDate);

    if (now.isBefore(dateLimit)) {
      setIsMinDateError(true);
    } else if (startDate !== null && endDate !== null) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        start_date: startDate,
        end_date: endDate,
      });
    }
  };

  const setTechnologiesFilter = (checked: boolean, value: string) => {
    let technologies = params["technologies"];

    if (checked) {
      technologies.push(value);
    } else {
      technologies = unsetArray(technologies, value);
    }

    setIsLoading(true);
    setParams({
      ...params,
      page: 1,
      technologies: technologies,
    });
  };

  const setTagsFilter = (checked: boolean, value: string) => {
    let tags = params["tags"];

    if (checked) {
      tags.push(value);
    } else {
      tags = unsetArray(tags, value);
    }

    setIsLoading(true);
    setParams({
      ...params,
      page: 1,
      tags: tags,
    });
  };

  const setIndustriesFilter = (checked: boolean, value: string) => {
    let industries = params["industries"];

    if (checked) {
      industries.push(value);
    } else {
      industries = unsetArray(industries, value);
    }

    setIsLoading(true);
    setParams({
      ...params,
      page: 1,
      industries: industries,
    });
  };

  const setClientsFilter = (checked: boolean, value: string) => {
    let clients = params["clients"];

    if (checked) {
      clients.push(value);
    } else {
      clients = unsetArray(clients, value);
    }

    setIsLoading(true);
    setParams({
      ...params,
      page: 1,
      clients: clients,
    });
  };

  const setCategoryFilter = (checked: boolean, value: FilterItem) => {
    let categories = params["categories"];

    if (checked) {
      categories.push(value.id);
    } else {
      categories = unsetArray(categories, value.id);
    }

    setIsLoading(true);
    setParams({
      ...params,
      page: 1,
      categories: categories,
    });
  };

  return (
    <div
      className={`px-5 py-8 pt-0 h-full group absolute left-0 top-0 z-[2] border-r
     border-gray-100 bg-gray-200 rounded-s-md ease-in duration-300 overflow-y-auto overflow-x-hidden flex flex-1 
      ${isFilterOpen ? `w-[12.125rem]` : `w-[4rem] hover:w-[12.125rem]`}`}
    >
      <div className="w-full">
        <div className="pt-8 pb-4 mb-3 flex justify-start items-center gap-2.5 relative after:bg-gray-200 after:h-[5rem] after:absolute after:-right-[1.3rem] after:top-0 after:w-9 after:-z[10]">
          <div className="flex gap-1.5 justify-start items-center order-2">
            <div className="text-200 font-bold text-gray-500">FILTER</div>
            <div className="text-gray-500 text-100">|</div>
            <button
              className="text-200 font-normal text-primary-100 whitespace-nowrap"
              onClick={() => {
                clearFilter();
              }}
            >
              Clear All
            </button>
          </div>
          <button
            className="relative bg-gray-100 order-1 block p-0 me-4"
            onClick={() => {
              setIsFilterOpen(!isFilterOpen);
            }}
          >
            {!isFilterOpen ? (
              <span className="absolute left-0 -top-1.5">
                <svg
                  width="1rem"
                  height="0.625rem"
                  viewBox="0 0 16 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.8753 0.875C15.2895 0.875 15.6253 1.21079 15.6253 1.625C15.6253 2.03921 15.2895 2.375 14.8753 2.375H1.12494C0.710726 2.375 0.374939 2.03921 0.374939 1.625C0.374939 1.21079 0.710726 0.875 1.12494 0.875H14.8753ZM15.6253 5.19667C15.6253 4.78246 15.2895 4.44667 14.8753 4.44667H1.12494C0.710726 4.44667 0.374939 4.78246 0.374939 5.19667C0.374939 5.61088 0.710726 5.94667 1.12494 5.94667H14.8753C15.2895 5.94667 15.6253 5.61088 15.6253 5.19667ZM15.6253 8.76833C15.6253 8.35411 15.2895 8.01833 14.8753 8.01833H1.12494C0.710726 8.01833 0.374939 8.35411 0.374939 8.76833C0.374939 9.18254 0.710726 9.51833 1.12494 9.51833H14.8753C15.2895 9.51833 15.6253 9.18254 15.6253 8.76833Z"
                    fill="#888888"
                  ></path>
                </svg>
              </span>
            ) : (
              <span className="absolute left-0 -top-2.5">
                <svg
                  width="1.25rem"
                  height="1.25rem"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.9751 4.94165L2.91677 9.99998L7.9751 15.0583"
                    stroke="#888888"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M17.0835 10H3.0585"
                    stroke="#888888"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </span>
            )}
          </button>
        </div>
        {!isClearFilter ? (
          <>
            {filters !== undefined && filters["status"] === "success" ? (
              <>
                <Accordion
                  isFilterOpen={isFilterOpen}
                  count={1}
                  subTitle=""
                  time=""
                  title="Sort By"
                  icon={
                    <svg
                      width="16"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 7H21"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M6 12H18"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M10 17H14"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  }
                >
                  <div
                    className={`${
                      isFilterOpen
                        ? `inline-block`
                        : `hidden group-hover:inline-block`
                    }`}
                  >
                    <div className="flex justify-start items-center gap-2 mt-3">
                      {/* <Checkbox
                        checked={searchByRecent}
                        onChange={(value) => setSearchByRecent(value.target.checked)}
                        size="sm"
                      /> */}
                       <RadioButton
                        selectedValue={searchByRecent}
                        name="searchByRecent"
                        onChange={(value) => setSearchByRecent(value)}
                        size="sm"
                        value="recent"
                      />
                      <label className="text-gray-500 font-normal text-200 line-clamp-1"> 
                        Recent
                      </label>
                    </div>
                    <div className="flex justify-start items-center gap-2 mt-3">
                      {/* <Checkbox
                        checked={!searchByRecent}
                        onChange={(value) => setSearchByRecent(!value.target.checked)}
                        size="sm"
                      /> */}
                      <RadioButton
                      selectedValue={searchByRecent} 
                      name="searchByOldest"
                      onChange={(value) => setSearchByRecent(value)}
                      size="sm"
                      value="oldest"
                    />
                      <label className="text-gray-500 font-normal text-200">
                        Oldest
                      </label>
                    </div>
                  </div>
                </Accordion>
                <Accordion
                  isFilterOpen={isFilterOpen}
                  count={2}
                  subTitle=""
                  time=""
                  title="Status"
                  icon={
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 18 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.7691 14.1923H4.84606C2.76914 14.1923 1.38452 13.1539 1.38452 10.7308V5.88463C1.38452 3.46156 2.76914 2.4231 4.84606 2.4231H11.7691C13.8461 2.4231 15.2307 3.46156 15.2307 5.88463V10.7308C15.2307 13.1539 13.8461 14.1923 11.7691 14.1923Z"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M11.7693 6.23077L9.60234 7.96154C8.88927 8.52924 7.71927 8.52924 7.00619 7.96154L4.84619 6.23077"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M13.8463 15.923C15.7502 15.923 17.3078 14.3653 17.3078 12.4615C17.3078 10.5576 15.7502 8.99994 13.8463 8.99994C11.9425 8.99994 10.3848 10.5576 10.3848 12.4615C10.3848 14.3653 11.9425 15.923 13.8463 15.923Z"
                        fill="#F8F8F8"
                        stroke="#888888"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M12.375 12.4614L13.3546 13.441L15.3173 11.4818"
                        stroke="#888888"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  }
                >
                  <div
                    className={`${
                      isFilterOpen
                        ? `inline-block`
                        : `hidden group-hover:inline-block`
                    }`}
                  >
                    <div className="flex justify-start items-center gap-2 mt-3">
                      <Checkbox
                        checked={[0,1,2].every(element => searchByStatus.includes(element))}
                        onChange={() => updateStatusFilter()}
                        size="sm"
                      />
                      <label className="text-gray-500 font-normal text-200">
                        All
                      </label>
                    </div>
                    <div className="flex justify-start items-center gap-2 mt-3">
                      <Checkbox
                        checked={searchByStatus.includes(1)}
                        onChange={() => updateStatusFilter(1)}
                        size="sm"
                      />
                      <label className="text-gray-500 font-normal text-200">
                        Published
                      </label>
                    </div>
                    <div className="flex justify-start items-center gap-2 mt-3">
                      <Checkbox
                        checked={searchByStatus.includes(2)}
                        onChange={() => updateStatusFilter(2)}
                        size="sm"
                      />
                      <label className="text-gray-500 font-normal text-200">
                        Archived
                      </label>
                    </div>
                    <div className="flex justify-start items-center gap-2 mt-3">
                      <Checkbox
                        checked={searchByStatus.includes(0)}
                        onChange={() => updateStatusFilter(0)}
                        size="sm"
                      />
                      <label className="text-gray-500 font-normal text-200">
                        Draft
                      </label>
                    </div>
                  </div>
                </Accordion>
                <Accordion
                  isFilterOpen={isFilterOpen}
                  count={3}
                  subTitle=""
                  time=""
                  title="Service Areas"
                  icon={
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19 22V11"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19 7V2"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 22V17"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 13V2"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5 22V11"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M5 7V2"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 11H7"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17 11H21"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 13H14"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                >
                  <div
                    className={`pb-3 ${
                      isFilterOpen
                        ? `inline-block`
                        : `hidden group-hover:inline-block`
                    }`}
                  >
                    <CustomFilters
                      selected={params["categories"]}
                      filter={filters?.data.categories}
                      type="key"
                      isSearchBar={true}
                      count={4}
                      onChange={setCategoryFilter}
                    />
                  </div>
                </Accordion>
                <Accordion
                  isFilterOpen={isFilterOpen}
                  count={4}
                  subTitle=""
                  time=""
                  title="Industries"
                  icon={
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 22H22"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.75 4V22H14.25V4C14.25 2.9 13.8 2 12.45 2H11.55C10.2 2 9.75 2.9 9.75 4Z"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 10V22H7V10C7 8.9 6.6 8 5.4 8H4.6C3.4 8 3 8.9 3 10Z"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17 15V22H21V15C21 13.9 20.6 13 19.4 13H18.6C17.4 13 17 13.9 17 15Z"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                >
                  <div
                    className={`pb-3 ${
                      isFilterOpen
                        ? `inline-block`
                        : `hidden group-hover:inline-block`
                    }`}
                  >
                    <CustomFilters
                      selected={params["industries"]}
                      filter={filters?.data.industries}
                      type="single"
                      isSearchBar={true}
                      count={4}
                      onChange={setIndustriesFilter}
                    />
                  </div>
                </Accordion>
                <Accordion
                  isFilterOpen={isFilterOpen}
                  count={5}
                  subTitle=""
                  time=""
                  title="Technologies"
                  icon={
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.6 20H14.4C18.4 20 20 18.4 20 14.4V9.6C20 5.6 18.4 4 14.4 4H9.6C5.6 4 4 5.6 4 9.6V14.4C4 18.4 5.6 20 9.6 20Z"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.5 17H13.5C16 17 17 16 17 13.5V10.5C17 8 16 7 13.5 7H10.5C8 7 7 8 7 10.5V13.5C7 16 8 17 10.5 17Z"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.01001 4V2"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 4V2"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 4V2"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20 8H22"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20 12H22"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20 16H22"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16 20V22"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12.01 20V22"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.01001 20V22"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2 8H4"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2 12H4"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2 16H4"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                >
                  <div
                    className={`pb-3 ${
                      isFilterOpen
                        ? `inline-block`
                        : `hidden group-hover:inline-block`
                    }`}
                  >
                    <CustomFilters
                      selected={params["technologies"]}
                      filter={filters?.data.technologies}
                      type="single"
                      isSearchBar={true}
                      count={4}
                      onChange={setTechnologiesFilter}
                    />
                  </div>
                </Accordion>
                <Accordion
                  isFilterOpen={isFilterOpen}
                  count={6}
                  subTitle=""
                  time=""
                  title="Tags"
                  icon={
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.40476 15.5264L8.93476 20.0564C10.7948 21.9164 13.8148 21.9164 15.6848 20.0564L20.0748 15.6664C21.9348 13.8064 21.9348 10.7864 20.0748 8.91637L15.5348 4.39637C14.5848 3.44637 13.2748 2.93637 11.9348 3.00637L6.93476 3.24637C4.93476 3.33637 3.34476 4.92637 3.24476 6.91637L3.00476 11.9164C2.94476 13.2664 3.45476 14.5764 4.40476 15.5264Z"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.73474 12.2264C11.1155 12.2264 12.2347 11.1071 12.2347 9.72636C12.2347 8.34565 11.1155 7.22636 9.73474 7.22636C8.35403 7.22636 7.23474 8.34565 7.23474 9.72636C7.23474 11.1071 8.35403 12.2264 9.73474 12.2264Z"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M13.2347 17.2264L17.2347 13.2264"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                >
                  <div
                    className={`pb-3 ${
                      isFilterOpen
                        ? `inline-block`
                        : `hidden group-hover:inline-block`
                    }`}
                  >
                    <CustomFilters
                      selected={params["tags"]}
                      filter={filters?.data.tags}
                      type="single"
                      isSearchBar={true}
                      count={4}
                      onChange={setTagsFilter}
                    />
                  </div>
                </Accordion>
                <Accordion
                  isFilterOpen={isFilterOpen}
                  count={7}
                  subTitle=""
                  time=""
                  title="Clients"
                  icon={
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.16 10.87C9.06 10.86 8.94 10.86 8.83 10.87C6.45 10.79 4.56 8.84 4.56 6.44C4.56 3.99 6.54 2 9 2C11.45 2 13.44 3.99 13.44 6.44C13.43 8.84 11.54 10.79 9.16 10.87Z"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.41 4C18.35 4 19.91 5.57 19.91 7.5C19.91 9.39 18.41 10.93 16.54 11C16.46 10.99 16.37 10.99 16.28 11"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.16 14.56C1.74 16.18 1.74 18.82 4.16 20.43C6.91 22.27 11.42 22.27 14.17 20.43C16.59 18.81 16.59 16.17 14.17 14.56C11.43 12.73 6.92 12.73 4.16 14.56Z"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M18.34 20C19.06 19.85 19.74 19.56 20.3 19.13C21.86 17.96 21.86 16.03 20.3 14.86C19.75 14.44 19.08 14.16 18.37 14"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                >
                  <div
                    className={`pb-3 ${
                      isFilterOpen
                        ? `inline-block`
                        : `hidden group-hover:inline-block`
                    }`}
                  >
                    <CustomFilters
                      selected={params["clients"]}
                      filter={filters?.data.clients}
                      type="single"
                      isSearchBar={true}
                      count={4}
                      onChange={setClientsFilter}
                    />
                  </div>
                </Accordion>
              </>
            ) : (
              ""
            )}
            <Accordion
              isFilterOpen={isFilterOpen}
              count={8}
              subTitle=""
              time=""
              title="Date"
              icon={
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 2V5"
                    stroke="#888888"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16 2V5"
                    stroke="#888888"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.5 9.09H20.5"
                    stroke="#888888"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                    stroke="#888888"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.6947 13.7H15.7037"
                    stroke="#888888"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.6947 16.7H15.7037"
                    stroke="#888888"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.9955 13.7H12.0045"
                    stroke="#888888"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.9955 16.7H12.0045"
                    stroke="#888888"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.29431 13.7H8.30329"
                    stroke="#888888"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.29431 16.7H8.30329"
                    stroke="#888888"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            >
              <div
                className={`pb-3 ${
                  isFilterOpen
                    ? `inline-block`
                    : `hidden group-hover:inline-block`
                }`}
              >
                <div className="mt-3 small-datepicker">
                  <label className="text-gray-700 font-normal text-300">
                    Start
                  </label>
                  <CustomDateTimePicker
                    dateFormat="YYYY-MM-DD"
                    size="sm"
                    onChange={(date: Date | null, value: string | null) => {
                      setStartDate(value);
                    }}
                    selectedDate={startDate}
                    maxDate={moment(new Date()).format("yyyy-MM-DD")}
                  />
                  {startDate === null && endDate !== null ? (
                    <Typography
                      className="text-primary-100 font-normal"
                      variant="p"
                      size="sm"
                    >
                      Please select start date
                    </Typography>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mt-3 small-datepicker">
                  <label className="text-gray-700 font-normal text-300">
                    End
                  </label>
                  <CustomDateTimePicker
                    dateFormat="YYYY-MM-DD"
                    size="sm"
                    onChange={(date: Date | null, value: string | null) => {
                      setEndDate(value);
                    }}
                    selectedDate={endDate}
                    minDate={minDate !== null ? minDate : null}
                    maxDate={moment(new Date()).format("yyyy-MM-DD")}
                  />
                  {startDate !== null && endDate === null ? (
                    <Typography
                      className="text-primary-100 font-normal"
                      variant="p"
                      size="sm"
                    >
                      Please select end date
                    </Typography>
                  ) : isMinDateError ? (
                    <Typography
                      className="text-primary-100 font-normal"
                      variant="p"
                      size="sm"
                    >
                      End date can not be less than start date
                    </Typography>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Accordion>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ProjectsFilter;
