import React, { useState } from "react";

export type AccordionProps = {
  /**
   * Accordion title.
   */
  title: string;
  count: number;
  /**
   * Element displayed when accordion is expanded.
   */
  children: React.ReactNode;
  icon?: React.ReactNode;
  className?: string;
  /**
   *  Optional description
   */
  subTitle: string;
  time?: string;
  open?: boolean;
  isFilterOpen?:boolean;
};

const Accordion: React.FC<AccordionProps> = ({
  title,
  icon,
  count,
  children,
  className,
  subTitle,
  time,
  open = false,
  isFilterOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={` border-b border-b-gray-100 mb-3 pb-3 ${isOpen ? "" : ""}`}>
      <div
        className="flex justify-start cursor-pointer relative w-full "
        onClick={toggleAccordion}
      >
        <div className="">
          {/* <div className="border border-gray-100 rounded-sm bg-white p-2">
            {count && (
              <div className="w-5 h-5 bg-secondary-100 rounded-full text-100 text-white flex items-center justify-center">
                {count}
              </div>
            )}
          </div> */}
          <div className="flex gap-2">
            {/* <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 22V11" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M19 7V2" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12 22V17" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12 13V2" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M5 22V11" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M5 7V2" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M3 11H7" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M17 11H21" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M10 13H14" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            </svg> */}
            {icon?(
              icon
            ):(
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.40476 15.5264L8.93476 20.0564C10.7948 21.9164 13.8148 21.9164 15.6848 20.0564L20.0748 15.6664C21.9348 13.8064 21.9348 10.7864 20.0748 8.91637L15.5348 4.39637C14.5848 3.44637 13.2748 2.93637 11.9348 3.00637L6.93476 3.24637C4.93476 3.33637 3.34476 4.92637 3.24476 6.91637L3.00476 11.9164C2.94476 13.2664 3.45476 14.5764 4.40476 15.5264Z" stroke="#888888" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9.73474 12.2264C11.1155 12.2264 12.2347 11.1071 12.2347 9.72636C12.2347 8.34565 11.1155 7.22636 9.73474 7.22636C8.35403 7.22636 7.23474 8.34565 7.23474 9.72636C7.23474 11.1071 8.35403 12.2264 9.73474 12.2264Z" stroke="#888888" strokeWidth="1.5" strokeLinecap="round"/>
                <path d="M13.2347 17.2264L17.2347 13.2264" stroke="#888888" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            )}
            <div className={`text-200 font-medium text-gray-300 whitespace-nowrap ${isFilterOpen?`inline-block`:`hidden group-hover:inline-block`}`}>
              {title}
              <span className="absolute right-0">
                <svg 
                  style={{
                    transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                  }}
                  width="13" height="13" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.91003 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91003 4.08008" stroke="#232323" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </span>
            </div>
          </div>
        </div>
        {/* <div className="flex items-center absolute right-0">
          {time ? (
            <span className="text-200 text-gray-700 pr-4 whitespace-nowrap">
              {time}
            </span>
          ) : null}
          <svg
            style={{
              transform: isOpen ? "rotate(180deg)" : "rotate(60deg)",
              transition: "transform 0.3s ease",
            }}
            width="12"
            height="12"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.4994 4.82082C14.2762 4.61528 13.9144 4.61528 13.6913 4.82082L8.00008 10.0627L2.30891 4.82082C2.08575 4.61528 1.72394 4.61528 1.50078 4.82082C1.27763 5.02636 1.27763 5.35961 1.50078 5.56514L7.59602 11.1792C7.70318 11.2779 7.84853 11.3333 8.00008 11.3333C8.15163 11.3333 8.29698 11.2779 8.40414 11.1792L14.4994 5.56514C14.7225 5.35961 14.7225 5.02636 14.4994 4.82082Z"
              fill="#232323"
            />
          </svg>
          <svg 
            style={{
              transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease",
            }}
            width="13" height="13" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.91003 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91003 4.08008" stroke="#232323" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>

        </div> */}
      </div>
      {isOpen && <div className="">{children}</div>}
    </div>
  );
};

export default Accordion;
