import React from "react";
import Typography from "../../components/Typography/Typography";
import ecoLogo from "../../images/ecoLogo.svg";

const Unauthorized = () => {
  return (
    <div className="h-screen flex flex-col bg-gray-600 items-center">
      <div className="pt-12">
        <img
          src={ecoLogo}
          alt="logo"
          className="w-auto max-w-full h-10 object-cover object-left-top"
        />
      </div>

      <div className="h-full grid justify-items-center items-center">
        <section className="text-center">
          <Typography className="font-bold mb-2" variant="h2">
            Unauthorized
          </Typography>
          <div className="tip-wrapper">
            <Typography className="my-5" variant="h4">
              You are Unauthorized!<br></br>
              Please connect with support.
            </Typography>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Unauthorized;
