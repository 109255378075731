import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getUserPhoto, logout } from "../../CommonFunctions";
import Typography from "../Typography/Typography";
import Icons from "../Icons/Icon";
import ShowAuditLogModal from "../../views/auditlog/AuditlogModel";
import { CustomRightToolTip } from "../../views/projects/ShowProjects";

interface HeaderProps {
  pageTitle: string;
  isOpen: boolean;
}

const Header: React.FC<HeaderProps> = ({ pageTitle, isOpen }) => {
  const User = useSelector((state: any) => state.user);
  const [isUlVisible, setIsUlVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [userPhoto, setUserPhoto] = useState("");
  const dropdownRef = useRef<HTMLUListElement>(null);

  const location = useLocation();
  const pathname = location.pathname.split("/");

  const ShowAuditLog = () => {
    ShowAuditLogModal.fire();
  };

  const handleImageClick = () => {
    setIsUlVisible(!isUlVisible);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsUlVisible(false);
    }
  };

  const copyToClipBoard = async (copyMe: string) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setIsCopied(true);
    } catch (err) {
      setIsCopied(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (User.headerData) {
      setIsCopied(false);
    }
  }, [User]);

  useEffect(() => {
    const getPhoto = async () => {
      const photo = await getUserPhoto();
      setUserPhoto(photo);
    };
    getPhoto();
  }, []);

  console.log(pathname, "pathname");
  console.log(User, "User");

  const renderHeaderContent = () => {
    if (User.headerData !== undefined && User.headerData !== null && pathname[1] === "links" && pathname[3] === "view") {
      return (
        <div className="flex justify-start items-center gap-3">
          <div className="">
            <button onClick={() => { User.headerData.navigate(-1); }} className="flex justify-start items-center gap-1">
              {/* <Icons name="Arrow-left" variant="stroke" size={24} /> */}
              <Icons name="left" variant="arrow" />
            </button>
          </div>
          <div>
            <div onClick={() => { copyToClipBoard(User.headerData.url); }} className="text-600 font-semibold text-gray-300 flex justify-start items-center gap-1.5 cursor-pointer">
              <span className="w-[30rem] truncate">
                {User.headerData.url}
              </span>
              <button className="">
                {isCopied ?
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z" fill="#2EB67D" />
                  </svg>
                  :
                  <Icons name="duplicate" variant="stroke" size={24} />
                }
              </button>
            </div>
            <div className="text-450 font-medium text-gray-300">
              {User.headerData.portfolio_title}
            </div>
          </div>
        </div>
      );
    } else if (User.headerData !== undefined && User.headerData !== null && pathname[1] === "portfolios" && pathname[2] === "show") {
      return (
        <div className="flex justify-start items-center gap-3">
          <div className="">
            <button onClick={() => { User.headerData.navigate("/portfolios"); }} className="flex justify-start items-center gap-1">
              <Icons name="left" variant="arrow" />
            </button>
          </div>
          <div>
            <div className="text-600 font-semibold text-gray-300 flex justify-start items-center gap-1.5 cursor-pointer">
              {User.headerData.portfolio_title}
            </div>
            <div className="text-450 font-medium text-gray-300">
              Create a Portfolio
            </div>
          </div>
        </div>
      );
    } else if (User.headerData !== undefined && User.headerData !== null && pathname[1] === "portfolios" && pathname[2] === "preview") {
      return (
        <div className="flex justify-start items-center gap-3">
          <div className="">
            <button onClick={() => { User.headerData.navigate(-1); }} className="flex justify-start items-center gap-1">
              <Icons name="left" variant="arrow" />
            </button>
          </div>
          <div>
            <div className="text-600 font-semibold text-gray-300 flex justify-start items-center gap-1.5 cursor-pointer">
              {User.headerData.portfolio_title}
            </div>
          
          </div>
        </div>
      );
    }else if (User.headerData !== undefined && User.headerData !== null && pathname[1] === "portfolios" && pathname[2] === "projects") {
      return (
        <div className="flex justify-start items-center gap-3">
          <div className="">
            <button onClick={() => { User.headerData.navigate(-1); }} className="flex justify-start items-center gap-1">
              <Icons name="left" variant="arrow" />
            </button>
          </div>
          <div>
            <div className="text-600 font-semibold text-gray-300 flex justify-start items-center gap-1.5 cursor-pointer">
              {User.headerData.portfolio_title}
            </div>
          
          </div>
        </div>
      );
    }else if (User.headerData !== undefined && User.headerData !== null && pathname[1] === "projects" && pathname[2] === "show") {
      return (
        <div className="flex justify-start items-center gap-3">
          <div className="">
            <button onClick={() => { User.headerData.navigate("/projects"); }} className="flex justify-start items-center gap-1">
              <Icons name="left" variant="arrow" />
            </button>
          </div>
          <div>
            <div className="text-600 font-semibold text-gray-300 flex justify-start items-center gap-1.5 cursor-pointer">
              {User.headerData.project_title}
              <CustomRightToolTip>
            <div className="tooltipRight">
              <div className="w-full">
                <Icons
                  name="info-circle"
                  variant="bold"
                  className="-mb-0.5 ms-1"
                  size={15}
                  color="#232323"
                />
              </div>
              <div className="tooltiptext">
                {User.headerData.project_description}
              </div>
            </div>
          </CustomRightToolTip>
            </div>
            <div className="text-450 font-medium text-gray-300">
            {User.headerData.client_name}
            </div>
          </div>
        </div>
      );
    } else if (User.headerData !== undefined && User.headerData !== null && pathname[1] === "projects" && pathname[2] === "preview") {
      return (
        <div className="flex justify-start items-center gap-3">
          <div className="">
            <button onClick={() => { User.headerData.navigate(-1); }} className="flex justify-start items-center gap-1">
              <Icons name="left" variant="arrow" />
            </button>
          </div>
          <div>
            <div className="text-600 font-semibold text-gray-300 flex justify-start items-center gap-1.5 cursor-pointer">
              {User.headerData.project_title}
              <CustomRightToolTip>
            <div className="tooltipRight">
              <div className="w-full">
                <Icons
                  name="info-circle"
                  variant="bold"
                  className="-mb-0.5 ms-1"
                  size={15}
                  color="#232323"
                />
              </div>
              <div className="tooltiptext">
                {User.headerData.project_description}
              </div>
            </div>
          </CustomRightToolTip>
            </div>
            <div className="text-450 font-medium text-gray-300">
            {User.headerData.client_name}
            </div>
          </div>
        </div>
      );
    }else if (User.headerData !== undefined && User.headerData !== null && pathname[1] === "portfolios" && pathname[2] === "shareInsights") {
      return (
        <div className="flex justify-start items-center gap-3">
        <div className="">
          <button onClick={() => { User.headerData.navigate("/portfolios"); }} className="flex justify-start items-center gap-1">
            <Icons name="left" variant="arrow" />
          </button>
        </div>
        <div>
          <div className="text-600 font-semibold text-gray-300 flex justify-start items-center gap-1.5 cursor-pointer">
          Sharing Insights
          </div>
        
        </div>
      </div>
      );
    }else {
      return (
        <div className="">
          <Typography className="text-secondary-200 font-semibold" variant="h3">
            {pageTitle}
          </Typography>
        </div>
      );
    }
  };

  return (
    <div className={`${isOpen ? `ms-[15rem] w-[calc(100%-15rem)]` : `ms-[4.5rem] w-[calc(100%-4.5rem)]`} min-h-[5rem] flex justify-between items-center shadow-300 bg-white px-8 pe-8 fixed left-0 top-0 z-20`}>
      {renderHeaderContent()}
      <div className="flex items-center justify-end gap-3">
        <div className="relative">
          <div className="relative h-8 w-8 cursor-pointer rounded-full part-one" onClick={handleImageClick}>
            <img src={userPhoto} alt="photo" className="w-full h-full object-cover rounded-full overflow-hidden" />
          </div>
          {isUlVisible && (
            <ul ref={dropdownRef} className="absolute -right-4 top-9 z-[9] part-two">
              <li
                onClick={logout}
                className="bg-white flex justify-end items-center gap-2.5 text-400 px-4 py-4 
                relative font-semibold text-gray-300 hover:bg-gray-200 cursor-pointer w-max border border-gray-400 rounded-md"
              >
                <span>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.90002 7.55999C9.21002 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24002 20.08 8.91002 16.54" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M15 12H3.62" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5.85 8.6499L2.5 11.9999L5.85 15.3499" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                <span className="line-clamp-1">Log Out</span>
              </li>
            </ul>
          )}
        </div>
        <button className="cursor-pointer relative" onClick={ShowAuditLog}>
          <Icons size={24} name="activity" variant="stroke" className="[&>svg]:mt-1" />
        </button>
      </div>
    </div>
  );
};

export default Header;
