import React, { useEffect, useRef, useState } from "react";
import { CustomDateTimePicker } from "../../components/DateTimePicker/DatePicker";
import Accordion from "../../components/Accordion/Accordion";
import Typography from "../../components/Typography/Typography";
import Checkbox from "../../components/CheckBox/CheckBox";
import SearchBarSmall from "../../components/SearchBar/SearchBarSmall";
import Icons from "../../components/Icons/Icon";
import { FetchData, unsetArray } from "../../CommonFunctions";
import { fetchPublickLinkFilters } from "./LinkManagementFunction";
import moment from "moment";
import CustomFilters, {
  Item as FilterItem,
} from "../../components/Filters/customFilters";
import RadioButton from "../../components/Radio button/RadioButton";
import { startOfDay } from "date-fns";

const LinkManagementFilter = (prop: any) => {
  const isFilterOpen = prop.isFilterOpen;
  const setIsFilterOpen = prop.setIsFilterOpen;

  const params = prop.params;
  const setParams = prop.events.setParams;
  const setIsLoading = prop.setIsLoading;
  const setSearchInpData = prop.events.setSearchInpData;
  const bulckExpire = prop.events.bulckExpire;
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [minDate, setMinDate] = useState<string | null>(null);
  const [isMinDateError, setIsMinDateError] = useState<Boolean>(false);
  const [expiryStartDate, setExpiryStartDate] = useState<string | null>(null);
  const [expiryEndDate, setExpiryEndDate] = useState<string | null>(null);
  const [expiryMinDate, setExpiryMinDate] = useState<string | null>(null);
  const [isExpiryMinDateError, setIsExpiryMinDateError] =
    useState<Boolean>(false);
  const [accessedStartDate, setAccessedStartDate] = useState<string | null>(
    null
  );
  const [accessedEndDate, setAccessedEndDate] = useState<string | null>(null);
  const [accessedMinDate, setAccessedMinDate] = useState<string | null>(null);
  const [isAccessedMinDateError, setIsAccessedMinDateError] =
    useState<Boolean>(false);
    const [isNotHavePassword, setIsNotHavePassword] = useState<boolean | null>(
      null
    );
  const [isNotHaveExpiry, setIsNotHaveExpiry] = useState<boolean>(false);
  const [isFilterShow, setIsFilterShow] = useState<boolean>(false);
  const [isLoadFilter, setLoadFilter] = useState<Boolean>(true);
  const [isClearFilter, setIsClearFilter] = useState<Boolean>(false);
  const [filters, setFilters] = useState<FetchData>();
  const [searchByRecent, setSearchByRecent] = useState<string>("recent");

  useEffect(() => {
    setParams({
      ...params,
      page: 1,
      sort_by: searchByRecent === "recent" ? ["created_at", "DESC"] : ["created_at", "ASC"],
    });
  }, [searchByRecent]);

  useEffect(() => {
    if (isLoadFilter === true) {
      loadFilters();
    }
  }, [isLoadFilter]);

  useEffect(() => {
    setIsClearFilter(false);
  }, [params]);

  useEffect(() => {
    setMinDate(startDate);
  }, [startDate]);

  useEffect(() => {
    setExpiryMinDate(expiryStartDate);
    // filterByExpiryDate();
  }, [expiryStartDate]);

  // useEffect(() => {
  //   filterByExpiryDate();
  // }, [expiryEndDate]);

  useEffect(() => {
    setAccessedMinDate(accessedStartDate);
  }, [accessedStartDate]);

  useEffect(() => {
    setIsLoading(true);
    if (isNotHavePassword === null) {
      setParams({
        ...params,
        page: 1,
        is_not_have_password: null,
      });
    } else {
      setParams({
        ...params,
        page: 1,
        // is_not_have_password: isNotHavePassword ? 1 : 0,
        is_not_have_password: isNotHavePassword === true ? 1 : isNotHavePassword === false ? 0 : null,
      });
    }
  }, [isNotHavePassword]);

  const handleHavePasswordChange = (value: boolean) => {
    setIsNotHavePassword(value ? false : null); 
  };
  
  const handleNotHavePasswordChange = (value: boolean) => {
    setIsNotHavePassword(value ? true : null); 
  };
  
  useEffect(() => {
    setIsLoading(true);
    setParams({
      ...params,
      page: 1,
      is_not_have_expiry: isNotHaveExpiry ? 1 : 0,
    });
  }, [isNotHaveExpiry]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const loadFilters = async () => {
    setLoadFilter(false);
    setIsClearFilter(false);
    const data = await fetchPublickLinkFilters();
    setFilters(data);
  };

  const clearFilter = () => {
    setIsClearFilter(true);
    setIsLoading(true);
    setStartDate(null);
    setEndDate(null);
    setExpiryStartDate(null);
    setExpiryEndDate(null);
    setAccessedStartDate(null);
    setAccessedEndDate(null);
    setIsNotHavePassword(null);
    setIsNotHaveExpiry(false);
    setSearchByRecent("recent");
    setParams({
      ...params,
      page: 1,
      sort_by: ["id", "DESC"],
      portfolios: [],
      users: [],
      status: [],
      start_date: null,
      end_date: null,
      expiry_start_date: null,
      expiry_end_date: null,
      accessed_start_date: null,
      accessed_end_date: null,
      is_not_have_password: null,
      is_not_have_expiry: 0,
    });
  };

  useEffect(() => {
    setIsMinDateError(false);

    if (startDate === null && endDate === null) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        start_date: startDate,
        end_date: endDate,
      });
    }

    if (startDate !== null && endDate !== null) {
      const dateLimit = moment(startDate);
      const now = moment(endDate);

      if (now.isBefore(dateLimit)) {
        setIsMinDateError(true);
      } else {
        setIsLoading(true);
        setParams({
          ...params,
          page: 1,
          start_date: startDate,
          end_date: endDate,
        });
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    setIsAccessedMinDateError(false);

    if (accessedStartDate === null && accessedEndDate === null) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        accessed_start_date: accessedStartDate,
        accessed_end_date: accessedEndDate,
      });
    }

    if (accessedStartDate !== null && accessedEndDate !== null) {
      const dateLimit = moment(accessedStartDate);
      const now = moment(accessedEndDate);

      if (now.isBefore(dateLimit)) {
        setIsAccessedMinDateError(true);
      } else if (accessedStartDate !== null && accessedEndDate !== null) {
        setIsLoading(true);
        setParams({
          ...params,
          page: 1,
          accessed_start_date: accessedStartDate,
          accessed_end_date: accessedEndDate,
        });
      }
    }
  }, [accessedStartDate, accessedEndDate]);

  useEffect(() => {
    setIsExpiryMinDateError(false);

    if (expiryStartDate === null && expiryEndDate === null) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        expiry_start_date: expiryStartDate,
        expiry_end_date: expiryEndDate,
      });
    }

    if (expiryStartDate !== null && expiryEndDate !== null) {
      const dateLimit = moment(expiryStartDate);
      const now = moment(expiryEndDate);

      if (now.isBefore(dateLimit)) {
        setIsExpiryMinDateError(true);
      } else {
        setIsLoading(true);
        setParams({
          ...params,
          page: 1,
          expiry_start_date: expiryStartDate,
          expiry_end_date: expiryEndDate,
        });
      }
    }
  }, [expiryStartDate, expiryEndDate]);

  const setPortfoliosFilter = (checked: boolean, value: FilterItem) => {
    let portfolios = params["portfolios"];

    if (checked) {
      portfolios.push(value.id);
    } else {
      portfolios = unsetArray(portfolios, value.id);
    }

    setIsLoading(true);
    setParams({
      ...params,
      page: 1,
      portfolios: portfolios,
    });
  };

  const setUsersFilter = (checked: boolean, value: FilterItem) => {
    let users = params["users"];

    if (checked) {
      users.push(value.id);
    } else {
      users = unsetArray(users, value.id);
    }

    setIsLoading(true);
    setParams({
      ...params,
      page: 1,
      users: users,
    });
  };

  const setStatusFilter = (checked: boolean, value: FilterItem) => {
    let status = params["status"];

    if (checked) {
      status.push(value.id);
    } else {
      status = unsetArray(status, value.id);
    }

    setIsLoading(true);
    setParams({
      ...params,
      page: 1,
      status: status,
    });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsFilterShow(false);
    }
  };

  return (
    <div
      className={`px-5 py-8 pt-0 h-full group absolute left-0 top-0 z-[11] border-r
     border-gray-100 bg-gray-200 rounded-s-md ease-in duration-300 overflow-y-auto overflow-x-hidden flex flex-1 
      ${isFilterOpen ? `w-[12.125rem]` : `w-[3.75rem] hover:w-[12.125rem]`}`}
    >
      <div className="w-full">
        <div className="pt-8 pb-4 mb-3 flex justify-start items-center gap-2.5 relative after:bg-gray-200 after:h-[5rem] after:absolute after:-right-[1.3rem] after:top-0 after:w-9 after:-z[10]">
          <div className="flex gap-1.5 justify-start items-center order-2">
            <div className="text-200 font-bold text-gray-500">FILTER</div>
            <div className="text-gray-500 text-100">|</div>
            <button
              className="text-200 font-normal text-primary-100 whitespace-nowrap"
              onClick={() => {
                clearFilter();
              }}
            >
              Clear All
            </button>
          </div>
          <button
            className="relative bg-gray-100 order-1 block p-0 me-4"
            onClick={() => {
              setIsFilterOpen(!isFilterOpen);
            }}
          >
            {!isFilterOpen ? (
              <span className="absolute left-0 -top-1.5">
                <svg
                  width="1rem"
                  height="0.625rem"
                  viewBox="0 0 16 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.8753 0.875C15.2895 0.875 15.6253 1.21079 15.6253 1.625C15.6253 2.03921 15.2895 2.375 14.8753 2.375H1.12494C0.710726 2.375 0.374939 2.03921 0.374939 1.625C0.374939 1.21079 0.710726 0.875 1.12494 0.875H14.8753ZM15.6253 5.19667C15.6253 4.78246 15.2895 4.44667 14.8753 4.44667H1.12494C0.710726 4.44667 0.374939 4.78246 0.374939 5.19667C0.374939 5.61088 0.710726 5.94667 1.12494 5.94667H14.8753C15.2895 5.94667 15.6253 5.61088 15.6253 5.19667ZM15.6253 8.76833C15.6253 8.35411 15.2895 8.01833 14.8753 8.01833H1.12494C0.710726 8.01833 0.374939 8.35411 0.374939 8.76833C0.374939 9.18254 0.710726 9.51833 1.12494 9.51833H14.8753C15.2895 9.51833 15.6253 9.18254 15.6253 8.76833Z"
                    fill="#888888"
                  ></path>
                </svg>
              </span>
            ) : (
              <span className="absolute left-0 -top-2.5">
                <svg
                  width="1.25rem"
                  height="1.25rem"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.9751 4.94165L2.91677 9.99998L7.9751 15.0583"
                    stroke="#888888"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M17.0835 10H3.0585"
                    stroke="#888888"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </span>
            )}
          </button>
        </div>
        <div>
          {!isClearFilter && filters !== undefined && filters["status"] === "success" ? (
            <>
              {/* {filters !== undefined && filters["status"] === "success" && (
                <> */}
                  <Accordion
                    isFilterOpen={isFilterOpen}
                    count={1}
                    subTitle=""
                    time=""
                    title="Password"
                    icon={
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 11V10C9 8.34 9.5 7 12 7C14.5 7 15 8.34 15 10V11"
                          stroke="#888888"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 14.6C12.3314 14.6 12.6 14.3314 12.6 14C12.6 13.6687 12.3314 13.4 12 13.4C11.6686 13.4 11.4 13.6687 11.4 14C11.4 14.3314 11.6686 14.6 12 14.6Z"
                          stroke="#888888"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14.5 17H9.5C7.5 17 7 16.5 7 14.5V13.5C7 11.5 7.5 11 9.5 11H14.5C16.5 11 17 11.5 17 13.5V14.5C17 16.5 16.5 17 14.5 17Z"
                          stroke="#888888"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                          stroke="#888888"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                  >
                    <div
                      className={`${
                        isFilterOpen
                          ? `inline-block`
                          : `hidden group-hover:inline-block`
                      }`}
                    >
                      <div className="flex justify-start items-center gap-2 mt-3">
                        {/* <Checkbox
                          checked={
                            (isNotHavePassword === null ||
                            isNotHavePassword === false) && false
                              // ? false
                              // : true
                          }
                          onCustomChange={(value) => {
                            setIsNotHavePassword(!value);
                          }}
                          size="sm"
                        /> */}
                       <Checkbox
                          checked={isNotHavePassword === false} 
                          onCustomChange={(value) => handleHavePasswordChange(value)}
                          size="sm"
                        />
                        <label className="text-gray-500 font-normal text-200">
                          Have Password
                        </label>
                      </div>
                      <div className="flex justify-start items-center gap-2 mt-3">
                        {/* <Checkbox
                          checked={isNotHavePassword ?? false}
                          onCustomChange={(value) => {
                            setIsNotHavePassword(value);
                          }}
                          size="sm"
                        /> */}
                       <Checkbox
                          checked={isNotHavePassword === true}
                          onCustomChange={(value) => handleNotHavePasswordChange(value)}
                          size="sm"
                        />
                        <label className="text-gray-500 font-normal text-200">
                          Not Have Password
                        </label>
                      </div>
                    </div>
                  </Accordion>
                  <Accordion
                    isFilterOpen={isFilterOpen}
                    count={2}
                    subTitle=""
                    time=""
                    title="Sort By"
                    icon={
                      <svg
                        width="16"
                        height="18"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 7H21"
                          stroke="#888888"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path
                          d="M6 12H18"
                          stroke="#888888"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path
                          d="M10 17H14"
                          stroke="#888888"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                    }
                  >
                    <div
                      className={`${
                        isFilterOpen
                          ? `inline-block`
                          : `hidden group-hover:inline-block`
                      }`}
                    >
                      <div className="flex justify-start items-center gap-2 mt-3">
                        {/* <Checkbox
                          checked={searchByRecent}
                          onChange={(value) =>
                            setSearchByRecent(value.target.checked)
                          }
                          size="sm"
                        /> */}
                         <RadioButton
                          selectedValue={searchByRecent}
                          name="searchByRecent"
                          onChange={(value) => setSearchByRecent(value)}
                          size="sm"
                          value="recent"
                        />
                        <label className="text-gray-500 font-normal text-200">
                          Recent
                        </label>
                      </div>
                      <div className="flex justify-start items-center gap-2 mt-3">
                        {/* <Checkbox
                          checked={!searchByRecent}
                          onChange={(value) =>
                            setSearchByRecent(!value.target.checked)
                          }
                          size="sm"
                        /> */}
                         <RadioButton
                          selectedValue={searchByRecent} 
                          name="searchByOldest"
                          onChange={(value) => setSearchByRecent(value)}
                          size="sm"
                          value="oldest"
                        />
                        <label className="text-gray-500 font-normal text-200">
                          Oldest
                        </label>
                      </div>
                    </div>
                  </Accordion>
                  <Accordion
                    isFilterOpen={isFilterOpen}
                    count={3}
                    subTitle=""
                    time=""
                    title="Portfolio"
                    icon={
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M22 11V17C22 21 21 22 17 22H7C3 22 2 21 2 17V7C2 3 3 2 7 2H8.5C10 2 10.33 2.44 10.9 3.2L12.4 5.2C12.78 5.7 13 6 14 6H17C21 6 22 7 22 11Z"
                          stroke="#888888"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                        />
                        <path
                          d="M8 2H17C19 2 20 3 20 5V6.38"
                          stroke="#888888"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                  >
                    <div
                      className={`${
                        isFilterOpen
                          ? `inline-block`
                          : `hidden group-hover:inline-block`
                      }`}
                    >
                      <CustomFilters
                        selected={params["portfolios"]}
                        filter={filters?.data.portfolios}
                        type="single"
                        isSearchBar={true}
                        count={4}
                        onChange={setPortfoliosFilter}
                      />
                    </div>
                  </Accordion>
                  <Accordion
                    isFilterOpen={isFilterOpen}
                    count={4}
                    subTitle=""
                    time=""
                    title="Status"
                    icon={
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 18 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.7691 14.1923H4.84606C2.76914 14.1923 1.38452 13.1539 1.38452 10.7308V5.88463C1.38452 3.46156 2.76914 2.4231 4.84606 2.4231H11.7691C13.8461 2.4231 15.2307 3.46156 15.2307 5.88463V10.7308C15.2307 13.1539 13.8461 14.1923 11.7691 14.1923Z"
                          stroke="#888888"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M11.7693 6.23077L9.60234 7.96154C8.88927 8.52924 7.71927 8.52924 7.00619 7.96154L4.84619 6.23077"
                          stroke="#888888"
                          strokeWidth="1.5"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M13.8463 15.923C15.7502 15.923 17.3078 14.3653 17.3078 12.4615C17.3078 10.5576 15.7502 8.99994 13.8463 8.99994C11.9425 8.99994 10.3848 10.5576 10.3848 12.4615C10.3848 14.3653 11.9425 15.923 13.8463 15.923Z"
                          fill="#F8F8F8"
                          stroke="#888888"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M12.375 12.4614L13.3546 13.441L15.3173 11.4818"
                          stroke="#888888"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    }
                  >
                    <div
                      className={`${
                        isFilterOpen
                          ? `inline-block`
                          : `hidden group-hover:inline-block`
                      }`}
                    >
                      <CustomFilters
                        selected={params["status"]}
                        filter={filters?.data.status}
                        type="single"
                        isSearchBar={false}
                        count={4}
                        onChange={setStatusFilter}
                      />
                    </div>
                  </Accordion>
                  <Accordion
                    isFilterOpen={isFilterOpen}
                    count={4}
                    subTitle=""
                    time=""
                    title="Generated By"
                    icon={
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.16 10.87C9.06 10.86 8.94 10.86 8.83 10.87C6.45 10.79 4.56 8.84 4.56 6.44C4.56 3.99 6.54 2 9 2C11.45 2 13.44 3.99 13.44 6.44C13.43 8.84 11.54 10.79 9.16 10.87Z"
                          stroke="#888888"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16.41 4C18.35 4 19.91 5.57 19.91 7.5C19.91 9.39 18.41 10.93 16.54 11C16.46 10.99 16.37 10.99 16.28 11"
                          stroke="#888888"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.16 14.56C1.74 16.18 1.74 18.82 4.16 20.43C6.91 22.27 11.42 22.27 14.17 20.43C16.59 18.81 16.59 16.17 14.17 14.56C11.43 12.73 6.92 12.73 4.16 14.56Z"
                          stroke="#888888"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.34 20C19.06 19.85 19.74 19.56 20.3 19.13C21.86 17.96 21.86 16.03 20.3 14.86C19.75 14.44 19.08 14.16 18.37 14"
                          stroke="#888888"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                  >
                    <div
                      className={`${
                        isFilterOpen
                          ? `inline-block`
                          : `hidden group-hover:inline-block`
                      }`}
                    >
                      <CustomFilters
                        selected={params["users"]}
                        filter={filters?.data.users}
                        type="single"
                        isSearchBar={true}
                        count={4}
                        onChange={setUsersFilter}
                      />
                    </div>
                  </Accordion>
                {/* </>
              ) : (
                ""
              )} */}
              <Accordion
                isFilterOpen={isFilterOpen}
                count={5}
                subTitle=""
                time=""
                title="Generated On"
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_1519_1966)">
                      <path
                        d="M6.98242 11.0254L8.10742 12.1504L11.1074 9.15039"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.5 4.5H10.5C12 4.5 12 3.75 12 3C12 1.5 11.25 1.5 10.5 1.5H7.5C6.75 1.5 6 1.5 6 3C6 4.5 6.75 4.5 7.5 4.5Z"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 3.01562C14.4975 3.15063 15.75 4.07312 15.75 7.50063V12.0006C15.75 15.0006 15 16.5006 11.25 16.5006H6.75C3 16.5006 2.25 15.0006 2.25 12.0006V7.50063C2.25 4.08063 3.5025 3.15063 6 3.01562"
                        stroke="#888888"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1519_1966">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                }
              >
                <div
                  className={`${
                    isFilterOpen
                      ? `inline-block`
                      : `hidden group-hover:inline-block`
                  }`}
                >
                  <div className="mt-3 small-datepicker">
                    <label className="text-gray-300 font-normal text-200 mb-1.5">
                      Start date
                    </label>
                    <CustomDateTimePicker
                      // onChange={(value) =>
                      //   setStartDate(value && value.toISOString())
                      // }
                      onChange={(value) => {
                        if (value) {
                          const timezoneOffsetMs = new Date().getTimezoneOffset() * 60000;
                          const adjustedDate = new Date(value.getTime() - timezoneOffsetMs);
                          setStartDate(adjustedDate.toISOString());
                        }
                        //  else {
                        //   setStartDate(null);
                        // }
                      }}
                      selectedDate={null}       
                      maxDate={new Date()}
                      size="sm"
                    />
                     {startDate === null && endDate ?(
                    <Typography
                      className="text-primary-100 font-normal mt-0.5"
                      variant="p"
                      size="sm"
                    >
                      Please select start date
                    </Typography>
                     ):""}
                  </div>
                  <div className="mt-2 small-datepicker">
                    <label className="text-gray-300 font-normal text-200 mb-1.5">
                      End date
                    </label>
                    <CustomDateTimePicker
                      // onChange={(value) =>
                      //   setEndDate(value && value.toISOString())
                      // }
                      onChange={(value) => {
                        if (value) {
                          const timezoneOffsetMs = new Date().getTimezoneOffset() * 60000;
                          const adjustedDate = new Date(value.getTime() - timezoneOffsetMs);
                          setEndDate(adjustedDate.toISOString());
                        } 
                        // else {
                        //   setEndDate(null);
                        // }
                      }}
                      selectedDate={null}
                      size="sm"
                      maxDate={new Date()} 
                    />
                     {startDate !== null && endDate === null?(
                    <Typography
                      className="text-primary-100 font-normal mt-0.5"
                      variant="p"
                      size="sm"
                    >
                      Please select end date
                    </Typography>
                     ):isMinDateError?(
                    <Typography
                      className="text-primary-100 font-normal mt-0.5"
                      variant="p"
                      size="sm"
                    >
                      End date can not be less than start date
                    </Typography>
                     ):""}
                  </div>
                </div>
              </Accordion>
              <Accordion
                isFilterOpen={isFilterOpen}
                count={6}
                subTitle=""
                time=""
                title="Expiry"
                icon={
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 2V5"
                      stroke="#888888"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16 2V5"
                      stroke="#888888"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.5 9.08997H20.5"
                      stroke="#888888"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M18 23C20.2091 23 22 21.2091 22 19C22 16.7909 20.2091 15 18 15C15.7909 15 14 16.7909 14 19C14 21.2091 15.7909 23 18 23Z"
                      stroke="#888888"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M19.07 20.11L16.95 18"
                      stroke="#888888"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M19.0499 18.02L16.9299 20.14"
                      stroke="#888888"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21 8.5V16.36C20.27 15.53 19.2 15 18 15C15.79 15 14 16.79 14 19C14 19.75 14.21 20.46 14.58 21.06C14.79 21.42 15.06 21.74 15.37 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                      stroke="#888888"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.9955 13.7H12.0045"
                      stroke="#888888"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.29431 13.7H8.30329"
                      stroke="#888888"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.29431 16.7H8.30329"
                      stroke="#888888"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
              >
                <div
                  className={`${
                    isFilterOpen
                      ? `inline-block`
                      : `hidden group-hover:inline-block`
                  }`}
                >
                  <div className="mt-3 small-datepicker">
                    <label className="text-gray-300 font-normal text-200 mb-1.5">
                      Start date
                    </label>
                    <CustomDateTimePicker
                      onChange={(value) =>
                        setExpiryStartDate(value && value.toISOString())
                      }
                      selectedDate={null}
                      size="sm"
                      maxDate={new Date()}
                    />
                    {expiryStartDate === null && expiryEndDate !== null ?(
                    <Typography
                      className="text-primary-100 font-normal mt-0.5"
                      variant="p"
                      size="sm"
                    >
                      Please select start date
                    </Typography>
                    ):""}
                  </div>
                  <div className="mt-2 small-datepicker">
                    <label className="text-gray-300 font-normal text-200 mb-1.5">
                      End date
                    </label>
                    <CustomDateTimePicker
                      onChange={(value) =>
                        setExpiryEndDate(value && value.toISOString())
                      }
                      selectedDate={null}
                      size="sm"
                      maxDate={new Date()}
                    />
                     {expiryStartDate !== null && expiryEndDate === null?(
                    <Typography
                      className="text-primary-100 font-normal mt-0.5"
                      variant="p"
                      size="sm"
                    >
                      Please select end date
                    </Typography>
                     ):isMinDateError?(
                    <Typography
                      className="text-primary-100 font-normal mt-0.5"
                      variant="p"
                      size="sm"
                    >
                      End date can not be less than start date
                    </Typography>
                      ):""}
                  </div>
                </div>
              </Accordion>
              <Accordion
                isFilterOpen={isFilterOpen}
                count={7}
                subTitle=""
                time=""
                title="Accessed On"
                icon={
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 2V5"
                      stroke="#888888"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16 2V5"
                      stroke="#888888"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.5 9.08997H20.5"
                      stroke="#888888"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M22 19C22 19.75 21.79 20.46 21.42 21.06C20.73 22.22 19.46 23 18 23C16.99 23 16.07 22.63 15.37 22C15.06 21.74 14.79 21.42 14.58 21.06C14.21 20.46 14 19.75 14 19C14 16.79 15.79 15 18 15C19.2 15 20.27 15.53 21 16.36C21.62 17.07 22 17.99 22 19Z"
                      stroke="#888888"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M16.4399 19L17.4299 19.99L19.5599 18.02"
                      stroke="#888888"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21 8.5V16.36C20.27 15.53 19.2 15 18 15C15.79 15 14 16.79 14 19C14 19.75 14.21 20.46 14.58 21.06C14.79 21.42 15.06 21.74 15.37 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
                      stroke="#888888"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.9955 13.7H12.0045"
                      stroke="#888888"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.29431 13.7H8.30329"
                      stroke="#888888"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M8.29431 16.7H8.30329"
                      stroke="#888888"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
              >
                <div
                  className={`${
                    isFilterOpen
                      ? `inline-block`
                      : `hidden group-hover:inline-block`
                  }`}
                >
                  <div className="mt-3 small-datepicker">
                    <label className="text-gray-300 font-normal text-200 mb-1.5">
                      Start date
                    </label>
                    {/* <CustomDateTimePicker
                      onChange={(value) =>
                        setAccessedStartDate(value && value.toISOString())
                      }
                      selectedDate={null}
                      size="sm"
                    /> */}
                    <CustomDateTimePicker
                      onChange={(value) => {
                        if (value) {
                          const timezoneOffsetMs = new Date().getTimezoneOffset() * 60000;
                          const adjustedDate = new Date(value.getTime() - timezoneOffsetMs);
                          setAccessedStartDate(adjustedDate.toISOString());
                        } else {
                          setAccessedStartDate(null);
                        }
                      }}
                      selectedDate={null}
                      size="sm"
                      maxDate={new Date()}
                      />
                      {accessedStartDate === null && accessedEndDate !== null ?(
                    <Typography
                      className="text-primary-100 font-normal mt-0.5"
                      variant="p"
                      size="sm"
                    >
                      Please select start date
                    </Typography>
                    ):""}
                  </div>
                  <div className="mt-2 small-datepicker">
                    <label className="text-gray-300 font-normal text-200 mb-1.5">
                      End date
                    </label>
                    {/* <CustomDateTimePicker
                      onChange={(value) =>
                        setAccessedEndDate(value && value.toISOString())
                      }
                      selectedDate={null}
                      size="sm"
                    /> */}
                    <CustomDateTimePicker
                      onChange={(value) => {
                        if (value) {
                          const timezoneOffsetMs = new Date().getTimezoneOffset() * 60000;
                          const adjustedDate = new Date(value.getTime() - timezoneOffsetMs);
                          setAccessedEndDate(adjustedDate.toISOString());
                        } else {
                          setAccessedEndDate(null);
                        }
                      }}
                      selectedDate={null}
                      size="sm"
                      maxDate={new Date()}
                    />
                     {accessedStartDate !== null && accessedEndDate === null?(
                    <Typography
                      className="text-primary-100 font-normal mt-0.5"
                      variant="p"
                      size="sm"
                    >
                      Please select end date
                    </Typography>
                    ):isMinDateError?(
                    <Typography
                      className="text-primary-100 font-normal mt-0.5 "
                      variant="p"
                      size="sm"
                    >
                      End date can not be less than start date
                    </Typography>
                      ):""}
                  </div>
                </div>
              </Accordion>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default LinkManagementFilter;
