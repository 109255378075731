import { createSlice } from "@reduxjs/toolkit";

export type UserState = {
  businessPhones: [];
  displayName: string;
  givenName: string;
  id: string;
  jobTitle: string;
  mail: string;
  mobilePhone: string;
  officeLocation: string;
  preferredLanguage: string;
  surname: string;
  userPrincipalName: string;
  role:string;
  headerData:any;
};

export const userState: UserState = {
  businessPhones: [],
  displayName: "",
  givenName: "",
  id: "",
  jobTitle: "",
  mail: "",
  mobilePhone: "",
  officeLocation: "",
  preferredLanguage: "",
  surname: "",
  userPrincipalName: "",
  role:"",
  headerData: null,
};

export const User = createSlice({
  name: "user",
  initialState: userState,
  reducers: {
    update: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.

      state.businessPhones = action.payload.businessPhones;
      state.displayName = action.payload.displayName;
      state.givenName = action.payload.givenName;
      state.id = action.payload.id;
      state.jobTitle = action.payload.jobTitle;
      state.mail = action.payload.mail;
      state.mobilePhone = action.payload.mobilePhone;
      state.officeLocation = action.payload.officeLocation;
      state.preferredLanguage = action.payload.preferredLanguage;
      state.surname = action.payload.surname;
      state.userPrincipalName = action.payload.userPrincipalName;
    },
    updateRole: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.

      state.role = action.payload;
    },
    updateHeaderData: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.

      state.headerData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { update,updateRole,updateHeaderData } = User.actions;

export default User.reducer;
