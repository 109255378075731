import React from "react";

const PortfolioEmpty = () => {
  return (
    <div className="text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="167"
        height="167"
        viewBox="0 0 167 167"
        fill="none"
      >
        <circle cx="83.8555" cy="83.8389" r="83.0293" fill="#F4F4F4" />
        <mask
          id="mask0_2428_13994"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="167"
          height="167"
        >
          <circle cx="83.8555" cy="83.8389" r="83.0293" fill="#F4F4F4" />
        </mask>
        <g mask="url(#mask0_2428_13994)">
          <rect
            x="115.074"
            y="63.2476"
            width="59.109"
            height="81.0366"
            rx="6"
            fill="white"
          />
          <rect
            x="123.654"
            y="113.279"
            width="42.9017"
            height="5.63733"
            rx="2.81866"
            fill="#EFEFEF"
          />
          <rect
            x="123.654"
            y="123.849"
            width="33.368"
            height="5.63733"
            rx="2.81866"
            fill="#EFEFEF"
          />
          <rect
            x="123.654"
            y="68.8848"
            width="42.9017"
            height="39.4613"
            rx="6"
            fill="#F8F8F8"
          />
          <rect
            x="-5.81641"
            y="63.2476"
            width="59.109"
            height="81.0366"
            rx="6"
            fill="white"
          />
          <rect
            x="2.76367"
            y="113.279"
            width="42.9017"
            height="5.63733"
            rx="2.81866"
            fill="#EFEFEF"
          />
          <rect
            x="2.76367"
            y="123.849"
            width="33.368"
            height="5.63733"
            rx="2.81866"
            fill="#EFEFEF"
          />
          <rect
            x="2.76367"
            y="68.8848"
            width="42.9017"
            height="39.4613"
            rx="6"
            fill="#F8F8F8"
          />
          <g filter="url(#filter0_d_2428_13994)">
            <rect
              x="49.9805"
              y="41.9922"
              width="67.3245"
              height="124.876"
              rx="6"
              fill="white"
            />
            <rect
              x="50.4805"
              y="42.4922"
              width="66.3245"
              height="123.876"
              rx="5.5"
              stroke="#EFEFEF"
            />
          </g>
          <rect
            x="59.752"
            y="119.089"
            width="48.8645"
            height="8.68703"
            rx="4"
            fill="#EFEFEF"
          />
          <rect
            x="59.752"
            y="135.378"
            width="38.0058"
            height="8.68703"
            rx="4"
            fill="#EFEFEF"
          />
          <rect
            x="59.752"
            y="50.6792"
            width="48.8645"
            height="60.8092"
            rx="6"
            fill="#F8F8F8"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_2428_13994"
            x="41.9805"
            y="35.9922"
            width="83.3242"
            height="140.876"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="2" />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_2428_13994"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_2428_13994"
              result="shape"
            />
          </filter>
        </defs>
      </svg>

      <div className="text-gray-700 text-300 font-normal mt-2">
        No Portfolio to show.
      </div>
    </div>
  );
};

export default PortfolioEmpty;
