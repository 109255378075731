import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import "./layout.css";
import { getActiveMenu,rolesGroup } from "../../CommonFunctions";
import Header from "../Header/Header";
import Menu from "../Menu/Menu";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const User = useSelector((state: any) => state.user);
  const location = useLocation();
  const pathname = location.pathname.split("/");

  const isActive = getActiveMenu(pathname[1]);
  const [isOpen,setIsOpen] = useState<boolean>(false);
  let navigationItems:any = [];

  if(
    User.role !== "" && 
    (
      User.role === "Millipixels Interactive LLP" || 
      User.role === "EchoShow-Admin"
    )
  ){
    navigationItems = [
      {
        icon: "property7-default",
        label: "Portfolios",
        link: "portfolios",
      },
      {
        icon: "property8-default",
        label: "Projects",
        link: "projects",
      },
      {
        icon: "property9-default",
        label: "Assets",
        link: "assets",
      },
      {
        icon: "property10-default",
        label: "Client Management",
        link: "clients",
      },
      {
        icon: "property11-default",
        label: "Service Areas",
        link: "categories",
      },
      {
        icon: "property12-default",
        label: "Link Management",
        link: "links",
      },
      // {
      //   icon: "property2-default",
      //   label: "User Management",
      //   link: "users",
      // },
    ];
  }else if(
    User.role !== "" && 
    (
      User.role === "EchoShow-Marketing"
    )
  ){
    navigationItems = [
      {
        icon: "property7-default",
        label: "Portfolios",
        link: "portfolios",
      },
      {
        icon: "property10-default",
        label: "Client Management",
        link: "clients",
      },
      {
        icon: "property12-default",
        label: "Link Management",
        link: "links",
      },
    ];
  }

  return (
    <div className="app bg-gray-200">
      <Menu navigationItems={navigationItems} isActive={isActive} isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className={`${isOpen?`ms-[15rem] w-[calc(100%-15rem)]`:`ms-[4.5rem] w-[calc(100%-4.5rem)]`} h-auto`}>
        <Header pageTitle={isActive} isOpen={isOpen} />
        <div className="page-body px-8 py-4 mt-20 min-h-[calc(100vh-5rem)]">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
