import React from "react";
import * as Yup from "yup";
import { showToastAlert, callFetch } from "../../CommonFunctions";

const controller = new AbortController();

export interface InputFiled {
  name: any;
  url: any;
  file_type: any;
}

export const ErrorMessages: InputFiled = {
  name: "",
  url: "",
  file_type: "",
};

export const validationSchema = Yup.object({
  name: Yup.string()
    .required("Title is required")
    .min(2, "Too Short!")
    .max(254, "Too Long!"),
  url: Yup.string().required("URL is required").url("Please enter a valid url"),
});

export const saveLinks = async (props: any) => {
  const errorMessage = ErrorMessages;
  props.setIsFromSubmiting(true);

  let url = process.env.REACT_APP_API_URL + "assets";
  url =
    props.asset !== undefined && props.asset !== null
      ? url + "/" + props.asset.id
      : url;

  return await callFetch({
    url: url,
    method: "POST",
    body: JSON.stringify(props.formData),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      props.setIsFromSubmiting(false);

      if (json.status === "success") {
        showToastAlert(json.message, 1);
        props.setIsLoading(true);
        if (props.asset === undefined) {
          props.setAsset(null);
        } else {
          props.setAsset(undefined);
        }
        props.resetForm();
        props.loadData();
      } else if (
        json.status === "error" &&
        json.error === "validation_errors"
      ) {
        for (const name in json.validation_errors) {
          errorMessage[name as keyof InputFiled] =
            json.validation_errors[name][0];
        }

        props.setErrorMessage(errorMessage);
      } else {
        showToastAlert(json.message);
      }

      return json;
    },
  });
};
