import React from "react";

export interface SkeletonLoaderProps {
  width: string | number;
  height: string | number;
  borderRadius?: string | number;
  customClass?: string;
  children?: React.ReactNode;
}

const SkeletonLoader: React.FC<SkeletonLoaderProps> = ({
  width,
  height,
  borderRadius,
  customClass,
  children,
}) => {
  const style: React.CSSProperties = {
    width,
    height,
    borderRadius,
    // backgroundColor: '#f0f0f0',
  };

  const classNames = ["skeleton-loader", customClass].filter(Boolean).join(" ");

  return (
    <div
      className={`border border-gray-100 shadow rounded-md p-4 max-w-sm w-full ${
        classNames ? classNames : ""
      }`}
      style={style}
    >
      <div className="animate-pulse flex space-x-4">{children}</div>
    </div>
  );
};

export default SkeletonLoader;
