import React, { ChangeEvent, ReactElement } from "react";
import TextInput from "../TextInput/TextInput";

export type InputWithIconProps = {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: ReactElement;
  className?: string;
  placeholder?: string;
  onFocus?:any;
  onBlur?:any;
  onKeyUp?:any;
};

const SearchBarSmall: React.FC<InputWithIconProps> = ({
  value,
  onChange,
  icon,
  className,
  placeholder,
  onFocus,
  onBlur,
  onKeyUp,
}) => {
  // const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   onChange(event.target.value);
  // };

  return (
    <div className={`relative ${className ? className : ""}`}>
      <TextInput
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        placeholder={placeholder ? placeholder : "Search"}
        value={value}
        size="sm"
        className={`${icon ? "!pr-8" : ""}`}
      />
      {icon && (
        <div className="icon-after absolute right-3 top-1/2 -translate-y-1/2 [&>svg]:!w-[14px] [&>svg]:!h-[14px] [&>svg]:!stroke-gray-700">
          {icon}
        </div>
      )}
    </div>
  );
};

export default SearchBarSmall;
