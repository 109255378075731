import React, { useState } from "react";

export interface TooltipProps {
  content: string;
  children: React.ReactNode;
  trigger?: boolean;
  placement?: string;
  size?: "small" | "medium" | "large"; // Add a size prop
}

const Tooltip: React.FC<TooltipProps> = ({
  content,
  children,
  trigger,
  placement = "top",
  size = "medium", // Default size is medium
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    setIsClicked(false);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setIsClicked(false);
  };

  const handleClick = () => {
    setIsClicked(!isClicked);
    setIsHovered(false);
  };

  const getTooltipSizeClass = () => {
    switch (size) {
      case "small":
        return "text-xs p-1";
      case "large":
        return "text-200 !min-w-full !max-w-lg !w-max p-4 font-medium";
      default:
        return "text-base p-2";
    }
  };

  const ToolTipData = () => {
    return (
      <div
        className={`absolute min-w-full w-auto max-w-xs z-10
          ${placement === "top" ? "pb-2 bottom-full" : ""}
          ${placement === "bottom" ? "bottom-auto pt-2 top-full" : ""}
          ${
            placement === "right"
              ? "top-2/4 -translate-y-2/4 bottom-auto pl-2 left-full"
              : ""
          }
          ${
            placement === "left"
              ? "top-2/4 -translate-y-2/4 bottom-auto pr-2 right-full"
              : ""
          }
          ${getTooltipSizeClass()} // Apply size class
        `}
      >
        <div className="bg-white text-gray-300 rounded-md w-fit text-center mx-auto p-4 shadow-100">
          {content}
        </div>
        <div
          className={`h-2 w-2 bg-white -rotate-45 absolute mx-auto shadow-100
            ${
              placement === "top"
                ? "-translate-y-1/2 bottom-0 left-0 right-0"
                : ""
            }
            ${
              placement === "bottom"
                ? "bottom-auto top-0 translate-y-1/2 left-0 right-0"
                : ""
            }
            ${
              placement === "right"
                ? "top-2/4 -translate-y-2/4 left-0 translate-x-2/4"
                : ""
            }
            ${
              placement === "left"
                ? "top-2/4 -translate-y-2/4 right-0 -translate-x-2/4"
                : ""
            }
          `}
        ></div>
      </div>
    );
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      className={`relative inline-block`}
    >
      {children}
      {trigger ? isHovered && <ToolTipData /> : isClicked && <ToolTipData />}
    </div>
  );
};

export default Tooltip;
