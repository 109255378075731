import React, {useState} from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import {
  inputChangeHandler,
  FetchData,
} from "../../CommonFunctions";
import {saveContactUsData,InputFiled,validationSchema} from "./ClientViewFunction";
import clarieniLogo from "../../images/clarieniLogo.png";
import Icons from "../../components/Icons/Icon";
import Typography from "../../components/Typography/Typography";
import AnotherInput from "../../components/TextInput/AnotherInput";
import Spinner from "../../components/Spinner/Spinner";

const InputClientDetails = () => {
  const formData: InputFiled = {
    client_name: "",
    company_name: "",
    phone_number: "",
    email: "",
    description: ""
  };

  const [FormData, setFormData] = useState<InputFiled>(formData);
  const [isFromSubmiting, setIsFromSubmiting] = useState(false);
  const navigate = useNavigate();

  const submitHandler = async (values: InputFiled, { setErrors }: any) => {
    await saveContactUsData({
      setIsFromSubmiting: setIsFromSubmiting,
      formData: values,
      setErrorMessage: setErrors,
      navigate: navigate,
    });
  };

  return (
    <div className="min-h-screen w-full bg-gray-400">
      <div className="p-5">
        <button type="button" onClick={()=>navigate(-1)} className="flex justify-center items-center text-300 text-gray-300 font-medium">
          <Icons name="Arrow-left" variant="bold" size={20} /> 
          <span className="align-super ms-0.5">Go Back</span>
        </button>
      </div>
      <div className="pt-[4.5rem] flex justify-center max-md:pt-8">
        <img src={clarieniLogo} alt={clarieniLogo} className="max-w-full max-md:w-[13.75rem] max-md:h-[3.125rem]" />
      </div>
      <div className="flex justify-center mt-[3.75rem]">
        <Icons name="questionBg" variant="bold" size={64} className="bounce" />
      </div>
      <div className="flex justify-center mt-3.5">
        <Typography variant="h3" className="text-gray-300 font-bold max-sm:text-600">
          Request Support
        </Typography>
      </div>
      <div className="grid grid-cols-5 justify-center xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-1 sm:grid-cols-1 min-[320px]:grid-cols-1">
        <div></div>
        <div className="col-span-3">
          <Formik
            initialValues={FormData}
            validationSchema={validationSchema}
            onSubmit={submitHandler}
          >
            {({ setValues, values }) => (
              <Form>
                <div className="lg:px-[3rem] md:px-20 min-[320px]:px-5">
                  <div className="flex justify-center items-center text-center mt-6 max-sm:mt-2 px-4">
                    <Typography
                      size="xxl"
                      variant="p"
                      className="text-gray-500 font-normal"
                    >
                      Welcome to our support page. Please fill out our support request
                      form to initiate a new ticket. Your prompt cooperation ensures a
                      swift resolution. Thank you.
                    </Typography>
                  </div>
                  <div className="grid grid-cols-1 mt-12 pb-14 border-b border-gray-800">
                    <div className="grid grid-cols-2 gap-x-4 gap-y-6 md:grid-cols-2 sm:grid-cols-1 min-[320px]:grid-cols-1">
                      <div>
                        <div className="p-[0.063rem] relative border border-gray-100 bg-white z-10 rounded-md overflow-hidden group">
                          <input
                            name="Client Name"
                            type="text"
                            className="text-gray-300 text-500 font-normal px-4 py-[11px] bg-white border-none outline-none z-20 rounded-[0.313rem] w-full placeholder:text-gray-700 peer"
                            placeholder="Your Name"
                            onChange={(event) => {
                              inputChangeHandler(event.target.value, {
                                setValues: setValues,
                                key: "client_name",
                                formData: values,
                              });
                            }}
                          />
                          <span
                            className="absolute top-0 left-0 w-[0%] h-[0%] -z-10 bg-gray-300 peer-focus:w-full f peer-focus:h-full"
                            style={{ transition: "height 0.5s, width 0.5s 0.3s" }}
                          ></span>
                          {/* <AnotherInput
                            onBlur={function noRefCheck() {}}
                            onChange={function noRefCheck() {}}
                            onFocus={function noRefCheck() {}}
                            onKeyPress={function noRefCheck() {}}
                            placeholder="Your Name"
                            type="text"
                          /> */}
                        </div>
                        <ErrorMessage
                          name="client_name"
                          component="div"
                          className="error text-error text-300 mt-1"
                        />
                      </div>
                      <div>
                        <div className="p-[0.063rem] relative border border-gray-100 bg-white z-10 rounded-md overflow-hidden group">
                          <input
                            name="company_name"
                            type="text"
                            className="text-gray-300 text-500 font-normal px-4 py-[11px] bg-white border-none outline-none z-20 rounded-[0.313rem] w-full placeholder:text-gray-700 peer"
                            placeholder="Your Company"
                            onChange={(event) => {
                              inputChangeHandler(event.target.value, {
                                setValues: setValues,
                                key: "company_name",
                                formData: values,
                              });
                            }}
                          />
                          <span
                            className="absolute top-0 left-0 w-[0%] h-[0%] -z-10 bg-gray-300 peer-focus:w-full f peer-focus:h-full"
                            style={{ transition: "height 0.5s, width 0.5s 0.3s" }}
                          ></span>
                          {/* <AnotherInput
                            onBlur={function noRefCheck() {}}
                            onChange={function noRefCheck() {}}
                            onFocus={function noRefCheck() {}}
                            onKeyPress={function noRefCheck() {}}
                            placeholder="Your Company"
                            type="text"
                          /> */}
                        </div>
                        <ErrorMessage
                          name="company_name"
                          component="div"
                          className="error text-error text-300 mt-1"
                        />
                      </div>
                      <div>
                        <div className="p-[0.063rem] relative border border-gray-100 bg-white z-10 rounded-md overflow-hidden group">
                          <input
                            type="phone_number"
                            className="text-gray-300 text-500 font-normal px-4 py-[11px] bg-white border-none outline-none z-20 rounded-[0.313rem] w-full placeholder:text-gray-700 peer"
                            placeholder="Phone Number"
                            onChange={(event) => {
                              inputChangeHandler(event.target.value, {
                                setValues: setValues,
                                key: "phone_number",
                                formData: values,
                              });
                            }}
                            maxLength={16}
                          />
                          <span
                            className="absolute top-0 left-0 w-[0%] h-[0%] -z-10 bg-gray-300 peer-focus:w-full f peer-focus:h-full"
                            style={{ transition: "height 0.5s, width 0.5s 0.3s" }}
                          ></span>
                          {/* <AnotherInput
                            onBlur={function noRefCheck() {}}
                            onChange={function noRefCheck() {}}
                            onFocus={function noRefCheck() {}}
                            onKeyPress={function noRefCheck() {}}
                            placeholder="Phone Number"
                            type="number"
                          /> */}
                        </div>
                        <ErrorMessage
                          name="phone_number"
                          component="div"
                          className="error text-error text-300 mt-1"
                        />
                      </div>
                      <div>
                        <div className="p-[0.063rem] relative border border-gray-100 bg-white z-10 rounded-md overflow-hidden group">
                          <input
                            name="email"
                            type="email"
                            className="text-gray-300 text-500 font-normal px-4 py-[11px] bg-white border-none outline-none z-20 rounded-[0.313rem] w-full placeholder:text-gray-700 peer"
                            placeholder="Your Email"
                            onChange={(event) => {
                              inputChangeHandler(event.target.value, {
                                setValues: setValues,
                                key: "email",
                                formData: values,
                              });
                            }}
                          />
                          <span
                            className="absolute top-0 left-0 w-[0%] h-[0%] -z-10 bg-gray-300 peer-focus:w-full f peer-focus:h-full"
                            style={{ transition: "height 0.5s, width 0.5s 0.3s" }}
                          ></span>
                          {/* <AnotherInput
                            onBlur={function noRefCheck() {}}
                            onChange={function noRefCheck() {}}
                            onFocus={function noRefCheck() {}}
                            onKeyPress={function noRefCheck() {}}
                            placeholder="Your Email"
                            type="email"
                          /> */}
                        </div>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error text-error text-300 mt-1"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 mt-6">
                      <div className="p-[0.063rem] relative border border-gray-100 bg-white z-10 rounded-md overflow-hidden group">
                        <textarea
                          name="description"
                          placeholder="How may we assist you today?"
                          className="text-gray-300 text-500 font-normal px-4 py-[11px] bg-white border-none outline-none z-20 rounded-[0.313rem] w-full h-full placeholder:text-gray-700 peer resize-none"
                          rows={4}
                          onChange={(event) => {
                            inputChangeHandler(event.target.value, {
                              setValues: setValues,
                              key: "description",
                              formData: values,
                            });
                          }}
                        ></textarea>
                        <span
                          className="absolute top-0 left-0 w-[0%] h-[0%] -z-10 bg-gray-300 peer-focus:w-full f peer-focus:h-full"
                          style={{ transition: "height 0.5s, width 0.5s 0.3s" }}
                        ></span>
                      </div>
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="error text-error text-300 mt-1"
                      />
                    </div>
                  </div>
                  <div className="flex justify-center mt-8 pb-14">
                    <button 
                      className="bg-secondary-100 py-[1.125rem] px-[1.875rem] rounded-md text-500 font-medium 
                        text-white transition-all hover:scale-105"
                      disabled={isFromSubmiting}
                    >
                      {
                        isFromSubmiting ? (
                          <span className="flex"><Spinner labal="Processing..." /></span>
                        ) : (
                          "Request Support"
                        )
                      }
                      
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default InputClientDetails;
