import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "../../components/Modal/Modal";
import Button from "../../components/Button/Button";
import Typography from "../../components/Typography/Typography";
import { Dropdown } from "../../components/DropDown/DropDown";
import TextInput from "../../components/TextInput/TextInput";
import InputWithDropdown from "../../components/InputWith Dropdown/InputWithDropdown";
import Checkbox from "../../components/CheckBox/CheckBox";
import TagInput from "../../components/TagInput/TagInput";
import { CustomDateTimePicker } from "../../components/DateTimePicker/DatePicker";

type Props = {
  onClose: () => void;
};

const AddNewUserModal: React.FC<Props> = ({ onClose }) => {
  return (
    <Modal
      isOpen
      onClose={onClose}
      size="mediumextra"
      placement="right"
      className="z-[9999]"
    >
      <ModalHeader className="bg-gray-200 !px-8">
        <Typography className="font-bold text-gray-300" variant="h3">
          Add User
        </Typography>
      </ModalHeader>
      <ModalBody className="!px-8 !py-8">
        <div className="grid grid-cols-2">
          <div className="w-[27.5rem]">
            <div>
              <label className="text-400 text-gray-300 font-medium mb-2 inline-block">
                Role<span className="text-primary-100 ms-0.5">*</span>
              </label>
              <Dropdown
                onChange={function noRefCheck() {}}
                options={[
                  {
                    label: "Admin",
                    value: "1",
                  },
                  {
                    label: "Sales & Marketing Admin",
                    value: "2",
                  },
                ]}
                type="box"
                placeholder="Select"
              />
              <span className="text-100 text-primary-100 hidden">
                Role is required!
              </span>
            </div>
            <div className="mt-8">
              <label className="text-400 text-gray-300 font-medium mb-2 inline-block">
                Employee name<span className="text-primary-100 ms-0.5">*</span>
              </label>
              <TextInput
                onBlur={function noRefCheck() {}}
                onChange={function noRefCheck() {}}
                onFocus={function noRefCheck() {}}
                onKeyPress={function noRefCheck() {}}
                placeholder="Enter employee name"
                type="text"
              />
              <span className="text-100 text-primary-100 hidden">
                Employee name is required!
              </span>
            </div>
            <div className="mt-8">
              <label className="text-400 text-gray-300 font-medium mb-2 inline-block">
                Company email address
                <span className="text-primary-100 ms-0.5">*</span>
              </label>
              <TextInput
                onBlur={function noRefCheck() {}}
                onChange={function noRefCheck() {}}
                onFocus={function noRefCheck() {}}
                onKeyPress={function noRefCheck() {}}
                placeholder="Enter company email address"
                type="email"
              />
              <span className="text-100 text-primary-100 hidden">
                Email address is required!
              </span>
            </div>
            <div className="mt-8">
              <label className="text-400 text-gray-300 font-medium mb-2 inline-block">
                Personal email address
                <span className="text-primary-100 ms-0.5">*</span>
              </label>
              <TextInput
                onBlur={function noRefCheck() {}}
                onChange={function noRefCheck() {}}
                onFocus={function noRefCheck() {}}
                onKeyPress={function noRefCheck() {}}
                placeholder="Enter personal email address"
                type="email"
              />
              <span className="text-100 text-primary-100 hidden">
                Email address is required!
              </span>
            </div>
            <div className="mt-8">
              <label className="text-400 text-gray-300 font-medium mb-2 inline-block">
                Phone number<span className="text-primary-100 ms-0.5">*</span>
              </label>
              <InputWithDropdown
                onSelect={function noRefCheck() {}}
                options={[
                  {
                    label: "+91",
                    value: "India",
                  },
                  {
                    label: "+1",
                    value: "United States",
                  },
                  {
                    label: "+44",
                    value: "United Kingdom",
                  },
                  {
                    label: "+61",
                    value: "Australia",
                  },
                ]}
              />
              <span className="text-100 text-primary-100 hidden">
                Phone number is required!
              </span>
            </div>
            <div className="mt-8">
              <label className="text-400 text-gray-300 font-medium mb-2 inline-block">
                Team<span className="text-primary-100 ms-0.5">*</span>
              </label>
              <Dropdown
                onChange={function noRefCheck() {}}
                options={[
                  {
                    label: "QA Team",
                    value: "1",
                  },
                  {
                    label: "Design Team",
                    value: "2",
                  },
                ]}
                type="box"
                placeholder="Select"
              />
              <span className="text-100 text-primary-100 hidden">
                Team is required!
              </span>
            </div>
            <div className="flex justify-start items-center mt-3 gap-2">
              <Checkbox checked onChange={function noRefCheck() {}} size="sm" />
              <label className="text-400 text-gray-500 font-normal">
                Mark User as helpdesk member
              </label>
            </div>
            <div className="mt-8">
              <label className="text-400 text-gray-300 font-medium mb-2 inline-block">
                Timezone<span className="text-primary-100 ms-0.5">*</span>
              </label>
              <Dropdown
                onChange={function noRefCheck() {}}
                options={[
                  {
                    label: "(UTC-11:00) Niue Time",
                    value: "1",
                  },
                  {
                    label: "(UTC-11:00) Samoa Standard Time",
                    value: "2",
                  },
                ]}
                type="box"
                placeholder="Select"
              />
              <span className="text-100 text-primary-100 hidden">
                Timezone is required!
              </span>
            </div>
          </div>
          <div className="w-[27.5rem]">
            <div>
              <label className="text-400 text-gray-300 font-medium mb-2 inline-block">
                Tags<span className="text-primary-100 ms-0.5">*</span>
              </label>
              <TagInput
                onTagsChange={function noRefCheck() {}}
                value={[]}
                placeholder="Type here to add tags…"
              />
              <span className="text-100 text-primary-100 hidden">
                Tags are required for user categorization.
              </span>
            </div>
            <div className="mt-8">
              <label className="text-400 text-gray-300 font-medium mb-2 inline-block">
                Joining date<span className="text-primary-100 ms-0.5">*</span>
              </label>
              <CustomDateTimePicker
                onChange={function noRefCheck() {}}
                selectedDate="10/2/2024"
              />
              <span className="text-100 text-primary-100 hidden">
                Joining date is required!
              </span>
            </div>
            <div className="mt-8 pt-8 border-t border-gray-100">
              <Typography variant="h5" className="font-bold text-gray-300">
                Login Credentials
              </Typography>
              <Typography
                size="lg"
                variant="p"
                className="text-gray-700 mt-1 font-normal"
              >
                Set credentials manually
              </Typography>
            </div>
            <div className="mt-8">
              <label className="text-400 text-gray-300 font-medium mb-2 inline-block">
                Username<span className="text-primary-100 ms-0.5">*</span>
              </label>
              <TextInput
                onBlur={function noRefCheck() {}}
                onChange={function noRefCheck() {}}
                onFocus={function noRefCheck() {}}
                onKeyPress={function noRefCheck() {}}
                placeholder="Enter user name"
                type="text"
                disabled
              />
              <span className="text-100 text-primary-100 hidden">
                User name is required!
              </span>
            </div>
            <div className="mt-8">
              <label className="text-400 text-gray-300 font-medium mb-2 inline-block">
                Password<span className="text-primary-100 ms-0.5">*</span>
              </label>
              <TextInput
                onBlur={function noRefCheck() {}}
                onChange={function noRefCheck() {}}
                onFocus={function noRefCheck() {}}
                onKeyPress={function noRefCheck() {}}
                placeholder="Enter password"
                type="password"
              />
              <span className="text-100 text-primary-100 hidden">
                Password is required!
              </span>
            </div>
            <div className="flex justify-start items-center mt-3 gap-2">
              <Checkbox checked onChange={function noRefCheck() {}} size="sm" />
              <label className="text-400 text-gray-500 font-normal">
                Change password on first login
              </label>
            </div>
            <div className="mt-8">
              <label className="text-400 text-gray-300 font-medium mb-2 inline-block">
                Schedule to send invite
                <span className="text-primary-100 ms-0.5">*</span>
              </label>
              <CustomDateTimePicker
                onChange={function noRefCheck() {}}
                selectedDate="10/2/2024"
              />
              <span className="text-100 text-primary-100 hidden">
                Schedule date is required!
              </span>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="!px-8">
        <div className="w-full flex justify-start items-end gap-3">
          <Button
            label="Invite User"
            onClick={function noRefCheck() {}}
            variant="primary"
            size="large"
            isDisabled
          />
          <Button
            label="Create & Invite Another"
            onClick={onClose}
            variant="outline"
            size="large"
            // isDisabled
          />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AddNewUserModal;
