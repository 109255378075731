import * as Yup from "yup";
import { showToastAlert, callFetch } from "../../CommonFunctions";

const controller = new AbortController();

export interface InputFiled {
  filename: any;
  file_type: any;
  source?: string;
  description?: string;
  file?: [];
}

export const ErrorMessages: InputFiled = {
  filename: "",
  source: "",
  description: "",
  file_type: "",
};

export const validationSchema = Yup.object({
  filename: Yup.array().required("File is required").test('fileFormat', 'Only image, video and pdf files are allowed', value => {
    if (value !== undefined && value) {
      for(const i in value){
        const fileData = value[i].split('.');
        const fileExtantion = String(fileData[fileData.length-1]);
        const supportedFormats = [
          'jpeg',
          'jpg',
          'png',
          'pdf',
          'doc',
          'docx',
          'csv',
          'mp4',
          'avi',
          'wmv',
        ];
        return supportedFormats.includes(fileExtantion);
      }
    }
    return true;
  }),
  // source: Yup.string()
  //   .required("File name is required")
  //   .min(2, "Too Short!")
  //   .max(254, "Too Long!"),
});

export const saveFiles = async (props: any) => {
  const errorMessage = ErrorMessages;
  props.setIsFromSubmiting(true);

  const formData = new FormData(props.form.current);

  let url = process.env.REACT_APP_API_URL + "assets";
  url =
    props.asset !== undefined &&
    props.asset !== null &&
    props.asset.id !== undefined
      ? url + "/" + props.asset.id
      : url;

  return await callFetch({
    url: url,
    method: "POST",
    body: formData,
    controller: controller,
    headers: {},
    callback: async (json: any) => {
      props.setIsFromSubmiting(false);

      if (json.status === "success") {
        showToastAlert(json.message, 1);
        props.setIsLoading(true);
        if (props.asset === undefined) {
          props.setAsset(null);
        } else {
          props.setAsset(undefined);
        }
        props.resetForm();
        props.loadData();
      } else if (
        json.status === "error" &&
        json.error === "validation_errors"
      ) {
        for (const name in json.validation_errors) {
          if (name === "file") {
            errorMessage["filename"] = json.validation_errors[name][0];
          } else {
            errorMessage[name as keyof InputFiled] =
              json.validation_errors[name][0];
          }
        }

        props.setErrorMessage(errorMessage);
      } else {
        showToastAlert(json.message);
      }

      return json;
    },
  });
};
