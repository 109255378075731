import React, { useState } from "react";
import Button from "../../components/Button/Button";
import Typography from "../../components/Typography/Typography";
import ecoLogo from "../../images/ecoLogo.svg";
type User = {
  name: string;
};

interface PageProps {
  event: any;
}

const Login = ({ event }: PageProps) => {
  const [user, setUser] = useState();

  return (
    <div className="h-screen flex flex-col bg-gray-600 items-center">
      <div className="pt-12">
        <img
          src={ecoLogo}
          alt="logo"
          className="w-auto max-w-full h-10 object-cover object-left-top"
        />
      </div>

      <div className="h-full grid justify-items-center items-center">
        <section className="text-center">
          <Typography className="font-bold mb-2" variant="h2">
            Login to Echo-Show
          </Typography>
          <div className="tip-wrapper">
            <Typography className="my-5" variant="h4">
              Welcome back!<br></br>
              Please sign-in to see your profile information.
            </Typography>
            <Button
              label="Login"
              variant="primary"
              type="button"
              size="large"
              onClick={event}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default Login;
