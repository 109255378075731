import React from 'react'
import styled from 'styled-components';

export const SearchInput = styled.div`
    input{
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3e%3cpath d='M9.58317 17.4993C13.9554 17.4993 17.4998 13.9549 17.4998 9.58268C17.4998 5.21043 13.9554 1.66602 9.58317 1.66602C5.21092 1.66602 1.6665 5.21043 1.6665 9.58268C1.6665 13.9549 5.21092 17.4993 9.58317 17.4993Z' stroke='%23888888' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/%3e%3cpath d='M18.3332 18.3327L16.6665 16.666' stroke='%23888888' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round'/%3e%3c/svg%3e");
      background-repeat:no-repeat;
      background-position-y:center;
      background-position-x:0.75rem;
    }
`;

const SearchCategory = (prop:any) => {
  return (
    <div className='full'>
        <SearchInput>
            <input 
              type='text' 
              placeholder='Search service area' 
              className='outline-none w-full bg-white border border-gray-100 ps-10 pe-3 py-[0.563rem] text-400 font-normal 
              rounded-md text-gray-300 placeholder:font-normal
          focus:border-secondary-100 focus:shadow-200
          disabled:bg-gray-200 read-only:!shadow-0 read-only:!border-gray-100'
            onChange={prop.setSearchInpData}
          />
        </SearchInput>
    </div>
  )
}

export default SearchCategory