import React from "react";
import { FieldProps } from "formik";
import TextInput, { TextInputProps } from "../TextInput/TextInput";

interface CustomFieldProps extends FieldProps, TextInputProps {
  label: string;
  isRequierd:boolean;
}

const CustomField: React.FC<CustomFieldProps> = ({
  field,
  form,
  label,
  type,
  placeholder,
  isRequierd,
  ...props
}) => {
  const { name } = field;

  return (
    <div>
      <label
        className="text-400 font-medium text-gray-300 block mb-2"
        htmlFor={name}
      >
        {label} {isRequierd?<span className="text-primary-100">*</span>:""}
      </label>
      <TextInput placeholder={placeholder} {...field} {...props} />
    </div>
  );
};

export default CustomField;
