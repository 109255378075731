import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { convertParamsToArr,FetchData,pushArray } from "../../CommonFunctions";
import { EmptyData } from "../../components/Errors/Errors";
import Typography from "../../components/Typography/Typography";
import { fetchPortfolios } from "./PortfolioFunction";
import SinglePortfolio from "./SinglePortfolio";
import PortfoliosFilter from "./PortfoliosFilter";
import PortfoliosTopbar from "./PortfoliosTopbar";
import SinglePortfolioArchive from "./SinglePortfolioArchive";
import SinglePortfolioDraft from "./SinglePortfolioDraft";
import EmptyPortfolios from "./EmptyPortfolios";
import SinglePortfolioPrivate from "./SinglePortfolioPrivate";
import CustomLoader from "../../components/Loader/CustomLoader";
import PortfolioEmpty from "./PortfolioEmpty";
import { useSelector } from "react-redux";

const Portfolios = () => {
  let paramsData = useParams();
  paramsData = convertParamsToArr({ params: paramsData });
  paramsData["sort_by"] = ["created_at", "DESC"];
  paramsData["status"] = paramsData[0] !== undefined && paramsData[0] !== "" ?"":"";
  paramsData["categories"] = []; 
  paramsData["tags"] = [];
  paramsData["start_date"] = null;
  paramsData["end_date"] = null;
  paramsData["is_private"] = 0;
  paramsData["page"] = 1;

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadMore, setIsLoadMore] = useState<Boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState<Boolean>(false);
  const [portfoliosData, setPortfoliosData] = useState<FetchData>();
  const [params, setParams] = useState(paramsData);
  const [searchInp, setSearchInp] = useState<string | null>(null);
  const [role, setRole] = useState<string>("");
  const User = useSelector((state: any) => state.user);
  
  useEffect(()=>{
    setRole(User.role)
  }, [User])
  useEffect(() => {
    setIsLoadMore(true);
    loadData();
  }, [params]);

  useEffect(() => {
    if (portfoliosData !== undefined) {
      setIsLoading(false);
    }
    setIsLoadMore(false);
  }, [portfoliosData]);

  const loadData = async () => {
    const data:FetchData = await fetchPortfolios({params});

    if(data.status === "error" && data.error === "abort"){return false;}
    
    // if(portfoliosData !== undefined && params["page"] > 1 && data.status === "success"){
    //   let log = portfoliosData["data"];
    //   for(const i in data["data"]){
    //     log = pushArray(log,data["data"][i]);
    //   }
    //   setPortfoliosData({
    //     ...portfoliosData,
    //     data:log
    //   });
    // }else if(params["page"] === 1){
      setPortfoliosData(data); 
    // }
  };

  useEffect(() => {
    searchPortfolios();
  }, [searchInp]);

  const setSearchInpData = (e: any) => {
    setSearchInp(e.target.value);
  };

  const searchPortfolios = async () => {
    if (searchInp != null && (searchInp.length >= 1 || searchInp.length <= 0)) {
      setIsLoading(true);
      setParams({
        ...params,
        page:1,
        0: searchInp,
      });
    }
  };
  const setPagination=(page: number)=>{
    setParams({
      ...params,
      page:page,
    });
  }
  const events = {
    loadData: loadData,
    setIsLoadMore:setIsLoadMore,
    setParams:setParams,
  };

  return (
    <div className="">
      <div className="my-2 relative h-[calc(100vh-8rem)] border border-gray-100 rounded-md shadow-300">
        <div className="flex h-full relative">
          <PortfoliosFilter 
            params={params}
            setParams={setParams}
            setIsLoading={setIsLoading}
            isFilterOpen={isFilterOpen}
            setIsFilterOpen={setIsFilterOpen}
          />
          <div className={`${isFilterOpen?`w-[calc(100%-12.125rem)] ms-[12.125rem]`:`w-[calc(100%-4rem)] ms-16`} h-full bg-gray-200 rounded-e-md overflow-auto`}>
            <PortfoliosTopbar 
              params={params}
              setParams={setParams}
              setIsLoading={setIsLoading}
              setSearchInpData={setSearchInpData}
              totalData={portfoliosData?.data?.length}
              meta={portfoliosData?.meta}
              setPagination={setPagination}
            />
            <div className="p-5">
              {isLoading === true ? (
                <div className="flex justify-center items-center w-full h-[calc(100vh-12.75rem)]">
                <CustomLoader/>
                </div>
                // <div className="grid grid-cols-4 gap-5">
                //   {Array.from({ length: 8 }).map((_, idx) => (
                //     <div key={idx} className="animate-pulse">
                //       <div className="flex-1 space-y-6">
                //         <div className="h-40 bg-gray-100 rounded" />
                //       </div>
                //       <div className="space-y-3 mt-2">
                //         <div className="flex justify-between items-center gap-2">
                //           <div className="h-2 w-1/2 bg-gray-100 rounded" />
                //           <div className="order-last h-2 w-5 bg-gray-100 rounded" />
                //         </div>
                //       </div>
                //     </div>
                //   ))}
                // </div>
              ) : portfoliosData !== undefined && portfoliosData["status"] === "success" && portfoliosData.data.length > 0 ? (
                <div className="grid grid-cols-4 gap-x-4 gap-y-3">
                  <PortfolioItem
                    portfoliosData={portfoliosData}
                    isLoadMore={isLoadMore}
                    params={params}
                    events={events}
                    role={role}
                  />
                </div>
              // ) : portfoliosData !== undefined && portfoliosData["status"] === "error" &&
              //   portfoliosData["error"] === 404 ? (
              //   <div className="flex justify-center items-center h-[calc(100vh-16.5rem)]">
              //     <EmptyPortfolios message="No portfolio added" />
              //   </div>
              ) : (
                <div className="flex justify-center items-center w-full h-[calc(100vh-12.75rem)]">
                <PortfolioEmpty/>
              </div>
                // <div className="flex justify-center items-center h-[calc(100vh-16.5rem)] text-center">
                //   <Typography variant="h5">Something went wrong!</Typography>
                // </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PortfolioItem = (props: any) => {
  const portfoliosData = props.portfoliosData;
  const isLoadMore = props.isLoadMore;
  const params = props.params;
  const loadData = props.events.loadData;
  const setParams = props.events.setParams;

  return (
    <>
      {portfoliosData["status"] === "success" ? (
        <>
          {/* {portfoliosData.data.map((item: any, idx: any) =>
            item.status === 1 ? (
              <SinglePortfolio key={idx} portfolio={item} loadData={loadData} />
            ) : item.status === 0? (
              <SinglePortfolioDraft
                key={idx}
                portfolio={item}
                loadData={loadData}
              />
            ): (
              <SinglePortfolioArchive
                key={idx}
                portfolio={item}
                loadData={loadData}
              />
            )
          )} */}
          {portfoliosData.data.map((item: any, idx: any) => {
              if (item.is_public === 0 && item.status === 1) {
                return (
                  <SinglePortfolioPrivate
                    key={idx}
                    portfolio={item}
                    loadData={loadData}
                    role={props.role}
                  />
                );
              } else if (item.is_public === 1 && item.status === 1) {
                return (
                  <SinglePortfolio
                    key={idx}
                    portfolio={item}
                    loadData={loadData}
                    role={props.role}
                  />
                );
              } else if (item.is_public === 1 && item.status === 0) {
                return (
                  <SinglePortfolioDraft
                    key={idx}
                    portfolio={item}
                    loadData={loadData}
                    role={props.role}
                  />
                );
              } else {
                return (
                  <SinglePortfolioArchive
                    key={idx}
                    portfolio={item}
                    loadData={loadData}
                    role={props.role}
                  />
                );
              }
            })}

          {/* <div className="col-span-4 w-full justify-center text-center items-center align-middle">
            {isLoadMore?(
              <div className="flex justify-center items-center mt-2">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="duration-200 animate-pulse-3"
                >
                  <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                </svg>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="duration-300 animate-pulse-2"
                >
                  <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                </svg>
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="duration-400 animate-pulse"
                >
                  <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                </svg>
              </div>
            ):(
              params['page'] < portfoliosData.meta.last_page?
                <button
                  className="text-200 font-bold text-blue-500 border-b inline-block"
                  onClick={()=>{
                    setParams({
                      ...params,
                      page:params["page"]+1
                    });
                  }}
                >
                  <span className="">Load More</span>
                </button>
              :""
            )}
          </div> */}
        </>
      ) : portfoliosData["status"] === "error" &&
        portfoliosData["error"] === 404 ? (
        <EmptyData
          heading={"You haven't created any portfolio"}
          btnTitle={"Create new portfolio"}
          link={"/portfolios/add"}
        />
      ) : (
        <div className="mt-20 text-center">
          <Typography variant="h5">Something went wrong!</Typography>
        </div>
      )}
    </>
  );
};

export const PortfoliosLoader = fetchPortfolios;

export default Portfolios;
