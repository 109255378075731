import React from "react";
import { FieldProps, useFormikContext } from "formik";
import {
  Dropdown,
  DropdownProps,
  SelectOption,
  SelectChangeHandler,
} from "../DropDown/DropDown";

interface CustomDropDownProps extends FieldProps, DropdownProps {
  label: string;
  isRequierd:boolean;
}

const CustomDropDown: React.FC<CustomDropDownProps> = ({
  field,
  form,
  label,
  type,
  placeholder,
  isRequierd,
  ...props
}) => {
  const { value, name } = field;

  return (
    <div>
      <label
        className="text-400 font-medium text-gray-300 block mb-2"
        htmlFor={name}
      >
        {label} {isRequierd?<span className="text-primary-100">*</span>:""}
      </label>
      <Dropdown placeholder={placeholder} type={type} {...field} {...props} />
    </div>
  );
};

export type SelectOptions = SelectOption;
export type ChangeHandler = SelectChangeHandler;

export default CustomDropDown;
