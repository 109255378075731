import React, { ChangeEvent, useEffect, useState } from "react";

export type CheckboxProps = {
  label?: string;
  size?: string;
  checked: boolean;
  name?: string;
  id?: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  value?: string;
  className?: string;
  containerClassName?: string;
  readOnly?: boolean;
  onCustomChange?: (checked: boolean) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  checked,
  onCustomChange,
  onChange,
  size,
  name,
  id,
  disabled,
  error,
  errorMessage,
  value,
  className,
  containerClassName,
  readOnly,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleChange = (event: any) => {
    const newChecked = event.target.checked;
    onChange?.(event);
    if (onCustomChange) {
      onCustomChange(newChecked);
    }
  };

  useEffect(() => {
    setIsChecked(checked)
  }, [checked]);

  const handleLabelClick = () => {
    const newChecked = !isChecked; // Toggle the checked state
    setIsChecked(newChecked);

    if (onChange) {
      onChange({
        target: { checked: newChecked },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  };

  return (
    <div
      className={`inline-block relative ${
        containerClassName ? containerClassName : ""
      } ${error ? "error" : ""}`}
    >
      <label
        className={`flex gap-3 items-center ${size === "sm" ? "text-sm" : ""}`}
        onClick={handleLabelClick}
      >
        <input
          type="checkbox"
          checked={isChecked}
          disabled={disabled}
          name={name}
          value={value}
          onClick={handleChange}
          className={`
          relative appearance-none rounded border border-gray-100 peer bg-white cursor-pointer
          checked:bg-gray-300 checked:border-gray-300
          after:content-[''] after:absolute after:inset-0 after:h-100 after:w-100 after:bg-check after:bg-no-repeat after:bg-center after:bg-[length:0.75rem_0.75rem]
          ${
            size === "sm"
              ? "w-4 h-4 after:bg-[length:0.5rem_0.5rem] checked:bg-gray-300 checked:border-gray-300"
              : "w-5 h-5"
          }
          ${
            error
              ? "rounded-b-none border-primary-100 focus:border-primary-100 focus:shadow-0"
              : ""
          }
          ${className ? className : ""}
          `}
          readOnly={readOnly}
          id={`${id ? id : ""}`}
        />
        {label ? <span>{label}</span> : null}
      </label>
      {error && (
        <div className="icon-container bg-primary-100 flex items-center gap-2 py-3 px-4 text-white text-400 rounded-b-md">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 9V14"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.9999 21.41H5.93993C2.46993 21.41 1.01993 18.93 2.69993 15.9L5.81993 10.28L8.75993 5.00003C10.5399 1.79003 13.4599 1.79003 15.2399 5.00003L18.1799 10.29L21.2999 15.91C22.9799 18.94 21.5199 21.42 18.0599 21.42H11.9999V21.41Z"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.9946 17H12.0036"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          {error && <div className="error">{errorMessage}</div>}
        </div>
      )}
    </div>
  );
};

export default Checkbox;
