import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  convertParamsToArr,
  FetchData,
  pushArray,
  unsetArray,
} from "../../CommonFunctions";
import Icons from "../../components/Icons/Icon";
import { CustomDateTimePicker } from "../../components/DateTimePicker/DatePicker";
import TextInput from "../../components/TextInput/TextInput";
import GoogleChart from "../../components/Chart/chart";
import SearchLink from "./SearchLink";
import { Dropdown } from "../../components/DropDown/DropDown";
import AccordionTwo from "../../components/Accordion/AccordionTwo";
import CustomFilters, {
  Item as FilterItem,
} from "../../components/Filters/customFilters";
import Checkbox from "../../components/CheckBox/CheckBox";
import Spinner from "../../components/Spinner/Spinner";
import Typography from "../../components/Typography/Typography";
import ClientProjectsSharedModal from "./ClientProjectsSharedModal";
import {
  fetchpublicLinksIPList,
  fetchPublickLinkViewFilters,
} from "./LinkManagementFunction";
import moment from "moment";
import IPListEmpty from "./IPListEmpty";
import CustomLoader from "../../components/Loader/CustomLoader";
import Accordion from "../../components/Accordion/Accordion";
import { PaginationComponent } from "../../components/Pagination/Pagination";

const IPList = (prop: any) => {
  let paramsData = useParams();
  paramsData = convertParamsToArr({ params: paramsData });
  paramsData["sort_by"] = ["created_at", "DESC"];
  paramsData["is_inactive"] = 0;
  paramsData["device"] = [];
  paramsData["browser"] = [];
  paramsData["view_rate"] = [];
  paramsData["link_id"] = prop.link_id;
  paramsData["start_date"] = null;
  paramsData["end_date"] = null;
  paramsData["type"] = "all";
  paramsData["page"] = 1;

  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const [isLoadMore, setIsLoadMore] = useState<Boolean>(false);
  const [publickLinkData, setPublickLinkData] = useState<FetchData>();
  const [searchInp, setSearchInp] = useState<any>(null);
  const [params, setParams] = useState(paramsData);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [minDate, setMinDate] = useState<string | null>(null);
  const [isMinDateError, setIsMinDateError] = useState<Boolean>(false);
  const [isLoadFilter, setLoadFilter] = useState<Boolean>(true);
  const [isClearFilter, setIsClearFilter] = useState<Boolean>(false);
  const [isShowGroupBy, setIsShowGroupBy] = useState<Boolean>(false);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [filters, setFilters] = useState<FetchData>();
  const counter = 2;

  const filterRef = useRef<any>();
  const timeRef = useRef<any>([]);

  useEffect(() => {
    if (isLoadFilter === true) {
      loadFilters();
    }
  }, [isLoadFilter]);

  useEffect(() => {
    setIsClearFilter(false);
  }, [params]);

  useEffect(() => {
    setMinDate(startDate);
    filterByDate();
  }, [startDate]);

  useEffect(() => {
    filterByDate();
  }, [endDate]);

  useEffect(() => {
    if (publickLinkData !== undefined) {
      setIsLoading(false);
    }

    setIsLoadMore(false);
  }, [publickLinkData]);

  useEffect(() => {
    setIsLoadMore(true);
    loadData(false);
  }, [params]);

  useEffect(() => {
    searchPublickLink();
  }, [searchInp]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const loadFilters = async () => {
    setLoadFilter(false);
    setIsClearFilter(false);
    const data = await fetchPublickLinkViewFilters(prop.link_id);
    setFilters(data);
  };

  const clearFilter = () => {
    setIsClearFilter(true);
    setIsLoading(true);
    setStartDate(null);
    setEndDate(null);
    setParams({
      ...params,
      page: 1,
      device: [],
      browser: [],
      view_rate: [],
      start_date: null,
      end_date: null,
    });
  };

  const filterByDate = () => {
    setIsMinDateError(false);

    if (startDate === null && endDate === null) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        start_date: startDate,
        end_date: endDate,
      });
    }

    if (startDate === null || endDate === null) {
      return false;
    }

    const dateLimit = moment(startDate);
    const now = moment(endDate);

    if (now.isBefore(dateLimit)) {
      setIsMinDateError(true);
    } else if (startDate !== null && endDate !== null) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        start_date: startDate,
        end_date: endDate,
      });
    }
  };

  const setDeviceFilter = (checked: boolean, value: string) => {
    let device = params["device"];

    if (checked) {
      device.push(value);
    } else {
      device = unsetArray(device, value);
    }

    setIsLoading(true);
    setParams({
      ...params,
      page: 1,
      device: device,
    });
  };

  const setBrowserFilter = (checked: boolean, value: string) => {
    let browser = params["browser"];

    if (checked) {
      browser.push(value);
    } else {
      browser = unsetArray(browser, value);
    }

    setIsLoading(true);
    setParams({
      ...params,
      page: 1,
      browser: browser,
    });
  };

  const setViewRateFilter = (checked: boolean, value: FilterItem) => {
    let view_rate = params["view_rate"];

    if (checked) {
      view_rate.push(value.id);
    } else {
      view_rate = unsetArray(view_rate, value.id);
    }

    setIsLoading(true);
    setParams({
      ...params,
      page: 1,
      view_rate: view_rate,
    });
  };

  const setSearchInpData = (e: any) => {
    setSearchInp(e.target.value);
  };

  const searchPublickLink = async () => {
    if (searchInp != null && (searchInp.length >= 1 || searchInp.length <= 0)) {
      setIsLoading(true);
      setParams({
        ...params,
        page: 1,
        0: searchInp,
      });
    }
  };

  const loadData = async (isLoader = true) => {
    if (isLoader) {
      setIsLoading(true);
      params["page"] = 1;
    }

    const data: FetchData = await fetchpublicLinksIPList({ params });

    if (data.status === "error" && data.error === "abort") {
      return false;
    }

    if (
      publickLinkData !== undefined &&
      params["page"] > 1 &&
      data.status === "success"
    ) {
      let log = publickLinkData["data"];
      for (const i in data["data"]) {
        log = pushArray(log, data["data"][i]);
      }
      setPublickLinkData({
        ...publickLinkData,
        data: log,
      });
    } else if (params["page"] === 1) {
      setPublickLinkData(data);
    }
  };

  const showFilter = () => {
    filterRef.current.className =
      "absolute top-[46px] left-[0px] max-h-[20rem] w-[14rem] z-20 rounded-md bg-white border py-8 px-5 border-gray-100 shadow-100 overflow-auto";
  };

  const showTime = (ip: any) => {
    timeRef.current[ip].className =
      "text-300 bg-white border border-gray-100 rounded-md shadow-200 absolute left-0 top-[24px] z-[1] p-3";
  };

  const ShowCount = (count: number) => {
    return count - counter;
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (filterRef.current !== undefined) {
      for (const i in filterRef.current) {
        if (
          filterRef.current !== null &&
          !filterRef.current.contains(event.target as Node)
        ) {
          filterRef.current.className =
            "absolute top-[46px] left-[0px] max-h-[20rem] w-[14rem] z-20 rounded-md bg-white border py-8 px-5 border-gray-100 shadow-100 overflow-auto hidden";
        }
      }
    }

    if (timeRef.current !== undefined) {
      for (const i in timeRef.current) {
        if (
          timeRef.current[i] !== null &&
          !timeRef.current[i].contains(event.target as Node)
        ) {
          timeRef.current[i].className =
            "text-300 bg-white border border-gray-100 rounded-md shadow-200 absolute left-0 top-[24px] z-[1] p-3 hidden";
        }
      }
    }
  };

  const SharedProjects = (ip: any) => {
    ClientProjectsSharedModal.fire(ip, prop.link_id);
  };

  const setGroupByCondition = () => {
    setIsShowGroupBy(!isShowGroupBy);
    setIsLoading(true);
    setParams({
      ...params,
      type: !isShowGroupBy ? "group" : "all",
      page: 1,
    });
  };

  const exportData = () => {
    if (
      publickLinkData !== undefined &&
      publickLinkData["status"] === "success"
    ) {
      let queryString = "";
      queryString = params[0] !== undefined ? "search=" + params[0] : "";
      queryString +=
        params["sort_by"] !== undefined && queryString !== "" ? "&" : "";
      queryString +=
        params["sort_by"] !== undefined ? "sort_by=" + params["sort_by"] : "";
      queryString +=
        params["type"] !== undefined &&
        params["type"] !== null &&
        queryString !== ""
          ? "&"
          : "";
      queryString +=
        params["type"] !== undefined && params["type"] !== null
          ? "type=" + params["type"]
          : "";
      queryString +=
        params["device"] !== undefined &&
        params["device"] !== null &&
        queryString !== ""
          ? "&"
          : "";
      queryString +=
        params["device"] !== undefined && params["device"] !== null
          ? "device=" + params["device"]
          : "";
      queryString +=
        params["browser"] !== undefined &&
        params["browser"] !== null &&
        queryString !== ""
          ? "&"
          : "";
      queryString +=
        params["browser"] !== undefined && params["browser"] !== null
          ? "browser=" + params["browser"]
          : "";
      queryString +=
        params["view_rate"] !== undefined &&
        params["view_rate"] !== null &&
        queryString !== ""
          ? "&"
          : "";
      queryString +=
        params["view_rate"] !== undefined && params["view_rate"] !== null
          ? "view_rate=" + params["view_rate"]
          : "";
      queryString +=
        params["start_date"] !== undefined &&
        params["start_date"] !== null &&
        queryString !== ""
          ? "&"
          : "";
      queryString +=
        params["start_date"] !== undefined && params["start_date"] !== null
          ? "start_date=" + params["start_date"]
          : "";
      queryString +=
        params["end_date"] !== undefined &&
        params["end_date"] !== null &&
        queryString !== ""
          ? "&"
          : "";
      queryString +=
        params["end_date"] !== undefined && params["end_date"] !== null
          ? "end_date=" + params["end_date"]
          : "";

      const url =
        filters !== undefined && filters["status"] === "success"
          ? filters.data.export_url
          : "" + "publicLinksExport?" + queryString;

      window.open(url, "_blank");
    }
  };
  const setPagination=(page: number)=>{
    setParams({
      ...params,
      page:page,
    });
  }
  return (
    <div className="mt-8 relative border border-gray-100 rounded-md flex justify-start items-start h-[620px]">
      <div className="h-full">
      <div
      className={`px-5 py-8 pt-0 h-full group absolute left-0 top-0 z-[11] border-r
     border-gray-100 bg-gray-200 rounded-s-md ease-in duration-300 overflow-y-auto overflow-x-hidden flex flex-1 
      ${isFilterOpen ? `w-[12.125rem]` : `w-[3.75rem] hover:w-[12.125rem]`}`}
    >
      <div className="w-full">
        <div className="pt-8 pb-4 mb-3 flex justify-start items-center gap-2.5 relative after:bg-gray-200 after:h-[5rem] after:absolute after:-right-[1.3rem] after:top-0 after:w-9 after:-z[10]">
          <div className="flex gap-1.5 justify-start items-center order-2">
            <div className="text-200 font-bold text-gray-500">FILTER</div>
            <div className="text-gray-500 text-100">|</div>
            <button className="text-200 font-normal text-primary-100 whitespace-nowrap"
              onClick={() => {
                clearFilter();
              }}
            >
              Clear All
            </button>
          </div>
          <button className="relative bg-gray-100 order-1 block p-0 me-4"
          onClick={() => {
            setIsFilterOpen(!isFilterOpen);
          }}
          >
            {!isFilterOpen ? (
              <span className="absolute left-0 -top-1.5">
                <svg
                  width="1rem"
                  height="0.625rem"
                  viewBox="0 0 16 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.8753 0.875C15.2895 0.875 15.6253 1.21079 15.6253 1.625C15.6253 2.03921 15.2895 2.375 14.8753 2.375H1.12494C0.710726 2.375 0.374939 2.03921 0.374939 1.625C0.374939 1.21079 0.710726 0.875 1.12494 0.875H14.8753ZM15.6253 5.19667C15.6253 4.78246 15.2895 4.44667 14.8753 4.44667H1.12494C0.710726 4.44667 0.374939 4.78246 0.374939 5.19667C0.374939 5.61088 0.710726 5.94667 1.12494 5.94667H14.8753C15.2895 5.94667 15.6253 5.61088 15.6253 5.19667ZM15.6253 8.76833C15.6253 8.35411 15.2895 8.01833 14.8753 8.01833H1.12494C0.710726 8.01833 0.374939 8.35411 0.374939 8.76833C0.374939 9.18254 0.710726 9.51833 1.12494 9.51833H14.8753C15.2895 9.51833 15.6253 9.18254 15.6253 8.76833Z"
                    fill="#888888"
                  ></path>
                </svg>
              </span>
            ) : (
              <span className="absolute left-0 -top-2.5">
                <svg
                  width="1.25rem"
                  height="1.25rem"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.9751 4.94165L2.91677 9.99998L7.9751 15.0583"
                    stroke="#888888"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M17.0835 10H3.0585"
                    stroke="#888888"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </span>
            )}
          </button>
        </div>
        <div>
        {!isClearFilter ? (
          <>
            {filters !== undefined &&
            filters["status"] === "success" ? (
              <>
          <Accordion
            isFilterOpen={isFilterOpen}
            count={1}
            subTitle=""
            time=""
            title="Device"
            icon={
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 16.95H6.21C2.84 16.95 2 16.11 2 12.74V6.74003C2 3.37003 2.84 2.53003 6.21 2.53003H16.74C20.11 2.53003 20.95 3.37003 20.95 6.74003"
                  stroke="#888888"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 21.4699V16.95"
                  stroke="#888888"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2 12.95H10"
                  stroke="#888888"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.73999 21.47H9.99999"
                  stroke="#888888"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22 12.8V18.51C22 20.88 21.41 21.47 19.04 21.47H15.49C13.12 21.47 12.53 20.88 12.53 18.51V12.8C12.53 10.43 13.12 9.83997 15.49 9.83997H19.04C21.41 9.83997 22 10.43 22 12.8Z"
                  stroke="#888888"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.2445 18.25H17.2535"
                  stroke="#888888"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          >
            <div
              className={`${
                isFilterOpen
                  ? `inline-block`
                  : `hidden group-hover:inline-block`
              }`}
            >
               <CustomFilters
                  selected={params["device"]}
                  filter={filters?.data.device}
                  type="single"
                  isSearchBar={false}
                  count={4}
                  onChange={setDeviceFilter}
                />
            </div>
          </Accordion>
          <Accordion
            isFilterOpen={isFilterOpen}
            count={2}
            subTitle=""
            time=""
            title="Browser"
            icon={
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22Z"
                  stroke="#888888"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 16C14.21 16 16 14.21 16 12C16 9.79 14.21 8 12 8C9.79 8 8 9.79 8 12C8 14.21 9.79 16 12 16Z"
                  stroke="#888888"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.17 8C18.15 7.34 15.02 7.34 12 8"
                  stroke="#888888"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.95001 6.06006L3.97001 6.12006C4.98001 9.01006 6.53001 11.6901 8.54001 14.0001"
                  stroke="#888888"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.88 21.94C12.94 19.67 14.49 16.99 15.43 14.08L15.46 14"
                  stroke="#888888"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          >
            <div
              className={`${
                isFilterOpen
                  ? `inline-block`
                  : `hidden group-hover:inline-block`
              }`}
            >
               <CustomFilters
                  selected={params["browser"]}
                  filter={filters?.data.browser}
                  type="single"
                  isSearchBar={false}
                  count={4}
                  onChange={setBrowserFilter}
                />
            </div>
          </Accordion>
          <Accordion
            isFilterOpen={isFilterOpen}
            count={3}
            subTitle=""
            time=""
            title="View Rate"
            icon={
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.58 12C15.58 13.98 13.98 15.58 12 15.58C10.02 15.58 8.42004 13.98 8.42004 12C8.42004 10.02 10.02 8.42004 12 8.42004C13.98 8.42004 15.58 10.02 15.58 12Z"
                  stroke="#888888"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 20.27C15.53 20.27 18.82 18.19 21.11 14.59C22.01 13.18 22.01 10.81 21.11 9.39997C18.82 5.79997 15.53 3.71997 12 3.71997C8.46997 3.71997 5.17997 5.79997 2.88997 9.39997C1.98997 10.81 1.98997 13.18 2.88997 14.59C5.17997 18.19 8.46997 20.27 12 20.27Z"
                  stroke="#888888"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          >
            <div
              className={`${
                isFilterOpen
                  ? `inline-block`
                  : `hidden group-hover:inline-block`
              }`}
            >
              <CustomFilters
                selected={params["view_rate"]}
                filter={filters?.data.view_rate}
                type="key"
                isSearchBar={false}
                count={4}
                onChange={setViewRateFilter}
              />
            </div>
          </Accordion>
          </>
          ) : (
            ""
          )}
          <Accordion
            isFilterOpen={isFilterOpen}
            count={4}
            subTitle=""
            time=""
            title="Accessed On"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 18 18"
                fill="none"
              >
                <g clipPath="url(#clip0_1519_1966)">
                  <path
                    d="M6.98242 11.0254L8.10742 12.1504L11.1074 9.15039"
                    stroke="#888888"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.5 4.5H10.5C12 4.5 12 3.75 12 3C12 1.5 11.25 1.5 10.5 1.5H7.5C6.75 1.5 6 1.5 6 3C6 4.5 6.75 4.5 7.5 4.5Z"
                    stroke="#888888"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 3.01562C14.4975 3.15063 15.75 4.07312 15.75 7.50063V12.0006C15.75 15.0006 15 16.5006 11.25 16.5006H6.75C3 16.5006 2.25 15.0006 2.25 12.0006V7.50063C2.25 4.08063 3.5025 3.15063 6 3.01562"
                    stroke="#888888"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1519_1966">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            }
          >
            <div
              className={`${
                isFilterOpen
                  ? `inline-block`
                  : `hidden group-hover:inline-block`
              }`}
            >
                        <div className="mt-4 small-datepicker">
                        <label className="text-gray-700 font-normal text-300 mb-2 inline-block">
                          Start
                        </label>
                        <CustomDateTimePicker
                          dateFormat="YYYY-MM-DD"
                          onChange={(
                            date: Date | null,
                            value: string | null
                          ) => {
                            setStartDate(value);
                          }}
                          selectedDate={startDate}
                          maxDate={moment(new Date()).format("yyyy-MM-DD")}
                          size="sm"
                        />
                        {startDate === null && endDate !== null ? (
                          <Typography
                            className="text-primary-100 font-normal"
                            variant="p"
                            size="sm"
                          >
                            Please select start date
                          </Typography>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="mt-3 small-datepicker">
                        <label className="text-gray-700 font-normal text-300 mb-2 inline-block">
                          End
                        </label>
                        <CustomDateTimePicker
                          dateFormat="YYYY-MM-DD"
                          onChange={(
                            date: Date | null,
                            value: string | null
                          ) => {
                            setEndDate(value);
                          }}
                          selectedDate={endDate}
                          minDate={minDate !== null ? minDate : null}
                          maxDate={moment(new Date()).format("yyyy-MM-DD")}
                          size="sm"
                        />
                        {startDate !== null && endDate === null ? (
                          <Typography
                            className="text-primary-100 font-normal"
                            variant="p"
                            size="sm"
                          >
                            Please select end date
                          </Typography>
                        ) : isMinDateError ? (
                          <Typography
                            className="text-primary-100 font-normal"
                            variant="p"
                            size="sm"
                          >
                            End date can not be less than start date
                          </Typography>
                        ) : (
                          ""
                        )}
                      </div>
            </div>
          </Accordion>
          </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
    </div>
    <div className={`${isFilterOpen?`w-[calc(100%-12.125rem)] ms-[12.125rem]`:`w-[calc(100%-3.75rem)] ms-[3.75rem]`} h-full bg-gray-200 rounded-e-md overflow-auto relative`}>
      {/* <div className="w-[calc(100%-3.75rem)] ms-[3.75rem] bg-gray-200 rounded-e-md overflow-auto relative h-full"> */}
        <div className="flex justify-between items-center bg-white shadow-300 py-4 px-5 relative w-full rounded-t-md">
          <div className="flex justify-start items-center gap-3">
            <div className="flex justify-start items-center text-gray-300 font-normal text-400">
              {/*<PaginationComponent handlePagination={setPagination} page={Number(publickLinkData?.meta?.current_page??0)} totalPages={Number(publickLinkData?.meta?.last_page)}/>*/}
            </div>
          </div>
          <div className="flex justify-end items-center gap-4">
            <div>
              {!isShowGroupBy ? (
                <button
                  onClick={setGroupByCondition}
                  className="text-400 text-primary-100 font-bold"
                >
                  Group By IP
                </button>
              ) : (
                <button
                  onClick={setGroupByCondition}
                  className="text-400 text-primary-100 font-bold"
                >
                  Show All
                </button>
              )}
            </div>
            {filters !== undefined && filters["status"] === "success" ? (
              <button
                onClick={exportData}
                className="text-400 text-primary-100 font-bold"
              >
                Export Data
              </button>
            ) : (
              ""
            )}
            <div className="w-[18.125rem]">
              <SearchLink setSearchInpData={setSearchInpData} />
            </div>
          </div>
        </div>
        <div className="overflow-auto bg-white h-[calc(100%-72px)]">
          <table className="min-w-[1160px] w-full">
            <thead className="sticky left-0 top-0 bg-gray-200 z-10">
              <tr>
                <th className="text-200 font-bold text-gray-700 px-5 py-4 ps-8 text-start uppercase">
                  Viewer IP Address
                </th>
                <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase">
                  Accessed On
                </th>
                <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase">
                  Favorite Projects
                </th>
                <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase">
                  Browser/ Devices
                </th>
                <th className="text-200 font-bold text-gray-700 px-5 py-4 text-start uppercase">
                  Region
                </th>
                <th className="text-200 font-bold text-gray-700 px-5 py-4 pe-8 text-start uppercase">
                  View Rate
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                 <div className="flex justify-center items-center w-full h-[calc(100%-8rem)] absolute left-0 top-[20%]">
                 <CustomLoader/>
               </div>
                // Array.from({ length: 6 }).map((idx: any) => (
                //   <tr key={idx}>
                //     {Array.from({ length: 6 }).map((idy: any) => (
                //       <td
                //         key={idy}
                //         className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200 w-[25rem]"
                //       >
                //         <div className="animate-pulse">
                //           <div className="flex-1 space-y-6">
                //             <div className="h-5 bg-gray-100 rounded" />
                //           </div>
                //         </div>
                //       </td>
                //     ))}
                //   </tr>
                // ))
              ) : publickLinkData !== undefined &&
                publickLinkData["status"] === "success" && publickLinkData.data.length > 0 ? (
                publickLinkData["data"].map((item: any, idx: any) => (
                  <tr key={idx} className="group">
                    <td className="text-300 text-gray-300 font-normal px-5 py-4 ps-8 text-start border-b border-gray-400 group-hover:bg-gray-200">
                      {item.ip}
                    </td>
                    <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                      <div className="relative">
                        <div
                          onClick={() => {
                            if (params["type"] === "group") {
                              showTime(item.ip);
                            }
                          }}
                          className="flex justify-start items-center gap-0.5 cursor-pointer"
                        >
                          <div>{item.accessed_on}</div>
                          {params["type"] === "group" ? (
                            <div>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12Z"
                                  stroke="#232323"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M15.71 15.18L12.61 13.33C12.07 13.01 11.63 12.24 11.63 11.61V7.51001"
                                  stroke="#232323"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {params["type"] === "group" ? (
                          <ul
                            ref={(el) => (timeRef.current[item.ip] = el)}
                            className="text-300 bg-white border border-gray-100 rounded-md shadow-200 absolute left-0 top-[24px] 
                            z-[1] p-3 hidden"
                          >
                            {item.accessed.slice().reverse().map((date: any, idy: any) => (
                              <li key={idy} className="mb-2">
                                {date}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                    <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                      {item.projects.length > 0 ? (
                        <ul
                          className="inline-block cursor-pointer"
                          onClick={() => {
                            SharedProjects(item.ip);
                          }}
                        >
                          {item.projects.map((project: any, id: any) =>
                            id + 1 <= counter ? (
                              <li key={id} className="inline-block font-medium">
                                {project}
                                {item.projects.length > id + 1 ? (
                                  <>&#x2c;&nbsp;</>
                                ) : (
                                  ""
                                )}
                              </li>
                            ) : (
                              ""
                            )
                          )}
                          {item.projects.length > counter ? (
                            <li className="inline-block font-bold text-blue-100 border-b border-blue-100">
                              +{ShowCount(item.projects.length)}
                            </li>
                          ) : (
                            ""
                          )}
                        </ul>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                      {item.userDetail?.browser}, {item.userDetail?.device}
                    </td>
                    <td className="text-300 text-gray-300 font-normal px-5 py-4 text-start border-b border-gray-400 group-hover:bg-gray-200">
                      <div>
                        {item.userDetail?.city}, {item.userDetail?.region},{" "}
                        {item.userDetail?.country}
                      </div>
                    </td>
                    <td className="text-300 text-gray-300 font-normal px-5 py-4 pe-8 text-start border-b border-gray-400 group-hover:bg-gray-200">
                      <div>{(item.viewPercent>100)?100:item.viewPercent}%</div>
                    </td>
                  </tr>
                ))
              // ) : publickLinkData !== undefined &&
              //   publickLinkData["status"] === "error" &&
              //   publickLinkData["error"] === 404 ? (
              //   <tr>
              //     <td
              //       colSpan={6}
              //       className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300"
              //     >
              //       <div className="flex justify-center items-center pt-36">
              //         <div className="text-center">
              //           <Typography
              //             className="text-gray-700 font-medium"
              //             variant="h4"
              //           >
              //             No records found
              //           </Typography>
              //         </div>
              //       </div>
              //     </td>
              //   </tr>
              ) : (
                <div className="flex justify-center items-center w-full h-[calc(100%-8rem)] absolute left-0">
            <IPListEmpty />
          </div>
                // <tr>
                //   <td
                //     colSpan={6}
                //     className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300"
                //   >
                //     <div className="mt-20 text-center">
                //       <Typography variant="h5">
                //         Something went wrong!
                //       </Typography>
                //     </div>
                //   </td>
                // </tr>
              )}
            </tbody>
          </table>
          {/* <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
            <IPListEmpty />
          </div> */}
          {/* <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
            <CustomLoader />
          </div> */}
          {!isLoading ? (
            <div className="w-full flex justify-center items-center py-6">
              {isLoadMore ? (
                <div className="flex mt-2">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="duration-200 animate-pulse-3"
                  >
                    <circle cx="12" cy="6" r="6" fill="#F15845" />
                  </svg>
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="duration-300 animate-pulse-2"
                  >
                    <circle cx="12" cy="6" r="6" fill="#F15845" />
                  </svg>
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="duration-400 animate-pulse"
                  >
                    <circle cx="12" cy="6" r="6" fill="#F15845" />
                  </svg>
                </div>
              ) : publickLinkData !== undefined &&
                publickLinkData.status === "success" &&
                params["page"] < publickLinkData.meta.last_page ? (
                <button
                  className="text-primary-100 text-300 font-semibold"
                  onClick={() => {
                    setParams({
                      ...params,
                      page: params["page"] + 1,
                    });
                  }}
                >
                  Load More...
                </button>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default IPList;
