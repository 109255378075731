import React from "react";
import * as Yup from "yup";
import {
  showToastAlert,
  convertParamsToArr,
  Params,
  callClientFetch,
  updateAssocciativeArray
} from "../../CommonFunctions";

const controller = new AbortController();
let projectController:any;
let projectAssetController:any;
let projectFilterController:any;

export interface InputFiled {
  client_name: any;
  company_name: any;
  phone_number: any;
  email: any;
  description: any;
  accessToken?: any;
}

export const ErrorMessages: InputFiled = {
  client_name: "",
  company_name: "",
  phone_number: "",
  email: "",
  description: "",
  accessToken: ""
};

export async function fetchPortfolioAssets({ params }: Params) {
  params = convertParamsToArr({ params });
  let queryString = "";
  queryString = params["search"] !== undefined ? "search=" + params["search"] : "";
  queryString +=
    params["category"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["category"] !== undefined ? "category=" + params["category"] : "";
  queryString +=
    params["page"] !== undefined && queryString !== "" ? "&" : "";
  queryString +=
    params["page"] !== undefined ? "page=" + params["page"] : "";

  if(projectController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await projectController.abort(reason);
  }

  projectController = new AbortController();

  return await callClientFetch({
    url: process.env.REACT_APP_API_URL + "clientView/portfolio/assets?" + queryString,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: projectController,
    callback: async (json) => {
      return json;
    },
  });
}

export async function viewAsset(project_id:number) {
  if(projectAssetController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await projectAssetController.abort(reason);
  }

  projectAssetController = new AbortController();

  return await callClientFetch({
    url: process.env.REACT_APP_API_URL + "clientView/viewAsset/" + project_id,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: projectAssetController,
    callback: async (json) => {
      return json;
    },
  });
}

export async function fetchAssetFilters() {
  if(projectFilterController){
    const reason = new DOMException('signal timed out', 'TimeoutError');
    await projectFilterController.abort(reason);
  }

  projectFilterController = new AbortController();

  return await callClientFetch({
    url: process.env.REACT_APP_API_URL + "clientView/asset_filter",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    controller: projectFilterController,
    callback: async (json) => {
      return json;
    },
  });
}

export const updateFavoriteStatus = async (props: any) => {
  const is_favorite = props.is_favorite ? 0 : 1;
  props.updateData(
    updateAssocciativeArray(
      props.oldProjects,
      "project_id",
      props.project_id,
      "is_favorite",
      is_favorite,
    ),
  );

  const url =
    process.env.REACT_APP_API_URL +
    "clientView/updateProjectFavoriteStatus/" +
    props.project_id;

  return await callClientFetch({
    url: url,
    method: "PUT",
    body: JSON.stringify({ is_favorite }),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      if (json.status == "success") {
        // showToastAlert(json.message, 1);
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          showToastAlert(json.validation_errors[name][0]);
        }
        props.updateData(
          updateAssocciativeArray(
            props.oldProjects,
            "project_id",
            props.project_id,
            "is_favorite",
            props.is_favorite,
          ),
        );
      } else {
        showToastAlert(json.message);
        props.updateData(
          updateAssocciativeArray(
            props.oldProjects,
            "id",
            props.project_id,
            "is_favorite",
            props.is_favorite,
          ),
        );
      }

      return json;
    },
  });
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export const validationSchema = Yup.object({
  client_name: Yup.string()
    .required("Client name is required")
    .min(2, "Too Short!")
    .max(100, "Too Long!"),
  company_name: Yup.string()
    .required("Company name is required")
    .min(2, "Too Short!")
    .max(254, "Too Long!"),
  // phone_number: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
  //   .required("Phone number is required")
  //   .min(2, "Too Short!")
  //   .max(254, "Too Long!"),
  phone_number: Yup.string()
    .matches(/^[0-9]{10,15}$/, 'Phone number must be between 10 and 15 digits')
    .required('Phone number is required'),
  email: Yup.string()
    .required("Email address is required").email("Please enter a valid email address")
    .min(2, "Too Short!")
    .max(254, "Too Long!"),
  description: Yup.string()
    .required("description is required")
    .min(10, "Too Short!"),
});

export const saveContactUsData = async (props: any) => {
  props.setIsFromSubmiting(true);
  const errorMessage = ErrorMessages;

  const url = process.env.REACT_APP_API_URL + "clientView/saveContactUs";

  return await callClientFetch({
    url: url,
    method: "POST",
    body: JSON.stringify(props.formData),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      props.setIsFromSubmiting(false);

      if (json.status == "success") {
        showToastAlert(json.message, 1);
        props.navigate("/client/portfolio");
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          errorMessage[name as keyof InputFiled] =
            json.validation_errors[name][0];
        }

        props.setErrorMessage(errorMessage);
      } else {
        showToastAlert(json.message);
      }

      return json;
    },
  });
};

export const saveSupportData = async (props: any) => {
  props.setIsFromSubmiting(true);
  const errorMessage = ErrorMessages;

  const url = process.env.REACT_APP_API_URL + "clientView/saveSupport";

  return await callClientFetch({
    url: url,
    method: "POST",
    body: JSON.stringify(props.formData),
    headers: {
      "Content-Type": "application/json",
    },
    controller: controller,
    callback: async (json: any) => {
      props.setIsFromSubmiting(false);

      if (json.status == "success") {
        showToastAlert(json.message, 1);
        props.navigate("/client/portfolio");
      } else if (json.status == "error" && json.error == "validation_errors") {
        for (const name in json.validation_errors) {
          errorMessage[name as keyof InputFiled] =
            json.validation_errors[name][0];
        }

        props.setErrorMessage(errorMessage);
      } else {
        showToastAlert(json.message);
      }

      return json;
    },
  });
};
