import React from "react";
import Select from "react-select";

export interface DropdownOption {
  value: string;
  label: string;
}

export interface DropdownProps {
  options: DropdownOption[];
  onSelect: (value: string) => void;
  className?: string;
  value?: string;
  defaultValue?: string;
  background?: boolean;
}

const ActionDropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  className,
  value,
  defaultValue,
  background,
}) => {
  const handleSelect = (selectedOption: DropdownOption | null) => {
    const value = selectedOption ? selectedOption.value : "";
    onSelect(value);
  };

  const selectedOption = options.find(
    (option) => option.value === defaultValue
  );

  const customSingleValue = () => (
    <div
      className={`input-select pb-0 rounded-md cursor-pointer ${
        background ? "bg-gray-200" : ""
      }`}
    >
      <svg
        width="20"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 10C3.9 10 3 10.9 3 12C3 13.1 3.9 14 5 14C6.1 14 7 13.1 7 12C7 10.9 6.1 10 5 10Z"
          stroke="#696969"
          strokeWidth="1.5"
        />
        <path
          d="M19 10C17.9 10 17 10.9 17 12C17 13.1 17.9 14 19 14C20.1 14 21 13.1 21 12C21 10.9 20.1 10 19 10Z"
          stroke="#696969"
          strokeWidth="1.5"
        />
        <path
          d="M12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
          stroke="#696969"
          strokeWidth="1.5"
        />
      </svg>
    </div>
  );

  return (
    <div className={`flex justify-end actiondropdown h-6`}>
      <div
        className={`w-auto inline-flex ms-auto ${className ? className : ""}`}
      >
        <Select
          options={options}
          onChange={handleSelect}
          value={selectedOption}
          unstyled
          classNames={{
            input: () => "p-0",
            control: (
              state
            ) => `bg-primary-100 rounded-md p-0 border-0 border-gray-100 text-300 text-gray-300 !min-h-0 !h-0 !inline-block [&>.css-1qpkzkj-ValueContainer]:inline-block [&>.css-14oxtc6]:inline-block
          ${state.isFocused ? "" : ""} ${state.menuIsOpen ? "" : ""}`,
            placeholder: () => "text-gray-500 p-0",
            dropdownIndicator: () => "text-gray-500 p-0",
            menu: () =>
              "bg-white text-200 font-semibold border border-gray-100 rounded-md rounded-t-0 mt-1 shadow-100 min-w-[6rem] right-0",
            option: ({
              isFocused,
              isSelected,
            }) => `px-3 py-2 text-200 relative text-gray-300 hover:cursor-pointer hover:bg-gray-200 hover:text-gray-300 first:rounded-t-md last:rounded-b-md
          ${
            isSelected
              ? "text-200 bg-gray-200 text-gray-300 hover:bg-gray-200 hover:text-gray-300 active:bg-gray-200 active:text-gray-500 after:ml-2 after:absolute after:right-2 after:content-[''] first:rounded-t-md last:rounded-b-md"
              : ""
          }
          ${isFocused ? "bg-gray-200 text-gray-300 hover:bg-gray-200" : ""} 
          `,
            noOptionsMessage: () =>
              "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm",
          }}
          isSearchable={false}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            SingleValue: customSingleValue,
          }}
          // components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null, }}
        />
      </div>
    </div>
  );
};

export default ActionDropdown;
