import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  FetchData
} from "../../CommonFunctions";
import { ErrorAPI500, ErrorAPI404 } from "../../components/Errors/Errors";
import { Modal } from "../../components/Modal/Modal";
import { ModalHeader } from "../../components/Modal/Modal";
import { ModalBody } from "../../components/Modal/Modal";
import Typography from "../../components/Typography/Typography";
import AssetSlider from "../projects/AssetSlider";
import {
  fetchProjectShow
} from "../projects/ProjectFunction";
import { fetchAsset } from "../assets/AssetsFunction";
import CustomLoader from "../../components/Loader/CustomLoader";

type Props = {
  onClose: () => void;
  asset: any;
};

class PreviewAssetModal extends React.Component {
  static async fire(asset: any) {
    const container = document.createElement("div");
    container.id = "custom-client-container";
    const root = createRoot(container);
    document.body.appendChild(container);

    const close = () => {
      root.unmount();
      container.remove();
      return false;
    };

    root.render(
      <ModalComponent onClose={close} asset={asset} />,
    );
  }

  render() {
    return null;
  }
}

const ModalComponent: React.FC<Props> = ({
  onClose,
  asset
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [assetData, setAssetData] = useState<FetchData>();

  useEffect(()=>{
    if(isLoading === true){
      loadData();
    }
  },[isLoading]);

  const loadData = async () => {
    let data;
    if(asset.project_id) {
      data = await fetchProjectShow({ params : { 0: asset.project_id} });
    } else {
      data = await fetchAsset(asset.asset_id);
    }
    setAssetData(data);
    setIsLoading(false);
  }

  return (
    <Modal
      isOpen
      onClose={onClose}
      placement='right' 
      size='large' className="z-[9999]"
    >
      <ModalHeader className="bg-gray-200">
        <Typography
            className="font-bold flex justify-start items-center"
            variant="h3"
        >
          {asset.asset_title ? asset.asset_title : asset.project_title}
        </Typography>
      </ModalHeader>
      <ModalBody className="!px-8 !pt-4 !pb-0">
        {isLoading === true ? (
          <>
            {/* <div className="animate-pulse flex space-x-4">
              <div className="flex-1 space-y-6 py-1">
                <div className="h-[30rem] bg-gray-100 rounded" />
              </div>
            </div>
            <div className="grid grid-cols-10 gap-3">
              {Array.from({ length: 10 }).map((_, idx) => (
                <div key={idx} className="animate-pulse flex space-x-4">
                  <div className="flex-1 space-y-6 py-1">
                    <div className="h-20 bg-gray-100 rounded" />
                  </div>
                </div>
              ))}
            </div> */}
            <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
                <CustomLoader/>
              </div>
          </>
        ) : assetData !== undefined && assetData["status"] === "success" ? (
          <div>
            <AssetSlider projectAssets={asset.project_id ? assetData["data"]["assets"] : [assetData["data"]]} />
          </div>
        ) : assetData !== undefined && assetData["status"] === "error" && assetData["error"] === 404 ? (
          <ErrorAPI404 />
        ) : (
          <ErrorAPI500 />
        )}
      </ModalBody>
    </Modal>
  );
};

export default PreviewAssetModal;
