import React, { useState,useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  FetchData,
  pushArray
} from "../../CommonFunctions";
import { ErrorAPI500, ErrorAPI404 } from "../../components/Errors/Errors";
import {
  Modal,
  ModalBody,
  ModalHeader,
} from "../../components/Modal/Modal";
import Typography from "../../components/Typography/Typography";
import {
  fetchSharedAssets
} from "./PortfolioFunction";
import Button from "../../components/Button/Button";
import ConfirmationPopup from "../../components/PopUP/ConfirmPopUp";

type Props = {
  onClose: () => void;
  portfolio_id: number;
  client_id: number;
  client:any
};

class ClientProjectsSharedModal extends React.Component {
  static async fire(id: number,client_id:number,client:any) {
    const container = document.createElement("div");
    container.id = "custom-favorite-projects-container";
    const root = createRoot(container);
    document.body.appendChild(container);

    const close = () => {
      root.unmount();
      container.remove();
      return false;
    };

    root.render(
      <ModalComponent
        onClose={close}
        portfolio_id={id}
        client_id={client_id}
        client={client}
      />,
    );
  }

  render() {
    return null;
  }
}

const ModalComponent: React.FC<Props> = ({ onClose,portfolio_id,client_id,client }) => {    
    const [isLoading, setIsLoading] = useState<Boolean>(true);
    const [isLoadMore, setIsLoadMore] = useState<Boolean>(false);
    const [projects, setProjects] = useState<FetchData>();
    const [page, setPage] = useState<number>(1);
    const counter = 2;

    const ShowCount = (count:number) => {
        return count - counter;
    }

    useEffect(() => {
        if(projects !== undefined){
            setIsLoading(false);
        }

        setIsLoadMore(false);
    }, [projects]);

    useEffect(() => {
        loadData();
    },[page]);

    const loadProjects = async (id: number,client_id:number) => {
        return await fetchSharedAssets(id,client_id,page);
    };

    const loadData = async () => {
        setIsLoadMore(true);

        const data:FetchData = await loadProjects(portfolio_id,client_id);

        if(data.status === "error" && data.error === "abort"){return false;}
        
        if(projects !== undefined && page > 1 && data.status === "success"){
            let log = projects["data"];
            for(const i in data["data"]){
                log = pushArray(log,data["data"][i]);
            }
            setProjects({
                ...projects,
                data:log
            });
        }else if(page === 1){
            setProjects(data); 
        }
    };

    const loadmoreData = () => {
        setPage(page+1);
    }

    return (
        <Modal isOpen onClose={onClose} size="medium" placement="right">
            <ModalHeader className="bg-gray-200">
                <div>
                    <Typography className="font-bold" variant="h3">
                        {client.name}
                    </Typography>
                </div>
            </ModalHeader>
            <ModalBody className="!px-0 !pt-0">
                {isLoading?(
                    <div className="">
                        <table className="w-full">
                            <thead className="sticky left-0 top-0 z-10">
                                <tr>
                                    <th className="bg-gray-400 px-5 py-3 text-gray-700 text-300 font-bold text-left ps-8 w-10 uppercase">SR.NO.</th>
                                    <th className="bg-gray-400 px-5 py-3 text-gray-700 text-300 font-bold text-left w-2/3 uppercase">Favorite Projects</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.from({ length: 10 }).map((idx: any) => (
                                    <tr key={idx}>
                                        <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300 ps-8">
                                            <div key={idx} className="animate-pulse">
                                                <div className="flex-1 space-y-6">
                                                    <div className="h-5 w-10 bg-gray-100 rounded" />
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300">
                                            <div key={idx} className="animate-pulse">
                                                <div className="flex-1 space-y-6">
                                                    <div className="h-5 w-1/2 bg-gray-100 rounded" />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ):projects !== undefined && projects["status"] === "success"?(
                    <div className="">
                        <table className="w-full">
                            <thead className="sticky left-0 top-0 z-10">
                                <tr>
                                    <th className="bg-gray-400 px-5 py-3 text-gray-700 text-300 font-bold text-left ps-8 uppercase">SR.NO.</th>
                                    <th className="bg-gray-400 px-5 py-3 text-gray-700 text-300 font-bold text-left w-2/3 uppercase">Favorite Project</th>
                                </tr>
                            </thead>
                            <tbody>
                                {projects["data"].map((item: any, idx: any) => (
                                    <tr key={idx}>
                                        <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300 ps-8">{idx+1}</td>
                                        <td className="text-left border-b border-gray-100 py-4 px-5 text-gray-300 text-300">{item.project_title}</td>
                                    </tr>
                                ))}
                                <tr>
                                    <td className="border-0" colSpan={2}>
                                        <div className="flex w-100 justify-center text-center items-center align-middle mt-5">
                                            {isLoadMore?(
                                                <div className="flex mt-2">
                                                    <svg
                                                        width="32"
                                                        height="32"
                                                        viewBox="0 0 32 32"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="duration-200 animate-pulse-3"
                                                    >
                                                        <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                                                    </svg>
                                                    <svg
                                                        width="32"
                                                        height="32"
                                                        viewBox="0 0 32 32"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="duration-300 animate-pulse-2"
                                                    >
                                                        <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                                                    </svg>
                                                    <svg
                                                        width="32"
                                                        height="32"
                                                        viewBox="0 0 32 32"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="duration-400 animate-pulse"
                                                    >
                                                        <circle cx="12" cy="6" r="6" fill="#3b82f6" />
                                                    </svg>
                                                </div>
                                            ):(
                                                page < projects.meta.last_page?
                                                    <button
                                                        className="text-200 font-bold text-blue-500 border-b inline-block"
                                                        onClick={()=>{
                                                            loadmoreData();
                                                        }}
                                                    >
                                                        <span className="">Load More</span>
                                                    </button>
                                                :""
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                ):projects !== undefined && projects["status"] === "error" && projects["error"] === 404?(
                    <ErrorAPI404 />
                ):(
                    <ErrorAPI500 />
                )}
            </ModalBody>
        </Modal>
    );
};

export default ClientProjectsSharedModal;
