type Access = {
    [key: string]: string[];
};

type UserType = {
    type: string;
    menuItems: string[];
    access: Access;
};

const userTypes: UserType[] = [
    {
      "type": "EchoShow-Admin",
      "menuItems": ["Portfolios", "Projects", "Assets", "ClientManagement", "ServiceAreas", "LinkManagement"],
      "access": {
        "Portfolios": ["Read", "Write", "Delete", "Update", "Share", "ShareHistory", "RevokeAccess"],
        "Projects": ["Read", "Write", "Delete", "Update"],
        "Assets": ["Read", "Write", "Delete", "Update"],
        "ClientManagement": ["Read", "Write", "Delete", "Update"],
        "ServiceAreas": ["Read", "Write", "Delete", "Update"],
        "LinkManagement": ["Read", "Write", "Delete", "Update", "RevokeLink"]
      }
    },
    {
      "type": "EchoShow-Marketing",
      "menuItems": ["Portfolios", "ClientManagement", "LinkManagement"],
      "access": {
        "Portfolios": ["Read", "Share", "ShareHistory"],
        "Projects": [],
        "Assets": [],
        "ClientManagement": ["Read", "Write"],
        "ServiceAreas": [],
        "LinkManagement": ["Read", "Write", "Delete", "Update", "RevokeLink"]
      }
    }
  ];;

export function hasPermission(userType: string, module: string, permission: string): boolean {
    // Find the user type
    const user = userTypes.find(ut => ut.type === userType);
    if (!user) {
        // If user type is not found, return false
        return false;
    }

    // Check if the module exists in the user's access list
    if (!user.access.hasOwnProperty(module)) {
        // If module is not found, return false
        return false;
    }

    // Check if the permission exists in the module's access array
    return user.access[module].includes(permission);
}