import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Modal } from "../../components/Modal/Modal";
import { ModalHeader } from "../../components/Modal/Modal";
import { ModalBody } from "../../components/Modal/Modal";
import Typography from "../../components/Typography/Typography";
import AddFiles from "../assets/AddFiles";
import AddLinks from "../assets/AddLinks";
import ListLoader from "../../components/ListLoader/ListLoader";
import CustomLoader from "../../components/Loader/CustomLoader";

type Props = {
  onClose: () => void;
  callback: any;
};

class CreateAssetModal extends React.Component {
  static async fire(loadData: any) {
    const container = document.createElement("div");
    container.id = "custom-project-container";
    const root = createRoot(container);
    document.body.appendChild(container);

    const close = () => {
      root.unmount();
      container.remove();
      return false;
    };

    const callback = () => {
      loadData();
      close();
    }

    root.render(
      <ModalComponent
        onClose={close}
        callback={callback}
      />,
    );
  }

  render() {
    return null;
  }
}

const ModalComponent: React.FC<Props> = ({
  onClose,
  callback,
}) => {
  const [asset, setAsset] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddActive, setIsAddActive] = useState("Upload Asset");

  useEffect(() => {
    setIsLoading(false);
  }, [asset]);

  return (
    <Modal
      isOpen
      onClose={onClose}
      size="medium"
      placement="center"
    >
      <ModalHeader className="bg-gray-200">
        <Typography className="font-bold" variant="h3">
          Add Asset
        </Typography>
      </ModalHeader>
      <ModalBody className="min-h-[496px]">
        <div>
          <div className="flex gap-5 text-gray-300 text-200">
            {["Upload Asset", "Upload Link"].map((item: any, idx: any) => (
              <div
                key={idx}
                className={`cursor-pointer font-medium tab pb-3 
                  ${
                    isAddActive === item
                      ? "active font-bold text-blue-300 border-blue-300 border-b-2"
                      : ""
                  }`}
                onClick={() => {
                  setIsAddActive(item);
                }}
              >
                {item}
              </div>
            ))}
          </div>
          <div className="pt-3">
            <div className="tab-pane active">
              <div className="">
                {isLoading ? (
                  <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
                  <CustomLoader/>
                </div>
                  // <ListLoader />
                ) : isAddActive === "Upload Asset" ? (
                  <AddFiles
                    asset={asset}
                    setAsset={setAsset}
                    setIsLoading={setIsLoading}
                    loadData={callback}
                  />
                ) : (
                  <AddLinks
                    asset={asset}
                    setAsset={setAsset}
                    setIsLoading={setIsLoading}
                    loadData={callback}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CreateAssetModal;
