import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  selectChangeHandler,
  inputChangeHandler,
  FetchData,
} from "../../CommonFunctions";
import { ErrorAPI500, ErrorAPI404 } from "../../components/Errors/Errors";
import { Modal } from "../../components/Modal/Modal";
import { ModalHeader } from "../../components/Modal/Modal";
import { ModalBody } from "../../components/Modal/Modal";
import { ModalFooter } from "../../components/Modal/Modal";
import Typography from "../../components/Typography/Typography";
import Button from "../../components/Button/Button";
import TextInput from "../../components/TextInput/TextInput";
import CustomDropDown, {
  SelectOptions,
} from "../../components/Formik/CustomDropDown";
import {
  fetchCategoriesShow,
  InputFiled,
  saveCategories,
  validationSchema,
  statusOptions,
  StatusOption,
} from "./CategoriesFunction";
import Spinner from "../../components/Spinner/Spinner";
import CustomLoader from "../../components/Loader/CustomLoader";
import CategoryEmpty from "./CategoryEmpty";

type Props = {
  onClose: () => void;
  category_id: number;
  navigate: any;
};

class CreateProjectModal extends React.Component {
  static async fire(id: number, navigate: any) {
    const container = document.createElement("div");
    container.id = "custom-project-container";
    const root = createRoot(container);
    document.body.appendChild(container);

    const close = () => {
      root.unmount();
      container.remove();
      return false;
    };

    root.render(
      <ModalComponent onClose={close} category_id={id} navigate={navigate} />
    );
  }

  render() {
    return null;
  }
}

const ModalComponent: React.FC<Props> = ({
  onClose,
  category_id,
  navigate,
}) => {
  let formData: InputFiled = {
    category_name: "",
    status: "",
  };

  let categoryDefualtStatus: any = null;

  const [isFromSubmiting, setIsFromSubmiting] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState<FetchData>();
  const [FormData, setFormData] = useState<InputFiled>(formData);
  const [categoryStatus, setCategoryStatus] = useState<SelectOptions>(
    categoryDefualtStatus
  );

  useEffect(() => {
    formData = {
      category_name:
        category !== undefined && category["data"] !== undefined
          ? category["data"].category_name
          : "",
      status:
        category !== undefined && category["data"] !== undefined
          ? category["data"].status
          : "",
    };

    categoryDefualtStatus =
      category !== undefined &&
      category["data"] !== undefined &&
      category["data"].status === 1
        ? { label: "Active", value: 1 }
        : category !== undefined &&
          category["data"] !== undefined &&
          category["data"].status === 0
        ? { label: "Inactive", value: 0 }
        : null;

    setCategoryStatus(categoryDefualtStatus);
    setFormData(formData);

    if (category !== undefined) {
      setIsLoading(false);
    }
  }, [category]);

  useEffect(() => {
    if (isLoading === true) {
      const loadData = async () => {
        if (category_id !== 0) {
          setCategory(await loadCategory(category_id));
        } else {
          setCategory({ status: "success" });
        }
      };

      loadData();
    }
  }, [isLoading]);

  const loadCategory = async (id: number) => {
    const params = {
      0: id,
    };

    return await fetchCategoriesShow({ params });
  };

  const submitHandler = async (values: InputFiled, { setErrors }: any) => {
    await saveCategories({
      setIsFromSubmiting: setIsFromSubmiting,
      categorieData: category,
      formData: values,
      setErrorMessage: setErrors,
      navigate: navigate,
      close: onClose,
    });
  };

  return (
    <>
      {isLoading === true ? (
        <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
        <CustomLoader/>
      </div>
        // <Modal isOpen onClose={onClose} size="medium" placement="right">
        //   <ModalHeader className="bg-gray-200">
        //     <div className="animate-pulse">
        //       <div className="flex-1 space-y-6">
        //         <div className="h-5 w-72 bg-gray-100 rounded" />
        //       </div>
        //     </div>
        //   </ModalHeader>
        //   <ModalBody>
        //     <div className="grid grid-cols-2">
        //       <div>
        //         <div>
        //           <div className="animate-pulse">
        //             <div className="flex-1 space-y-2">
        //               <div className="h-5 w-40 bg-gray-100 rounded" />
        //               <div className="h-10 bg-gray-100 rounded" />
        //             </div>
        //           </div>
        //         </div>
        //         <div className="mt-7">
        //           <div className="animate-pulse">
        //             <div className="flex-1 space-y-2">
        //               <div className="h-5 w-40 bg-gray-100 rounded" />
        //               <div className="h-10 bg-gray-100 rounded" />
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   </ModalBody>
        //   <ModalFooter>
        //     <div className="animate-pulse">
        //       <div className="flex gap-5">
        //         <div className="h-14 w-24 bg-gray-100 rounded" />
        //         <div className="h-14 w-24 bg-gray-100 rounded" />
        //       </div>
        //     </div>
        //   </ModalFooter>
        // </Modal>
      ) : category !== undefined && category["status"] === "success" ? (
        <Formik
          initialValues={FormData}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ setValues, values }) => (
            <Form>
              <Modal isOpen onClose={onClose} size="medium" placement="right">
                <ModalHeader className="bg-gray-200">
                  <Typography className="font-bold" variant="h3">
                    {category["data"] !== undefined
                      ? category["data"]["category_name"]
                      : "Create a Service Area"}
                  </Typography>
                </ModalHeader>
                <ModalBody>
                  <div className="grid grid-cols-2">
                    <div className="w-[27.5rem]">
                      <div>
                        <label className="text-400 font-medium text-gray-300 block mb-2">
                          Service Area Name
                          <span className="text-primary-100 ms-0.5">*</span>
                        </label>
                        <TextInput
                          name="category_name"
                          onChange={(event) => {
                            inputChangeHandler(event.target.value, {
                              setValues: setValues,
                              key: "category_name",
                              formData: values,
                            });
                          }}
                          placeholder="Enter service area name"
                          type="text"
                          value={values["category_name"]}
                        />
                        <ErrorMessage
                          name="category_name"
                          component="div"
                          className="error text-error text-200 mt-1"
                        />
                      </div>
                      <div className="mt-8">
                        <label className="text-400 font-medium text-gray-300 block mb-2">
                          Status
                          <span className="text-primary-100 ms-0.5">*</span>
                        </label>
                        <Field
                          name="status"
                          options={statusOptions}
                          component={CustomDropDown}
                          type="box"
                          onChange={(
                            value: SelectOptions | SelectOptions[] | null
                          ) => {
                            selectChangeHandler(value, {
                              setValues: setValues,
                              key: "status",
                              formData: values,
                            });
                          }}
                          className="custom-dropdown"
                          placeholder="Select status"
                          defaultValue={categoryStatus}
                        />
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="error text-error text-200 mt-1"
                        />
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <div className="flex justify-start gap-5">
                    <Button
                      type="submit"
                      label={
                        isFromSubmiting ? (
                          <Spinner labal="Processing..." />
                        ) : (
                          "Save"
                        )
                      }
                      isDisabled={isFromSubmiting}
                      variant="primary"
                      size="large"
                    />
                    <Button
                      type="button"
                      label="Cancel"
                      onClick={onClose}
                      variant="outline"
                      size="large"
                    />
                  </div>
                </ModalFooter>
              </Modal>
            </Form>
          )}
        </Formik>
      // ) : category !== undefined &&
      //   category["status"] === "error" &&
      //   category["error"] === 404 ? (
      //   <Modal isOpen onClose={onClose} size="medium" placement="right">
      //     <ModalHeader className="bg-gray-200">{""}</ModalHeader>
      //     <ModalBody>
      //       <ErrorAPI404 />
      //     </ModalBody>
      //     <ModalFooter>
      //       <div className="flex justify-start gap-5">
      //         <Button
      //           type="button"
      //           label="Cancel"
      //           onClick={onClose}
      //           variant="outline"
      //           size="large"
      //         />
      //       </div>
      //     </ModalFooter>
      //   </Modal>
      ) : (
        <div className="flex justify-center items-center w-full h-[calc(100%-8rem)]">
            <CategoryEmpty />
          </div>
        // <Modal isOpen onClose={onClose} size="medium" placement="right">
        //   <ModalHeader className="bg-gray-200">{""}</ModalHeader>
        //   <ModalBody>
        //     <ErrorAPI500 />
        //   </ModalBody>
        //   <ModalFooter>
        //     <div className="flex justify-start gap-5">
        //       <Button
        //         type="button"
        //         label="Cancel"
        //         onClick={onClose}
        //         variant="outline"
        //         size="large"
        //       />
        //     </div>
        //   </ModalFooter>
        // </Modal>
      )}
    </>
  );
};

export default CreateProjectModal;
