import React, { useEffect } from "react";
import Icons from "../Icons/Icon";

export interface Props {
  page: number;
  totalPages: number;
  handlePagination: (page: number) => void;
  className?: string;
  placement?: "left" | "end" | "center";
}

export const PaginationComponent: React.FC<Props> = ({
  page,
  totalPages,
  handlePagination,
  className,
  placement,
}) => {
  const buttonClasses =
    "w-10 h-8 text-200 flex items-center justify-center rounded-md font-bold hover:shadow-100 border border-gray-100 bg-white text-secondary-100 hover:border-secondary-200 disabled:shadow-0 disabled:border-secondary-100 disabled:text-white disabled:bg-secondary-100";

  useEffect(() => {
    console.log("Am I updating s1")

    if (page > totalPages) {
      handlePagination(totalPages);
    }
  }, [page, totalPages]);

  const handlePreviousPage = () => {
    console.log("Am I updating s2")
    if (page > 1) {
      handlePagination(page - 1);
    }
  };

  const handleNextPage = () => {
    console.log("Am I updating s3")
    if (page < totalPages) {
      handlePagination(page + 1);
    }
  };

  const renderPageNumbers = () => {
    console.log("Am I updating s4")
    const pageNumbers = [];

    const startPage = Math.max(1, page - 1);
    const endPage = Math.min(page + 1, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          className={`${buttonClasses} ${
            page === i ? "bg-primary-100 text-white" : ""
          }`}
          onClick={() => handlePagination(i)}
          type="button"
          disabled={page === i} // Disable the button if it represents the current page
        >
          {i}
        </button>,
      );
    }

    return pageNumbers;
  };

  return (
    <div
      className={`flex gap-2 ${
        placement === "center" ? "justify-center" : ""
      } ${placement === "end" ? "justify-end" : ""} ${
        className ? className : ""
      }`}
    >
      {page > 1 && (
        <button
          className={`${buttonClasses} `}
          onClick={handlePreviousPage}
          type="button"
        >
          <Icons name="left" variant="arrow" />
        </button>
      )}

      {renderPageNumbers()}

      {page < totalPages && (
        <button
          className={`${buttonClasses} `}
          onClick={handleNextPage}
          type="button"
        >
          <Icons name="right" variant="arrow" />
        </button>
      )}
    </div>
  );
};

export const Pagination = PaginationComponent;
